import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GetAdminDepartments, GetGroupsPermissions, UpdateGroupPermission, UpdateGroupPermissionVariables } from '../../GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';

interface Props extends Pick<GetGroupsPermissions['groupsPermissions'][number], 'id' | 'groupDepartments'> {
	close: () => unknown;
}

type Department = GetAdminDepartments['departmentsAdmin'][number];
type LocationDepartmentMap = Array<
	Pick<Department['location'], 'id' | 'name'> & {
		departments: Array<
			Pick<Department, 'id' | 'departmentNumber' | 'name'> & {
				selected: boolean;
			}
		>;
	}
>;

const GET_DEPARTMENTS = loader('../../GraphQL/Department/GetAdminDepartments.gql');
const UPDATE_GROUP_PERMISSION = loader('../../GraphQL/GroupPermission/UpdateGroupPermission.gql');
const GET_GROUPS_PERMISSIONS = loader('../../GraphQL/GroupPermission/GetGroupsPermissions.gql');

const ADGroupRequisitionDepartmentModal: React.FC<Props> = ({ close, id, groupDepartments }) => {
	const [selectedDepartments, setSelectedDepartments] = React.useState(groupDepartments);

	const { data } = useQuery<GetAdminDepartments>(GET_DEPARTMENTS);
	const [updateGroupPermission] = useMutation<UpdateGroupPermission, UpdateGroupPermissionVariables>(UPDATE_GROUP_PERMISSION);

	const locationDepartmentMap = React.useMemo(
		() =>
			(data?.departmentsAdmin ?? []).reduce<LocationDepartmentMap>((map, department) => {
				let location = map.find(l => l.id === department.location.id);
				if (typeof location === 'undefined') {
					const mapLength = map.push({
						id: department.location.id,
						name: department.location.name,
						departments: [],
					});
					location = map[mapLength - 1];
				}

				location.departments.push({
					id: department.id,
					selected: selectedDepartments.includes(department.id),
					departmentNumber: department.departmentNumber,
					name: department.name,
				});

				map.sort((a, b) => a.name.localeCompare(b.name));
				return map;
			}, []),
		[data?.departmentsAdmin, selectedDepartments],
	);

	return (
		<Modal
			title=""
			visible
			close={close}
			size={ModalSize.LARGE}
			body={
				<div className="flex flex-row flex-wrap">
					{locationDepartmentMap.map(l => (
						<div key={l.id} className="my-2 w-1/2">
							<FormFieldHeader title={l.name} labelClass="text-xl" />

							{l.departments.map(d => (
								<div key={d.id}>
									<Checkbox
										name={d.id}
										title={`${d.name} (${d.departmentNumber})`}
										checkedControlled={selectedDepartments.includes(d.id)}
										onChange={e => {
											if (e.target.checked && !selectedDepartments.includes(d.id)) {
												setSelectedDepartments([...selectedDepartments, d.id]);
											} else if (!e.target.checked && selectedDepartments.includes(d.id)) {
												setSelectedDepartments(selectedDepartments.filter(dep => dep !== d.id));
											}
										}}
									/>
								</div>
							))}
						</div>
					))}
				</div>
			}
			footer={
				<>
					<Button
						primary
						text="common.save"
						onClick={async () => {
							await updateGroupPermission({
								variables: {
									id,
									groupRequisitionDepartments: selectedDepartments,
								},
								update: (cache, { data: cacheData }): void => {
									if (typeof cacheData === 'undefined' || cacheData === null) {
										return;
									}

									const cachedRequest = cache.readQuery<GetGroupsPermissions>({
										query: GET_GROUPS_PERMISSIONS,
									});

									if (cachedRequest === null || cachedRequest.groupsPermissions === null) {
										return;
									}

									cache.writeQuery<GetGroupsPermissions>({
										query: GET_GROUPS_PERMISSIONS,
										data: {
											groupsPermissions: cachedRequest.groupsPermissions.map(grp => (grp.id === cacheData.updateGroupPermission.id ? cacheData.updateGroupPermission : grp)),
										},
									});
								},
							});
							close();
						}}
					/>
					<Button
						primary
						text="common.allowAll"
						onClick={async () => {
							await updateGroupPermission({
								variables: {
									id,
									groupRequisitionDepartments: [],
								},
								update: (cache, { data: cacheData }): void => {
									if (typeof cacheData === 'undefined' || cacheData === null) {
										return;
									}

									const cachedRequest = cache.readQuery<GetGroupsPermissions>({
										query: GET_GROUPS_PERMISSIONS,
									});

									if (cachedRequest === null || cachedRequest.groupsPermissions === null) {
										return;
									}

									cache.writeQuery<GetGroupsPermissions>({
										query: GET_GROUPS_PERMISSIONS,
										data: {
											groupsPermissions: cachedRequest.groupsPermissions.map(grp => (grp.id === cacheData.updateGroupPermission.id ? cacheData.updateGroupPermission : grp)),
										},
									});
								},
							});
							close();
						}}
					/>
				</>
			}
		/>
	);
};

export default ADGroupRequisitionDepartmentModal;
