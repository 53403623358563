import React from 'react';
import { useQuery } from '@apollo/client';
import { faPlus, faEdit, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { CollectionItemType, GetCollectionItems, GetCollectionItems_collectionItems } from '../../../GraphQL';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import TextButton from '@ssg/common/Components/TextButton';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import CollectionItemFilters from './CollectionItemFilters';
import CollectionItemModal from './CollectionItemModal';

const COLLECTION_ITEMS = loader('src/GraphQL/CollectionItems/GetCollectionItems.gql');

const CollectionItemsOverview: React.FC = () => {
	const { t } = useTranslation();

	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);

	const [createCI, setCreateCI] = React.useState(false);
	const [updateCI, setUpdateCI] = React.useState(false);
	const [deleteCI, setDeleteCI] = React.useState(false);
	const [collectionItem, setCollectionItem] = React.useState<GetCollectionItems_collectionItems>();

	const [collectionItemType, setCollectionItemType] = React.useState<CollectionItemType>();

	const { data, loading } = useQuery<GetCollectionItems>(COLLECTION_ITEMS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			type: collectionItemType,
		},
	});
	const collectionItems = React.useMemo(() => data?.collectionItems ?? [], [data]);

	const fuse = React.useMemo(
		() =>
			new Fuse(collectionItems, {
				shouldSort: true,
				threshold: 0.1,
				keys: ['name'],
			}),
		[collectionItems],
	);

	const filteredCollectionItems =
		searchTerm.length > 0
			? fuse
					.search(searchTerm)
					.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
					.map(v => v.item)
			: collectionItems;

	const [cIDropdownItems] = React.useState<SelectOption[]>(() => {
		const collectionItemTypeDropdownItems: SelectOption[] = [];
		for (const cit in CollectionItemType) {
			collectionItemTypeDropdownItems.push({
				value: cit,
				label: t(`enumCollectionItemTypes.${cit}`),
			});
		}
		return collectionItemTypeDropdownItems;
	});

	return (
		<div>
			<header className="flex">
				<div className="w-1/2">
					<CollectionItemFilters setFilterTerm={setSearchTerm} cIDropdownItems={cIDropdownItems} setCollectionItemType={setCollectionItemType} collectionItemType={collectionItemType} />
				</div>
				<div className="flex w-1/2 justify-end">
					<TextButton icon={faPlus} text="collectionItem.create" onClick={() => setCreateCI(true)} />
				</div>
			</header>

			<div className="text-blue relative bg-white pb-1">
				{loading ? (
					<div className="h-40">
						<Loading />
					</div>
				) : (
					<Table
						data={filteredCollectionItems ?? []}
						columns={[
							{
								label: 'collectionItem.text',
								selectFn: c => <p className="py-1">{c.name}</p>,
								sortFn: (a, b) => a.name.localeCompare(b.name),
							},
							{
								label: 'collectionItem.type',
								selectFn: c => <p className="py-1">{t(`enumCollectionItemTypes.${c.type}`)}</p>,
								sortFn: (a, b) => a.type.localeCompare(b.type),
							},
							{
								label: 'common.edit',
								classNameTh: 'text-right',
								selectFn: c => (
									<div className="flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faEdit}
											size="lg"
											onClick={() => {
												setCollectionItem(c);
												setUpdateCI(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
							{
								label: 'common.delete',
								classNameTh: 'text-right',
								selectFn: c => (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setCollectionItem(c);
												setDeleteCI(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
						]}
						keySelector={c => c.id}
					/>
				)}
			</div>
			{createCI && (
				<CollectionItemModal
					collectionItemType={collectionItemType}
					cIDropdownItems={cIDropdownItems}
					collectionItemIndex={collectionItems.findIndex(a => a === collectionItem)}
					open={createCI}
					close={() => setCreateCI(false)}
					submitCb={() => {
						setCreateCI(false);
					}}
				/>
			)}

			{updateCI && (
				<CollectionItemModal
					collectionItemType={collectionItemType}
					cIDropdownItems={cIDropdownItems}
					collectionItemIndex={collectionItems.findIndex(a => a === collectionItem)}
					open={updateCI}
					close={() => setUpdateCI(false)}
					edit
					data={collectionItem}
					submitCb={() => {
						setUpdateCI(false);
					}}
				/>
			)}

			{deleteCI && (
				<CollectionItemModal
					collectionItemType={collectionItemType}
					cIDropdownItems={cIDropdownItems}
					collectionItemIndex={collectionItems.findIndex(a => a === collectionItem)}
					erase
					open={deleteCI}
					close={() => setDeleteCI(false)}
					data={collectionItem}
					submitCb={() => {
						setDeleteCI(false);
					}}
				/>
			)}
		</div>
	);
};

export default CollectionItemsOverview;
