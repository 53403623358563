import { dynamicSort } from '@ssg/common/Helpers/Helpers';
import { Permissions as EnumPermissions } from '../../GraphQL/index';
import trans from '../../i18n';

export interface Permission {
	value: string;
	text: string;
	name: string;
	selected: boolean;
}

export interface Group {
	name: string;
	items: Permission[];
}

export const prefix = 'Test_BC_';

export const setEnumValue = (text: string): string => {
	const valueArray = text.split('_');

	let resultArray = '';

	valueArray.forEach(val => (resultArray += val.charAt(0) + val.toLowerCase().slice(1)));

	return resultArray.trim();
};

export const setLabelText = (text: string): string => {
	const result = text.replace(prefix, '').replaceAll('_', ' ');

	return result.charAt(0).toUpperCase() + result.toLowerCase().slice(1);
};

export const getGroupPermissionsList = (groupPermissions: string[]): Group[] => {
	const inputTypesAsString: string[] = [];
	const result: Group[] = [];
	for (const gp in EnumPermissions) {
		inputTypesAsString.push(gp);
	}

	const caseArray: Permission[] = [];
	const administrationArray: Permission[] = [];
	const catalogArray: Permission[] = [];
	const kpiArray: Permission[] = [];
	const planningArray: Permission[] = [];
	const machineArray: Permission[] = [];
	const movablesArray: Permission[] = [];
	const taskArray: Permission[] = [];
	const timeArray: Permission[] = [];
	const myPageArray: Permission[] = [];
	const drivingSlipArray: Permission[] = [];
	const requisitionArray: Permission[] = [];

	const selectedValues: string[] = [];

	groupPermissions.map(perm => selectedValues.push(perm));

	inputTypesAsString.forEach(value => {
		if (value.startsWith('CASES_')) {
			caseArray.push({
				value: setEnumValue(value),
				name: value,
				text: trans.t(`enumPermissions.${value}`),
				selected: selectedValues.find(selectedValue => selectedValue.toLowerCase() === value.toLowerCase()) !== undefined,
			});
		}
		if (value.startsWith('ADMINISTRATION_')) {
			administrationArray.push({
				value: setEnumValue(value),
				name: value,
				text: trans.t(`enumPermissions.${value}`),
				selected: selectedValues.find(selectedValue => selectedValue.toLowerCase() === value.toLowerCase()) !== undefined,
			});
		}
		if (value.startsWith('CATALOGS_')) {
			catalogArray.push({
				value: setEnumValue(value),
				name: value,
				text: trans.t(`enumPermissions.${value}`),
				selected: selectedValues.find(selectedValue => selectedValue.toLowerCase() === value.toLowerCase()) !== undefined,
			});
		}
		if (value.startsWith('DRIVING_')) {
			drivingSlipArray.push({
				value: setEnumValue(value),
				name: value,
				text: trans.t(`enumPermissions.${value}`),
				selected: selectedValues.find(selectedValue => selectedValue.toLowerCase() === value.toLowerCase()) !== undefined,
			});
		}
		if (value.startsWith('KP_')) {
			kpiArray.push({
				value: setEnumValue(value),
				name: value,
				text: trans.t(`enumPermissions.${value}`),
				selected: selectedValues.find(selectedValue => selectedValue.toLowerCase() === value.toLowerCase()) !== undefined,
			});
		}
		if (value.startsWith('PLANNING_')) {
			planningArray.push({
				value: setEnumValue(value),
				name: value,
				text: trans.t(`enumPermissions.${value}`),
				selected: selectedValues.find(selectedValue => selectedValue.toLowerCase() === value.toLowerCase()) !== undefined,
			});
		}
		if (value.startsWith('MACHINES_')) {
			machineArray.push({
				value: setEnumValue(value),
				name: value,
				text: trans.t(`enumPermissions.${value}`),
				selected: selectedValues.find(selectedValue => selectedValue.toLowerCase() === value.toLowerCase()) !== undefined,
			});
		}
		if (value.startsWith('MOVABLES_')) {
			movablesArray.push({
				value: setEnumValue(value),
				name: value,
				text: trans.t(`enumPermissions.${value}`),
				selected: selectedValues.find(selectedValue => selectedValue.toLowerCase() === value.toLowerCase()) !== undefined,
			});
		}
		if (value.startsWith('MY_PAGE')) {
			myPageArray.push({
				value: setEnumValue(value),
				name: value,
				text: trans.t(`enumPermissions.${value}`),
				selected: selectedValues.find(selectedValue => selectedValue.toLowerCase() === value.toLowerCase()) !== undefined,
			});
		}
		if (value.startsWith('TASKS_')) {
			taskArray.push({
				value: setEnumValue(value),
				name: value,
				text: trans.t(`enumPermissions.${value}`),
				selected: selectedValues.find(selectedValue => selectedValue.toLowerCase() === value.toLowerCase()) !== undefined,
			});
		}
		if (value.startsWith('TIME_')) {
			timeArray.push({
				value: setEnumValue(value),
				name: value,
				text: trans.t(`enumPermissions.${value}`),
				selected: selectedValues.find(selectedValue => selectedValue.toLowerCase() === value.toLowerCase()) !== undefined,
			});
		}

		if (value.startsWith('REQUISITION_')) {
			requisitionArray.push({
				value: setEnumValue(value),
				name: value,
				text: trans.t(`enumPermissions.${value}`),
				selected: selectedValues.find(selectedValue => selectedValue.toLowerCase() === value.toLowerCase()) !== undefined,
			});
		}
	});

	result.push({ name: trans.t('groupPermissions.cases'), items: caseArray });
	result.push({
		name: trans.t('groupPermissions.administration'),
		items: administrationArray,
	});
	result.push({
		name: trans.t('groupPermissions.catalogs'),
		items: catalogArray,
	});
	result.push({
		name: trans.t('groupPermissions.drivingSlips'),
		items: drivingSlipArray,
	});
	result.push({
		name: trans.t('groupPermissions.kpi'),
		items: kpiArray,
	});
	result.push({
		name: trans.t('groupPermissions.planning'),
		items: planningArray,
	});
	result.push({
		name: trans.t('groupPermissions.machines'),
		items: machineArray,
	});
	result.push({
		name: trans.t('groupPermissions.movables'),
		items: movablesArray,
	});
	result.push({
		name: trans.t('groupPermissions.myPage'),
		items: myPageArray,
	});
	result.push({
		name: trans.t('groupPermissions.tasks'),
		items: taskArray,
	});
	result.push({
		name: trans.t('groupPermissions.timeTracking'),
		items: timeArray,
	});
	result.push({
		name: trans.t('groupPermissions.requisitions'),
		items: requisitionArray,
	});

	result.sort(dynamicSort('name'));

	return result;
};
