import { faFileCsv } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@ssg/common/Components/Button';
import { useGetCasesNotPostedInBcLazyQuery } from '@ssg/common/GraphQL/indexV2';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

const CheckNotPostedInBc: React.FC = (): React.ReactElement => {

	const { t } = useTranslation();
	const [getCases, { data, loading }] = useGetCasesNotPostedInBcLazyQuery({
		onCompleted: data => {
			console.log(data);
		},
		onError: error => {
			console.log(error);
		},
	});
	return (
		<div>
			{typeof data === 'undefined' &&
				<Button primary text="invoices.getNotPostedInBc" onClick={() => getCases()} loading={loading} />
			}
			{data &&
				<CSVLink
					data={data?.casesNotPostedInBc ?? []}
					separator={';'}
					filename={`invoicesNotPostedInBC-${formatDate(new Date())}.csv`}
					className="text-hover-blue text-sm font-medium focus:outline-none text-blue"
				>
					<FontAwesomeIcon icon={faFileCsv} className="mr-2" />
					{t('invoices.downloadNotPostedInBc')}
				</CSVLink>
			}
		</div>
	);
};

export default CheckNotPostedInBc;