import React from 'react';
import Button from '@ssg/common/Components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faFileCsv } from '@fortawesome/pro-regular-svg-icons';
import { CSVLink } from 'react-csv';
import { formatDate } from '@ssg/common/Helpers/Helpers';

interface Props {
	data: string;
}

const LogExport: React.FC<Props> = ({ data }): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<Button link>
			<CSVLink
				data={data}
				separator={';'}
				filename={`${t('log.headerTitle')}-${formatDate(new Date())}.csv`}
				className="rounded-default flex items-center text-sm font-medium focus:outline-none"
			>
				<FontAwesomeIcon icon={faFileCsv} className="mr-2" />
				{t('log.exportForExcel')}
			</CSVLink>
		</Button>
	);
};

export default LogExport;
