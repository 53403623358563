import BoxContainer from '@ssg/common/Components/BoxContainer';
import React from 'react';
import Box from '../../Components/Layout/Box';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GetCaseInvoices, GetCaseInvoicesVariables, GetAdminCases, GetAdminCasesVariables, GetAdminCases_cases, DeleteInvoiceAdminVariables, DeleteInvoiceAdmin } from '../../GraphQL';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import { useForm } from 'react-hook-form';
import Button from '@ssg/common/Components/Button';
import Table from '@ssg/common/Components/Table';
import Alert from '@ssg/common/Components/Alert';
import { useTranslation } from 'react-i18next';
import { faCheckCircle, faExclamationCircle, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import TextButton from '@ssg/common/Components/TextButton';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';

const GET_CASES = loader('src/GraphQL/Cases/GetAdminCases.gql');
const GET_CASE_INVOICES = loader('src/GraphQL/Invoices/GetCaseInvoices.gql');
const DELETE_INVOICE_ADMIN = loader('src/GraphQL/Invoices/DeleteInvoiceAdmin.gql');

const DeleteBrokenInvoice: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();
	const [casesSearchText, setCasesSearchText] = React.useState('');
	const [selectedCase, setSelectedCase] = React.useState<GetAdminCases_cases | undefined>();
	const [showDeleteModal, setShowDeleteModal] = React.useState<string | undefined>(undefined);
	const [deletedInvoice, setDeletedInvoice] = React.useState<string | undefined>(undefined);

	const { data, loading } = useQuery<GetAdminCases, GetAdminCasesVariables>(GET_CASES, {
		fetchPolicy: 'cache-and-network',
		variables: { erpReferenceNo: casesSearchText },
		skip: casesSearchText.length === 0,
	});

	const [fetchInvoices, { data: invoices, loading: loadingInvoices }] = useLazyQuery<GetCaseInvoices, GetCaseInvoicesVariables>(GET_CASE_INVOICES);

	const [deleteInvoiceAdminMutation, { loading: loadingDelete }] = useMutation<DeleteInvoiceAdmin, DeleteInvoiceAdminVariables>(DELETE_INVOICE_ADMIN);

	const submitDelete = async (caseId: string, invoiceERPReference: string) => {
		const res = await deleteInvoiceAdminMutation({
			variables: {
				caseId,
				invoiceERPReference,
			},
			update: (cache, { data: cacheData }): void => {
				if (typeof cacheData === 'undefined' || cacheData === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetCaseInvoices, GetCaseInvoicesVariables>({
					query: GET_CASE_INVOICES,
					variables: { caseId: selectedCase?.id ?? '' },
				});

				if (cachedRequest === null || cachedRequest.invoices === null) {
					return;
				}

				cache.writeQuery<GetCaseInvoices, GetCaseInvoicesVariables>({
					query: GET_CASE_INVOICES,
					variables: { caseId: selectedCase?.id ?? '' },
					data: {
						invoices: cachedRequest.invoices.filter(d => d.erpReferenceNo !== invoiceERPReference),
					},
				});
			},
		});
		if (res.data) {
			setDeletedInvoice(res.data.deleteInvoiceAdmin);
		}
		setShowDeleteModal(undefined);
	};

	return (
		<BoxContainer>
			<Box title="invoices.deleteHelper" full>
				<div className="flex flex-row items-end space-x-2">
					<div>
						<SearchableSelect
							name="caseId"
							title="invoices.caseNo"
							searchFn={searchText => setCasesSearchText(searchText)}
							onSelect={value => {
								const thisCase = data?.cases.find(c => c.id === value);
								setSelectedCase(thisCase);
							}}
							onBlur={() => undefined}
							minInputLength={2}
							options={(data?.cases ?? []).map(c => ({
								label: c.erpNo,
								value: c.id,
							}))}
							isLoading={loading}
							control={useForm().control}
							required
						/>
					</div>
					<div>
						<Button
							primary
							text="invoices.fetch"
							loading={loadingInvoices}
							disabled={typeof selectedCase === 'undefined'}
							onClick={() =>
								fetchInvoices({
									variables: {
										caseId: selectedCase?.id ?? '',
									},
								})
							}
						/>
					</div>
				</div>
				{deletedInvoice && (
					<div className="py-2">
						<Alert
							success
							text={t('invoices.deleteSuccess', {
								invoice: deletedInvoice,
							})}
							icon={faCheckCircle}
						/>
					</div>
				)}
				<Table
					data={invoices?.invoices ?? []}
					keySelector={i => i.erpReferenceNo}
					loading={loadingInvoices}
					noDataFoundText={invoices ? 'invoices.none' : 'invoices.selectCase'}
					columns={[
						{
							label: 'invoices.no',
							selectFn: i => i.erpReferenceNo,
						},
						{
							label: 'invoices.types',
							selectFn: i => (
								<>
									<p>{t('invoices.categories.' + i.category)}</p>
									{i.screeningERPReferenceNoList?.map(s => <span>{s} </span>)}
								</>
							),
						},
						{
							label: 'invoices.status',
							selectFn: i => <p>{t('invoices.statuses.' + i.status)}</p>,
						},
						{
							label: 'invoices.lines',
							selectFn: i => {
								if (i.lines.length === 0) return <p className="font-bold">{t('invoices.noLines')}</p>;
								return (
									<div>
										{i.lines.map((l, index) => (
											<p>
												<span>{index}. </span>
												{l.description}
											</p>
										))}
									</div>
								);
							},
						},
						{
							label: '',
							selectFn: i => <TextButton icon={faTrashAlt} className="text-red ml-auto mr-0" onClick={() => setShowDeleteModal(i.erpReferenceNo)} />,
						},
					]}
				/>
			</Box>
			{selectedCase && showDeleteModal && (
				<Modal
					size={ModalSize.MEDIUM}
					close={() => setShowDeleteModal(undefined)}
					title={t('invoices.modalTitle', {
						case: selectedCase.erpNo,
						invoice: showDeleteModal,
					})}
					visible={typeof selectedCase !== 'undefined' && typeof showDeleteModal !== 'undefined'}
					body={
						<div>
							<Alert danger text="invoices.deleteWarning" icon={faExclamationCircle} />
							<div className="flex justify-between">
								<Button
									danger
									loading={loadingDelete}
									text={t('invoices.deleteButtonText', {
										invoice: showDeleteModal,
									})}
									className="mt-3"
									onClick={() => submitDelete(selectedCase.id, showDeleteModal)}
								/>
								<Button secondary text="common.cancel" className="mt-3" onClick={() => setShowDeleteModal(undefined)} />
							</div>
						</div>
					}
				/>
			)}
		</BoxContainer>
	);
};

export default DeleteBrokenInvoice;
