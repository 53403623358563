import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Header from '@ssg/common/Components/Header';
import CatalogCustomers from './CatalogCustomers';
import CatalogSettings from './CatalogSettings';

const tabs: string[] = ['catalog.customer.title', 'catalogNotification.overviewTitle'];

const CatalogTabs: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();

	const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);

	return (
		<div>
			<Header title="catalog.title">
				<div className="flex">
					<div className="flex w-3/4 justify-start">
						{tabs.map((title, i) => {
							return (
								<div className="mr-3 mt-1" key={i}>
									<button
										type="button"
										onClick={() => setActiveTab(title)}
										className={classNames('text-blue focus:outline-none', {
											'border-blue border-b-2': title === activeTab,
										})}
									>
										{t(title)}
									</button>
								</div>
							);
						})}
					</div>
				</div>
			</Header>
			<div>
				{activeTab === 'catalog.customer.title' && <CatalogCustomers />}
				{activeTab === 'catalogNotification.overviewTitle' && <CatalogSettings />}
			</div>
		</div>
	);
};

export default CatalogTabs;
