import React, { Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { CollectionItemType } from '../../../GraphQL';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Input from '@ssg/common/Components/Input';
import Dropdown from '@ssg/common/Components/Dropdown';

interface IProps {
	setFilterTerm: (v: string) => void;
	cIDropdownItems: SelectOption[];
	setCollectionItemType: Dispatch<SetStateAction<CollectionItemType | undefined>>;
	collectionItemType: CollectionItemType | undefined;
}

const CollectionItemFilters: React.FC<IProps> = ({ setFilterTerm, cIDropdownItems, setCollectionItemType, collectionItemType }) => {
	const { t } = useTranslation();

	const [dropdownItems, setDropdownItems] = React.useState<SelectOption[]>();
	React.useEffect(() => {
		if (cIDropdownItems) {
			setDropdownItems([{ label: '', value: '' }, ...cIDropdownItems]);
		}
	}, [cIDropdownItems]);

	return (
		<div className="mr-6 flex flex-col flex-wrap lg:flex-row">
			<div className="mb-3 mr-3">
				<label className="text-blue mb-1 block text-xs font-medium">{t('collectionItem.filters.search')}</label>
				<div className="flex">
					<div className="relative flex">
						<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
						<Input
							name="collectionItem-search"
							placeholder={t('collectionItem.searchPlaceholder')}
							className="pr-8 text-sm lg:w-full"
							onChange={(e): void => setFilterTerm(e.target.value)}
						/>
					</div>
				</div>
			</div>
			<div className="mb-3 mr-3 w-1/3">
				<label className="text-blue mb-1 block text-xs font-medium">{t('collectionItem.type')}</label>
				<div className="flex">
					<Dropdown
						name="type"
						data={dropdownItems}
						required
						onChange={e => {
							if (e.target.value === '') {
								setCollectionItemType(undefined);
							} else {
								setCollectionItemType(CollectionItemType[e.target.value as string as keyof typeof CollectionItemType]);
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default CollectionItemFilters;
