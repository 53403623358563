import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { CreateSettings, CreateSettingsVariables, GetSettings, GetSettings_setting_SMSSettings, UpdateSettings, UpdateSettingsVariables } from '../../GraphQL';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import Loading from '@ssg/common/Components/Loading';

const GET_SETTINGS = loader('../../GraphQL/AdminSettings/GetSettings.gql');
const CREATE_SETTING = loader('../../GraphQL/AdminSettings/CreateSettings.gql');
const UPDATE_SETTING = loader('../../GraphQL/AdminSettings/UpdateSettings.gql');

const SmsTab: React.FC = () => {
	const [visible, setVisible] = React.useState(false);

	const [createSetting] = useMutation<CreateSettings, CreateSettingsVariables>(CREATE_SETTING);
	const [updateSetting] = useMutation<UpdateSettings, UpdateSettingsVariables>(UPDATE_SETTING);

	const { data, loading, refetch } = useQuery<GetSettings>(GET_SETTINGS, {
		variables: {
			type: 'SMS',
		},
	});

	const smsSettings = data?.setting[0];

	const { handleSubmit, register } = useForm();

	const onSubmit = async (setting: GetSettings_setting_SMSSettings) => {
		if (data?.setting[0].id) {
			try {
				await updateSetting({
					variables: {
						id: data.setting[0].id,
						smsSetting: {
							type: 'SMS',
							endpoint: setting.endpoint,
							originator: setting.originator,
							username: setting.username,
							password: setting.password,
						},
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await createSetting({
					variables: {
						smsSetting: {
							type: 'SMS',
							endpoint: setting.endpoint,
							originator: setting.originator,
							username: setting.username,
							password: setting.password,
						},
					},
				});
			} catch (e) {
				console.log(e);
			}
		}

		refetch();
	};

	return (
		<div className="text-blue h-full px-3 pb-3">
			<form onSubmit={handleSubmit(onSubmit)}>
				{loading && typeof data == 'undefined' ? (
					<div className="relative h-40">
						<Loading />
					</div>
				) : (
					<div className="flex flex-col">
						<Input
							title="integrations.endpoint"
							name={'endpoint'}
							innerRef={register}
							defaultValue={typeof smsSettings !== 'undefined' && 'endpoint' in smsSettings ? smsSettings.endpoint : ''}
						/>

						<div className="mr-4 w-1/2">
							<Input
								title="integrations.originator"
								name={'originator'}
								innerRef={register}
								defaultValue={typeof smsSettings !== 'undefined' && 'originator' in smsSettings ? smsSettings.originator : ''}
							/>

							<Input
								title="integrations.username"
								name={'username'}
								innerRef={register}
								defaultValue={typeof smsSettings !== 'undefined' && 'username' in smsSettings ? smsSettings.username : ''}
							/>

							<div className="relative">
								<div className="pointer-events-none absolute mt-8 flex content-start justify-end pr-2 lg:w-full">
									<FontAwesomeIcon icon={visible ? faEyeSlash : faEye} onClick={() => setVisible(!visible)} className="pointer-events-auto" />
								</div>
								<Input
									title="integrations.password"
									name={'password'}
									innerRef={register}
									type={visible ? 'text' : 'password'}
									defaultValue={typeof smsSettings !== 'undefined' && 'password' in smsSettings ? smsSettings.password : ''}
									className="z-50 pr-8 text-sm lg:w-full"
									// onChange={(e): void => setFilterTerm(e.target.value)}
								/>
							</div>

							<Button submit success text="common.save" className="mt-4" />
						</div>
					</div>
				)}
			</form>
		</div>
	);
};

export default SmsTab;
