import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import {
	CreateSettings,
	CreateSettingsVariables,
	DrivingSlipCategoryMappingInput,
	DrivingSlipCategoryMappingSettingsInput,
	GetAdminDamageCategories,
	GetAdminDamageCauses,
	GetDrivingSlipCategories,
	GetSettings,
	GetSettings_setting_DrivingSlipCategoryMappingSettings,
	UpdateSettings,
	UpdateSettingsVariables,
} from '../../../GraphQL';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import { useTranslation } from 'react-i18next';

import { removeTypenameGeneric } from '@ssg/common/Helpers/typenameHelper';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Table from '@ssg/common/Components/Table';
import TextButton from '@ssg/common/Components/TextButton';
import { toTitleCase } from '../../Integrations/In4mo/DamageMappings';

const GET_SETTINGS = loader('../../../GraphQL/AdminSettings/GetSettings.gql');
const CREATE_SETTING = loader('../../../GraphQL/AdminSettings/CreateSettings.gql');
const UPDATE_SETTING = loader('../../../GraphQL/AdminSettings/UpdateSettings.gql');

const DrivingSlipCategoryMappings: React.FC = () => {
	const { t } = useTranslation();

	const [createSetting, { loading: createSettingLoading }] = useMutation<CreateSettings, CreateSettingsVariables>(CREATE_SETTING);
	const [updateSetting, { loading: updateSettingLoading }] = useMutation<UpdateSettings, UpdateSettingsVariables>(UPDATE_SETTING);

	const [settings, setSettings] = React.useState<DrivingSlipCategoryMappingSettingsInput>({
		type: 'DrivingSlipCategoryMappings',
		drivingSlipCategoryMappings: [],
	});

	const { control: drivingSlipCategoryMappingsControl } = useForm();

	const onSubmit = async () => {
		if (settings.drivingSlipCategoryMappings.some(x => !x.drivingSlipCategory)) {
			const message = `${t('dcm.drivingSlipCategory')} ${t('dcm.cannotBeEmptyLowercase')}`;
			alert(message);
			return;
		}

		if (settings.drivingSlipCategoryMappings.some(x => !x.damageCauseId)) {
			const message = `${t('dcm.damageCause')} ${t('dcm.cannotBeEmptyLowercase')}`;
			alert(message);
			return;
		}

		if (settings.drivingSlipCategoryMappings.some(x => !x.damageCategoryId)) {
			const message = `${t('dcm.damageCategory')} ${t('dcm.cannotBeEmptyLowercase')}`;
			alert(message);
			return;
		}

		const seen = [] as DrivingSlipCategoryMappingInput[];

		const duplicates = new Set();
		settings.drivingSlipCategoryMappings.filter(item => {
			const duplicate = seen.find(x => x.damageCategoryId === item.damageCategoryId && x.damageCauseId === item.damageCauseId);
			if (duplicate) duplicates.add(item);
			seen.push(item);
			return duplicate;
		});

		if (duplicates.size >= 1) {
			alert(t('error.duplicatesNotAllowed'));
			return;
		}

		const santizied = removeTypenameGeneric(settings);
		const settingsInput = {
			type: santizied.type,
			drivingSlipCategoryMappings: santizied.drivingSlipCategoryMappings,
		} as DrivingSlipCategoryMappingSettingsInput;

		console.log('data', settingsInput);
		if (exisitingDCSMSettings?.id) {
			await updateSetting({
				variables: {
					id: exisitingDCSMSettings.id,
					drivingSlipCategoryMappingSettings: settingsInput,
				},
			});
		} else {
			await createSetting({
				variables: { drivingSlipCategoryMappingSetting: settingsInput },
			});
		}

		refetch();
	};

	const GET_DAMAGE_CATEGORIES = loader('src/GraphQL/DamageCategory/GetAdminDamageCategories.gql');
	const GET_DAMAGE_CAUSES = loader('src/GraphQL/DamageCause/GetAdminDamageCauses.gql');
	const GET_DRIVING_SLIP_CATEGORIES = loader('src/GraphQL/DrivingSlipCategory/GetDrivingSlipCategory.gql');

	const { data: drivingSlipCategory, loading: loadingDSC } = useQuery<GetDrivingSlipCategories>(GET_DRIVING_SLIP_CATEGORIES);
	const { data: category } = useQuery<GetAdminDamageCategories>(GET_DAMAGE_CATEGORIES);
	const { data: cause } = useQuery<GetAdminDamageCauses>(GET_DAMAGE_CAUSES);

	const drivingSlipCategoriesOptions = useMemo(() => {
		if (drivingSlipCategory?.drivingSlipCategories) {
			return drivingSlipCategory?.drivingSlipCategories.map(type => ({
				label: toTitleCase(type.name),
				value: type.name,
			}));
		}

		return [] as SelectOption[];
	}, [drivingSlipCategory?.drivingSlipCategories]);

	const damageCategoryOptions = useMemo(() => {
		if (category?.damageCategoriesAdmin) {
			return category?.damageCategoriesAdmin.map(type => ({
				label: toTitleCase(type.name),
				value: type.id,
			}));
		}

		return [] as SelectOption[];
	}, [category?.damageCategoriesAdmin]);

	const damageCauseOptions = useMemo(() => {
		if (cause?.damageCausesAdmin) {
			return cause?.damageCausesAdmin.map(type => ({
				label: toTitleCase(type.name),
				value: type.id,
			}));
		}

		return [] as SelectOption[];
	}, [cause?.damageCausesAdmin]);

	/*
	 * fetching settings
	 */
	const { data, loading, refetch } = useQuery<GetSettings>(GET_SETTINGS, {
		variables: {
			type: 'DrivingSlipCategoryMappings',
		},
	});

	const exisitingDCSMSettings = useMemo(() => {
		if (data?.setting && data.setting.length > 0) {
			const setting = data.setting[0] as GetSettings_setting_DrivingSlipCategoryMappingSettings;
			setSettings(setting);
			return setting;
		}
	}, [data]);

	return (
		<div className="text-blue">
			<header>
				<div className="flex justify-end">
					{!loading && (
						<Button
							primary
							text="common.save"
							icon={faSave}
							onClick={onSubmit}
							disabled={createSettingLoading || updateSettingLoading}
							loading={createSettingLoading || updateSettingLoading}
						/>
					)}
				</div>
			</header>

			{loading ? (
				<div className="relative h-40">
					<Loading />
				</div>
			) : (
				<Table
					style={{ minHeight: '400px' }} // className="min-h-[400px]" not working
					data={settings.drivingSlipCategoryMappings ?? []}
					columns={[
						{
							label: 'dcm.drivingSlipCategory',
							selectFn: (m, i) => {
								const initialSelected = drivingSlipCategoriesOptions.find(x => x.value === m.drivingSlipCategory);

								return (
									<SearchableSelect
										name="drivingSlipCategoryMappings.drivingSlipCategory"
										control={drivingSlipCategoryMappingsControl}
										options={drivingSlipCategoriesOptions}
										searchFn={searchText => console.log(searchText)}
										onSelect={value => {
											const dsc = value;
											if (!dsc) return;

											const alreadyExists = exisitingDCSMSettings?.drivingSlipCategoryMappings.find(x => x.drivingSlipCategory === dsc);
											if (alreadyExists) {
												alert(t('dcm.drivingSlipCategoryAlreadyUsed'));
												return;
											}

											return setSettings(current => ({
												...current,
												drivingSlipCategoryMappings: [
													...current.drivingSlipCategoryMappings.slice(0, i),
													{
														...current.drivingSlipCategoryMappings[i],
														drivingSlipCategory: dsc,
													},
													...current.drivingSlipCategoryMappings.slice(i + 1),
												],
											}));
										}}
										onBlur={() => undefined}
										minInputLength={-1}
										isLoading={loadingDSC}
										initialSelection={initialSelected}
									/>
								);
							},

							noTruncate: true,
						},
						{
							label: 'dcm.damageCategory',
							selectFn: (m, i) => {
								const initialSelected = damageCategoryOptions.find(x => x.value === m.damageCategoryId);
								return (
									<SearchableSelect
										name="drivingSlipCategoryMappings.damageCategoryId"
										control={drivingSlipCategoryMappingsControl}
										options={damageCategoryOptions}
										searchFn={searchText => console.log(searchText)}
										onSelect={value => {
											const dsc = value;
											if (!dsc) return;

											return setSettings(current => ({
												...current,
												drivingSlipCategoryMappings: [
													...current.drivingSlipCategoryMappings.slice(0, i),
													{
														...current.drivingSlipCategoryMappings[i],
														damageCategoryId: dsc,
													},
													...current.drivingSlipCategoryMappings.slice(i + 1),
												],
											}));
										}}
										onBlur={() => undefined}
										minInputLength={-1}
										isLoading={loadingDSC}
										initialSelection={initialSelected}
									/>
								);
							},

							noTruncate: true,
						},
						{
							label: 'dcm.damageCause',
							selectFn: (m, i) => {
								const initialSelected = damageCauseOptions.find(x => x.value === m.damageCauseId);
								return (
									<SearchableSelect
										name="drivingSlipCategoryMappings.damageCauseId"
										control={drivingSlipCategoryMappingsControl}
										options={damageCauseOptions}
										searchFn={searchText => console.log(searchText)}
										onSelect={value => {
											const dsc = value;
											if (!dsc) return;

											return setSettings(current => ({
												...current,
												drivingSlipCategoryMappings: [
													...current.drivingSlipCategoryMappings.slice(0, i),
													{
														...current.drivingSlipCategoryMappings[i],
														damageCauseId: dsc,
													},
													...current.drivingSlipCategoryMappings.slice(i + 1),
												],
											}));
										}}
										onBlur={() => undefined}
										minInputLength={-1}
										isLoading={loadingDSC}
										initialSelection={initialSelected}
									/>
								);
							},

							noTruncate: true,
						},

						{
							label: 'common.delete',
							classNameTh: 'text-right',
							selectFn: (m, i) => (
								<div className="text-red flex content-start justify-end text-right">
									<FontAwesomeIcon
										className="cursor-pointer"
										icon={faTrashAlt}
										size="lg"
										onClick={() => {
											const confirm = window.confirm(t('common.wantToDelete'));
											if (confirm) {
												setSettings(current => ({
													...current,
													drivingSlipCategoryMappings: [...current.drivingSlipCategoryMappings.slice(0, i), ...current.drivingSlipCategoryMappings.slice(i + 1)],
												}));
											}
										}}
									/>
								</div>
							),
						},
					]}
					keySelector={(m, i) => String(i)}
					noDataFoundText="dcm.noAdcmsFound"
				/>
			)}

			{!loading && (
				<TextButton
					icon={faPlus}
					text="common.add"
					className="mt-1"
					onClick={() =>
						setSettings(current => ({
							...current,
							drivingSlipCategoryMappings: [
								...current.drivingSlipCategoryMappings,
								{
									drivingSlipCategory: '',
									damageCategoryId: '',
									damageCauseId: '',
								},
							],
						}))
					}
				/>
			)}
		</div>
	);
};

export default DrivingSlipCategoryMappings;
