import React from 'react';
import { useQuery } from '@apollo/client';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { GetAdminDamageCauses, GetAdminDamageCauses_damageCausesAdmin } from '../../../GraphQL';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import DamageCauseModal from './DamageCauseModal';
import classNames from 'classnames';

const DAMAGE_CAUSE = loader('src/GraphQL/DamageCause/GetAdminDamageCauses.gql');

const DamageCause: React.FC = () => {
	const { t } = useTranslation();

	const [createCause, setCreateCause] = React.useState(false);
	const [updateCause, setUpdateCause] = React.useState(false);
	const [deleteCause, setDeleteCause] = React.useState(false);

	const [causeData, setCauseData] = React.useState<GetAdminDamageCauses_damageCausesAdmin>();

	const { data, loading, refetch } = useQuery<GetAdminDamageCauses>(DAMAGE_CAUSE);

	return (
		<div>
			<header className="flex">
				<div className="w-1/2"></div>
				<div className="flex w-1/2 justify-end">
					<Button primary text="damageCause.create" icon={faPlus} onClick={() => setCreateCause(true)} className="mb-2" />
				</div>
			</header>

			<div className="text-blue relative bg-white pb-1">
				{loading ? (
					<div className="h-40">
						<Loading />
					</div>
				) : (
					<Table
						data={data?.damageCausesAdmin ?? []}
						columns={[
							{
								label: 'common.name',
								selectFn: c => <p>{c.name}</p>,
								sortFn: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
							},
							{
								label: 'damageCause.category',
								selectFn: c => <p>{c.category.name}</p>,
								sortFn: (a, b) => (a.category.name ?? '').localeCompare(b.category.name ?? ''),
							},
							{
								label: 'damageCause.area',
								selectFn: c => <p>{c.businessArea.name}</p>,
								sortFn: (a, b) => (a.businessArea.name ?? '').localeCompare(b.businessArea.name ?? ''),
							},
							{
								label: 'damageCause.track',
								selectFn: c => <p>{c.track}</p>,
							},
							{
								label: 'damageCause.urgent',
								selectFn: c => (
									<p
										className={classNames({
											'font-bold': c.urgent,
										})}
									>
										{c.urgent ? t('common.yes') : t('common.no')}
									</p>
								),
								sortFn: (a, b) => (a.urgent === b.urgent ? 0 : a.urgent ? -1 : 1),
							},
							{
								label: 'damageCause.departments',
								selectFn: c => (
									<div className="py-1">
										{c.departments?.map((d, i) => {
											return (
												<span>
													{i ? ', ' : ''}
													{d?.name}
												</span>
											);
										})}
									</div>
								),
								noTruncate: true,
							},
							{
								label: 'common.edit',
								classNameTh: 'text-right',
								selectFn: c => (
									<div className="flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faEdit}
											size="lg"
											onClick={() => {
												setCauseData(c);
												setUpdateCause(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
							{
								label: 'common.delete',
								classNameTh: 'text-right',
								selectFn: c => (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setCauseData(c);
												setDeleteCause(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
						]}
						keySelector={c => c.id}
					/>
				)}
			</div>

			{createCause && (
				<DamageCauseModal
					open={createCause}
					close={() => setCreateCause(false)}
					submitCb={() => {
						refetch();
						setCreateCause(false);
					}}
				/>
			)}

			{updateCause && (
				<DamageCauseModal
					edit
					open={updateCause}
					close={() => setUpdateCause(false)}
					data={causeData}
					submitCb={() => {
						refetch();
						setUpdateCause(false);
					}}
				/>
			)}

			{deleteCause && (
				<DamageCauseModal
					erase
					open={deleteCause}
					close={() => setDeleteCause(false)}
					data={causeData}
					submitCb={() => {
						refetch();
						setDeleteCause(false);
					}}
				/>
			)}
		</div>
	);
};

export default DamageCause;
