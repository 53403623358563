import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '../../../Components/Layout/Box';
import {
	CollectionItemType,
	CreateCollectionItem,
	CreateCollectionItemVariables,
	CreateCollectionItem_createCollectionItem,
	DeleteCollectionItem,
	DeleteCollectionItemVariables,
	GetCollectionItems,
	GetCollectionItemsVariables,
	GetCollectionItems_collectionItems,
	UpdateCollectionItem,
	UpdateCollectionItemVariables,
} from '../../../GraphQL';
import { CollectionItemSchema } from '../../../Schemas/CollectionItemSchema';

const GET_COLLECTION_ITEMS = loader('src/GraphQL/CollectionItems/GetCollectionItems.gql');
const UPDATE_COLLECTION_ITEM = loader('src/GraphQL/CollectionItems/UpdateCollectionItem.gql');
const DELETE_COLLECTION_ITEM = loader('src/GraphQL/CollectionItems/DeleteCollectionItem.gql');
const CREATE_COLLECTION_ITEM = loader('src/GraphQL/CollectionItems/CreateCollectionItem.gql');

interface IProps {
	open: boolean;
	close: () => void;
	edit?: boolean;
	erase?: boolean;
	data?: GetCollectionItems_collectionItems;
	submitCb?: () => unknown;
	collectionItemIndex: number;
	collectionItemType?: CollectionItemType;
	cIDropdownItems: SelectOption[];
}

const CollectionItemModal: React.FC<IProps> = ({ open, close, edit = false, erase = false, data, submitCb, collectionItemIndex, collectionItemType, cIDropdownItems }) => {
	const { t } = useTranslation();

	const [updateCollectionItem] = useMutation<UpdateCollectionItem, UpdateCollectionItemVariables>(UPDATE_COLLECTION_ITEM);
	const [createCollectionItem] = useMutation<CreateCollectionItem, CreateCollectionItemVariables>(CREATE_COLLECTION_ITEM);
	const [deleteCollectionItem] = useMutation<DeleteCollectionItem, DeleteCollectionItemVariables>(DELETE_COLLECTION_ITEM);

	const { handleSubmit, register, errors } = useForm<CreateCollectionItem_createCollectionItem>({
		resolver: yupResolver(CollectionItemSchema),
	});

	const onSubmit = async (formData: CreateCollectionItem_createCollectionItem) => {
		if (edit) {
			try {
				await updateCollectionItem({
					variables: {
						id: data?.id ?? '',
						name: formData.name,
						type: formData.type,
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}
						const cachedRequest = cache.readQuery<GetCollectionItems, GetCollectionItemsVariables>({
							query: GET_COLLECTION_ITEMS,
							variables: { type: collectionItemType },
						});

						if (cachedRequest === null || cachedRequest.collectionItems === null) {
							return;
						}

						const newCollectionItems = [...cachedRequest.collectionItems];
						newCollectionItems[collectionItemIndex] = cacheData.updateCollectionItem;

						cache.writeQuery<GetCollectionItems, GetCollectionItemsVariables>({
							query: GET_COLLECTION_ITEMS,
							variables: {
								type: collectionItemType,
							},
							data: {
								collectionItems: newCollectionItems,
							},
						});
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await createCollectionItem({
					variables: {
						collectionItem: {
							name: formData.name,
							type: formData.type,
						},
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}
						const cachedRequest = cache.readQuery<GetCollectionItems, GetCollectionItemsVariables>({
							query: GET_COLLECTION_ITEMS,
							variables: { type: collectionItemType },
						});

						if (cachedRequest === null || cachedRequest.collectionItems === null) {
							return;
						}

						cache.writeQuery<GetCollectionItems, GetCollectionItemsVariables>({
							query: GET_COLLECTION_ITEMS,
							variables: {
								type: collectionItemType,
							},
							data: {
								collectionItems: [...cachedRequest.collectionItems, cacheData.createCollectionItem],
							},
						});
					},
				});
			} catch (e) {
				console.log(e);
			}
		}

		submitCb?.();
	};

	return (
		<Modal
			title={(erase && 'collectionItem.delete') || (edit && 'collectionItem.update') || 'collectionItem.create'}
			size={ModalSize.SMALL}
			visible={open}
			close={close}
			body={
				erase ? (
					<div className="text-blue">
						<FormFieldHeader title="collectionItem.wantToDelete" />
						<div className="flex flex-col">
							<div>
								<p>{`${t('collectionItem.text')}: ${data?.name}`}</p>
								<p>{`${t('collectionItem.type')}: ${t(`enumCollectionItemTypes.${data?.type}`)}`}</p>
							</div>

							<Button
								danger
								text="collectionItem.delete"
								onClick={() => {
									deleteCollectionItem({
										variables: {
											id: data?.id ?? '',
										},
										update: (cache, { data: cacheData }): void => {
											if (typeof cacheData === 'undefined' || cacheData === null) {
												return;
											}
											const cachedRequest = cache.readQuery<GetCollectionItems, GetCollectionItemsVariables>({
												query: GET_COLLECTION_ITEMS,
												variables: {
													type: collectionItemType,
												},
											});

											if (cachedRequest === null || cachedRequest.collectionItems === null) {
												return;
											}

											cache.writeQuery<GetCollectionItems, GetCollectionItemsVariables>({
												query: GET_COLLECTION_ITEMS,
												variables: {
													type: collectionItemType,
												},
												data: {
													collectionItems: cachedRequest.collectionItems.filter(d => d.id !== data?.id),
												},
											});
										},
									});

									submitCb?.();
								}}
								className="mt-4"
							/>
						</div>
					</div>
				) : (
					<Box form title="">
						<form autoComplete="nope" onSubmit={handleSubmit(onSubmit)}>
							<Input title="collectionItem.text" name="name" required defaultValue={data?.name} innerRef={register} errorMessage={errors?.name?.message ?? ''} />

							<Dropdown title="collectionItem.type" name="type" innerRef={register} data={cIDropdownItems} required defaultValue={data?.type} />

							<Button success submit text={edit ? 'collectionItem.update' : 'collectionItem.create'} className="mt-4" />
						</form>
					</Box>
				)
			}
		/>
	);
};

export default CollectionItemModal;
