import React, { useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import {
	GetSettings,
	GetAdminDebitors,
	CreateSettings,
	UpdateSettings,
	CreateSettingsVariables,
	UpdateSettingsVariables,
	GetDebitorsByReferenceNo,
	GetDebitorsByReferenceNoVariables,
	GetSettings_setting_In4moSettings,
	In4moSettingsInput,
} from '../../../GraphQL';
import { loader } from 'graphql.macro';
import Input from '@ssg/common/Components/Input';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import TextButton from '@ssg/common/Components/TextButton';
import { H3 } from '@ssg/common/Components/Text';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import In4moDamageMappings from './DamageMappings';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { removeTypenameGeneric } from '@ssg/common/Helpers/typenameHelper';

const GET_SETTINGS = loader('../../../GraphQL/AdminSettings/GetSettings.gql');
const CREATE_SETTING = loader('../../../GraphQL/AdminSettings/CreateSettings.gql');
const UPDATE_SETTING = loader('../../../GraphQL/AdminSettings/UpdateSettings.gql');

const GET_DEBITORS = loader('../../../GraphQL/Debitors/GetAdminDebitors.gql');
const GET_DEBITORS_BY_NO = loader('../../../GraphQL/Debitors/GetDebitorsByReferenceNo.gql');

const In4moTab: React.FC = () => {
	const { t } = useTranslation();
	const [visible, setVisible] = React.useState(false);

	const [createSetting, { loading: createSettingLoading }] = useMutation<CreateSettings, CreateSettingsVariables>(CREATE_SETTING);
	const [updateSetting, { loading: updateSettingLoading }] = useMutation<UpdateSettings, UpdateSettingsVariables>(UPDATE_SETTING);

	const [settings, setSettings] = React.useState<In4moSettingsInput>({
		type: 'IN4MO',
		username: '',
		password: '',
		debitorMappings: [],
		damageMappings: [],
	});

	const { control: debitorControl } = useForm();

	/*
	 * fetching In4mo settings
	 */
	const { data, loading, refetch } = useQuery<GetSettings>(GET_SETTINGS, {
		variables: {
			type: 'IN4MO',
		},
	});

	const [initialSelectedDebitorsLoaded, setInitialSelectedDebitorsLoaded] = React.useState(false);

	const existingIn4moSetting = useMemo(() => {
		if (data?.setting && data.setting.length > 0) {
			const setting = data.setting[0] as GetSettings_setting_In4moSettings;
			setSettings(setting);
			return setting;
		}
	}, [data]);

	const referenceNoList = useMemo(() => settings.debitorMappings?.map(m => m.debitor).filter((d, i, a) => a.indexOf(d) === i) ?? [], [settings.debitorMappings]);

	const { data: selectedDebitors } = useQuery<GetDebitorsByReferenceNo, GetDebitorsByReferenceNoVariables>(GET_DEBITORS_BY_NO, {
		variables: {
			referenceNoList: referenceNoList,
		},
		nextFetchPolicy: 'cache-and-network',
		skip: !settings.debitorMappings,
	});

	const [debitorsByReferenceNo, setDebitorsByReferenceNo] = React.useState<Record<string, string>>({});
	useEffect(() => {
		if (typeof selectedDebitors?.debitorsByReferenceNo !== 'undefined') {
			for (const debitor of selectedDebitors.debitorsByReferenceNo) {
				setDebitorsByReferenceNo(current => ({
					...current,
					[debitor.debitorId]: debitor.company,
				}));
			}

			setInitialSelectedDebitorsLoaded(true);
		}
	}, [selectedDebitors]);

	const [debitorSearchText, setDebitorSearchText] = useDebouncedState('', 100);
	const { data: debitors, loading: debitorsLoading } = useQuery<GetAdminDebitors>(GET_DEBITORS, {
		variables: {
			searchText: debitorSearchText,
		},
	});

	const debitorOptions = useMemo(() => {
		if (debitors?.debitors) {
			return debitors.debitors.map(d => ({
				label: `${d.company} (${d.debitorId})`,
				value: d.debitorId,
			}));
		}

		return [] as SelectOption[];
	}, [debitors]);

	const onSubmit = async () => {
		if (settings.debitorMappings.some(x => !x.debitor || !x.insuranceCompany)) {
			const message = `${t('integrations.debitor')}/${t('integrations.insuranceCompany')} ${t('integrations.cannotBeEmptyLowercase')}`;
			alert(message);
			return;
		}

		if (settings.damageMappings.some(x => !x.damageCause)) {
			const message = `${t('integrations.cause')} ${t('integrations.cannotBeEmptyLowercase')}`;
			alert(message);
			return;
		}

		const santizied = removeTypenameGeneric(settings);
		const settingsInput = {
			type: santizied.type,
			username: santizied.username,
			password: santizied.password,
			debitorMappings: santizied.debitorMappings,
			damageMappings: santizied.damageMappings,
		} as In4moSettingsInput;

		if (existingIn4moSetting?.id) {
			await updateSetting({
				variables: {
					id: existingIn4moSetting.id,
					in4moSetting: settingsInput,
				},
			});
		} else {
			await createSetting({ variables: { in4moSetting: settingsInput } });
		}

		refetch();
	};

	return loading && typeof data == 'undefined' ? (
		<div className="relative h-40">
			<Loading />
		</div>
	) : (
		<div className="text-blue h-full px-3 pb-3">
			<div className="flex">
				<div className="mr-4 w-1/2">
					<Input
						title="integrations.username"
						name={'username'}
						onChange={e =>
							setSettings(current => ({
								...current,
								username: e.target.value,
							}))
						}
						defaultValue={existingIn4moSetting ? existingIn4moSetting.username : ''}
					/>
					<div className="relative mb-4">
						<div className="pointer-events-none absolute mt-8 flex content-start justify-end pr-2 lg:w-full">
							<FontAwesomeIcon icon={visible ? faEyeSlash : faEye} onClick={() => setVisible(!visible)} className="pointer-events-auto" />
						</div>
						<Input
							title="integrations.password"
							name={'password'}
							type={visible ? 'text' : 'password'}
							className="z-50 pr-8 text-sm lg:w-full"
							onChange={e =>
								setSettings(current => ({
									...current,
									password: e.target.value,
								}))
							}
							defaultValue={existingIn4moSetting ? existingIn4moSetting.password : ''}
						/>
					</div>
				</div>
			</div>

			<div className="mb-6">
				<H3 className="mb-2">{t('integrations.in4moDebitorMappings')}</H3>
				<div>
					<Table
						disableOverflowAuto
						data={settings.debitorMappings || []}
						columns={[
							{
								label: 'integrations.insuranceCompany',
								selectFn: (m, i) => (
									<Input
										name="insuranceCompany"
										value={m.insuranceCompany}
										onChange={e =>
											setSettings(current => ({
												...current,
												debitorMappings: [
													...current.debitorMappings.slice(0, i),
													{
														...current.debitorMappings[i],
														insuranceCompany: e.target.value,
													},
													...current.debitorMappings.slice(i + 1),
												],
											}))
										}
									/>
								),
							},
							{
								label: 'integrations.debitor',
								selectFn: (m, i) =>
									initialSelectedDebitorsLoaded ? (
										<SearchableSelect
											name="debitorMappings-debitor"
											control={debitorControl}
											options={debitorOptions}
											searchFn={searchText => setDebitorSearchText(searchText)}
											onSelect={value =>
												setSettings(current => ({
													...current,
													debitorMappings: [
														...current.debitorMappings.slice(0, i),
														{
															...current.debitorMappings[i],
															debitor: value,
														},
														...current.debitorMappings.slice(i + 1),
													],
												}))
											}
											onBlur={() => undefined}
											minInputLength={2}
											isLoading={debitorsLoading}
											initialSelection={
												m.debitor.length > 0
													? {
															label: `${debitorsByReferenceNo[m.debitor] ?? '?'} (${m.debitor})`,
															value: m.debitor,
													  }
													: undefined
											}
										/>
									) : (
										<Input name="initialSelectedDebitorsLoading" disabled />
									),
								noTruncate: true,
							},
							{
								label: 'common.delete',
								classNameTh: 'text-right',
								selectFn: (m, i) => (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											className="cursor-pointer"
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												const confirm = window.confirm(t('common.wantToDelete'));
												if (!confirm) {
													return;
												}

												setSettings(current => ({
													...current,
													debitorMappings: [...current.debitorMappings.slice(0, i), ...current.debitorMappings.slice(i + 1)],
												}));
											}}
										/>
									</div>
								),
							},
						]}
						keySelector={(m, i) => String(i)}
					/>
				</div>

				<TextButton
					icon={faPlus}
					text="common.add"
					className="mt-1"
					onClick={() =>
						setSettings(current => ({
							...current,
							debitorMappings: [...current.debitorMappings, { insuranceCompany: '', debitor: '' }],
						}))
					}
				/>
			</div>
			<In4moDamageMappings settings={settings} setSettings={setSettings} />
			<Button
				onClick={() => onSubmit()}
				loading={createSettingLoading || updateSettingLoading}
				disabled={createSettingLoading || updateSettingLoading}
				success
				text="common.save"
				className="mt-4"
			/>
		</div>
	);
};

export default In4moTab;
