import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GetAdminCurrentUser } from './GraphQL';
import { RestrictedAreaProvider } from '@ssg/common/Components/RestrictedArea';
import { useMicrosoftAuth } from '@ssg/common/Components/MicrosoftAuth';
import { useUnleashContext } from '@unleash/proxy-client-react';

const GET_CURRENT_USER = loader('./GraphQL/Users/GetAdminCurrentUser.gql');

interface UserContextValues {
	user: GetAdminCurrentUser['currentUser'] | null;
	loading: boolean;
}

const UserContext = React.createContext<UserContextValues>({
	user: null,
	loading: false,
});

export const UserContextProvider: React.FC = ({ children }) => {
	const { account } = useMicrosoftAuth();
	const [getCurrentUser, { data, loading }] = useLazyQuery<GetAdminCurrentUser>(GET_CURRENT_USER);
	const prevAccountUsername = React.useRef<string>();
	const updateContext = useUnleashContext();

	React.useEffect(() => {
		if (account != null && prevAccountUsername.current !== account.username) {
			getCurrentUser();
			prevAccountUsername.current = account.username;
		}
	}, [getCurrentUser, account]);

	const context: UserContextValues = React.useMemo(
		() => ({
			user: data?.currentUser ?? null,
			loading,
		}),
		[data?.currentUser, loading],
	);

	React.useEffect(() => {
		updateContext({ userId: context.user?.id });
	}, [context, updateContext]);

	return (
		<UserContext.Provider value={context}>
			<RestrictedAreaProvider value={context.user?.permissions ?? []}>{children}</RestrictedAreaProvider>
		</UserContext.Provider>
	);
};

export default UserContext;
