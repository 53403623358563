import React from 'react';
import Table from '@ssg/common/Components/Table';
import Toggle from '@ssg/common/Components/Toggle';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { CreateSettings, CreateSettingsVariables, GetSettings, GetSettingsVariables, GetSettings_setting_MachineNotificationSetting, GetSettings_setting_MachineNotificationSetting_machineNotifications, UpdateSettings, UpdateSettingsVariables } from '../../../GraphQL';
import Button from '@ssg/common/Components/Button';

const GET_SETTINGS = loader('../../../GraphQL/AdminSettings/GetSettings.gql');
const CREATE_SETTING = loader('../../../GraphQL/AdminSettings/CreateSettings.gql');
const UPDATE_SETTING = loader('../../../GraphQL/AdminSettings/UpdateSettings.gql');

const MachineNotificationTypeName = 'MachineNotification';

const notifications: GetSettings_setting_MachineNotificationSetting_machineNotifications[] = [
	{
		days: 21,
		active: true,
	},
	{
		days: 100,
		active: false,
	},
];

const MachineTab: React.FC = () => {

	const [machineNotifications, setMachineNotifications] = React.useState<GetSettings_setting_MachineNotificationSetting_machineNotifications[]>([]);

	const [createSetting, { loading: createLoading }] = useMutation<CreateSettings, CreateSettingsVariables>(CREATE_SETTING);
	const [updateSetting, { loading: updateLoading }] = useMutation<UpdateSettings, UpdateSettingsVariables>(UPDATE_SETTING);

	const { data, loading, refetch } = useQuery<GetSettings, GetSettingsVariables>(GET_SETTINGS, {
		variables: {
			type: MachineNotificationTypeName,
		},
		async onCompleted(data) {
			if (data.setting.length === 0) {
				await createSetting({
					variables: {
						machineNotificationSetting: {
							type: MachineNotificationTypeName,
							machineNotifications: notifications,
						},
					},
				});
				setMachineNotifications(notifications);
				refetch();
			} else {
				if ('machineNotifications' in data.setting[0]) {
					setMachineNotifications(data.setting[0].machineNotifications);
				}
			}
		},
	});

	const onSubmit = async () => {
		if (data && data.setting.length > 0) {
			try {
				await updateSetting({
					variables: {
						id: data.setting[0].id,
						machineNotificationSetting: {
							type: MachineNotificationTypeName,
							machineNotifications: machineNotifications.map(m => ({ active: m.active, days: m.days })),
						},
					},
				});
			} catch (e) {
				console.log(e);
			}
		}
	};


	const change = (data: Partial<GetSettings_setting_MachineNotificationSetting_machineNotifications>, index: number) => setMachineNotifications(current => {
		console.log(index, data);
		return [
			...current.slice(0, index),
			{
				...current[index],
				...data,
			},
			...current.slice(index + 1),
		];
	});
	return (
		<div>
			<Table
				data={machineNotifications}
				loading={loading || createLoading}
				columns={[
					{
						label: 'Notifikation',
						selectFn: (_, i) => <p className="font-medium">{`Maskiner har stået x dage på skadesadresse (${i + 1}. alarm)`}</p>,
					},
					{
						label: 'Trigger',
						numeric: true,
						selectFn: (n, i) => (
							<label>
								<input type="number" className="mr-3" value={n.days} onChange={(e) => change({ days: e.currentTarget.valueAsNumber }, i)} />
								dage
							</label>
						),
					},
					{
						label: 'Aktiv',
						selectFn: (n, i) => <Toggle name="toggle-boy" text="" checked={n.active} onChange={(e) => change({ active: e.currentTarget.checked }, i)} />,
					},
				]}
				keySelector={(n, i) => i.toString()}
			/>
			<Button onClick={() => onSubmit()} success text="common.save" loading={updateLoading} />
		</div>
	);
};

export default MachineTab;
