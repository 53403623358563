import { useQuery } from '@apollo/client';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import { loader } from 'graphql.macro';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetNotificationItems, GetNotificationItemsVariables } from '../../GraphQL';
import NotificationsFilters from './NotificationsFilters';
import { formatDateForInput, formatDateForInputWithTime } from '@ssg/common/Helpers/dateToDateOnlyString';
import { tokenizeStringWithQuotesBySpaces } from '../../helper';

const GET_NOTIFICATIONS = loader('../../GraphQL/Notifications/GetNotifications.gql');

interface NotificationsFilterItem {
	id: string;
	trigger: string;
	sentTo: string;
	sentAt: string;
	type: string;
	objectType: string;
	objectId: string;
	sentSuccessfully: string;
	error: string;
}

const Notifications: React.FC = () => {
	const { t } = useTranslation();

	const { loading, data, refetch } = useQuery<GetNotificationItems, GetNotificationItemsVariables>(GET_NOTIFICATIONS);

	// Dates
	const [fromDate, setFromDate] = useState<Date>(() => {
		const d = new Date();
		d.setDate(d.getDate() - 7);
		return d;
	});
	const [toDate, setToDate] = useState<Date>(() => {
		return new Date();
	});

	const refetchNotifications = () => {
		refetch({
			dateTo: `${('0' + (toDate.getMonth() + 1)).slice(-2)}-${('0' + toDate.getDate()).slice(-2)}-${toDate.getFullYear()} 23:59:59`,
			dateFrom: `${('0' + (fromDate.getMonth() + 1)).slice(-2)}-${('0' + fromDate.getDate()).slice(-2)}-${fromDate.getFullYear()} 00:00:00`,
		});
	};

	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);

	const notifications = useMemo(() => data?.notificationItems ?? [], [data]);

	const [activeFilteredNotifications, setActiveFilteredNotifications] = useState<NotificationsFilterItem[]>(() => {
		return notifications.map(
			val =>
				({
					id: val.id,
					trigger: val.trigger,
					sentTo: val.sendTo,
					sentAt: formatDateForInputWithTime(new Date(val.sentAt)),
					objectId: val.objectId,
					objectType: val.objectType,
					sentSuccessfully: val.sentSuccessfully ? t('common.yes') : t('common.no'),
					type: val.type,
					error: val.error,
				}) as NotificationsFilterItem,
		);
	});

	useEffect(() => {
		setActiveFilteredNotifications(
			notifications.map(
				val =>
					({
						id: val.id,
						trigger: val.trigger,
						sentTo: val.sendTo,
						sentAt: formatDateForInputWithTime(new Date(val.sentAt)),
						objectId: val.objectId,
						objectType: val.objectType,
						sentSuccessfully: val.sentSuccessfully ? t('common.yes') : t('common.no'),
						type: val.type,
						error: val.error,
					}) as NotificationsFilterItem,
			),
		);
	}, [notifications, t]);

	const fuse = useMemo(
		() =>
			new Fuse(activeFilteredNotifications, {
				shouldSort: true,
				threshold: 0.3,
				keys: ['trigger', 'sentTo', 'sentAt', 'type', 'objectType', 'objectId', 'sentSuccessfully', 'error'],
			}),
		[activeFilteredNotifications],
	);

	const filteredNotifications =
		searchTerm.length > 0
			? fuse
					.search({
						$and: tokenizeStringWithQuotesBySpaces(searchTerm).map((searchToken: string) => {
							const orFields: Fuse.Expression[] = [
								{ name: searchToken },
								{ trigger: searchToken },
								{ sentTo: searchToken },
								{ sentAt: searchToken },
								{ type: searchToken },
								{ objectType: searchToken },
								{ objectId: searchToken },
								{ sentSuccessfully: searchToken },
								{ error: searchToken },
							];

							return {
								$or: orFields,
							};
						}),
					})
					.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
					.map(v => v.item)
			: activeFilteredNotifications;

	return (
		<div>
			<header>
				{/* <h1 className="text-2xl font-bold text-blue mb-2">{t('notificationItem.overviewTitle')}</h1> */}
				<div>
					<NotificationsFilters setFilterTerm={setSearchTerm} />
					<div className="my-3 flex">
						<label className="text-blue mb-1 mr-3 text-xs font-medium">
							{t('notificationItem.datePickerText')}
							<div className="flex items-center">
								<input
									type="date"
									value={formatDateForInput(fromDate, false)}
									max={formatDateForInput(toDate, false)}
									onChange={e => {
										const date = e.target.valueAsDate;
										if (date === null) {
											return;
										}

										date.setHours(0, 0, 0, 0);
										setFromDate(date);
									}}
									className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
									style={{
										height: '36px',
										maxWidth: '140px',
										minWidth: '100px',
									}}
								/>
								<span className="px-1">&mdash;</span>
								<input
									type="date"
									max={formatDateForInput(new Date(), false)}
									value={formatDateForInput(toDate, false)}
									onChange={e => {
										const date = e.target.valueAsDate;
										if (date === null) {
											return;
										}

										date.setHours(23, 59, 59, 999);
										setToDate(date);

										if (date < fromDate) {
											const from = new Date(date);
											from.setHours(23, 59, 59, 999);
											setFromDate(from);
										}
									}}
									className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
									style={{
										height: '36px',
										maxWidth: '140px',
										minWidth: '100px',
									}}
								/>
								<Button className="ml-3" text={'common.update'} onClick={refetchNotifications} primary />
							</div>
						</label>
					</div>
				</div>
			</header>
			<div className="text-blue relative bg-white pb-1">
				{loading ? (
					<div className="h-40">
						<Loading />
					</div>
				) : (
					<Table
						data={filteredNotifications ?? []}
						columns={[
							{
								label: t('notificationItem.trigger'),
								selectFn: c => <p className="font-medium">{c.trigger}</p>,
								sortFn: (a, b) => a.trigger.localeCompare(b.trigger),
							},
							{
								label: t('notificationItem.type'),
								selectFn: c => <p className="font-medium">{c.type}</p>,
								sortFn: (a, b) => a.type.localeCompare(b.type),
							},
							{
								label: t('notificationItem.sentTo'),
								selectFn: c => <p className="font-medium">{c.sentTo}</p>,
								sortFn: (a, b) => a.sentTo.localeCompare(b.sentTo),
							},
							{
								label: t('notificationItem.sentAt'),
								selectFn: c => <p className="font-medium">{c.sentAt}</p>,
								sortFn: (a, b) => a.sentAt.localeCompare(b.sentAt),
							},
							{
								label: t('notificationItem.sentSuccessfully'),
								selectFn: c => <p>{c.sentSuccessfully}</p>,
								sortFn: (a, b) => (a === b ? 0 : a ? 1 : -1),
							},
							{
								label: t('notificationItem.objectId'),
								selectFn: c => <p className="font-medium">{c.objectId}</p>,
								sortFn: (a, b) => a.objectId.localeCompare(b.objectId),
							},
							{
								label: t('notificationItem.objectType'),
								selectFn: c => <p className="font-medium">{c.objectType}</p>,
								sortFn: (a, b) => a.objectType.localeCompare(b.objectType),
							},
							{
								label: t('notificationItem.error'),
								selectFn: c => (c.error ? <p className="font-medium">{c.error}</p> : <p></p>),
							},
						]}
						keySelector={e => e.id}
					/>
				)}
			</div>
		</div>
	);
};

export default Notifications;
