import React from 'react';
import { GetBusinessArea, GetBusinessArea_businessAreas } from '../../../GraphQL';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import BusinessAreaModal from './BusinessAreaModal';

const BUSINESS_AREAS = loader('src/GraphQL/BusinessArea/GetBusinessArea.gql');

const BusinessArea: React.FC = () => {
	const [createArea, setCreateArea] = React.useState(false);
	const [updateArea, setUpdateArea] = React.useState(false);
	const [deleteArea, setDeleteArea] = React.useState(false);
	const [areaData, setAreaData] = React.useState<GetBusinessArea_businessAreas>();

	const { data, loading, refetch } = useQuery<GetBusinessArea>(BUSINESS_AREAS);

	return (
		<div>
			<header className="flex">
				<div className="w-1/2"></div>
				<div className="flex w-1/2 justify-end">
					<Button primary text="businessArea.create" icon={faPlus} onClick={() => setCreateArea(true)} className="mb-2" />
				</div>
			</header>

			<div className="text-blue relative bg-white pb-1">
				{loading ? (
					<div className="h-40">
						<Loading />
					</div>
				) : (
					<Table
						data={data?.businessAreas ?? []}
						columns={[
							{
								label: 'common.name',
								selectFn: c => <p className="py-1">{c.name}</p>,
								sortFn: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
							},
							{
								label: 'common.edit',
								classNameTh: 'text-right',
								selectFn: c => (
									<div className="flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faEdit}
											size="lg"
											onClick={() => {
												setAreaData(c);
												setUpdateArea(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
							{
								label: 'common.delete',
								classNameTh: 'text-right',
								selectFn: c => (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setAreaData(c);
												setDeleteArea(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
						]}
						keySelector={c => c.id}
					/>
				)}
			</div>

			{createArea && (
				<BusinessAreaModal
					open={createArea}
					close={() => setCreateArea(false)}
					submitCb={() => {
						refetch();
						setCreateArea(false);
					}}
				/>
			)}

			{updateArea && (
				<BusinessAreaModal
					edit
					open={updateArea}
					close={() => setUpdateArea(false)}
					data={areaData}
					submitCb={() => {
						refetch();
						setUpdateArea(false);
					}}
				/>
			)}

			{deleteArea && (
				<BusinessAreaModal
					erase
					open={deleteArea}
					close={() => setDeleteArea(false)}
					data={areaData}
					submitCb={() => {
						refetch();
						setDeleteArea(false);
					}}
				/>
			)}
		</div>
	);
};

export default BusinessArea;
