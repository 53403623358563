import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import Input from '@ssg/common/Components/Input';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Dropdown from '@ssg/common/Components/Dropdown';
import { VisitationFilter } from './Visitation';

interface IProps {
	setFilterTerm: (v: string) => void;
	setFilter: React.Dispatch<React.SetStateAction<VisitationFilter>>;
	catDropDown: SelectOption[];
	causeDropDown: SelectOption[];
}

const VisitationFilters: React.FC<IProps> = ({ setFilterTerm, setFilter, catDropDown, causeDropDown }) => {
	const { t } = useTranslation();

	return (
		<div className="mr-6 flex flex-col flex-wrap lg:flex-row">
			<div className="mb-3 mr-3">
				<label className="text-blue mb-1 block text-xs font-medium">{t('collectionItem.filters.search')}</label>
				<div className="flex">
					<div className="relative flex">
						<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
						<Input
							name="collectionItem-search"
							placeholder={t('collectionItem.searchPlaceholder')}
							className="pr-8 text-sm lg:w-full"
							onChange={(e): void => setFilterTerm(e.target.value)}
						/>
					</div>
				</div>
			</div>
			<div className="mb-3 mr-3">
				<label className="text-blue mb-1 block text-xs font-medium">{t('visitationQuestion.damageCategory')}</label>
				<div className="flex">
					<Dropdown
						name="type"
						data={[{ value: '', label: '---' }, ...catDropDown]}
						required
						onChange={e => {
							setFilter(prevState => ({
								...prevState,
								category: e.target.value,
								cause: '',
							}));
						}}
						style={{ width: '200px' }}
					/>
				</div>
			</div>
			<div className="mb-3 mr-3">
				<label className="text-blue mb-1 block text-xs font-medium">{t('visitationQuestion.damageCause')}</label>
				<div className="flex">
					<Dropdown
						name="type"
						data={[{ value: '', label: '---' }, ...causeDropDown]}
						required
						onChange={e => {
							setFilter(prevState => ({
								...prevState,
								cause: e.target.value,
							}));
						}}
						style={{ width: '200px' }}
					/>
				</div>
			</div>
		</div>
	);
};

export default VisitationFilters;
