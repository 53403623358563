import React from 'react';
import {
	CreateDamageCause,
	CreateDamageCauseVariables,
	DeleteDamageCause,
	DeleteDamageCauseVariables,
	GetBusinessArea,
	GetAdminDamageCategories,
	GetAdminDamageCauses,
	GetAdminDamageCauses_damageCausesAdmin,
	GetAdminDepartments,
	UpdateDamageCause,
	UpdateDamageCauseVariables,
} from '../../../GraphQL';
import { useMutation, useQuery } from '@apollo/client';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { IDamageCause } from '../../../Schemas/IDamageCause';
import { yupResolver } from '@hookform/resolvers/yup';
import { DamageCauseSchema } from '../../../Schemas/DamageCauseSchema';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Select, { ValueType, ControlProps } from 'react-select';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';
import Checkbox from '@ssg/common/Components/Checkbox';
import Loading from '@ssg/common/Components/Loading';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import classNames from 'classnames';

const CREATE_DAMAGE_CAUSE = loader('src/GraphQL/DamageCause/CreateDamageCause.gql');
const UPDATE_DAMAGE_CAUSE = loader('src/GraphQL/DamageCause/UpdateDamageCause.gql');
const DELETE_DAMAGE_CAUSE = loader('src/GraphQL/DamageCause/DeleteDamageCause.gql');
const GET_DEPARTMENTS = loader('src/GraphQL/Department/GetAdminDepartments.gql');
const GET_CATEGORIES = loader('src/GraphQL/DamageCategory/GetAdminDamageCategories.gql');
const GET_AREAS = loader('src/GraphQL/BusinessArea/GetBusinessArea.gql');
const GET_DAMAGE_CAUSES = loader('src/GraphQL/DamageCause/GetAdminDamageCauses.gql');

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	erase?: boolean;
	data?: GetAdminDamageCauses_damageCausesAdmin;
	submitCb?: () => unknown;
}

const DamageCauseModal: React.FC<Props> = ({ open, close, edit = false, erase = false, data, submitCb }) => {
	const { t } = useTranslation();

	const [searchText, setSearchText] = React.useState('');

	const [createDamageCause, { loading: createLoading }] = useMutation<CreateDamageCause, CreateDamageCauseVariables>(CREATE_DAMAGE_CAUSE);
	const [updateDamageCause, { loading: updateLoading }] = useMutation<UpdateDamageCause, UpdateDamageCauseVariables>(UPDATE_DAMAGE_CAUSE);
	const [deleteDamageCause, { loading: deleteLoading }] = useMutation<DeleteDamageCause, DeleteDamageCauseVariables>(DELETE_DAMAGE_CAUSE);

	const { data: deps } = useQuery<GetAdminDepartments>(GET_DEPARTMENTS);
	const { data: cat } = useQuery<GetAdminDamageCategories>(GET_CATEGORIES);
	const { data: area } = useQuery<GetBusinessArea>(GET_AREAS);

	const departments = React.useMemo(() => {
		return deps?.departmentsAdmin.map((d): SelectOption => ({ value: d.id ?? '', label: d.name ?? '' }));
	}, [deps]);

	const categories = React.useMemo(() => {
		return cat?.damageCategoriesAdmin.map((d): SelectOption => ({ value: d.id ?? '', label: d.name ?? '' }));
	}, [cat]);

	if (categories && categories[0].label !== '' && categories[0].value !== '') {
		categories?.unshift({ value: '', label: '' });
	}

	const areas = React.useMemo(() => {
		return area?.businessAreas.map((d): SelectOption => ({ value: d.id ?? '', label: d.name ?? '' }));
	}, [area]);

	if (areas && areas[0].label !== '' && areas[0].value !== '') {
		areas?.unshift({ value: '', label: '' });
	}

	const departmentsHandler = (value: ValueType<SelectOption, true>): void => {
		const values = Array.isArray(value) ? value.map(v => v.value) : [];
		setValue('departments', values, { shouldValidate: true });
	};

	const selectedDeps = React.useMemo(() => {
		return data?.departments?.map((d): SelectOption => ({ value: d?.id ?? '', label: d?.name ?? '' }));
	}, [data]);

	const tracks: SelectOption[] = [
		{
			label: 'damageCause.track0',
			value: '0',
		},
		{
			label: 'damageCause.track1',
			value: '1',
		},
		{
			label: 'damageCause.track2',
			value: '2',
		},
		{
			label: 'damageCause.track3',
			value: '3',
		},
	];

	const { handleSubmit, register, setValue, control, errors } = useForm<IDamageCause>({
		resolver: yupResolver(DamageCauseSchema),
		defaultValues: {
			departments: selectedDeps?.map(s => s.value),
		},
	});

	const onSubmit = async (cause: IDamageCause) => {
		if (edit) {
			try {
				await updateDamageCause({
					variables: {
						id: data?.id ?? '',
						name: cause.name,
						departments: cause.departments,
						category: cause.category,
						business: cause.business,
						track: cause.track,
						urgent: cause.urgent,
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await createDamageCause({
					variables: {
						damageCause: {
							name: cause.name,
							departments: cause.departments,
							category: cause.category,
							businessArea: cause.business,
							track: cause.track,
							urgent: cause.urgent,
						},
					},
				});
			} catch (e) {
				console.log(e);
			}
		}

		submitCb?.();
	};

	const customStyles = {
		indicatorSeparator: () => ({
			display: 'none',
		}),
		dropdownIndicator: () => ({
			display: 'none',
		}),
		control: (provided: React.CSSProperties) => ({
			...provided,
			border: 'none',
			fontSize: '1rem',
			outline: '0px',
		}),
		valueContainer: (provided: React.CSSProperties, state: ControlProps<{ label: string; value: string }, true>) => ({
			...provided,
			padding: '4px',
			outline: state.isFocused ? '0px' : '0px',
		}),
	};

	return (
		<>
			{typeof categories === 'undefined' || typeof areas === 'undefined' ? (
				<div className="relative h-screen">
					<Loading />
				</div>
			) : (
				<Modal
					title={(erase && 'damageCause.delete') || (data && 'damageCause.update') || 'damageCause.create'}
					size={ModalSize.SMALL}
					visible={open}
					close={close}
					body={
						erase && typeof data !== 'undefined' ? (
							<div>
								<FormFieldHeader title="damageCause.wantToDelete" />
								<div>
									<div>{data.name}</div>
									<div>
										{data.departments?.map((d, i) => {
											return <p>{d?.name}</p>;
										})}
									</div>
									<div>{data.category.name}</div>
									<div>{data.businessArea.name}</div>
									<div>{t(`damageCause.track${data.track}`)}</div>
									<div>{data.urgent}</div>

									<Button
										danger
										text="damageCause.delete"
										loading={deleteLoading}
										onClick={async () => {
											await deleteDamageCause({
												variables: {
													id: data.id,
												},
												update: (cache, { data: cacheData }): void => {
													if (typeof cacheData === 'undefined' || cacheData === null) {
														return;
													}
													const cachedRequest = cache.readQuery<GetAdminDamageCauses>({
														query: GET_DAMAGE_CAUSES,
													});

													if (cachedRequest === null || cachedRequest.damageCausesAdmin === null) {
														return;
													}

													cache.writeQuery<GetAdminDamageCauses>({
														query: GET_DAMAGE_CAUSES,
														data: {
															damageCausesAdmin: cachedRequest.damageCausesAdmin.filter(c => c.id !== data.id),
														},
													});
												},
											});
											close();
										}}
										className="mt-4"
									/>
								</div>
							</div>
						) : (
							<form onSubmit={handleSubmit(onSubmit)}>
								<Input title="common.name" required innerRef={register} name="name" defaultValue={data?.name} errorMessage={errors.name?.message} />

								<FormFieldHeader title="damageCause.departments" htmlFor="departments" required />
								<Controller
									name="departments"
									control={control}
									render={() => (
										<Select
											isMulti
											options={departments?.filter(d => d.label.toLowerCase().includes(searchText.toLowerCase())) ?? []}
											closeMenuOnSelect={false}
											noOptionsMessage={inputValue =>
												inputValue.inputValue === '' ? t('damageCause.noDepartment') : `${t('damageCause.noDepartmentWithName')} "${inputValue.inputValue}"`
											}
											placeholder={<div>{t('damageCause.searchDepartments')}</div>}
											styles={customStyles}
											inputRef={register}
											onChange={val => departmentsHandler(val)}
											onInputChange={val => setSearchText(val)}
											defaultValue={selectedDeps}
											className={classNames('border-1 rounded-default border-gray-600', {
												'border-red border-2': typeof errors.departments !== 'undefined' ? (errors.departments as unknown as FieldError).message ?? undefined : undefined,
											})}
										/>
									)}
								/>
								<FormErrorText text={typeof errors.departments !== 'undefined' ? (errors.departments as unknown as FieldError).message ?? '' : ''} />

								<Dropdown
									innerRef={register}
									name="category"
									title="damageCause.category"
									required
									// onChange={(e) => setCategoryState(e.target.value)}
									defaultValue={data?.category.id}
									data={categories}
									errorMessage={errors.category?.message}
								/>

								<Dropdown
									innerRef={register}
									name="business"
									title="damageCause.area"
									required
									// onChange={(e) => setAreaState(e.target.value)}
									defaultValue={data?.businessArea.id}
									data={areas}
									errorMessage={errors.business?.message}
								/>

								<Dropdown
									name="track"
									innerRef={register({ valueAsNumber: true })}
									title="damageCause.track"
									required
									defaultValue={data?.track}
									data={tracks}
									errorMessage={errors.track?.message}
								/>

								<Checkbox name="urgent" innerRef={register} title="damageCause.urgent" defaultChecked={data?.urgent} className="mt-4" errorMessage={errors.urgent?.message} />

								<Button success submit text={data ? 'damageCause.update' : 'damageCause.create'} loading={createLoading || updateLoading} className="mt-4" />
							</form>
						)
					}
				/>
			)}
		</>
	);
};

export default DamageCauseModal;
