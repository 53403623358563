import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		min: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const NodeCreationSchema = yup.object().shape({
	type: yup.string().required(),
	label: yup.string().required().trim(),
	placeholder: yup.string().nullable().trim(),
	helpText: yup.string().nullable().trim(),
	triggersTrackTwoChange: yup.boolean().default(false),
	isOptional: yup.boolean().nullable(),
});

export const NodeUpdateSchema = yup.object().shape({
	label: yup.string().required().trim(),
	placeholder: yup.string().nullable().trim(),
	helpText: yup.string().nullable().trim(),
	triggersTrackTwoChange: yup.boolean().nullable(),
	isOptional: yup.boolean().nullable(),
});
