import React from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
	CreateBusinessArea,
	CreateBusinessAreaVariables,
	DeleteBusinessArea,
	DeleteBusinessAreaVariables,
	GetBusinessArea,
	GetBusinessArea_businessAreas,
	UpdateBusinessArea,
	UpdateBusinessAreaVariables,
} from '../../../GraphQL';
import { useForm } from 'react-hook-form';
import { IBusinessArea } from '../../../Schemas/IBusinessArea';
import { yupResolver } from '@hookform/resolvers/yup';
import { BusinessAreaSchema } from '../../../Schemas/BusinessAreaSchema';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';

const GET_BUSINESS_AREAS = loader('src/GraphQL/BusinessArea/GetBusinessArea.gql');
const UPDATE_BUSINESS_AREA = loader('src/GraphQL/BusinessArea/UpdateBusinessArea.gql');
const DELETE_BUSINESS_AREA = loader('src/GraphQL/BusinessArea/DeleteBusinessArea.gql');
const CREATE_BUSINESS_AREA = loader('src/GraphQL/BusinessArea/CreateBusinessArea.gql');

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	erase?: boolean;
	data?: GetBusinessArea_businessAreas;
	submitCb?: () => unknown;
}

const BusinessAreaModal: React.FC<Props> = ({ open, close, edit = false, erase = false, data, submitCb }) => {
	const [updateBusinessArea, { loading: updateLoading }] = useMutation<UpdateBusinessArea, UpdateBusinessAreaVariables>(UPDATE_BUSINESS_AREA);
	const [createBusinessArea, { loading: createLoading }] = useMutation<CreateBusinessArea, CreateBusinessAreaVariables>(CREATE_BUSINESS_AREA);
	const [deleteBusinessArea, { loading: deleteLoading }] = useMutation<DeleteBusinessArea, DeleteBusinessAreaVariables>(DELETE_BUSINESS_AREA);

	const { handleSubmit, register, errors } = useForm<IBusinessArea>({
		resolver: yupResolver(BusinessAreaSchema),
	});

	const onSubmit = async (area: IBusinessArea) => {
		if (edit) {
			try {
				await updateBusinessArea({
					variables: {
						id: data?.id ?? '',
						name: area.name,
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await createBusinessArea({
					variables: {
						business: {
							name: area.name,
						},
					},
				});
			} catch (e) {
				console.log(e);
			}
		}

		submitCb?.();
	};

	return (
		<Modal
			title={(erase && 'businessArea.delete') || (data && 'businessArea.update') || 'businessArea.create'}
			size={ModalSize.SMALL}
			visible={open}
			close={close}
			body={
				erase && typeof data !== 'undefined' ? (
					<div>
						<FormFieldHeader title="businessArea.wantToDelete" />
						<div>
							<div>{data?.name}</div>

							<Button
								danger
								text="businessArea.delete"
								loading={deleteLoading}
								onClick={async () => {
									await deleteBusinessArea({
										variables: {
											id: data.id,
										},
										update: (cache, { data: cacheData }): void => {
											if (typeof cacheData === 'undefined' || cacheData === null) {
												return;
											}
											const cachedRequest = cache.readQuery<GetBusinessArea>({
												query: GET_BUSINESS_AREAS,
											});

											if (cachedRequest === null || cachedRequest.businessAreas === null) {
												return;
											}

											cache.writeQuery<GetBusinessArea>({
												query: GET_BUSINESS_AREAS,
												data: {
													businessAreas: cachedRequest.businessAreas.filter(c => c.id !== data.id),
												},
											});
										},
									});
									close();
								}}
								className="mt-4"
							/>
						</div>
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<Input title="common.name" required name="name" innerRef={register} defaultValue={data?.name} errorMessage={errors.name?.message} />

						<Button success submit text={data ? 'businessArea.update' : 'businessArea.create'} loading={createLoading || updateLoading} className="mt-4" />
					</form>
				)
			}
		/>
	);
};

export default BusinessAreaModal;
