import React from 'react';
import { GetAdminCatalogCustomers, GetAdminCatalogCustomers_catalogCustomers } from '../../GraphQL';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashAlt, faUsers, faUsersSlash } from '@fortawesome/pro-regular-svg-icons';
import Table from '@ssg/common/Components/Table';
import CatalogCustomerModal from './CatalogCustomerModal';
import TextButton from '@ssg/common/Components/TextButton';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import AllCatalogCustomersModal from './AllCatalogCustomersModal';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';

const CATALOG_CUSTOMERS = loader('../../GraphQL/CatalogCustomers/GetAdminCatalogCustomers.gql');


const CatalogCustomers: React.FC = (): React.ReactElement => {
	const { loading: loadingCustomerList, data: customersList, refetch } = useQuery<GetAdminCatalogCustomers>(CATALOG_CUSTOMERS, {
		fetchPolicy: 'cache-and-network',
	});

	const addRemoveAllCatalogCustomersFlag = useFlag(FeatureFlagEnums.ADD_REMOVE_ALL_CATALOG_CUSTOMERS);
	const [createCatalogCustomer, setCreateCatalogCustomer] = React.useState(false);
	const [updateCatalogCustomer, setUpdateCatalogCustomer] = React.useState(false);
	const [deleteCatalogCustomer, setDeleteCatalogCustomer] = React.useState(false);
	const [addRemove, setAddRemove] = React.useState<'ADD' | 'REMOVE'>();
	const [catalogCustomer, setCatalogCustomer] = React.useState<GetAdminCatalogCustomers_catalogCustomers>();

	return (
		<div>
			<div className="text-blue mx-14 bg-white p-4 pb-1">

				<div className="flex flex-row justify-end space-x-4 w-full">
					<div>
						{addRemoveAllCatalogCustomersFlag &&
							<>
								<FormFieldHeader title="catalog.customer.userActions" />
								<TextButton text="catalog.customer.addToAll" icon={faUsers} onClick={() => setAddRemove('ADD')} />
								<TextButton text="catalog.customer.removeFromAll" icon={faUsersSlash} onClick={() => setAddRemove('REMOVE')} />
							</>
						}
					</div>
					<div>
						<FormFieldHeader title="catalog.customer.actions" />
						<TextButton text="catalog.customer.addCustomer" icon={faPlus} onClick={() => setCreateCatalogCustomer(true)} />
					</div>

				</div>
				<Table
					data={customersList?.catalogCustomers ?? []}
					loading={loadingCustomerList}
					columns={[
						{
							label: 'common.name',
							selectFn: c => <p className="py-2">{c.name} </p>,
							sortFn: (a, b) => a.name.localeCompare(b.name),
						},
						{
							label: 'catalog.customer.users',
							selectFn: c => (
								<div>
									{c.users.map(u => (
										<p key={u.id}>
											{u.name} ({u.email})
										</p>
									))}{' '}
								</div>
							),
							sortFn: (a, b) => ((a.user && a?.user.id) ?? '').localeCompare((b.user && b?.user.id) ?? ''),
						},
						{
							label: 'common.edit',
							classNameTh: 'text-right',
							selectFn: c => (
								<div className="flex content-start justify-end text-right">
									<FontAwesomeIcon
										icon={faEdit}
										size="lg"
										onClick={() => {
											setCatalogCustomer(c);
											setUpdateCatalogCustomer(true);
										}}
										className="cursor-pointer"
									/>
								</div>
							),
						},
						{
							label: 'common.delete',
							classNameTh: 'text-right',
							selectFn: c => (
								<div className="text-red flex content-start justify-end text-right">
									<FontAwesomeIcon
										icon={faTrashAlt}
										size="lg"
										onClick={() => {
											setCatalogCustomer(c);
											setDeleteCatalogCustomer(true);
										}}
										className="cursor-pointer"
									/>
								</div>
							),
						},
					]}
					keySelector={c => c.id}
				/>
				{createCatalogCustomer && <CatalogCustomerModal open={createCatalogCustomer} close={() => setCreateCatalogCustomer(false)} />}

				{updateCatalogCustomer && <CatalogCustomerModal edit open={updateCatalogCustomer} close={() => setUpdateCatalogCustomer(false)} customer={catalogCustomer} />}

				{deleteCatalogCustomer && <CatalogCustomerModal erase open={deleteCatalogCustomer} close={() => setDeleteCatalogCustomer(false)} customer={catalogCustomer} />}

				{addRemove && <AllCatalogCustomersModal visible={typeof addRemove !== 'undefined'} close={() => setAddRemove(undefined)} add={addRemove === 'ADD'} refetch={refetch} />}
			</div>
		</div>
	);
};

export default CatalogCustomers;
