import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tree, DragLayerMonitorProps, getDescendants, NodeModel, TreeMethods, DropOptions } from '@minoru/react-dnd-treeview';
import { faPlus, faExpandArrowsAlt, faCompressArrowsAlt, faEdit } from '@fortawesome/pro-regular-svg-icons';
import { DrivingSlipQuestionnaireNode } from './DrivingSlipQuestionnaireNode';
import { DrivingSlipQuestionnaireNodeDragPreview } from './DrivingSlipQuestionnaireNodeDragPreview';
import { DrivingSlipQuestionnaireNodeData } from './DrivingSlipQuestionnaireNodeData';
import DrivingSlipQuestionnaireNodeCreationModal from './DrivingSlipQuestionnaireNodeCreationModal';
import { BaseQuestionnaireTemplateFragment_sections as Section, BaseQuestionnaireTemplateFragment_sections_choices as Choice, CaseAgreementCategory, QuestionnaireChoiceType } from '../../GraphQL';
import { ItemModel } from '../../Components/ItemManager/ItemModel';
import Dropdown from '@ssg/common/Components/Dropdown';
import Button from '@ssg/common/Components/Button';
import newGuid from '@ssg/common/Helpers/guidHelper';
import 'react-sortable-tree/style.css';
import styles from './DrivingSlipQuestionnaireTab.module.css';
import ItemManagerModal from '../../Components/ItemManager/ItemManager';
import Checkbox from '@ssg/common/Components/Checkbox';
import { act } from 'react-dom/test-utils';

function choiceTypeHasSelectableOptions(choiceType?: QuestionnaireChoiceType): boolean {
	return choiceType === QuestionnaireChoiceType.DROPDOWN || choiceType === QuestionnaireChoiceType.RADIO_GROUP;
}

const choicesToNodes = (choices: Choice[], rootParentNodeId: string): NodeModel<DrivingSlipQuestionnaireNodeData>[] =>
	choices.map((choice: Choice) => ({
		id: choice.id,
		parent: choice.parentId ?? rootParentNodeId,
		text: choice.label,
		droppable: true,
		data: {
			choiceType: choice.type,
			placeholder: choice.placeholder,
			helpText: choice.helpText,
			isOptional: choice.isOptional,
			triggersTrackTwoChange: choice.triggersTrackTwoChange ?? false,
			unitOfMeasure: choice.unitOfMeasure,
			isMultiSelectAllowed: false,
		},
	}));

const nodesToChoices = (nodes: NodeModel<DrivingSlipQuestionnaireNodeData>[], rootParentNodeId?: string): Choice[] =>
	nodes
		.filter(node => node.parent === rootParentNodeId)
		.reduce((choices: Choice[], node: NodeModel<DrivingSlipQuestionnaireNodeData>) => {
			if (node.data) {
				choices.push({
					id: node.id as string,
					parentId: node.parent as string,
					type: node.data.choiceType,
					label: node.text,
					value: node.data.choiceType === QuestionnaireChoiceType.SELECT_OPTION ? node.text : null,
					placeholder: node.data.placeholder,
					helpText: node.data.helpText,
					isOptional: node.data.isOptional,
					triggersTrackTwoChange: node.data.triggersTrackTwoChange,
					unitOfMeasure: node.data.unitOfMeasure,
					isMultiSelectAllowed: node.data.isMultiSelectAllowed,
					multiSelectValues: [],
				});
			}

			return choices.concat(nodesToChoices(nodes, node.id as string));
		}, []);

interface Props {
	sections: Section[];
	activeSection?: string;
	onChangeChoices: (sectionName: string, updatedChoices: Choice[]) => void;
	onChangeSections: (updatedSections: Section[], sectionName?: string) => void;
}

const DrivingSlipQuestionnaireTab: React.FC<Props> = ({ sections, activeSection: initialActiveSection, onChangeChoices, onChangeSections }) => {
	const { t } = useTranslation();

	const [activeSection, setActiveSection] = React.useState<string>();
	const activeSectionObject = useMemo(() => sections.find(section => section.label === activeSection), [activeSection, sections]);

	const [treeData, setTreeData] = React.useState<NodeModel<DrivingSlipQuestionnaireNodeData>[]>([]);

	const [visibleSectionsEditor, setVisibleSectionsEditor] = React.useState<boolean>();
	const [visibleCreateNodeModal, setVisibleCreateNodeModal] = React.useState<boolean>(false);
	const [parentOfNodeToCreate, setParentOfNodeToAdd] = React.useState<NodeModel<DrivingSlipQuestionnaireNodeData>>();

	const ref = React.useRef<TreeMethods>(null);
	const handleOpenAll = () => setTimeout(() => ref.current?.openAll(), 10);
	const handleCloseAll = () => setTimeout(() => ref.current?.closeAll(), 10);

	// Nodes do not open or close unless wrapped within a timeout
	const handleOpen = (nodeId: string | string[]) => setTimeout(() => ref.current?.open(nodeId), 10);
	const handleClose = (nodeId: string | string[]) => setTimeout(() => ref.current?.close(nodeId), 10);

	// Assign the name of the section as the root choice parent id
	const getRootParentNodeId = React.useCallback(() => activeSection!, [activeSection]);

	React.useEffect(() => {
		setActiveSection(initialActiveSection ?? sections[0]?.label);
	}, [sections, initialActiveSection]);

	React.useEffect(() => {
		const section = sections.find(s => s.label === activeSection);
		if (section) {
			const choices = [...choicesToNodes(section.choices, getRootParentNodeId())];
			setTreeData(choices);
			handleOpenAll();
		} else {
			setTreeData([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeSection]);

	React.useEffect(() => {
		if (activeSection) {
			const newChoices = nodesToChoices(treeData, getRootParentNodeId());

			// Update parent state every time the tree data changes
			onChangeChoices(activeSection, newChoices);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [treeData]);

	const getSectionsAsItems = React.useCallback(
		() =>
			sections.map((section, index) => ({
				id: index,
				text: section.label,
			})),
		[sections],
	);

	const canDrop = (_currentTree: NodeModel<DrivingSlipQuestionnaireNodeData>[], options: DropOptions<DrivingSlipQuestionnaireNodeData>) => {
		const { dragSource, dropTarget } = options;

		// If the target is a Dropdown/Radio then we can only move SelectOptions there
		if (choiceTypeHasSelectableOptions(dropTarget?.data?.choiceType)) {
			return dragSource?.data?.choiceType === QuestionnaireChoiceType.SELECT_OPTION;
		}

		// If the source is a SelectOption then only allow moving to a Dropdown/Radio
		if (dragSource?.data?.choiceType === QuestionnaireChoiceType.SELECT_OPTION) {
			return choiceTypeHasSelectableOptions(dropTarget?.data?.choiceType);
		}

		// Return default behaviour => cannot drop nodes on themselves
		return;
	};

	const handleDrop = (newTree: NodeModel<DrivingSlipQuestionnaireNodeData>[]) => setTreeData(newTree);

	const handleDeleteNode = (id: NodeModel['id']) => {
		const deleteIds = [id, ...getDescendants(treeData, id).map(node => node.id)];
		const newTree = treeData.filter(node => !deleteIds.includes(node.id));

		setTreeData(newTree);
	};

	const handleCopyNode = (id: NodeModel['id']) => {
		const targetNode = treeData.find(node => node.id === id);

		if (!targetNode) {
			return;
		}

		const targetNodeNewId = newGuid();

		const oldIdToNewIdMap = new Map<string, string>();
		oldIdToNewIdMap.set(targetNode.id as string, targetNodeNewId);

		const descendants = getDescendants(treeData, id);
		descendants.forEach(node => oldIdToNewIdMap.set(node.id as string, newGuid()));

		const partialTree = descendants.map((node: NodeModel<DrivingSlipQuestionnaireNodeData>) => ({
			...node,
			id: oldIdToNewIdMap.get(node.id as string),
			parent: oldIdToNewIdMap.get(node.parent as string),
		}));

		const data = [
			...treeData,
			{
				...targetNode,
				id: targetNodeNewId,
			},
			...partialTree,
		] as NodeModel<DrivingSlipQuestionnaireNodeData>[];

		setTreeData(data);
		handleOpen(targetNodeNewId);
	};

	const handleEditNode = (updatedNode: NodeModel<DrivingSlipQuestionnaireNodeData>) => {
		const newTree = treeData.map(node => {
			if (node.id === updatedNode.id) {
				return {
					...updatedNode,
				};
			}

			return node;
		});

		setTreeData(newTree);
	};

	const handleCreateNode = (newNode: Omit<NodeModel<DrivingSlipQuestionnaireNodeData>, 'id'>, newNodeChildNodes?: Omit<NodeModel<DrivingSlipQuestionnaireNodeData>, 'id' | 'parent'>[]) => {
		const newNodeId = newGuid();

		const children: NodeModel<DrivingSlipQuestionnaireNodeData>[] =
			newNodeChildNodes?.map((child: Omit<NodeModel<DrivingSlipQuestionnaireNodeData>, 'id' | 'parent'>) => ({
				...child,
				id: newGuid(),
				parent: newNodeId,
			})) ?? [];

		const nodesToAdd = [
			{
				...newNode,
				id: newNodeId,
			},
			...children,
		];

		const newTreeData = [...treeData, ...nodesToAdd];

		setTreeData(newTreeData);
		handleOpen(nodesToAdd.map(node => node.id as string));

		setVisibleCreateNodeModal(false);
		setParentOfNodeToAdd(undefined);
	};

	const handleShowCreateNodeModal = (id: NodeModel['id']) => {
		const parent = treeData.find(node => node.id === id);
		setParentOfNodeToAdd(parent);
		setVisibleCreateNodeModal(true);
	};

	const handleCloseCreateNodeModal = () => {
		setParentOfNodeToAdd(undefined);
		setVisibleCreateNodeModal(false);
	};

	const openSectionsEditor = () => {
		setVisibleSectionsEditor(true);
	};

	const handleOnCloseSectionsEditor = () => {
		setVisibleSectionsEditor(false);
	};

	const handleOnSubmitSectionsEditor = (updatedItems: ItemModel[]) => {
		const originalItems = getSectionsAsItems();

		const getNewActiveSection = (sections: Section[]): string | undefined => {
			const activeSectionItem = originalItems.find(i => i.text === activeSection);
			const updatedSectionItem = updatedItems.find(i => i.id === activeSectionItem?.id);

			// If the section(s) has been been deleted then set active to undefined
			if (sections.length === 0) {
				return undefined;
			}

			if (!activeSectionItem || !updatedSectionItem) {
				return sections[0].label;
			}

			const sectionLabel = updatedSectionItem.text === activeSectionItem.text ? activeSection : updatedSectionItem.text;
			return sectionLabel;
		};

		const updatedSections = updatedItems.reduce((newSections: Section[], updatedItem: ItemModel) => {
			const sectionItem = originalItems.find(i => i.id === updatedItem.id);
			if (!sectionItem) {
				return newSections.concat([
					{
						label: updatedItem.text,
						choices: [],
						photoRequired: false,
						shouldSaveInScalePoint: true,
						caseAgreementCategory: CaseAgreementCategory.OTHER,
					},
				]);
			}

			const section = sections.find(t => t.label === sectionItem.text);

			if (section) {
				newSections.push({
					...section,
					label: updatedItem.text,
					choices: section.choices.map(choice => {
						// If the section name has changed then we must update the root choice's parent id too
						if (choice.parentId === sectionItem.text) {
							return {
								...choice,
								parentId: updatedItem.text,
							};
						}

						return choice;
					}),
				});
			}

			return newSections;
		}, []);

		const newActiveSection = getNewActiveSection(updatedSections);
		onChangeSections(updatedSections, newActiveSection);
		setVisibleSectionsEditor(false);
	};

	const handleSectionUpdates = (updates: Partial<Section>) => {
		if (activeSection) {
			onChangeSections(
				sections.map(section => {
					if (section.label !== activeSection) {
						return section;
					}

					return { ...section, ...updates };
				}),
				activeSection,
			);
		}
	};

	return (
		<>
			<div className="text-blue h-full w-full">
				<div className="flex">
					<p className="text-blue p-2 font-semibold">{t('questionnaire.sections')}</p>
				</div>
				<div className="border-b-1 flex items-center border-gray-500 p-3">
					<div className="flex w-1/3">
						<Dropdown
							name="section-name"
							onChange={e => {
								const value = e.target.value;
								const section = sections.find(section => section.label === value);
								if (section) {
									setActiveSection(section.label);
								}
							}}
							data={sections.map(section => ({
								value: section.label,
								label: section.label,
							}))}
							className="h-9 w-full pr-8"
							value={activeSection}
							disabled={sections.length === 0}
							disableSortLabelAlphabetically={true}
						/>
					</div>
					<div className="flex w-2/3 justify-between">
						<div className="flex">
							<Checkbox
								name="sectionPhotoRequired"
								title={t('common.pictureRequired')}
								className="ml-3"
								checkedControlled={activeSectionObject?.photoRequired ?? false}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const checked = e.target.checked;
									const updates: Partial<Section> = {
										photoRequired: checked,
									};
									handleSectionUpdates(updates);
								}}
								topDivClassName="justify-center"
							/>
						</div>

						<div className="flex">
							<Checkbox
								name="sectionShouldSaveInScalePoint"
								title={t('questionnaire.saveInScalePoint')}
								className="ml-3"
								checkedControlled={activeSectionObject?.shouldSaveInScalePoint ?? true}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const checked = e.target.checked;
									const updates: Partial<Section> = {
										shouldSaveInScalePoint: checked,
									};
									handleSectionUpdates(updates);
								}}
								topDivClassName="justify-center"
							/>
						</div>

						<div className="flex">
							<Dropdown
								title="caseAgreements.caseAgreement"
								name="section-caseAgreementCategory"
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
									const value = e.target.value;
									const updates: Partial<Section> = {
										caseAgreementCategory: value as CaseAgreementCategory,
									};
									handleSectionUpdates(updates);
								}}
								data={[
									{
										label: t(`caseAgreements.${CaseAgreementCategory.CAUSE}`),
										value: CaseAgreementCategory.CAUSE,
									},
									{
										label: t(`caseAgreements.${CaseAgreementCategory.SCOPE}`),
										value: CaseAgreementCategory.SCOPE,
									},
									{
										label: t(`caseAgreements.${CaseAgreementCategory.WORK_COMPLETED}`),
										value: CaseAgreementCategory.WORK_COMPLETED,
									},
									{
										label: t(`caseAgreements.${CaseAgreementCategory.FURTHER_ACTION}`),
										value: CaseAgreementCategory.FURTHER_ACTION,
									},
									{
										label: t(`caseAgreements.${CaseAgreementCategory.OTHER}`),
										value: CaseAgreementCategory.OTHER,
									}, // Default
									{
										label: t(`caseAgreements.${CaseAgreementCategory.PRIVATE}`),
										value: CaseAgreementCategory.PRIVATE,
									},
								]}
								className="ml-2 h-9 w-full pr-8"
								style={{ width: '200px' }}
								value={activeSectionObject?.caseAgreementCategory ?? CaseAgreementCategory.OTHER}
								disabled={sections.length === 0}
								disableSortLabelAlphabetically={true}
							/>
						</div>

						<div className="flex">
							<Button primary icon={faEdit} onClick={() => openSectionsEditor()} className="ml-auto h-9">
								{t('questionnaire.sectionsEditor')}
							</Button>
						</div>
					</div>
				</div>

				<div className="flex">
					<p className="text-blue p-2 font-semibold">{t('questionnaire.choices')}</p>
				</div>

				<div className="mt-2 flex px-3">
					<Button secondary onClick={handleOpenAll} icon={faExpandArrowsAlt} disabled={treeData.length === 0}>
						{t('questionnaire.openAll')}
					</Button>
					<Button secondary onClick={handleCloseAll} icon={faCompressArrowsAlt} className="ml-2" disabled={treeData.length === 0}>
						{t('questionnaire.closeAll')}
					</Button>
					<Button
						secondary
						onClick={() => {
							if (!activeSection) {
								alert(t('questionnaire.sectionNameRequired'));
								return;
							}
							setVisibleCreateNodeModal(true);
						}}
						icon={faPlus}
						className="ml-2"
						disabled={sections.length === 0}
					>
						{t('questionnaire.addChoice')}
					</Button>
				</div>

				<div className="mt-2 overflow-auto px-3" style={{ height: 640 }}>
					{/* If this bugs out with Invariant Violation: Expected drag drop context check wiki for potential fix */}
					<Tree
						ref={ref}
						tree={treeData}
						rootId={getRootParentNodeId()}
						render={(node: NodeModel<DrivingSlipQuestionnaireNodeData>, options) => (
							<DrivingSlipQuestionnaireNode node={node} {...options} onDelete={handleDeleteNode} onCopy={handleCopyNode} onAddChild={handleShowCreateNodeModal} onEdit={handleEditNode} />
						)}
						dragPreviewRender={(monitorProps: DragLayerMonitorProps<DrivingSlipQuestionnaireNodeData>) => <DrivingSlipQuestionnaireNodeDragPreview monitorProps={monitorProps} />}
						canDrop={canDrop}
						onDrop={handleDrop}
						classes={{
							root: styles.treeRoot,
							draggingSource: styles.draggingSource,
							dropTarget: styles.dropTarget,
						}}
						sort={false}
					/>
				</div>
			</div>
			{visibleCreateNodeModal && (
				<DrivingSlipQuestionnaireNodeCreationModal
					visible={visibleCreateNodeModal}
					parent={parentOfNodeToCreate}
					rootParentNodeId={getRootParentNodeId()}
					onClose={handleCloseCreateNodeModal}
					onSubmit={handleCreateNode}
					treeData={treeData}
				/>
			)}
			{visibleSectionsEditor && (
				<ItemManagerModal
					visible={visibleSectionsEditor}
					title={t('questionnaire.sectionsEditor')}
					items={getSectionsAsItems()}
					newItemInputPlaceholder={t('questionnaire.sectionName')}
					onClose={handleOnCloseSectionsEditor}
					onSubmit={handleOnSubmitSectionsEditor}
				/>
			)}
		</>
	);
};

export default DrivingSlipQuestionnaireTab;
