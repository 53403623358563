import React from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
	CreateDamageCategory,
	CreateDamageCategoryVariables,
	DeleteDamageCategory,
	DeleteDamageCategoryVariables,
	GetAdminDamageCategories,
	GetAdminDamageCategories_damageCategoriesAdmin,
	UpdateDamageCategory,
	UpdateDamageCategoryVariables,
} from '../../../GraphQL';
import { useForm } from 'react-hook-form';
import { IDamageCategory } from '../../../Schemas/IDamageCategory';
import { yupResolver } from '@hookform/resolvers/yup';
import { DamageCategorySchema } from '../../../Schemas/DamageCategorySchema';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';

const GET_DAMAGE_CATEGORIES = loader('src/GraphQL/DamageCategory/GetAdminDamageCategories.gql');
const UPATE_DAMAGE_CATEGORIES = loader('src/GraphQL/DamageCategory/UpdateDamageCategory.gql');
const DELETE_DAMAGE_CATEGORIES = loader('src/GraphQL/DamageCategory/DeleteDamageCategory.gql');
const CREATE_DAMAGE_CATEGORIES = loader('src/GraphQL/DamageCategory/CreateDamageCategory.gql');

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	erase?: boolean;
	data?: GetAdminDamageCategories_damageCategoriesAdmin;
	submitCb?: () => unknown;
}

const DamageCategoryModal: React.FC<Props> = ({ open, close, edit = false, erase = false, data, submitCb }) => {
	const [updateDamageCategory, { loading: updateLoading }] = useMutation<UpdateDamageCategory, UpdateDamageCategoryVariables>(UPATE_DAMAGE_CATEGORIES);
	const [createDamageCategory, { loading: createLoading }] = useMutation<CreateDamageCategory, CreateDamageCategoryVariables>(CREATE_DAMAGE_CATEGORIES);
	const [deleteDamageCategory, { loading: deleteLoading }] = useMutation<DeleteDamageCategory, DeleteDamageCategoryVariables>(DELETE_DAMAGE_CATEGORIES);

	const { handleSubmit, register, errors } = useForm<IDamageCategory>({
		resolver: yupResolver(DamageCategorySchema),
	});

	const onSubmit = async (category: IDamageCategory) => {
		if (edit) {
			try {
				await updateDamageCategory({
					variables: {
						id: data?.id ?? '',
						name: category.name,
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await createDamageCategory({
					variables: {
						damageCategory: {
							name: category.name,
						},
					},
				});
			} catch (e) {
				console.log(e);
			}
		}

		submitCb?.();
	};

	return (
		<Modal
			title={(erase && 'damageCategory.delete') || (data && 'damageCategory.update') || 'damageCategory.create'}
			size={ModalSize.SMALL}
			visible={open}
			close={close}
			body={
				erase && typeof data !== 'undefined' ? (
					<div>
						<FormFieldHeader title="damageCategory.wantToDelete" />
						<div>
							<div>{data.name}</div>

							<Button
								danger
								text="damageCategory.delete"
								loading={deleteLoading}
								onClick={async () => {
									await deleteDamageCategory({
										variables: {
											id: data.id,
										},
										update: (cache, { data: cacheData }): void => {
											if (typeof cacheData === 'undefined' || cacheData === null) {
												return;
											}
											const cachedRequest = cache.readQuery<GetAdminDamageCategories>({
												query: GET_DAMAGE_CATEGORIES,
											});

											if (cachedRequest === null || cachedRequest.damageCategoriesAdmin === null) {
												return;
											}

											cache.writeQuery<GetAdminDamageCategories>({
												query: GET_DAMAGE_CATEGORIES,
												data: {
													damageCategoriesAdmin: cachedRequest.damageCategoriesAdmin.filter(m => m.id !== data.id),
												},
											});
										},
									});
									close();
								}}
								className="mt-4"
							/>
						</div>
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<Input title="common.name" required name="name" innerRef={register} defaultValue={data?.name} errorMessage={errors.name?.message} />

						<Button success submit text={data ? 'damageCategory.update' : 'damageCategory.create'} loading={createLoading || updateLoading} className="mt-4" />
					</form>
				)
			}
		/>
	);
};

export default DamageCategoryModal;
