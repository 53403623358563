import React from 'react';
import Header from '@ssg/common/Components/Header';

// const GET_CURRENT_USER = loader('../GraphQL/User/GetCurrentUser.gql');

const Home: React.FC = () => {
	// const { data } = useQuery(GET_CURRENT_USER);

	return (
		<div>
			<Header title="Hjem"></Header>
		</div>
	);
};

export default Home;
