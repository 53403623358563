import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlus, faCopy, faTrash, faEdit } from '@fortawesome/pro-regular-svg-icons';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { useDragOver } from '@minoru/react-dnd-treeview';
import { DrivingSlipQuestionnaireNodeData } from './DrivingSlipQuestionnaireNodeData';
import { QuestionnaireChoiceType } from '../../GraphQL';
import styles from './DrivingSlipQuestionnaireNode.module.css';
import DrivingSlipQuestionnaireNodeEditModal from './DrivingSlipQuestionnaireNodeEditModal';

type Props = {
	node: NodeModel<DrivingSlipQuestionnaireNodeData>;
	depth: number;
	isOpen: boolean;
	onToggle: (id: NodeModel['id']) => void;
	onDelete: (id: NodeModel['id']) => void;
	onCopy: (id: NodeModel['id']) => void;
	onAddChild: (id: NodeModel['id']) => void;
	onEdit: (updatedNode: NodeModel<DrivingSlipQuestionnaireNodeData>) => void;
};

export const DrivingSlipQuestionnaireNode: React.FC<Props> = ({ depth, node, isOpen, onToggle, onDelete, onCopy, onAddChild, onEdit }) => {
	const { id } = node;
	const indent = depth * 24;

	const { t } = useTranslation();
	const [hover, setHover] = React.useState<boolean>(false);
	const [visibleEditNodeModal, setVisibleEditNodeModal] = React.useState<boolean>(false);

	const getTranslationForChoiceType = (choiceType: QuestionnaireChoiceType | undefined) => {
		switch (choiceType) {
			case QuestionnaireChoiceType.DROPDOWN: // Dropdowns are technically supported but we should avoid using them to enhance UX on mobile
				return t('questionnaire.choiceRadioGroup');
			case QuestionnaireChoiceType.RADIO_GROUP:
				return t('questionnaire.choiceRadioGroup');
			case QuestionnaireChoiceType.SELECT_OPTION:
				return t('questionnaire.choiceRadioGroupOption');
			case QuestionnaireChoiceType.FREE_TEXT:
				return t('questionnaire.choiceFreeText');
			case QuestionnaireChoiceType.NUMBER:
				return t('questionnaire.choiceNumber');
			default:
				return;
		}
	};

	const handleToggle = (e: React.MouseEvent) => {
		e.stopPropagation();
		onToggle(node.id);
	};

	const dragOverProps = useDragOver(id, isOpen, onToggle);

	return (
		<>
			<div className={`tree-node ${styles.root}`} style={{ paddingInlineStart: indent }} {...dragOverProps} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
				<div className={`${styles.expandIconWrapper} ${isOpen ? styles.isOpen : ''}`}>
					{node.droppable && (
						<div onClick={handleToggle}>
							<FontAwesomeIcon
								icon={faArrowRight}
								className="cursor-pointer"
								style={{
									height: '0.875rem',
									width: '0.875rem',
								}}
							/>
						</div>
					)}
				</div>
				<div style={{ paddingInlineStart: '8px' }}>
					<>
						<span>{node.text}</span>
						<span className="ml-2">({getTranslationForChoiceType(node.data?.choiceType)})</span>
					</>
				</div>
				{hover && (
					<>
						<div className="ml-5 pl-1 pr-1">
							<FontAwesomeIcon icon={faEdit} className="cursor-pointer" onClick={() => setVisibleEditNodeModal(true)} title={t('common.edit')} />
						</div>
						<div className="pl-1 pr-1">
							<FontAwesomeIcon icon={faTrash} className="cursor-pointer" onClick={() => onDelete(id)} title={t('common.delete')} />
						</div>
						<div className="pl-1 pr-1">
							<FontAwesomeIcon icon={faPlus} className="cursor-pointer" onClick={() => onAddChild(id)} title={t('common.add')} />
						</div>
						<div className="pl-1 pr-1">
							<FontAwesomeIcon icon={faCopy} className="cursor-pointer" onClick={() => onCopy(id)} title={t('common.copy')} />
						</div>
					</>
				)}
			</div>
			{visibleEditNodeModal && (
				<DrivingSlipQuestionnaireNodeEditModal
					visible={visibleEditNodeModal}
					node={node}
					onClose={() => setVisibleEditNodeModal(false)}
					onSubmit={(updatedNode: NodeModel<DrivingSlipQuestionnaireNodeData>) => {
						onEdit(updatedNode);
						setVisibleEditNodeModal(false);
					}}
				/>
			)}
		</>
	);
};
