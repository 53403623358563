import React from 'react';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { CreateSettings, CreateSettingsVariables, GetSettings, GetSettings_setting_CatalogNotificationSetting_debitorVerifyNotifications, GetSettings_setting_CatalogNotificationSetting_verifyNotifications, UpdateSettings, UpdateSettingsVariables } from '../../GraphQL';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import Loading from '@ssg/common/Components/Loading';
import Box from '../../Components/Layout/Box';
import { useTranslation } from 'react-i18next';
import TextButton from '@ssg/common/Components/TextButton';
import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { removeTypename } from '@ssg/common/Helpers/typenameHelper';
import FormErrorText from '@ssg/common/Components/FormErrorText';

const GET_SETTINGS = loader('src/GraphQL/AdminSettings/GetSettings.gql');
const CREATE_SETTING = loader('src/GraphQL/AdminSettings/CreateSettings.gql');
const UPDATE_SETTING = loader('src/GraphQL/AdminSettings/UpdateSettings.gql');

const CatalogNotification = 'CatalogNotification';

const CatalogSettings: React.FC = () => {

	const { t } = useTranslation();

	const [verifyNotifications, setVerifyNotifications] = React.useState<GetSettings_setting_CatalogNotificationSetting_verifyNotifications[]>([]);
	const [debitorVerifyNotifications, setDebitorVerifyNotifications] = React.useState<GetSettings_setting_CatalogNotificationSetting_debitorVerifyNotifications[]>([]);
	const {
		data,
		loading: CatalogNotificationSettingLoading,
		refetch,
	} = useQuery<GetSettings>(GET_SETTINGS, {
		variables: {
			type: CatalogNotification,
		},
		onCompleted(data) {
			if ('verifyNotifications' in data.setting[0]) {
				setVerifyNotifications(data.setting[0].verifyNotifications);
				setDebitorVerifyNotifications(data.setting[0].debitorVerifyNotifications);
			}
		},
	});


	const [createSetting, { loading: loadingCreate }] = useMutation<CreateSettings, CreateSettingsVariables>(CREATE_SETTING);
	const [updateSetting, { loading: loadingUpdate }] = useMutation<UpdateSettings, UpdateSettingsVariables>(UPDATE_SETTING);
	const [error, setError] = React.useState<'EMPTY'>();
	// const { handleSubmit, register, errors } = useForm<Emails>({
	// 	resolver: yupResolver(CatalogEmailSchema),
	// 	mode: 'all',
	// 	reValidateMode: 'onChange',
	// });

	const onSubmit = async () => {
		setError(undefined);
		const id = data?.setting[0]?.id;

		const notifications = [...verifyNotifications, ...debitorVerifyNotifications];

		if (notifications.some(n => n.email.length === 0)) {
			setError('EMPTY');
			return;
		}
		if (id) {
			await updateSetting({
				variables: {
					id: id,
					catalogNotificationSetting: {
						type: CatalogNotification,
						notifications: removeTypename(notifications),
					},
				},
			});

		} else {
			await createSetting({
				variables: {
					catalogNotificationSetting: {
						type: CatalogNotification,
						notifications: notifications,
					},
				},
			});
			await refetch();
		}
	};

	const change = (data: GetSettings_setting_CatalogNotificationSetting_debitorVerifyNotifications, index: number) => {
		if (data.debitorVerification) return setDebitorVerifyNotifications(current => {
			return [
				...current.slice(0, index),
				{
					...current[index],
					...data,
				},
				...current.slice(index + 1),
			];
		});

		return setVerifyNotifications(current => {
			return [
				...current.slice(0, index),
				{
					...current[index],
					...data,
				},
				...current.slice(index + 1),
			];
		});
	};

	const addEmpty = (debitorVerify: boolean) => {
		const newNotification: GetSettings_setting_CatalogNotificationSetting_debitorVerifyNotifications = {
			email: '',
			debitorVerification: debitorVerify,
		};

		if (newNotification.debitorVerification) return setDebitorVerifyNotifications(current => [...current, newNotification]);

		return setVerifyNotifications(current => [...current, newNotification]);
	};

	const deleteEmail = (debitorVerify: boolean, deleteIndex: number) => {
		if (debitorVerify) return setDebitorVerifyNotifications(current => current.filter((_, index) => index !== deleteIndex));
		return setVerifyNotifications(current => current.filter((_, index) => index !== deleteIndex));
	};

	if (CatalogNotificationSettingLoading) return <Loading />;

	return (
		<Box title="">
			<div className="text-blue mb-2">
				<form>
					<h2>{t('catalogNotification.changeVerifyMail')}:</h2>
					<div className="space-y-2">
						{verifyNotifications.map((v, i) =>
							<div key={i} className="flex flex-row space-x-2">
								<Input
									title=""
									name="verifyMail.email"
									onChange={(e) => change({ ...v, email: e.currentTarget.value }, i)}
									value={v.email}
								/>
								<TextButton icon={faTrash} iconClassName="text-red text-lg" onClick={() => deleteEmail(false, i)} />
							</div>,
						)}
					</div>
					<TextButton text="common.add" icon={faPlus} loading={loadingCreate || loadingUpdate} onClick={() => addEmpty(false)} className="mt-2" />
					<h2 className="mt-2">{t('catalogNotification.changeDebitorVerifyMail')}</h2>
					<div className="space-y-2">
						{debitorVerifyNotifications.map((v, i) =>
							<div key={i} className="flex flex-row space-x-2">
								<Input
									title=""
									name="verifyMail.email"
									onChange={(e) => change({ ...v, email: e.currentTarget.value }, i)}
									value={v.email}
								/>
								<TextButton icon={faTrash} iconClassName="text-red text-lg" onClick={() => deleteEmail(true, i)} />
							</div>,
						)}
					</div>
					<TextButton text="common.add" icon={faPlus} loading={loadingCreate || loadingUpdate} onClick={() => addEmpty(true)} className="mt-2" />
					<div>
						<Button success text="common.save" onClick={() => onSubmit()} loading={loadingCreate || loadingUpdate} className="mt-2" />
						{error === 'EMPTY' && <FormErrorText text='catalogNotification.noEmpty' />}
					</div>
				</form>
				{/* <div>
						<div className="w-1/2">
							<div className="relative">
								<Input
									title="catalogNotification.changeVerifyMail"
									name="verifyMail.email"
									innerRef={register}
									defaultValue={emailForVerify && emailForVerify.email}
									errorMessage={errors.verifyMail?.email?.message}
									className="z-50 pr-8 text-sm lg:w-full"
								/>
								<Input title="" name="verifyMail.type" innerRef={register} defaultValue={CatalogVerifyEmail} className="invisible" />
							</div>
						</div>
					</div>
					<div>
						<div className="w-1/2">
							<div className="relative">
								<Input
									title="catalogNotification.changeDebitorVerifyMail"
									name="verifyDebitorMail.email"
									innerRef={register}
									defaultValue={debitorEmailForVerify && debitorEmailForVerify.email}
									errorMessage={errors.verifyDebitorMail?.email?.message}
									className="z-50 pr-8 text-sm lg:w-full"
								/>
								<Input title="" name="verifyDebitorMail.type" innerRef={register} defaultValue={CatalogVerifyDebitorEmail} className="invisible" />
							</div>
						</div>

						<div>
							<Button success text="common.save" loading={loadingCreate || loadingUpdate} className="mt-2" />
						</div>
					</div> */}
			</div>
		</Box>
	);
};

export default CatalogSettings;
