import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Modal from '@ssg/common/Components/Modal';
import Input from '@ssg/common/Components/Input';
import Button from '@ssg/common/Components/Button';

interface Props {
	visible: boolean;
	close: () => unknown;
	blockedValues: string[];
	addValue: (value: string) => Promise<unknown>;
}

const AddAutomaticInvoiceGroupPrefixModal: React.FC<Props> = ({ visible, close, blockedValues, addValue }) => {
	const { handleSubmit, register, errors, formState } = useForm<{
		value: string;
	}>({
		mode: 'onChange',
		resolver: yupResolver(
			yup.object().shape({
				value: yup
					.string()
					.required()
					.test('not-blocked-case-insensitive', 'error.duplicatesNotAllowed', v => blockedValues.every(bv => bv.toLocaleLowerCase() !== v?.toLocaleLowerCase())),
			}),
		),
	});

	const [loading, setLoading] = React.useState(false);

	return (
		<Modal
			title="invoices.addAutomaticInvoiceGroupPrefix"
			visible={visible}
			close={close}
			body={
				<form
					onSubmit={handleSubmit(async ({ value }) => {
						setLoading(true);
						await addValue(value);
						setLoading(false);
					})}
				>
					<Input title="common.name" required name="value" innerRef={register} errorMessage={errors.value?.message} />

					<Button success submit text="invoices.addAutomaticInvoiceGroupPrefix" loading={loading} className="mt-4" disabled={loading || !formState.isValid} />
				</form>
			}
		/>
	);
};
export default AddAutomaticInvoiceGroupPrefixModal;
