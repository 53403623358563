import * as yup from 'yup';
import { AddressSchema } from './AddressSchema';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		email: 'error.email',
		min: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const LocationSchema = yup.object().shape({
	name: yup.string().required(),

	address: AddressSchema,

	movables: yup.boolean().required(),

	email: yup.string().email().required(),

	attachmentOk: yup.boolean().oneOf([true]),

	attachedPostalCodesInputString: yup.string(),
});
