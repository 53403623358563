import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { CreateScreeningPositionText, CreateScreeningPositionTextVariables, GetAdminScreeningPositionTexts, GetAdminScreeningPositionTextsVariables, GetScreeningTemplates } from '../../../GraphQL';
import { ScreeningPositionTextSchema } from '../../../Schemas/ScreeningPositionTextSchema';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import Textarea from '@ssg/common/Components/Textarea';

const CREATE_SCREENING_POSITION_TEXT = loader('src/GraphQL/Screening/CreateScreeningPositionText.gql');
const GET_SCREENING_POSITION_TEXTS = loader('src/GraphQL/Screening/GetAdminScreeningPositionTexts.gql');
const GET_SCREENING_TEMPLATES = loader('src/GraphQL/Screening/GetScreeningTemplates.gql');

const NO_SCREENING_TEMPLATE = '__NO_SELECTION__';

interface Props {
	close: () => unknown;
}

const ScreeningPositionTextCreateModal: React.FC<Props> = ({ close }) => {
	const [selectedTemplateCode, setSelectedTemplateCode] = React.useState(NO_SCREENING_TEMPLATE);

	const { data: templateData } = useQuery<GetScreeningTemplates>(GET_SCREENING_TEMPLATES);

	const [createScreeningPositionText] = useMutation<CreateScreeningPositionText, CreateScreeningPositionTextVariables>(CREATE_SCREENING_POSITION_TEXT);

	const { handleSubmit, register, errors, formState } = useForm<{
		templateCode: string;
		position: string;
		text: string;
	}>({
		resolver: yupResolver(ScreeningPositionTextSchema),
		mode: 'all',
	});

	const submit = handleSubmit(async ({ templateCode, position, text }) => {
		await createScreeningPositionText({
			variables: {
				screeningPositionText: { templateCode, position, text },
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetAdminScreeningPositionTexts, GetAdminScreeningPositionTextsVariables>({
					query: GET_SCREENING_POSITION_TEXTS,
					variables: { templateCodes: '' },
				});
				if (cachedRequest === null || cachedRequest.screeningPositionTexts === null) {
					return;
				}

				cache.writeQuery<GetAdminScreeningPositionTexts>({
					query: GET_SCREENING_POSITION_TEXTS,
					data: {
						screeningPositionTexts: [...cachedRequest.screeningPositionTexts, data.createScreeningPositionText],
					},
				});
			},
		});
		close();
	});

	return (
		<Modal
			size={ModalSize.SMALL}
			title="common.create"
			visible
			close={close}
			body={
				<>
					<Dropdown
						required
						title="screening.screeningTemplate"
						name="templateCode"
						data={[
							{ label: '', value: NO_SCREENING_TEMPLATE },
							...(templateData?.screeningTemplates ?? []).map(st => ({
								label: st.templateName,
								value: st.templateCode,
							})),
						]}
						onChange={e => setSelectedTemplateCode(e.target.value)}
						innerRef={register}
					/>

					<Dropdown
						required
						title="screening.position"
						name="position"
						data={[
							...(templateData?.screeningTemplates.find(t => t.templateCode === selectedTemplateCode)?.lines ?? [])
								.map(l =>
									l.position
										? {
												label: `${l.title} (${l.position})`,
												value: l.position,
										  }
										: { label: '', value: '' },
								)
								.filter(so => so.value !== '' && so.label !== ''),
						]}
						innerRef={register}
						errorMessage={errors.position?.message}
						disabled={selectedTemplateCode === NO_SCREENING_TEMPLATE}
					/>

					<Textarea required title="screening.helpText" name="text" rows={6} innerRef={register} errorMessage={errors.text?.message} />
				</>
			}
			footer={<Button success text="common.create" onClick={submit} disabled={!formState.isValid} />}
		/>
	);
};
export default ScreeningPositionTextCreateModal;
