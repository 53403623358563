import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GetAdminLocations, GetAdminLocations_locationsAdmin } from '../../../GraphQL';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { countries } from '@ssg/common/Helpers/countries';
import { tokenizeStringWithQuotesBySpaces } from '../../../helper';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import LocationModal from './LocationModal';
import LocationFilters from './LocationFilters';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import classNames from 'classnames';

const GET_LOCATIONS = loader('src/GraphQL/Locations/GetAdminLocations.gql');

interface LocationsFilter {
	name: string[];
	road: string[];
	houseNumber: string[];
	floor: string[];
	postalCode: string[];
	city: string[];
	country: string[];
}

const SSGLocations: React.FC = () => {
	const { t } = useTranslation();
	const [createLocation, setCreateLocation] = React.useState(false);
	const [updateLocation, setUpdateLocation] = React.useState(false);
	const [deleteLocation, setDeleteLocation] = React.useState(false);
	const [locationData, setLocationData] = React.useState<GetAdminLocations_locationsAdmin>();

	const { data, loading, refetch } = useQuery<GetAdminLocations>(GET_LOCATIONS);

	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);
	const [activeLocationFilters, setActiveLocationFilters] = React.useState<LocationsFilter>({
		name: [],
		road: [],
		houseNumber: [],
		floor: [],
		postalCode: [],
		city: [],
		country: [],
	});

	const locations = React.useMemo(() => data?.locationsAdmin ?? [], [data?.locationsAdmin]);
	const activeFilteredLocations = React.useMemo(
		() =>
			locations
				.filter(l => {
					return activeLocationFilters.name.length > 0 ? activeLocationFilters.name.flatMap(l => l).includes(l.name) : true;
				})
				.filter(l => {
					return activeLocationFilters.road.length > 0 ? activeLocationFilters.road.flatMap(l => l).includes(l.address.road) : true;
				})
				.filter(l => {
					return activeLocationFilters.houseNumber.length > 0 ? activeLocationFilters.houseNumber.flatMap(l => l).includes(l.address.houseNumber) : true;
				})
				.filter(l => {
					return activeLocationFilters.floor.length > 0 ? activeLocationFilters.floor.flatMap(l => l).includes(l.address.floor ?? '') : true;
				})
				.filter(l => {
					return activeLocationFilters.postalCode.length > 0 ? activeLocationFilters.postalCode.flatMap(l => l).includes(l.address.postalCode) : true;
				})
				.filter(l => {
					return activeLocationFilters.city.length > 0 ? activeLocationFilters.city.flatMap(l => l).includes(l.address.city) : true;
				})
				.filter(l => {
					return activeLocationFilters.country.length > 0 ? activeLocationFilters.country.flatMap(l => l).includes(l.address.country) : true;
				}),
		[
			locations,
			activeLocationFilters.name,
			activeLocationFilters.road,
			activeLocationFilters.houseNumber,
			activeLocationFilters.floor,
			activeLocationFilters.postalCode,
			activeLocationFilters.city,
			activeLocationFilters.country,
		],
	);

	const fuse = React.useMemo(
		() =>
			new Fuse(activeFilteredLocations, {
				shouldSort: true,
				threshold: 0.1,
				keys: ['name', 'address.road', 'address.houseNumber', 'address.floor', 'address.postalCode', 'address.city', 'address.country'],
			}),
		[activeFilteredLocations],
	);

	const filteredLocations =
		searchTerm.length > 0
			? fuse
					.search({
						$and: tokenizeStringWithQuotesBySpaces(searchTerm).map((searchToken: string) => {
							const orFields: Fuse.Expression[] = [
								{ name: searchToken },
								{ 'address.road': searchToken },
								{ 'address.houseNumber': searchToken },
								{ 'address.floor': searchToken },
								{ 'address.postalCode': searchToken },
								{ 'address.city': searchToken },
								{ 'address.country': searchToken },
							];

							return {
								$or: orFields,
							};
						}),
					})
					.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
					.map(v => v.item)
			: activeFilteredLocations;

	return (
		<div>
			<header className="flex">
				<div className="w-1/2">
					<LocationFilters setFilterTerm={setSearchTerm} />
				</div>
				<div className="flex w-1/2 justify-end">
					<Button primary text="locations.create" icon={faPlus} onClick={() => setCreateLocation(true)} className="mb-2" />
				</div>
			</header>

			<div className="text-blue relative bg-white pb-1">
				{loading ? (
					<div className="h-40">
						<Loading />
					</div>
				) : (
					<Table
						data={filteredLocations ?? []}
						columns={[
							{
								label: 'common.name',
								selectFn: l => <p className="font-semibold">{l.name}</p>,
								sortFn: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
							},
							{
								label: 'locations.address',
								numeric: true,
								selectFn: l => (
									<p>
										{l.address.road} {l.address.houseNumber} {l.address.floor ?? ''}
										<br />
										{l.address.postalCode} {l.address.city}
									</p>
								),
								sortFn: (a, b) => (a.address.postalCode ?? '').localeCompare(b.address.postalCode ?? ''),
							},
							{
								label: 'common.country',
								selectFn: l => <p>{countries.find(c => c.value === l.address.country)?.label}</p>,
								sortFn: (a, b) => (a.address.country ?? '').localeCompare(b.address.country ?? ''),
							},
							{
								label: 'locations.movables',
								align: 'CENTER',
								selectFn: l => (
									<p
										className={classNames({
											'font-bold': l.movables,
										})}
									>
										{l.movables ? t('common.yes') : t('common.no')}
									</p>
								),
								sortFn: (a, b) => (a.movables === b.movables ? 0 : a.movables ? -1 : 1),
							},
							{
								label: 'common.email',
								selectFn: l => l.email,
								sortFn: (a, b) => a.email.localeCompare(b.email),
							},
							{
								label: 'locations.attachedPostalCodes',
								noTruncate: true,
								selectFn: l => (
									<div>
										{l.attachedPostalCodes.map((p, i) => (
											<span key={p}>{`${p}${i + 1 !== l.attachedPostalCodes.length ? ', ' : ''}`} </span>
										))}
									</div>
								),
							},
							{
								label: 'common.edit',
								classNameTh: 'text-right',
								selectFn: l => (
									<div className="flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faEdit}
											size="lg"
											onClick={() => {
												setLocationData(l);
												setUpdateLocation(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
							{
								label: 'common.delete',
								classNameTh: 'text-right',
								selectFn: l => (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setLocationData(l);
												setDeleteLocation(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
						]}
						keySelector={l => l.id}
					/>
				)}
			</div>

			{createLocation && (
				<LocationModal
					open={createLocation}
					close={() => setCreateLocation(false)}
					submitCb={() => {
						refetch();
						setCreateLocation(false);
					}}
				/>
			)}

			{updateLocation && (
				<LocationModal
					open={updateLocation}
					close={() => setUpdateLocation(false)}
					edit
					data={locationData}
					submitCb={() => {
						refetch();
						setUpdateLocation(false);
					}}
				/>
			)}

			{deleteLocation && (
				<LocationModal
					erase
					open={deleteLocation}
					close={() => setDeleteLocation(false)}
					data={locationData}
					submitCb={() => {
						refetch();
						setDeleteLocation(false);
					}}
				/>
			)}
		</div>
	);
};

export default SSGLocations;
