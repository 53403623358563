import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'react-hook-form';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';

const SkyHostTab: React.FC = () => {
	const [visible, setVisible] = React.useState(false);

	const { handleSubmit, register } = useForm();

	const onSubmit = () => {
		console.log('submit');
	};

	return (
		<div className="text-blue h-full px-3 pb-3">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex">
					<div className="mr-4 w-1/2">
						<Input title="integrations.username" name={'username'} innerRef={register} />

						<div className="relative">
							<div className="pointer-events-none absolute mt-8 flex content-start justify-end pr-2 lg:w-full">
								<FontAwesomeIcon icon={visible ? faEyeSlash : faEye} onClick={() => setVisible(!visible)} className="pointer-events-auto" />
							</div>
							<Input
								title="integrations.password"
								name={'password'}
								innerRef={register}
								type={visible ? 'text' : 'password'}
								className="z-50 pr-8 text-sm lg:w-full"
								// onChange={(e): void => setFilterTerm(e.target.value)}
							/>
						</div>

						<Button submit success text="common.save" className="mt-4" />
					</div>
				</div>
			</form>
		</div>
	);
};

export default SkyHostTab;
