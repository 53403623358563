import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { GetInputFieldsAll, GetInputFieldsAll_inputFieldsAll, Permissions } from '../../GraphQL';
import Header from '@ssg/common/Components/Header';
import Checkbox from '@ssg/common/Components/Checkbox';
import Loading from '@ssg/common/Components/Loading';
import FieldModal from './FieldModal';
import Table from '@ssg/common/Components/Table';
import TextButton from '@ssg/common/Components/TextButton';

const GET_INPUT_FIELDS = loader('../../GraphQL/InputFields/GetInputFieldsAll.gql');

const FieldOverview: React.FC = () => {
	const { t } = useTranslation();
	const [createField, setCreateField] = React.useState(false);
	const [deleteField, setDeleteField] = React.useState(false);
	const [updateField, setUpdateField] = React.useState(false);
	const [fieldData, setFieldData] = React.useState<GetInputFieldsAll_inputFieldsAll>();

	const { data, loading, refetch } = useQuery<GetInputFieldsAll>(GET_INPUT_FIELDS);

	return (
		<div>
			<Header title="fieldOverview.overviewTitle" textClassNames="text-blue-default">
				<TextButton icon={faPlus} text="fieldOverview.create" onClick={() => setCreateField(true)} />
			</Header>

			<div className="text-blue-default relative mx-14 bg-white px-4 pb-1">
				{loading ? (
					<div className="h-40">
						<Loading />
					</div>
				) : (
					<Table
						data={data?.inputFieldsAll ?? []}
						columns={[
							{
								label: t('fieldOverview.translationKey'),
								selectFn: c => <p className="my-2">{c.key}</p>,
							},
							{
								label: t('fieldOverview.permissions'),
								selectFn: c => (
									<>
										{c.permissions.map((p, i) => {
											return <div key={i}>{c.permissions.length > 1 && p === Permissions.NONE ? '' : p}</div>;
										})}
									</>
								),
							},
							{
								label: t('fieldOverview.required'),
								selectFn: c => (
									<>
										<Checkbox disabled name={'required'} defaultChecked={c.required} className="my-1" />
									</>
								),
							},
							{
								label: t('common.edit'),
								selectFn: c => (
									<div className="flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faEdit}
											size="lg"
											onClick={() => {
												setUpdateField(true);
												setFieldData(c);
											}}
										/>
									</div>
								),
							},
							{
								label: t('common.delete'),
								selectFn: c => (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setDeleteField(true);
												setFieldData(c);
											}}
										/>
									</div>
								),
							},
						]}
						keySelector={c => c.key}
					/>
				)}
			</div>

			{createField && (
				<FieldModal
					open={createField}
					close={() => setCreateField(false)}
					submitCb={() => {
						refetch();
						setCreateField(false);
					}}
				/>
			)}

			{updateField && (
				<FieldModal
					edit
					open={updateField}
					close={() => setUpdateField(false)}
					submitCb={() => {
						refetch();
						setUpdateField(false);
					}}
					fieldData={fieldData}
				/>
			)}

			{deleteField && (
				<FieldModal
					erase
					open={deleteField}
					close={() => setDeleteField(false)}
					submitCb={() => {
						refetch();
						setDeleteField(false);
					}}
					fieldData={fieldData}
				/>
			)}
		</div>
	);
};

export default FieldOverview;
