import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { DrivingSlipQuestionnaireNodeData } from './DrivingSlipQuestionnaireNodeData';
import { QuestionnaireChoiceInputType, QuestionnaireChoiceType } from '../../GraphQL';
import { NodeCreationSchema } from '../../Schemas/NodeCreationSchema';
import Modal from '@ssg/common/Components/Modal';
import Input from '@ssg/common/Components/Input';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import Checkbox from '@ssg/common/Components/Checkbox';
import UnitOfMeasurementSuggestions from '@ssg/common/Components/UnitOfMeasurementSuggestions';
import classNames from 'classnames';

function choiceTypeHasSelectableOptions(choiceType?: QuestionnaireChoiceType): boolean {
	return choiceType === QuestionnaireChoiceType.DROPDOWN || choiceType === QuestionnaireChoiceType.RADIO_GROUP;
}

function choiceTypeHasUnitOfMeasure(choiceType?: QuestionnaireChoiceType): boolean {
	return choiceType === QuestionnaireChoiceType.NUMBER;
}

export const sanitiseString = (s: string | null | undefined): string | null => {
	const trimmed = s?.trim();
	if (trimmed === '') {
		return null;
	}
	return trimmed ?? null;
};

interface IdentifiableSelectOption extends SelectOption {
	id: number;
}

interface Props {
	visible: boolean;
	parent?: NodeModel<DrivingSlipQuestionnaireNodeData>;
	rootParentNodeId: string;
	onClose: () => void;
	treeData: NodeModel<DrivingSlipQuestionnaireNodeData>[];
	onSubmit: (newNode: Omit<NodeModel<DrivingSlipQuestionnaireNodeData>, 'id'>, newNodeChildNodes?: Omit<NodeModel<DrivingSlipQuestionnaireNodeData>, 'id' | 'parent'>[]) => void;
}

const DrivingSlipQuestionnaireNodeCreationModal: React.FC<Props> = ({ visible, parent, rootParentNodeId, treeData, onClose, onSubmit }) => {
	const { t } = useTranslation();

	const choiceTypes = React.useMemo<SelectOption[]>(() => {
		function getChoiceTypes(parent?: NodeModel<DrivingSlipQuestionnaireNodeData>): SelectOption[] {
			switch (parent?.data?.choiceType) {
				case QuestionnaireChoiceType.DROPDOWN:
					return [
						{
							label: t('questionnaire.choiceRadioGroupOption'),
							value: QuestionnaireChoiceType.SELECT_OPTION,
						},
					];
				case QuestionnaireChoiceType.RADIO_GROUP:
					return [
						{
							label: t('questionnaire.choiceRadioGroupOption'),
							value: QuestionnaireChoiceType.SELECT_OPTION,
						},
					];
				default:
					return [
						// Dropdowns are technically supported but we should avoid using them to enhance UX on mobile
						// { label: 'Dropdown', value: QuestionnaireChoiceType.DROPDOWN },
						{
							label: t('questionnaire.choiceRadioGroup'),
							value: QuestionnaireChoiceType.RADIO_GROUP,
						},
						{
							label: t('questionnaire.choiceFreeText'),
							value: QuestionnaireChoiceType.FREE_TEXT,
						},
						{
							label: t('questionnaire.choiceNumber'),
							value: QuestionnaireChoiceType.NUMBER,
						},
					];
			}
		}

		return getChoiceTypes(parent);
	}, [parent, t]);

	const [choiceType, setChoiceType] = React.useState<QuestionnaireChoiceType>(choiceTypes[0].value as QuestionnaireChoiceType);
	const [selectOptions, setSelectOptions] = React.useState<IdentifiableSelectOption[]>([{ id: 0, label: '', value: '' }]);

	// If the parent node has selectable options (i.e. is a dropdown/radio) then ensure we can only add select options and not be able to change choice types.
	const canChangeChoiceType = useMemo(() => !choiceTypeHasSelectableOptions(parent?.data?.choiceType), [parent?.data?.choiceType]);

	const { handleSubmit, register, errors, setValue } = useForm<QuestionnaireChoiceInputType>({
		resolver: yupResolver(NodeCreationSchema),
	});

	const getLastId = (options: IdentifiableSelectOption[]): number => {
		const reversedArray = [...options].sort((a, b) => {
			if (a.id < b.id) {
				return 1;
			} else if (a.id > b.id) {
				return -1;
			}

			return 0;
		});

		if (reversedArray.length > 0) {
			return reversedArray[0].id as number;
		}

		return 0;
	};

	const handleAddOption = () => {
		const newId = getLastId(selectOptions) + 1;
		setSelectOptions([...selectOptions, { id: newId, label: '', value: '' }]);
	};

	const handleChangeOption = (option: IdentifiableSelectOption, newValue: string) => {
		setSelectOptions([
			...selectOptions.map(o => {
				return o.id === option.id ? { ...o, label: newValue, value: newValue } : { ...o };
			}),
		]);
	};

	const handleRemoveOption = (id: number) => {
		setSelectOptions([...selectOptions.filter(s => s.id !== id)]);
	};

	const checkIfChildrenHasChildren = (parentChildren: NodeModel<DrivingSlipQuestionnaireNodeData>[]) => {
		for (let i = 0; i < parentChildren.length; i++) {
			const childrenExist = treeData.filter(x => x.parent === parentChildren[i].id);
			if (childrenExist != null) {
				return true;
			}
		}
		return false;
	};

	const onSubmitNewItem = async (formData: QuestionnaireChoiceInputType) => {
		const parentChildren = treeData.filter(x => x.parent === parent?.id);
		const resp = checkIfChildrenHasChildren(parentChildren);

		if (resp === true && (choiceType === QuestionnaireChoiceType.DROPDOWN || choiceType === QuestionnaireChoiceType.RADIO_GROUP) && formData.isMultiSelectAllowed === true) {
			alert('Cannot use multiselect for this control.'); // TODO: Translate
			return;
		}

		const childNodes = choiceTypeHasSelectableOptions(choiceType)
			? selectOptions
					.filter(x => x.value !== '')
					.map(x => ({
						text: x.value,
						droppable: true,
						data: {
							choiceType: QuestionnaireChoiceType.SELECT_OPTION,
							placeholder: null,
							helpText: null,
							isOptional: false,
							triggersTrackTwoChange: false,
							unitOfMeasure: null,
							isMultiSelectAllowed: false,
						},
					}))
			: undefined;

		onSubmit(
			{
				text: formData.label.trim(),
				parent: parent?.id ?? rootParentNodeId,
				droppable: true,
				data: {
					/*
                        IMPORTANT:
                        Form data values may be undefined. Ensure to use null coalescing.
                     */
					choiceType: formData.type,
					placeholder: sanitiseString(formData.placeholder),
					helpText: sanitiseString(formData.helpText),
					isOptional: formData.isOptional ?? false,
					triggersTrackTwoChange: formData.triggersTrackTwoChange ?? false,
					unitOfMeasure: sanitiseString(formData.unitOfMeasure),
					isMultiSelectAllowed: formData.isMultiSelectAllowed ?? false,
				},
			},
			childNodes,
		);
	};

	return (
		<Modal
			visible={visible}
			close={onClose}
			title={t('questionnaire.addChoice')}
			body={
				<form onSubmit={handleSubmit(onSubmitNewItem)}>
					<Dropdown
						name="type"
						title={t('questionnaire.type')}
						innerRef={register}
						required
						data={choiceTypes}
						defaultValue={choiceType}
						className={classNames({
							'cursor-not-allowed bg-gray-300 opacity-100': !canChangeChoiceType,
						})}
						onChange={e => setChoiceType(e.target.value as QuestionnaireChoiceType)}
						onMouseDown={e => {
							if (!canChangeChoiceType) {
								// Prevent opening of the dropdown if we cannot change choice type.
								// Do not use the disabled attribute as this will pass undefined to React Hook Form.
								e.preventDefault();
							}
						}}
						errorMessage={errors.type?.message}
					/>

					<Input name="label" title={t('questionnaire.label')} innerRef={register} required errorMessage={errors.label?.message} />

					{choiceTypeHasUnitOfMeasure(choiceType) && (
						<>
							<Input name="unitOfMeasure" title={t('questionnaire.unitOfMeasure')} innerRef={register} />
							<UnitOfMeasurementSuggestions
								onClick={value =>
									setValue('unitOfMeasure', value, {
										shouldValidate: false,
										shouldDirty: false,
									})
								}
							/>
						</>
					)}

					{/* TODO: Decide if we need help text */}
					{/* <Input name="helpText" title={t('questionnaire.helpText')} innerRef={register} /> */}

					<div
						className={classNames({
							hidden: choiceType === QuestionnaireChoiceType.SELECT_OPTION,
						})}
					>
						<Input name="placeholderText" title={t('questionnaire.placeholderText')} innerRef={register} />

						<Checkbox name="isOptional" title={t('questionnaire.isOptional')} innerRef={register} className="mt-2" />

						{choiceTypeHasSelectableOptions(choiceType) && <Checkbox name="isMultiSelectAllowed" title={t('questionnaire.isMultiSelectAllowed')} innerRef={register} className="mt-2" />}
					</div>

					<Checkbox
						name="triggersTrackTwoChange"
						title={t('questionnaire.triggersTrackTwoChange')}
						innerRef={register}
						className={classNames('mt-4', {
							hidden: choiceType !== QuestionnaireChoiceType.SELECT_OPTION,
						})}
					/>

					{choiceTypeHasSelectableOptions(choiceType) && (
						<div className="border-b-1 border-t-1 mt-3 border-dashed border-indigo-600 py-3">
							<div className="block">
								<label className="text-blue text-base font-semibold">{t('questionnaire.options')}</label>
							</div>

							<div className="overflow-y-auto" style={{ maxHeight: '400px' }}>
								{selectOptions.map(selectOption => (
									<div className="flex" key={selectOption.id}>
										<div className="flex w-5/6">
											<Input
												name="label"
												defaultValue={selectOption.value}
												onChange={e => handleChangeOption(selectOption, e.target.value)}
												className="mb-2 w-full lg:w-full"
												placeholder={t('questionnaire.optionText')}
											/>
										</div>
										<div className="flex w-1/6 items-center justify-center">
											<FontAwesomeIcon icon={faTrash} className="cursor-pointer" onClick={() => handleRemoveOption(selectOption.id)} />
										</div>
									</div>
								))}
							</div>

							<Button className="mt-2 p-2" secondary text={t('questionnaire.addOption')} icon={faPlus} onClick={handleAddOption} />
						</div>
					)}

					<Button success submit text={t('common.save')} className="mt-4" />
				</form>
			}
		/>
	);
};

export default DrivingSlipQuestionnaireNodeCreationModal;
