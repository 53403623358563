import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { DrivingSlipQuestionnaireNodeData } from './DrivingSlipQuestionnaireNodeData';
import { QuestionnaireChoiceInputType, QuestionnaireChoiceType } from '../../GraphQL';
import { NodeUpdateSchema } from '../../Schemas/NodeCreationSchema';
import Modal from '@ssg/common/Components/Modal';
import Input from '@ssg/common/Components/Input';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import Checkbox from '@ssg/common/Components/Checkbox';
import UnitOfMeasurementSuggestions from '@ssg/common/Components/UnitOfMeasurementSuggestions';
import classNames from 'classnames';
import { sanitiseString } from './DrivingSlipQuestionnaireNodeCreationModal';

interface Props {
	visible: boolean;
	node: NodeModel<DrivingSlipQuestionnaireNodeData>;
	onClose: () => void;
	onSubmit: (updatedNode: NodeModel<DrivingSlipQuestionnaireNodeData>) => void;
}

const DrivingSlipQuestionnaireNodeEditModal: React.FC<Props> = ({ visible, node, onClose, onSubmit }) => {
	const { t } = useTranslation();

	const choiceTypes = React.useMemo<SelectOption[]>(() => {
		switch (node.data?.choiceType) {
			case QuestionnaireChoiceType.SELECT_OPTION:
				return [
					{
						label: t('questionnaire.choiceRadioGroupOption'),
						value: QuestionnaireChoiceType.SELECT_OPTION,
					},
				];
			// Dropdowns are technically supported but we should avoid using them to enhance UX on mobile
			// case QuestionnaireChoiceType.DROPDOWN:
			//     return [
			//         { label: t('questionnaire.choiceRadioGroup'), value: QuestionnaireChoiceType.DROPDOWN },
			//         { label: t('questionnaire.choiceRadioGroup'), value: QuestionnaireChoiceType.RADIO_GROUP },
			//     ];
			case QuestionnaireChoiceType.RADIO_GROUP:
				return [
					{
						label: t('questionnaire.choiceRadioGroup'),
						value: QuestionnaireChoiceType.RADIO_GROUP,
					},
					// Dropdowns are technically supported but we should avoid using them to enhance UX on mobile
					// { label: t('questionnaire.choiceRadioGroupOption'), value: QuestionnaireChoiceType.DROPDOWN },
				];
			case QuestionnaireChoiceType.NUMBER:
				return [
					{
						label: t('questionnaire.choiceNumber'),
						value: QuestionnaireChoiceType.NUMBER,
					},
					{
						label: t('questionnaire.choiceFreeText'),
						value: QuestionnaireChoiceType.FREE_TEXT,
					},
				];
			case QuestionnaireChoiceType.FREE_TEXT:
				return [
					{
						label: t('questionnaire.choiceFreeText'),
						value: QuestionnaireChoiceType.FREE_TEXT,
					},
					{
						label: t('questionnaire.choiceNumber'),
						value: QuestionnaireChoiceType.NUMBER,
					},
				];
			default:
				return [];
		}
	}, [node, t]);

	const defaultChoiceType = React.useMemo<SelectOption>(() => choiceTypes[0], [choiceTypes]);

	const [choiceType, setChoiceType] = React.useState<QuestionnaireChoiceType>(defaultChoiceType.value as QuestionnaireChoiceType);

	const choiceTypeIsSelectOption = React.useMemo(() => choiceType === QuestionnaireChoiceType.SELECT_OPTION, [choiceType]);
	const choiceTypeHasUnitOfMeasure = React.useMemo(() => choiceType === QuestionnaireChoiceType.NUMBER, [choiceType]);

	const { handleSubmit, register, errors, setValue } = useForm<QuestionnaireChoiceInputType>({
		resolver: yupResolver(NodeUpdateSchema),
	});

	const onSubmitNewItem = async (formData: QuestionnaireChoiceInputType) => {
		onSubmit({
			...node,
			text: formData.label,
			data: {
				/*
                    IMPORTANT:
                    Form data values may be undefined. Ensure to use null coalescing.
                */
				choiceType: choiceType,
				placeholder: sanitiseString(formData.placeholder),
				helpText: sanitiseString(formData.helpText),
				isOptional: formData.isOptional ?? false,
				triggersTrackTwoChange: formData.triggersTrackTwoChange ?? false,
				unitOfMeasure: sanitiseString(formData.unitOfMeasure),
				isMultiSelectAllowed: formData.isMultiSelectAllowed ?? false,
			},
		});
	};

	return (
		<Modal
			visible={visible}
			close={onClose}
			title={t(choiceTypeIsSelectOption ? 'questionnaire.editOption' : 'questionnaire.editChoice')}
			body={
				<form onSubmit={handleSubmit(onSubmitNewItem)}>
					<Dropdown
						name="type"
						title={t('questionnaire.type')}
						innerRef={register}
						required
						data={choiceTypes}
						defaultValue={defaultChoiceType.value}
						onChange={e => setChoiceType(e.target.value as QuestionnaireChoiceType)}
						onMouseDown={e => {
							if (choiceTypeIsSelectOption || choiceTypes.length === 1) {
								// Prevent opening of the dropdown if we cannot change choice type.
								// Do not use the disabled attribute as this will pass undefined to React Hook Form.
								e.preventDefault();
							}
						}}
						className={classNames({
							'cursor-not-allowed bg-gray-300 opacity-100': choiceTypeIsSelectOption || choiceTypes.length === 1,
						})}
					/>

					<Input name="label" title={t('questionnaire.label')} defaultValue={node.text} innerRef={register} required errorMessage={errors.label?.message} />

					<Checkbox
						name="triggersTrackTwoChange"
						title={t('questionnaire.triggersTrackTwoChange')}
						defaultChecked={node.data?.triggersTrackTwoChange}
						innerRef={register}
						className={classNames('mt-4', {
							hidden: !choiceTypeIsSelectOption,
						})}
					/>

					{/* For Select Options we don't care about the below properties */}
					<div
						className={classNames({
							hidden: choiceTypeIsSelectOption,
						})}
					>
						{choiceTypeHasUnitOfMeasure && (
							<>
								<Input name="unitOfMeasure" title={t('questionnaire.unitOfMeasure')} defaultValue={node.data?.unitOfMeasure ?? undefined} innerRef={register} />
								<UnitOfMeasurementSuggestions
									onClick={value =>
										setValue('unitOfMeasure', value, {
											shouldValidate: false,
											shouldDirty: false,
										})
									}
								/>
							</>
						)}

						<Input name="placeholder" title={t('questionnaire.placeholderText')} defaultValue={node.data?.placeholder ?? undefined} innerRef={register} />

						{/* TODO: Decide if we need help text */}
						{/* <Input name="helpText" title={t('questionnaire.helpText')} defaultValue={node.data?.helpText ?? undefined} innerRef={register} /> */}

						<Checkbox name="isOptional" title={t('questionnaire.isOptional')} defaultChecked={node.data?.isOptional} innerRef={register} className="mt-4 w-full lg:w-full" />
					</div>

					<Button success submit text={t('common.save')} className="mt-4" />
				</form>
			}
		/>
	);
};

export default DrivingSlipQuestionnaireNodeEditModal;
