import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faEdit, faPlus, faSearch, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import {
	DeleteScreeningPositionText,
	DeleteScreeningPositionTextVariables,
	GetAdminScreeningPositionTexts,
	GetAdminScreeningPositionTextsVariables,
	GetAdminScreeningPositionTexts_screeningPositionTexts,
	GetScreeningTemplates,
} from '../../../GraphQL';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import Table from '@ssg/common/Components/Table';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import ScreeningPositionTextCreateModal from './ScreeningPositionTextCreateModal';
import ScreeningPositionTextEditModal from './ScreeningPositionTextEditModal';
import TextButton from '@ssg/common/Components/TextButton';

const GET_SCREENING_POSITION_TEXTS = loader('src/GraphQL/Screening/GetAdminScreeningPositionTexts.gql');
const DELETE_SCREENING_POSITION_TEXT = loader('src/GraphQL/Screening/DeleteScreeningPositionText.gql');
const GET_SCREENING_TEMPLATES = loader('src/GraphQL/Screening/GetScreeningTemplates.gql');

interface WithFuse {
	positionText: GetAdminScreeningPositionTexts_screeningPositionTexts;
	positionHighlight: React.ReactNode | undefined;
	textHighlight: React.ReactNode | undefined;
}
const getHighlights = (inputText: string, matches: readonly Fuse.FuseResultMatch[] | undefined, key: string): React.ReactNode | undefined => {
	if (matches && matches.length > 0) {
		const matchOfKey = matches.find(m => m.key === key);
		if (matchOfKey) {
			return highlightMatches(inputText, matchOfKey.indices);
		}
	}
	return;
};

export const highlightMatches = (inputText: string, regions: readonly Fuse.RangeTuple[] = []): React.ReactNode => {
	const children: React.ReactNode[] = [];
	let nextUnhighlightedRegionStartingIndex = 0;

	regions.forEach((region, i) => {
		const lastRegionNextIndex = region[1] + 1;

		children.push(
			...[
				inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]).replace(' ', '\u00A0'),
				<span key={region + ' ' + i} className="bg-yellow-highlight">
					{inputText.substring(region[0], lastRegionNextIndex).replace(' ', '\u00A0')}
				</span>,
			],
		);

		nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;
	});

	children.push(inputText.substring(nextUnhighlightedRegionStartingIndex).replace(' ', '\u00A0'));

	return <>{children}</>;
};

const ScreeningPositionTextsOverview: React.FC = () => {
	const { t } = useTranslation();

	const [selectedTemplateCode, setSelectedTemplateCode] = React.useState('');

	const { data: templateData } = useQuery<GetScreeningTemplates>(GET_SCREENING_TEMPLATES);

	const { data, loading } = useQuery<GetAdminScreeningPositionTexts, GetAdminScreeningPositionTextsVariables>(GET_SCREENING_POSITION_TEXTS, {
		variables: {
			templateCodes: selectedTemplateCode,
		},
		fetchPolicy: 'cache-and-network',
	});
	const screeningPositionTexts: GetAdminScreeningPositionTexts_screeningPositionTexts[] = React.useMemo(() => {
		if (typeof data === 'undefined') return [];
		if (typeof templateData === 'undefined') return [];
		return data.screeningPositionTexts.map<GetAdminScreeningPositionTexts_screeningPositionTexts>(s => {
			const positionsWithText = templateData.screeningTemplates.flatMap(sT => sT.lines.filter(l => l.position === s.position));
			console.log(positionsWithText);
			let pos = s.position;
			if (positionsWithText.length > 0) {
				pos = positionsWithText.map(p => `${p.title} (${p.position})`).join(', ');
			}
			return {
				id: s.id,
				position: pos,
				templateCode: s.templateCode,
				text: s.text,
			};
		});
	}, [data, templateData]);

	const [deleteFn] = useMutation<DeleteScreeningPositionText, DeleteScreeningPositionTextVariables>(DELETE_SCREENING_POSITION_TEXT);

	const [showCreateModal, setShowCreateModal] = React.useState(false);

	const [showEditModal, setShowEditModal] = React.useState<GetAdminScreeningPositionTexts['screeningPositionTexts'][number]['id']>();
	const editEntry = React.useMemo(() => screeningPositionTexts.find(spt => spt.id === showEditModal), [screeningPositionTexts, showEditModal]);

	const fuse = React.useMemo(
		() =>
			new Fuse<GetAdminScreeningPositionTexts_screeningPositionTexts>(screeningPositionTexts, {
				shouldSort: true,
				threshold: 0.0,
				ignoreLocation: true,
				keys: ['position', 'text'],
				findAllMatches: true,
				includeMatches: true,
				includeScore: true,
				useExtendedSearch: true,
			}),
		[screeningPositionTexts],
	);

	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);
	const filteredScreeningPositionTexts: WithFuse[] =
		searchTerm.length > 0
			? fuse
					.search("'" + searchTerm)
					.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
					.map(v => ({
						positionText: v.item,
						positionHighlight: getHighlights(v.item.position, v.matches, 'position'),
						textHighlight: getHighlights(v.item.text, v.matches, 'text'),
					}))
			: screeningPositionTexts.map(s => ({
					positionText: s,
					positionHighlight: undefined,
					textHighlight: undefined,
			  }));

	const filtere = fuse.search("'" + searchTerm).sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER));

	console.log(fuse);
	console.log(filtere);

	return (
		<>
			<header className="flex">
				<div className="flex w-1/2">
					<div className="mb-3 mr-3">
						<label className="text-blue mb-1 block text-xs font-medium">{t('screening.screeningTemplate')}</label>
						<Dropdown
							name="templateCode"
							data={[
								{ label: '', value: '' },
								...(templateData?.screeningTemplates ?? []).map(st => ({
									label: st.templateName,
									value: st.templateCode,
								})),
							]}
							onChange={e => setSelectedTemplateCode(e.target.value)}
						/>
					</div>
					<div className="mb-3 mr-3">
						<label className="text-blue mb-1 block text-xs font-medium">{t('common.search')}</label>
						<div className="flex">
							<div className="relative flex">
								<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
								<Input name="search" placeholder={t('common.searchPlaceholder')} className="pr-8 text-sm lg:w-full" onChange={e => setSearchTerm(e.target.value)} />
							</div>
						</div>
					</div>
				</div>
				<div className="flex w-1/2 justify-end">
					<TextButton icon={faPlus} text="collectionItem.create" onClick={() => setShowCreateModal(true)} />
				</div>
			</header>

			<div className="text-blue relative bg-white pb-1">
				<Table
					data={filteredScreeningPositionTexts ?? []}
					loading={loading}
					columns={[
						{
							label: 'screening.screeningTemplate',
							selectFn: spt => <p className="py-1">{templateData?.screeningTemplates.find(st => st.templateCode === spt.positionText.templateCode)?.templateName}</p>,
							sortFn: (a, b) => a.positionText.templateCode.localeCompare(b.positionText.templateCode),
						},
						{
							label: 'screening.position',
							selectFn: spt => (
								<p className="py-1">
									{spt.positionHighlight ?? spt.positionText.position}
									{/* {templateData?.screeningTemplates.map(st =>
                                            st.lines.map(l => {
                                                if (l.position === spt.positionText.position) return `${l.title} (${spt.positionHighlight ?? spt.positionText.position})`;
                                            }),
                                        )} */}
								</p>
							),
							sortFn: (a, b) => a.positionText.position.localeCompare(b.positionText.position),
						},
						{
							label: 'screening.helpText',
							noTruncate: true,
							selectFn: spt => <p className="py-1">{spt.textHighlight ?? spt.positionText.text}</p>,
							sortFn: (a, b) => a.positionText.text.localeCompare(b.positionText.text),
						},
						{
							label: 'common.edit',
							classNameTh: 'text-right',
							selectFn: spt => (
								<div className="flex content-start justify-end text-right">
									<FontAwesomeIcon icon={faEdit} size="lg" onClick={() => setShowEditModal(spt.positionText.id)} className="cursor-pointer" />
								</div>
							),
						},
						{
							label: 'common.delete',
							classNameTh: 'text-right',
							selectFn: spt => (
								<div className="text-red flex content-start justify-end text-right">
									<FontAwesomeIcon
										icon={faTrashAlt}
										size="lg"
										onClick={() => {
											if (window.confirm(t('screening.confirmDelete'))) {
												deleteFn({
													variables: {
														id: spt.positionText.id,
													},
													update: (cache, { data }): void => {
														if (typeof data === 'undefined' || data === null) {
															return;
														}

														const cachedRequest = cache.readQuery<GetAdminScreeningPositionTexts>({
															query: GET_SCREENING_POSITION_TEXTS,
														});
														if (cachedRequest === null || cachedRequest.screeningPositionTexts === null) {
															return;
														}

														cache.writeQuery<GetAdminScreeningPositionTexts>({
															query: GET_SCREENING_POSITION_TEXTS,
															data: {
																screeningPositionTexts: cachedRequest.screeningPositionTexts.filter(cspt => cspt.id !== spt.positionText.id),
															},
														});
													},
												});
											}
										}}
										className="cursor-pointer"
									/>
								</div>
							),
						},
					]}
					keySelector={spt => spt.positionText.id}
					noDataFoundText="screening.noScreeningTextsFound"
				/>
			</div>

			{showCreateModal && <ScreeningPositionTextCreateModal close={() => setShowCreateModal(false)} />}

			{typeof editEntry !== 'undefined' && <ScreeningPositionTextEditModal close={() => setShowEditModal(undefined)} current={editEntry} />}
		</>
	);
};
export default ScreeningPositionTextsOverview;
