import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCloud, faComments, faEnvelope, faKey, faScrubber, faBolt, faDatabase } from '@fortawesome/pro-regular-svg-icons';
import Box from '../../Components/Layout/Box';
import Header from '@ssg/common/Components/Header';
import Tabs from '@ssg/common/Components/Tabs';
import BCTab from './BCTab';
import ScalePointTab from './ScalePoint/ScalePointTab';
import In4moTab from './In4mo/In4moTab';
import MindKeyTab from './MindKeyTab';
import DefGoTab from './DefGoTab';
import SkyHostTab from './SkyHostTab';
import SmsTab from './SmsTab';
import SmtpTab from './SmtpTab';

const tabs: string[] = ['BC', 'ScalePoint', 'In4mo', 'MindKey', 'DefGo', 'SkyHost', 'SMS', 'SMTP'];
const icons: (IconDefinition | null)[] = [faDatabase, faScrubber, faBolt, faKey, null, faCloud, faComments, faEnvelope];

const Integrations: React.FC = () => {
	const [activeTab, setActiveTab] = React.useState(tabs[0]);

	return (
		<div>
			<Header title="integrations.overviewTitle"></Header>

			<div className="px-10 pb-10">
				<Box title="integrations.setup" full>
					<Tabs full titles={tabs} icons={icons} active={activeTab} onClick={setActiveTab}>
						{activeTab === 'BC' && <BCTab />}
						{activeTab === 'ScalePoint' && <ScalePointTab />}
						{activeTab === 'In4mo' && <In4moTab />}
						{activeTab === 'MindKey' && <MindKeyTab />}
						{activeTab === 'DefGo' && <DefGoTab />}
						{activeTab === 'SkyHost' && <SkyHostTab />}
						{activeTab === 'SMS' && <SmsTab />}
						{activeTab === 'SMTP' && <SmtpTab />}
					</Tabs>
				</Box>
			</div>
		</div>
	);
};

export default Integrations;
