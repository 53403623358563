import React from 'react';
import Header from '@ssg/common/Components/Header';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Cars from './SSGVehicles/Cars';
import Materials from 'Pages/Materials/SSGMaterials/Materials';



const tabs = ['vehicles.cars', 'materials.overviewTitle'];

const VehicleTabs: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);

	return (
		<>
			<Header title="vehicles.overviewTitle">
				<div className="flex">
					{tabs.map((title, i) => (
						<div key={i} className="mr-3 mt-1">
							<button
								type="button"
								onClick={() => setActiveTab(title)}
								className={classNames('text-blue focus:outline-none', {
									'border-blue border-b-2': title === activeTab,
								})}
							>
								{t(title)}
							</button>
						</div>
					))}
				</div>
			</Header>

			<div className="mx-14 bg-white p-4 pb-1">
				{activeTab === 'vehicles.cars' && <Cars />}
				{activeTab === 'materials.overviewTitle' && <Materials />}


			</div>
		</>
	);
};

export default VehicleTabs;
