import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import Input from '@ssg/common/Components/Input';
import { useTranslation } from 'react-i18next';

interface Props {
	setFilterTerm: (v: string) => void;
}

const DepartmentsFilters: React.FC<Props> = ({ setFilterTerm }) => {
	const { t } = useTranslation();

	return (
		<div className="text-blue flex">
			<div className="relative w-1/3">
				<div className="pointer-events-none absolute mt-2 flex content-start justify-end pr-2 lg:w-full">
					<FontAwesomeIcon icon={faSearch} />
				</div>
				<Input name="overview-search" placeholder={t('departmentsLocations.searchPlaceholder')} className="pr-8 text-sm lg:w-full" onChange={(e): void => setFilterTerm(e.target.value)} />
			</div>
		</div>
	);
};

export default DepartmentsFilters;
