import React from 'react';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import Table from '@ssg/common/Components/Table';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTimes, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { useMutation, useQuery } from '@apollo/client';
import {
	CreateSettings,
	CreateSettingsVariables,
	GetAdminDamageCategories,
	GetAdminDamageCauses,
	GetAdminDebitors,
	GetDebitorsByReferenceNo,
	GetDebitorsByReferenceNoVariables,
	GetSettings,
	GetSettings_setting_SPSettings,
	UpdateSettings,
	UpdateSettingsVariables,
} from '../../../GraphQL';
import { loader } from 'graphql.macro';
import Loading from '@ssg/common/Components/Loading';
import { H3 } from '@ssg/common/Components/Text';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import { useForm } from 'react-hook-form';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import classNames from 'classnames';
import TextButton from '@ssg/common/Components/TextButton';

const GET_SETTINGS = loader('../../../GraphQL/AdminSettings/GetSettings.gql');
const CREATE_SETTING = loader('../../../GraphQL/AdminSettings/CreateSettings.gql');
const UPDATE_SETTING = loader('../../../GraphQL/AdminSettings/UpdateSettings.gql');
const GET_DEBITORS = loader('../../../GraphQL/Debitors/GetAdminDebitors.gql');
const GET_DEBITORS_BY_NO = loader('../../../GraphQL/Debitors/GetDebitorsByReferenceNo.gql');
const GET_DAMAGE_CATEGORIES = loader('../../../GraphQL/DamageCategory/GetAdminDamageCategories.gql');
const GET_DAMAGE_CAUSES = loader('../../../GraphQL/DamageCause/GetAdminDamageCauses.gql');

const UNKNOWN_ID = '_UNKNOWN_';

const ScalePointTab: React.FC = () => {
	const { t } = useTranslation();

	const { data, loading } = useQuery<GetSettings>(GET_SETTINGS, {
		variables: {
			type: 'SP',
		},
	});
	const [settings, setSettings] = React.useState<GetSettings_setting_SPSettings>({
		logins: [],
		debitorMappings: [],
		damageMappings: [],
		type: 'SP',
		id: UNKNOWN_ID,
	});
	React.useEffect(() => {
		const settings = (data?.setting?.slice()[0] as GetSettings_setting_SPSettings) ?? {
			logins: [],
			debitorMappings: [],
			damageMappings: [],
			type: 'SP',
			id: UNKNOWN_ID,
		};

		setSettings({
			// Set fields again to remove __typename field
			id: settings.id,
			type: settings.type,
			logins: settings.logins.map(({ insuranceCompany, userLogin, token, debitors, contentSectorCategories }) => ({
				insuranceCompany,
				userLogin,
				token,
				debitors,
				contentSectorCategories,
			})),
			debitorMappings: settings.debitorMappings.map(({ insuranceCompany, insuranceSubCompany, debitor }) => ({
				insuranceCompany,
				insuranceSubCompany,
				debitor,
			})),
			damageMappings: settings.damageMappings.map(({ insuranceCompany, damageCategories, damageCauses }) => ({
				insuranceCompany,
				damageCategories: damageCategories.map(({ key, value }) => ({ key, value })),
				damageCauses: damageCauses.map(({ key, value }) => ({
					key,
					value,
				})),
			})),
		});
		setInitialSelectedDebitorsLoaded(false);
	}, [data]);

	const settingsAreValid = React.useMemo(() => {
		// Check logins
		if (settings.logins.some(l => l.insuranceCompany.length === 0 || l.userLogin.length === 0 || l.token.length === 0 || l.debitors.length === 0)) {
			return false;
		}

		// Check debitor mappings
		if (settings.debitorMappings.some(dm => dm.insuranceCompany.length === 0 || dm.debitor.length === 0)) {
			return false;
		}

		// Check damage category/cause mappings
		if (
			settings.damageMappings.some(
				dm =>
					dm.insuranceCompany.length === 0 ||
					dm.damageCategories.some(dc => dc.key.length === 0 || dc.value.length === 0) ||
					dm.damageCauses.some(dc => dc.key.length === 0 || dc.value.length === 0),
			)
		) {
			return false;
		}

		return true;
	}, [settings]);

	const [createSetting, { loading: createLoading }] = useMutation<CreateSettings, CreateSettingsVariables>(CREATE_SETTING);
	const [updateSetting, { loading: updateLoading }] = useMutation<UpdateSettings, UpdateSettingsVariables>(UPDATE_SETTING);

	const { data: selectedDebitors, loading: selectedDebitorsLoaded } = useQuery<GetDebitorsByReferenceNo, GetDebitorsByReferenceNoVariables>(GET_DEBITORS_BY_NO, {
		variables: {
			referenceNoList: [...settings.logins.flatMap(l => l.debitors), ...settings.debitorMappings.map(m => m.debitor)].filter((d, i, a) => a.indexOf(d) === i),
		},
		nextFetchPolicy: 'cache-and-network',
	});
	const [initialSelectedDebitorsLoaded, setInitialSelectedDebitorsLoaded] = React.useState(true);
	React.useEffect(() => setInitialSelectedDebitorsLoaded(true), [selectedDebitorsLoaded]);

	const [debitorsByReferenceNo, setDebitorsByReferenceNo] = React.useState<Record<string, string>>({});
	React.useEffect(() => {
		if (typeof selectedDebitors?.debitorsByReferenceNo !== 'undefined') {
			for (const debitor of selectedDebitors.debitorsByReferenceNo) {
				setDebitorsByReferenceNo(current => ({
					...current,
					[debitor.debitorId]: debitor.company,
				}));
			}
		}
	}, [selectedDebitors]);

	const [debitorSearchText, setDebitorSearchText] = useDebouncedState('', 100);
	const { data: debitors, loading: debitorsLoading } = useQuery<GetAdminDebitors>(GET_DEBITORS, {
		variables: {
			searchText: debitorSearchText,
		},
	});
	const { control: debitorControl } = useForm();

	const [damageCategorySearchText, setDamageCategorySearchText] = useDebouncedState('', 100);
	const { control: contentSectorCategoriesControl } = useForm();

	const { data: damageCategories } = useQuery<GetAdminDamageCategories>(GET_DAMAGE_CATEGORIES);
	const [editDamageCategoryMappings, setEditDamageCategoryMappings] = React.useState<number>();

	const { data: damageCauses } = useQuery<GetAdminDamageCauses>(GET_DAMAGE_CAUSES);
	const [editDamageCauseMappings, setEditDamageCauseMappings] = React.useState<number>();

	return (
		<div className="text-blue h-full px-3 pb-3">
			<div>
				{loading && typeof data == 'undefined' ? (
					<div className="relative h-40">
						<Loading />
					</div>
				) : (
					<>
						<H3>{t('integrations.scalepointLogins')}</H3>
						<Table
							disableOverflowAuto
							data={settings.logins ?? []}
							columns={[
								{
									label: t('integrations.insuranceCompany'),
									selectFn: (l, i) => (
										<Input
											name="insuranceCompany"
											value={l.insuranceCompany}
											onChange={e =>
												setSettings(current => ({
													...current,
													logins: [
														...current.logins.slice(0, i),
														{
															...current.logins[i],
															insuranceCompany: e.target.value,
														},
														...current.logins.slice(i + 1),
													],
												}))
											}
										/>
									),
								},
								{
									label: t('integrations.fallbackUsername'),
									selectFn: (l, i) => (
										<Input
											name="userLogin"
											value={l.userLogin}
											onChange={e =>
												setSettings(current => ({
													...current,
													logins: [
														...current.logins.slice(0, i),
														{
															...current.logins[i],
															userLogin: e.target.value,
														},
														...current.logins.slice(i + 1),
													],
												}))
											}
										/>
									),
								},
								{
									label: t('integrations.customerToken'),
									selectFn: (l, i) => (
										<Input
											name="token"
											value={l.token}
											onChange={e =>
												setSettings(current => ({
													...current,
													logins: [
														...current.logins.slice(0, i),
														{
															...current.logins[i],
															token: e.target.value,
														},
														...current.logins.slice(i + 1),
													],
												}))
											}
										/>
									),
								},
								{
									label: t('integrations.debitors'),
									selectFn: (l, i) => (
										<div>
											<SearchableSelect
												name="logins-debitor"
												control={debitorControl}
												options={(debitors?.debitors ?? []).map(d => ({
													label: `${d.company} (${d.debitorId})`,
													value: d.debitorId,
												}))}
												searchFn={searchText => setDebitorSearchText(searchText)}
												onSelect={value => {
													if (value.length > 0) {
														setSettings(current => ({
															...current,
															logins: [
																...current.logins.slice(0, i),
																{
																	...current.logins[i],
																	debitors: [...current.logins[i].debitors, value],
																},
																...current.logins.slice(i + 1),
															],
														}));
													}
												}}
												onBlur={clearFn => clearFn()}
												minInputLength={2}
												isLoading={debitorsLoading}
											/>

											<ul>
												{l.debitors.map(d => (
													<li key={d} className="mt-2">
														<FontAwesomeIcon
															icon={faTimes}
															onClick={() =>
																setSettings(current => ({
																	...current,
																	logins: [
																		...current.logins.slice(0, i),
																		{
																			...current.logins[i],
																			debitors: current.logins[i].debitors.filter(ed => ed !== d),
																		},
																		...current.logins.slice(i + 1),
																	],
																}))
															}
															className="mr-2 cursor-pointer"
														/>
														{debitorsByReferenceNo[d] ?? '?'}
														&nbsp;({d})
													</li>
												))}
											</ul>
										</div>
									),
									noTruncate: true,
								},
								{
									label: t('integrations.contentSectorCategories'),
									selectFn: (l, i) => (
										<div>
											<SearchableSelect
												name="logins-content-sector-categories"
												control={contentSectorCategoriesControl}
												options={(damageCategories?.damageCategoriesAdmin ?? [])
													.filter(dc => dc.name.toLocaleLowerCase().includes(damageCategorySearchText.toLocaleLowerCase()))
													.map(dc => ({
														label: dc.name,
														value: dc.id,
													}))}
												searchFn={searchText => setDamageCategorySearchText(searchText)}
												onSelect={value => {
													if (value.length > 0) {
														setSettings(current => ({
															...current,
															logins: [
																...current.logins.slice(0, i),
																{
																	...current.logins[i],
																	contentSectorCategories: [...current.logins[i].contentSectorCategories, value],
																},
																...current.logins.slice(i + 1),
															],
														}));
													}
												}}
												onBlur={clearFn => clearFn()}
												minInputLength={2}
												isLoading={false}
											/>

											<ul>
												{l.contentSectorCategories.map(cat => (
													<li key={cat} className="mt-2">
														<FontAwesomeIcon
															icon={faTimes}
															onClick={() =>
																setSettings(current => ({
																	...current,
																	logins: [
																		...current.logins.slice(0, i),
																		{
																			...current.logins[i],
																			contentSectorCategories: current.logins[i].contentSectorCategories.filter(scs => scs !== cat),
																		},
																		...current.logins.slice(i + 1),
																	],
																}))
															}
															className="mr-2 cursor-pointer"
														/>
														{damageCategories?.damageCategoriesAdmin.find(dca => dca.id === cat)?.name ?? '?'}
													</li>
												))}
											</ul>
										</div>
									),
									noTruncate: true,
								},
								{
									label: t('common.delete'),
									classNameTh: 'text-right',
									selectFn: (l, i) => (
										<div className="text-red flex content-start justify-end text-right">
											<FontAwesomeIcon
												icon={faTrashAlt}
												size="lg"
												className="cursor-pointer"
												onClick={() => {
													if (window.confirm(t('integrations.wantToDelete'))) {
														setSettings(current => ({
															...current,
															logins: [...current.logins.slice(0, i), ...current.logins.slice(i + 1)],
														}));
													}
												}}
											/>
										</div>
									),
								},
							]}
							keySelector={(l, i) => String(i)}
						/>
						<TextButton
							icon={faPlus}
							text="common.add"
							className="mt-1"
							onClick={() =>
								setSettings(current => ({
									...current,
									logins: [
										...current.logins,
										{
											insuranceCompany: '',
											userLogin: '',
											token: '',
											debitors: [],
											contentSectorCategories: [],
										},
									],
								}))
							}
						/>

						<hr className="my-8 border-gray-200" />

						<H3>{t('integrations.scalepointDebitorMappings')}</H3>
						<Table
							disableOverflowAuto
							data={settings.debitorMappings ?? []}
							columns={[
								{
									label: t('integrations.insuranceCompany'),
									selectFn: (m, i) => (
										<Input
											name="insuranceCompany"
											value={m.insuranceCompany}
											onChange={e =>
												setSettings(current => ({
													...current,
													debitorMappings: [
														...current.debitorMappings.slice(0, i),
														{
															...current.debitorMappings[i],
															insuranceCompany: e.target.value,
														},
														...current.debitorMappings.slice(i + 1),
													],
												}))
											}
										/>
									),
								},
								{
									label: t('integrations.insuranceSubCompany'),
									selectFn: (m, i) => (
										<Input
											name="insuranceSubCompany"
											value={m.insuranceSubCompany ?? ''}
											onChange={e =>
												setSettings(current => ({
													...current,
													debitorMappings: [
														...current.debitorMappings.slice(0, i),
														{
															...current.debitorMappings[i],
															insuranceSubCompany: e.target.value,
														},
														...current.debitorMappings.slice(i + 1),
													],
												}))
											}
										/>
									),
								},
								{
									label: t('integrations.debitor'),
									selectFn: (m, i) =>
										initialSelectedDebitorsLoaded ? (
											<SearchableSelect
												name="debitorMappings-debitor"
												control={debitorControl}
												options={(debitors?.debitors ?? []).map(d => ({
													label: `${d.company} (${d.debitorId})`,
													value: d.debitorId,
												}))}
												searchFn={searchText => setDebitorSearchText(searchText)}
												onSelect={value =>
													setSettings(current => ({
														...current,
														debitorMappings: [
															...current.debitorMappings.slice(0, i),
															{
																...current.debitorMappings[i],
																debitor: value,
															},
															...current.debitorMappings.slice(i + 1),
														],
													}))
												}
												onBlur={() => undefined}
												minInputLength={2}
												isLoading={debitorsLoading}
												initialSelection={
													m.debitor.length > 0
														? {
																label: `${debitorsByReferenceNo[m.debitor] ?? '?'} (${m.debitor})`,
																value: m.debitor,
														  }
														: undefined
												}
											/>
										) : (
											<Input name="initialSelectedDebitorsLoading" disabled />
										),
									noTruncate: true,
								},
								{
									label: t('common.delete'),
									classNameTh: 'text-right',
									selectFn: (m, i) => (
										<div className="text-red flex content-start justify-end text-right">
											<FontAwesomeIcon
												icon={faTrashAlt}
												size="lg"
												onClick={() => {
													if (window.confirm(t('integrations.wantToDelete'))) {
														setSettings(current => ({
															...current,
															debitorMappings: [...current.debitorMappings.slice(0, i), ...current.debitorMappings.slice(i + 1)],
														}));
													}
												}}
											/>
										</div>
									),
								},
							]}
							keySelector={(m, i) => String(i)}
						/>
						<TextButton
							icon={faPlus}
							text="common.add"
							className="mt-1"
							onClick={() =>
								setSettings(current => ({
									...current,
									debitorMappings: [
										...current.debitorMappings,
										{
											insuranceCompany: '',
											insuranceSubCompany: null,
											debitor: '',
										},
									],
								}))
							}
						/>

						<hr className="my-8 border-gray-200" />

						<H3>{t('integrations.scalepointDamageMappings')}</H3>
						<Table
							disableOverflowAuto
							data={settings.damageMappings ?? []}
							columns={[
								{
									label: t('integrations.insuranceCompany'),
									selectFn: (m, i) => (
										<Input
											name="insuranceCompany"
											value={m.insuranceCompany}
											onChange={e =>
												setSettings(current => ({
													...current,
													damageMappings: [
														...current.damageMappings.slice(0, i),
														{
															...current.damageMappings[i],
															insuranceCompany: e.target.value,
														},
														...current.damageMappings.slice(i + 1),
													],
												}))
											}
										/>
									),
								},
								{
									label: t('integrations.category'),
									selectFn: (m, i) => (
										<div className="flex flex-row space-x-8">
											<dl className="space-y-4 overflow-y-scroll" style={{ maxHeight: '12em' }}>
												{m.damageCategories.map(mdc => {
													const values = mdc.value.split(',');

													return (
														<div key={mdc.key}>
															<dt className="font-semibold">{damageCategories?.damageCategoriesAdmin.find(dc => dc.id === mdc.key)?.name}</dt>

															{values.map(v => (
																<dd key={v} className="font-mono">
																	=&nbsp;{v}
																</dd>
															))}
														</div>
													);
												})}
											</dl>

											<TextButton icon={faEdit} text="common.edit" className="mt-1" onClick={() => setEditDamageCategoryMappings(i)} />

											<Modal
												size={ModalSize.XLARGE}
												visible={editDamageCategoryMappings === i}
												close={() => setEditDamageCategoryMappings(undefined)}
												title="integrations.category"
												body={
													<div className="flex flex-row flex-wrap">
														{damageCategories?.damageCategoriesAdmin
															.slice()
															.sort((a, b) => a.name.localeCompare(b.name))
															.map(dc => (
																<div key={dc.id} className="w-1/4 py-4">
																	<span className="block font-semibold">{dc.name}</span>
																	<Input
																		name={dc.id}
																		value={m.damageCategories.find(mdc => mdc.key === dc.id)?.value}
																		onChange={e =>
																			setSettings(current => {
																				const categories = current.damageMappings[i].damageCategories.slice();
																				let index = categories.findIndex(mdc => mdc.key === dc.id);
																				if (index === -1) {
																					index =
																						categories.push({
																							key: dc.id,
																							value: '',
																						}) - 1;
																				}

																				return {
																					...current,
																					damageMappings: [
																						...current.damageMappings.slice(0, i),
																						{
																							...current.damageMappings[i],
																							damageCategories: [
																								...categories.slice(0, index),
																								{
																									key: dc.id,
																									value: e.target.value,
																								},
																								...categories.slice(index + 1),
																							].filter(({ value }) => value.length !== 0),
																						},
																						...current.damageMappings.slice(i + 1),
																					],
																				};
																			})
																		}
																	/>
																</div>
															))}
													</div>
												}
											/>
										</div>
									),
								},
								{
									label: t('integrations.cause'),
									selectFn: (m, i) => (
										<div className="flex flex-row space-x-8">
											<dl className="space-y-4 overflow-y-scroll" style={{ maxHeight: '12em' }}>
												{m.damageCauses.map(mdc => {
													const values = mdc.value.split(',');

													return (
														<div key={mdc.key}>
															<dt className="font-semibold">{damageCauses?.damageCausesAdmin.find(dc => dc.id === mdc.key)?.name}</dt>

															{values.map(v => (
																<dd key={v} className="font-mono">
																	=&nbsp;{v}
																</dd>
															))}
														</div>
													);
												})}
											</dl>

											<TextButton icon={faEdit} text="common.edit" className="mt-1" onClick={() => setEditDamageCauseMappings(i)} />

											<Modal
												size={ModalSize.XLARGE}
												visible={editDamageCauseMappings === i}
												close={() => setEditDamageCauseMappings(undefined)}
												title="integrations.cause"
												body={
													<div className="flex flex-row flex-wrap">
														{damageCauses?.damageCausesAdmin
															.slice()
															.sort((a, b) => {
																const categoryCompare = a.category.name.localeCompare(b.category.name);
																if (categoryCompare !== 0) {
																	return categoryCompare;
																}

																return a.name.localeCompare(b.name);
															})
															.map((dc, dci, arr) => (
																<>
																	{(dci === 0 || dc.category.id !== arr[dci - 1].category.id) && (
																		<div
																			className={classNames('w-full', {
																				'border-t-1 mt-4 border-gray-300 pt-4': dci > 0,
																			})}
																		>
																			<span className="text-blue text-lg font-bold">{dc.category.name}</span>
																		</div>
																	)}

																	<div key={dc.id} className="w-1/4 py-4">
																		<span className="block font-semibold">{dc.name}</span>
																		<Input
																			name={dc.id}
																			value={m.damageCauses.find(mdc => mdc.key === dc.id)?.value}
																			onChange={e =>
																				setSettings(current => {
																					const causes = current.damageMappings[i].damageCauses.slice();
																					let index = causes.findIndex(mdc => mdc.key === dc.id);
																					if (index === -1) {
																						index =
																							causes.push({
																								key: dc.id,
																								value: '',
																							}) - 1;
																					}

																					return {
																						...current,
																						damageMappings: [
																							...current.damageMappings.slice(0, i),
																							{
																								...current.damageMappings[i],
																								damageCauses: [
																									...causes.slice(0, index),
																									{
																										key: dc.id,
																										value: e.target.value,
																									},
																									...causes.slice(index + 1),
																								].filter(({ value }) => value.length !== 0),
																							},
																							...current.damageMappings.slice(i + 1),
																						],
																					};
																				})
																			}
																		/>
																	</div>
																</>
															))}
													</div>
												}
											/>
										</div>
									),
								},
								{
									label: t('common.delete'),
									classNameTh: 'text-right',
									selectFn: (m, i) => (
										<div className="text-red flex content-start justify-end text-right">
											<FontAwesomeIcon
												icon={faTrashAlt}
												size="lg"
												onClick={() => {
													if (window.confirm(t('integrations.wantToDelete'))) {
														setSettings(current => ({
															...current,
															damageMappings: [...current.damageMappings.slice(0, i), ...current.damageMappings.slice(i + 1)],
														}));
													}
												}}
											/>
										</div>
									),
								},
							]}
							keySelector={(m, i) => String(i)}
						/>
						<TextButton
							icon={faPlus}
							text="common.add"
							className="mt-1"
							onClick={() =>
								setSettings(current => ({
									...current,
									damageMappings: [
										...current.damageMappings,
										{
											insuranceCompany: '',
											damageCategories: [],
											damageCauses: [],
										},
									],
								}))
							}
						/>

						<div className="mt-12">
							<Button
								success
								text="common.save"
								onClick={() => {
									const { id, ...spSetting } = settings;
									spSetting.debitorMappings.forEach(m => {
										// Set sub company to null if not filled out
										if (typeof m.insuranceSubCompany === 'string' && m.insuranceSubCompany.length === 0) {
											m.insuranceSubCompany = null;
										}
									});

									if (id === UNKNOWN_ID) {
										createSetting({
											variables: { spSetting },
										});
									} else {
										updateSetting({
											variables: { id, spSetting },
										});
									}
								}}
								disabled={!settingsAreValid || createLoading || updateLoading}
							/>

							{!settingsAreValid && <span className="text-red">{t('integrations.invalidSettings')}</span>}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default ScalePointTab;
