import React from 'react';
import Header from '@ssg/common/Components/Header';
import SSGLocations from './SSGLocations/SSGLocations';
import Movables from './MovablesLocations/Movables';
import Departments from './Department/Departments';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import MachinesOverview from './Machines/MachineOverview';

const tabs = ['locations.overviewTitle', 'movablesLocations.overviewTitle', 'departmentsLocations.overviewTitle'];

const LocationsTabs: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);

	return (
		<>
			<Header title="locations.overviewTitle">
				<div className="flex">
					{tabs.map((title, i) => (
						<div key={i} className="mr-3 mt-1">
							<button
								type="button"
								onClick={() => setActiveTab(title)}
								className={classNames('text-blue focus:outline-none', {
									'border-blue border-b-2': title === activeTab,
								})}
							>
								{t(title)}
							</button>
						</div>
					))}
				</div>
			</Header>

			<div className="mx-14 bg-white p-4 pb-1">
				{activeTab === 'locations.overviewTitle' && <SSGLocations />}
				{activeTab === 'movablesLocations.overviewTitle' && <Movables />}
				{activeTab === 'departmentsLocations.overviewTitle' && <Departments />}
				{/* {activeTab === 'groupPermissions.machines' && <MachinesOverview />} */}
			</div>
		</>
	);
};

export default LocationsTabs;
