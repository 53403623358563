import * as yup from 'yup';

yup.setLocale({
	string: {
		required: 'error.required',
	},
	number: {
		required: 'error.required',
	},
});

export const MovablesLocationSchema = yup.object().shape({
	name: yup.string().required(),

	volume: yup.number().required(),

	locationId: yup.string().required(),
});
