import React from 'react';
import { GetAdminDamageCategories, GetAdminDamageCategories_damageCategoriesAdmin } from '../../../GraphQL';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import DamageCategoryModal from './DamageCategoryModal';

const DAMAGE_CATEGORIES = loader('src/GraphQL/DamageCategory/GetAdminDamageCategories.gql');

const DamageCategory: React.FC = () => {
	const [createCategory, setCreateCategory] = React.useState(false);
	const [updateCategory, setUpdateCategory] = React.useState(false);
	const [deleteCategory, setDeleteCategory] = React.useState(false);
	const [categoryData, setCategoryData] = React.useState<GetAdminDamageCategories_damageCategoriesAdmin>();

	const { data, loading, refetch } = useQuery<GetAdminDamageCategories>(DAMAGE_CATEGORIES);

	return (
		<div>
			<header className="flex">
				<div className="w-1/2"></div>
				<div className="flex w-1/2 justify-end">
					<Button primary text="damageCategory.create" icon={faPlus} onClick={() => setCreateCategory(true)} className="mb-2" />
				</div>
			</header>

			<div className="text-blue relative bg-white pb-1">
				{loading ? (
					<div className="h-40">
						<Loading />
					</div>
				) : (
					<Table
						data={data?.damageCategoriesAdmin ?? []}
						columns={[
							{
								label: 'common.name',
								selectFn: c => <p className="py-1">{c.name}</p>,
								sortFn: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
							},
							{
								label: 'common.edit',
								classNameTh: 'text-right',
								selectFn: c => (
									<div className="flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faEdit}
											size="lg"
											onClick={() => {
												setCategoryData(c);
												setUpdateCategory(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
							{
								label: 'common.delete',
								classNameTh: 'text-right',
								selectFn: c => (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setCategoryData(c);
												setDeleteCategory(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
						]}
						keySelector={c => c.id}
					/>
				)}
			</div>

			{createCategory && (
				<DamageCategoryModal
					open={createCategory}
					close={() => setCreateCategory(false)}
					submitCb={() => {
						refetch();
						setCreateCategory(false);
					}}
				/>
			)}

			{updateCategory && (
				<DamageCategoryModal
					edit
					open={updateCategory}
					close={() => setUpdateCategory(false)}
					data={categoryData}
					submitCb={() => {
						refetch();
						setUpdateCategory(false);
					}}
				/>
			)}

			{deleteCategory && (
				<DamageCategoryModal
					erase
					open={deleteCategory}
					close={() => setDeleteCategory(false)}
					data={categoryData}
					submitCb={() => {
						refetch();
						setDeleteCategory(false);
					}}
				/>
			)}
		</div>
	);
};

export default DamageCategory;
