import React from 'react';
import { useMicrosoftAuth } from '@ssg/common/Components/MicrosoftAuth';
import { useTranslation } from 'react-i18next';
import Button from '@ssg/common/Components/Button';
import BigLogo from '@ssg/common/Components/Logo/BigLogo';

const LoginPage: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();
	const { login } = useMicrosoftAuth();

	return (
		<div className="mt-64 flex flex-col items-center justify-center">
			<BigLogo className="h-20 object-contain" black />
			<p className="text-blue text-6xl font-semibold italic">5C</p>

			<p className="text-blue mt-5 text-xl font-semibold">
				{/* {t('description')} */}
				Her kan du tilgå administrationssiden for 5C
			</p>
			<div className="mt-24 flex flex-row items-center">
				{/* <Link to="/casecreation" className="flex items-center font-semibold bg-blue text-xl text-white rounded-default w-56 px-16 py-3 mr-10">
                    {t('create')}
                </Link>
                <p className="text-blue font-semibold text-xl">
                    {t('or')}
                </p> */}
				<Button success onClick={() => login()}>
					{t('common.login')}
				</Button>
			</div>
		</div>
	);
};

export default LoginPage;
