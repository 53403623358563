import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	CatalogCustomerInputType,
	CreateCatalogCustomer,
	CreateCatalogCustomerVariables,
	DeleteCatalogCustomer,
	DeleteCatalogCustomerVariables,
	GetAdminCatalogCustomers,
	GetAdminCatalogCustomers_catalogCustomers,
	SearchAdminUsers,
	SearchAdminUsersVariables,
	UpdateCatalogCustomer,
	UpdateCatalogCustomerVariables,
} from '../../GraphQL';
import { CatalogCustomerSchema } from '../../Schemas/CatalogCustomerSchema';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import TextButton from '@ssg/common/Components/TextButton';
import { faSpinner, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { useDeleteCatalogCraftsmanMutation, useGetAdminCatalogsQuery, useDeleteCatalogMutation, useDeleteCatalogContactMutation, useDeleteTimedMessageMutation } from '@ssg/common/GraphQL/indexV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';

const SEARCH_USERS = loader('src/GraphQL/Users/SearchAdminUsers.gql');
const GET_CATALOG_CUSTOMERS = loader('src/GraphQL/CatalogCustomers/GetAdminCatalogCustomers.gql');
const UPDATE_CATALOG_CUSTOMER = loader('src/GraphQL/CatalogCustomers/UpdateCatalogCustomer.gql');
const DELETE_CATALOG_CUSTOMER = loader('src/GraphQL/CatalogCustomers/DeleteCatalogCustomer.gql');
const CREATE_CATALOG_CUSTOMER = loader('src/GraphQL/CatalogCustomers/CreateCatalogCustomer.gql');

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	erase?: boolean;
	customer?: GetAdminCatalogCustomers_catalogCustomers;
}

const CatalogCustomerModal: React.FC<Props> = ({ open, close, edit = false, erase = false, customer }) => {
	const { t } = useTranslation();
	const catalogCostumerAllDeleteFlag = useFlag(FeatureFlagEnums.CATALOG_CUSTUMER_ALL_DELETE);
	const [updateCatalogCustomer, { loading: updateLoading }] = useMutation<UpdateCatalogCustomer, UpdateCatalogCustomerVariables>(UPDATE_CATALOG_CUSTOMER);
	const [createCatalogCustomer, { loading: createLoading }] = useMutation<CreateCatalogCustomer, CreateCatalogCustomerVariables>(CREATE_CATALOG_CUSTOMER);
	const [deleteCatalogCustomer] = useMutation<DeleteCatalogCustomer, DeleteCatalogCustomerVariables>(DELETE_CATALOG_CUSTOMER);

	const [deleting, setDeleting] = React.useState(false);
	// For deletion
	const { data: catalogCustomerData, loading: catalogCustomerDataLoading } = useGetAdminCatalogsQuery({
		variables: {
			customerIds: [customer?.id ?? ''],
		},
		skip: typeof customer === 'undefined' || !erase || !catalogCostumerAllDeleteFlag,
	});

	const [deleteCatalogCraftsman] = useDeleteCatalogCraftsmanMutation();
	const [deleteCatalogContact] = useDeleteCatalogContactMutation();
	const [deleteCatalogTimedMessage] = useDeleteTimedMessageMutation();
	const [deleteCatalog] = useDeleteCatalogMutation();


	const { handleSubmit, register, errors, control } = useForm<CatalogCustomerInputType>({
		resolver: yupResolver(CatalogCustomerSchema),
	});
	const dataToDelete = React.useMemo(() => {
		if (typeof catalogCustomerData === 'undefined') {
			return undefined;
		}
		const allCatalogs = catalogCustomerData.catalogs;
		// const catalogsIdAndName = allCatalogs.map<IdName>(c => ({ id: c.id, name: c.propertyNumber }));
		// const craftsmenIdAndName = allCatalogs.flatMap(c => c.craftsmen.map<IdName>(c => ({ id: c.id, name: c.contactName })));
		// const contactsIdAndName = allCatalogs.flatMap(c => c.contacts.map<IdName>(c => ({ id: c.id, name: c.contactName })));
		// const messagesIdAndName = allCatalogs.flatMap(c => c.timedMessages.map<IdName>(c => ({ id: c.id, name: c.message })));
		const catalogsIdAndName = allCatalogs.map(c => c.id).filter((d, i, a) => a.indexOf(d) === i);
		const craftsmenIdAndName = allCatalogs.flatMap(c => c.craftsmen.map(c => c.id)).filter((d, i, a) => a.indexOf(d) === i);
		const contactsIdAndName = allCatalogs.flatMap(c => c.contacts.map(c => c.id)).filter((d, i, a) => a.indexOf(d) === i);
		const messagesIdAndName = allCatalogs.flatMap(c => c.timedMessages.map(c => c.id)).filter((d, i, a) => a.indexOf(d) === i);
		return { catalogsIdAndName, craftsmenIdAndName, contactsIdAndName, messagesIdAndName };
	}, [catalogCustomerData]);
	const [showHelpText, setShowHelpText] = React.useState(false);
	const [searchUsernameText, setSearchUsernameText] = useDebouncedState<string>('', 200);

	const { loading: loadingUsers, data: users } = useQuery<SearchAdminUsers, SearchAdminUsersVariables>(SEARCH_USERS, {
		variables: {
			searchText: searchUsernameText,
		},
		skip: searchUsernameText === '',
	});

	const [selectedADUsers, setSelectedADUsers] = React.useState<SelectOption[]>(
		(customer?.users ?? []).map(u => ({
			value: u.id,
			label: `${u.name} (${u.email})`,
		})),
	);

	const searchSelectHandler = (value: string): void => {
		console.log(value);
		if (value.length === 0 || selectedADUsers.some(d => d.value === value)) {
			return;
		}

		const adUser = searchedUsers.find(su => su.value === value);
		if (typeof adUser !== 'undefined') {
			setSelectedADUsers(adUsers => [...adUsers, { value: adUser.value, label: adUser.label }]);
		}
	};

	React.useEffect(() => console.log(selectedADUsers), [selectedADUsers]);
	const onSubmit = async (formData: CatalogCustomerInputType) => {
		if (edit) {
			try {
				await updateCatalogCustomer({
					variables: {
						id: customer?.id ?? '',
						name: formData.name,
						adUsers: selectedADUsers.map(u => u.value),
					},
					// update: (cache, { data: cacheData }): void => {
					// 	if (typeof cacheData === 'undefined' || cacheData === null) {
					// 		return;
					// 	}
					// 	const cachedRequest = cache.readQuery<GetCatalogCustomers>({ query: GET_CATALOG_CUSTOMERS });

					// 	if (cachedRequest === null || cachedRequest.catalogCustomers === null) {
					// 		return;
					// 	}

					// 	const newCatalogCustomers = [...cachedRequest.catalogCustomers];
					// 	const index = newCatalogCustomers.findIndex(cat => cat.id === customer?.id ?? '');
					// 	newCatalogCustomers[index] = cacheData.updateCatalogCustomer;

					// 	cache.writeQuery<GetCatalogCustomers>({
					// 		query: GET_CATALOG_CUSTOMERS,
					// 		data: {
					// 			catalogCustomers: newCatalogCustomers,
					// 		},
					// 	});
					// },
				});
				close();
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await createCatalogCustomer({
					variables: {
						catalogCustomer: {
							name: formData.name,
							adUsers: selectedADUsers.map(u => u.value),
						},
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}
						const cachedRequest = cache.readQuery<GetAdminCatalogCustomers>({
							query: GET_CATALOG_CUSTOMERS,
						});

						if (cachedRequest === null || cachedRequest.catalogCustomers === null) {
							return;
						}

						cache.writeQuery<GetAdminCatalogCustomers>({
							query: GET_CATALOG_CUSTOMERS,
							data: {
								catalogCustomers: [...cachedRequest.catalogCustomers, cacheData.createCatalogCustomer],
							},
						});
					},
				});
				close();
			} catch (e) {
				console.log(e);
			}
		}
	};

	const onDelete = async (id: string) => {
		setDeleting(true);
		try {
			if (dataToDelete && catalogCostumerAllDeleteFlag) {
				const { catalogsIdAndName, contactsIdAndName, craftsmenIdAndName, messagesIdAndName } = dataToDelete;
				for (let index = 0; index < craftsmenIdAndName.length; index++) {
					await deleteCatalogCraftsman({
						variables: {
							id: craftsmenIdAndName[index],
							changedBy: 'Admin',
						},
					});
				}
				for (let index = 0; index < catalogsIdAndName.length; index++) {
					await deleteCatalog({
						variables: {
							id: catalogsIdAndName[index],
							changedBy: 'Admin',
						},
					});
				}
				for (let index = 0; index < contactsIdAndName.length; index++) {
					await deleteCatalogContact({
						variables: {
							id: contactsIdAndName[index],
							changedBy: 'Admin',
						},
					});
				}
				for (let index = 0; index < messagesIdAndName.length; index++) {
					await deleteCatalogTimedMessage({
						variables: {
							id: messagesIdAndName[index],
							changedBy: 'Admin',
						},
					});
				}
			}
			await deleteCatalogCustomer({
				variables: {
					id: id,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}
					const cachedRequest = cache.readQuery<GetAdminCatalogCustomers>({
						query: GET_CATALOG_CUSTOMERS,
					});

					if (cachedRequest === null || cachedRequest.catalogCustomers === null) {
						return;
					}

					cache.writeQuery<GetAdminCatalogCustomers>({
						query: GET_CATALOG_CUSTOMERS,
						data: {
							catalogCustomers: cachedRequest.catalogCustomers.filter(c => c.id !== id),
						},
					});
				},
			});
			setDeleting(false);
			close();
		} catch (e) {
			console.log(e);
		}
		setDeleting(false);
	};

	const searchedUsers = React.useMemo(
		() =>
			(users?.searchUsers ?? []).map(
				(u): SelectOption => ({
					value: u.id,
					label: `${u.name} (${u.email})`,
				}),
			),
		[users],
	);

	return (
		<Modal
			title={erase ? t('catalog.customer.delete') : edit ? t('catalog.customer.update') : t('catalog.customer.create')}
			size={ModalSize.MEDIUM}
			visible={open}
			close={close}
			body={
				erase && customer ? (
					<div>
						<FormFieldHeader title="catalog.customer.wantToDelete" />
						<div>
							<div>
								{customer.name} - {customer.user && customer.user.name}
							</div>

							{catalogCustomerDataLoading && <FontAwesomeIcon icon={faSpinner} className="text-blue mr-1 animate-spin" size="1x" />}
							{catalogCostumerAllDeleteFlag && typeof dataToDelete !== 'undefined' &&
								<div>
									<FormFieldHeader title="catalog.deleteWarning" />
									<p>{t('catalog.catalogs')}: {dataToDelete.catalogsIdAndName.length}</p>
									<p>{t('catalog.craftsmen')}: {dataToDelete.craftsmenIdAndName.length}</p>
									<p>{t('catalog.contacts')}: {dataToDelete.contactsIdAndName.length}</p>
									<p>{t('catalog.timedMessages')}: {dataToDelete.messagesIdAndName.length}</p>
								</div>
							}

							<Button danger text="catalog.customer.delete" loading={deleting} disabled={catalogCustomerDataLoading} onClick={() => onDelete(customer.id)} className="mt-4" />
						</div>
					</div>
				) : (
					<div>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Input title="catalog.customer.alias" required name="name" innerRef={register} defaultValue={customer?.name} errorMessage={errors.name?.message} />

							<SearchableSelect
								key="userlabel"
								control={control}
								name="adUsers"
								title="catalog.customer.adUser"
								helpText="catalog.customer.helpText.adUser"
								showHelpText={showHelpText}
								options={searchedUsers ?? []}
								searchFn={searchText => setSearchUsernameText(searchText)}
								onSelect={value => {
									searchSelectHandler(value);
								}}
								onBlur={() => undefined}
								minInputLength={0}
								isLoading={loadingUsers}
							/>
							<div className="flex-grow-default mt-2">
								<FormFieldHeader title="catalog.customer.selectedUsers" />
								<ul className="w-full">
									{selectedADUsers.map(d => (
										<li key={d.value} className="text-blue my-2">
											<div className="inline-block">
												<TextButton icon={faTrash} onClick={() => setSelectedADUsers(current => current.filter(ad => ad.value !== d.value))} />
											</div>
											<span className="ml-1">{d.label}</span>
										</li>
									))}
								</ul>
							</div>
							<div className="mt-4 flex">
								<div className="flex flex-row items-center">
									<Button success submit text={customer ? 'catalog.customer.update' : 'catalog.customer.create'} loading={createLoading || updateLoading} className="mt-4" />
								</div>
							</div>
						</form>
					</div>
				)
			}
		/>
	);
};

export default CatalogCustomerModal;
