import React from 'react';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	CreateRiskEvaluationCategory,
	CreateRiskEvaluationCategoryVariables,
	DeleteRiskEvaluationCategory,
	DeleteRiskEvaluationCategoryVariables,
	GetRiskEvaluationCategories,
	GetRiskEvaluationCategories_riskEvaluationCategories,
	UpdateRiskEvaluationCategory,
	UpdateRiskEvaluationCategoryVariables,
} from '../../../GraphQL';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';

const GET_RISK_EVALUATION_CATEGORIES = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationCategories.gql');
const CREATE_RISK_EVALUATION_CATEGORY = loader('src/GraphQL/RiskEvaluation/CreateRiskEvaluationCategory.gql');
const UPDATE_RISK_EVALUATION_CATEGORY = loader('src/GraphQL/RiskEvaluation/UpdateRiskEvaluationCategory.gql');
const DELETE_RISK_EVALUATION_CATEGORY = loader('src/GraphQL/RiskEvaluation/DeleteRiskEvaluationCategory.gql');

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	erase?: boolean;
	data?: GetRiskEvaluationCategories_riskEvaluationCategories;
}

const RiskEvaluationCategoryModal: React.FC<Props> = ({ open, close, edit = false, erase = false, data }): React.ReactElement => {
	const { t } = useTranslation();

	const [createRiskEvaluationCategoty, { loading: createLoading }] = useMutation<CreateRiskEvaluationCategory, CreateRiskEvaluationCategoryVariables>(CREATE_RISK_EVALUATION_CATEGORY);
	const [updateRiskEvaluationCategoty, { loading: updateLoading }] = useMutation<UpdateRiskEvaluationCategory, UpdateRiskEvaluationCategoryVariables>(UPDATE_RISK_EVALUATION_CATEGORY);
	const [deleteRiskEvaluationCategoty, { loading: deleteLoading }] = useMutation<DeleteRiskEvaluationCategory, DeleteRiskEvaluationCategoryVariables>(DELETE_RISK_EVALUATION_CATEGORY);

	const { handleSubmit, register } = useForm({
		reValidateMode: 'onChange',
	});

	const onSubmit = async (category: GetRiskEvaluationCategories_riskEvaluationCategories) => {
		console.log(category);

		if (data) {
			try {
				await updateRiskEvaluationCategoty({
					variables: {
						id: data.id,
						categoryName: category.categoryName,
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}

						const cachedRequest = cache.readQuery<GetRiskEvaluationCategories>({
							query: GET_RISK_EVALUATION_CATEGORIES,
						});

						if (cachedRequest === null || cachedRequest.riskEvaluationCategories === null) {
							return;
						}

						const newQuestion = [...cachedRequest.riskEvaluationCategories];
						const index = newQuestion.findIndex(q => q.id === data?.id ?? '');
						newQuestion[index] = cacheData.updateRiskEvaluationCategory;

						cache.writeQuery<GetRiskEvaluationCategories>({
							query: GET_RISK_EVALUATION_CATEGORIES,
							data: {
								riskEvaluationCategories: newQuestion,
							},
						});
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await createRiskEvaluationCategoty({
					variables: {
						riskEvaluationCategory: {
							categoryName: category.categoryName,
						},
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}

						const cachedRequest = cache.readQuery<GetRiskEvaluationCategories>({
							query: GET_RISK_EVALUATION_CATEGORIES,
						});

						if (cachedRequest === null || cachedRequest.riskEvaluationCategories === null) {
							return;
						}

						cache.writeQuery<GetRiskEvaluationCategories>({
							query: GET_RISK_EVALUATION_CATEGORIES,
							data: {
								riskEvaluationCategories: [...cachedRequest.riskEvaluationCategories, cacheData.createRiskEvaluationCategory],
							},
						});
					},
				});
			} catch (e) {
				console.log(e);
			}
		}
		close();
	};

	return (
		<Modal
			title={(erase && 'riskEvaluation.deleteCategory') || (data && 'riskEvaluation.updateCategory') || 'riskEvaluation.createCategory'}
			visible={open}
			close={close}
			size={ModalSize.SMALL}
			body={
				erase && typeof data !== 'undefined' ? (
					<div>
						<FormFieldHeader title="riskEvaluation.wantToDeleteCategory" />
						<div>
							<span className="text-blue font-semibold">{t('riskEvaluation.riskCategory')}:</span> {data.categoryName}
						</div>

						<Button
							danger
							text="riskEvaluation.deleteCategory"
							loading={deleteLoading}
							onClick={async () => {
								await deleteRiskEvaluationCategoty({
									variables: {
										id: data.id,
									},
									update: (cache, { data: cacheData }): void => {
										if (typeof cacheData === 'undefined' || cacheData === null) {
											return;
										}

										const cachedRequest = cache.readQuery<GetRiskEvaluationCategories>({
											query: GET_RISK_EVALUATION_CATEGORIES,
										});

										if (cachedRequest === null || cachedRequest.riskEvaluationCategories === null) {
											return;
										}

										cache.writeQuery<GetRiskEvaluationCategories>({
											query: GET_RISK_EVALUATION_CATEGORIES,
											data: {
												riskEvaluationCategories: cachedRequest.riskEvaluationCategories.filter(c => c.id !== data.id),
											},
										});
									},
								});
								close();
							}}
							className="mt-4"
						/>
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<Input name="categoryName" title="riskEvaluation.riskCategory" required defaultValue={data?.categoryName} innerRef={register} />

						<Button success submit text={data ? 'riskEvaluation.updateCategory' : 'riskEvaluation.createCategory'} loading={createLoading || updateLoading} className="mt-4" />
					</form>
				)
			}
		/>
	);
};

export default RiskEvaluationCategoryModal;
