import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import {
	CreateSettings,
	CreateSettingsVariables,
	GetInvoiceReasonCodes,
	GetInvoiceReasonCodes_invoiceReasonCodes,
	GetSettings,
	GetSettingsVariables,
	GetSettings_setting_InvoiceSettings,
	UpdateSettings,
	UpdateSettingsVariables,
} from '../../GraphQL';
import Button from '@ssg/common/Components/Button';
import Header from '@ssg/common/Components/Header';
import Table from '@ssg/common/Components/Table';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import Box from '../../Components/Layout/Box';
import CreateInvoiceReasonCodeModal from './CreateInvoiceReasonCodeModal';
import TextButton from '@ssg/common/Components/TextButton';
import UpdateInvoiceReasonCodeModal from './UpdateInvoiceReasonCodeModal';
import DeleteInvoiceReasonCodeModal from './DeleteInvoiceReasonCodeModal';
import AddAutomaticInvoiceGroupPrefixModal from './AddAutomaticInvoiceGroupPrefixModal';
import RemoveAutomaticInvoiceGroupPrefixModal from './RemoveAutomaticInvoiceGroupPrefixModal';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Input from '@ssg/common/Components/Input';
import { useFlag } from '@unleash/proxy-client-react';
import DeleteBrokenInvoice from './DeleteBrokenInvoice';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import CheckNotPostedInBc from './CheckNotPostedInBc';

const GET_INVOICE_REASON_CODES = loader('src/GraphQL/Invoices/GetInvoiceReasonCodes.gql');
const GET_SETTINGS = loader('src/GraphQL/AdminSettings/GetSettings.gql');
const CREATE_SETTING = loader('src/GraphQL/AdminSettings/CreateSettings.gql');
const UPDATE_SETTING = loader('src/GraphQL/AdminSettings/UpdateSettings.gql');

const invoiceSettingsType = 'INVOICE';

const Invoices: React.FC = (): React.ReactElement => {
	const [showCreateReasonModal, setShowCreateReasonModal] = React.useState(false);
	const [reasonCodeToEdit, setReasonCodeToEdit] = React.useState<GetInvoiceReasonCodes_invoiceReasonCodes>();
	const [reasonCodeToDelete, setReasonCodeToDelete] = React.useState<GetInvoiceReasonCodes_invoiceReasonCodes>();
	const [newIsAmountBelowAmount, setNewIsAmountBelowAmount] = React.useState(0);
	const [showMinCaseHourlyRate, setShowMinCaseHourlyRate] = React.useState(false);
	const [minCaseHourlyRate, setMinCaseHourlyRate] = React.useState(0);

	const deleteInvoiceAdminFlag = useFlag(FeatureFlagEnums.DELETE_INVOICE_ADMIN);
	const adminSetMinCaseHourlyRate = useFlag(FeatureFlagEnums.ADMIN_SET_MIN_CASE_HOURLY_RATE);
	const { data: invoiceReasonCodesData, loading: invoiceReasonCodesLoading } = useQuery<GetInvoiceReasonCodes>(GET_INVOICE_REASON_CODES);

	const {
		data: invoiceSettingsData,
		loading: loadingInvoiceSettingsData,
		refetch: refetchInvoiceSettings,
	} = useQuery<GetSettings, GetSettingsVariables>(GET_SETTINGS, {
		variables: {
			type: invoiceSettingsType,
		},
	});
	const automaticInvoiceGroupPrefixes = React.useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore disable the error temporary
		const setting = invoiceSettingsData?.setting.find(s => s.type === invoiceSettingsType);
		if (typeof setting === 'undefined') {
			return [];
		}

		return (setting as GetSettings_setting_InvoiceSettings).automaticInvoiceGroupPrefixes;
	}, [invoiceSettingsData?.setting]);

	const isAmountBelowAmount = React.useMemo(() => {
		const setting = invoiceSettingsData?.setting.find(s => s.type === invoiceSettingsType);
		if (typeof setting === 'undefined') {
			return 0;
		}
		setNewIsAmountBelowAmount((setting as GetSettings_setting_InvoiceSettings).isAmountBelowAmount);
		return (setting as GetSettings_setting_InvoiceSettings).isAmountBelowAmount;
	}, [invoiceSettingsData?.setting]);

	const minCaseHourlyRateData = React.useMemo(() => {
		const setting = invoiceSettingsData?.setting.find(s => s.type === invoiceSettingsType);
		if (typeof setting === 'undefined') {
			return 0;
		}
		setMinCaseHourlyRate((setting as GetSettings_setting_InvoiceSettings).minHourlyRate);
		return (setting as GetSettings_setting_InvoiceSettings).minHourlyRate;
	}, [invoiceSettingsData?.setting]);

	const [showAddAutomaticInvoiceGroupModal, setShowAddAutomaticInvoiceGroupModal] = React.useState(false);
	const [showUpdateIsAmountBelowAmount, setShowUpdateIsAmountBelowAmount] = React.useState(false);
	const [automaticInvoiceGroupToRemove, setAutomaticInvoiceGroupToRemove] = React.useState<(typeof automaticInvoiceGroupPrefixes)[number]>();
	const [createInvoiceSetting, { loading: createLoading }] = useMutation<CreateSettings, CreateSettingsVariables>(CREATE_SETTING);
	const [updateInvoiceSetting, { loading: updateLoading }] = useMutation<UpdateSettings, UpdateSettingsVariables>(UPDATE_SETTING);

	return (
		<div>
			<Header title="invoices.title" />
				<BoxContainer>
					<Box title="invoices.isAmountBelowAmount" full icon={faEdit} onClick={() => setShowUpdateIsAmountBelowAmount(true)}>
						<p className="font-bold">{isAmountBelowAmount} DKK</p>
					</Box>
					<Modal
						size={ModalSize.SMALL}
						title="invoices.setIsAmountBelowAmount"
						close={() => setShowUpdateIsAmountBelowAmount(false)}
						visible={showUpdateIsAmountBelowAmount}
						body={
							<form>
								<Input
									type="number"
									title="invoices.amount"
									name="isAmountBelowAmount"
									defaultValue={isAmountBelowAmount}
									onChange={e => setNewIsAmountBelowAmount(e.currentTarget.valueAsNumber)}
								/>

								<Button
									success
									submit
									text="common.save"
									loading={updateLoading || createLoading}
									className="mt-4"
									disabled={isNaN(newIsAmountBelowAmount)}
									onClick={async () => {
										if (isNaN(newIsAmountBelowAmount)) return;

										const existing = invoiceSettingsData?.setting.find(s => s.type === invoiceSettingsType);
										if (typeof existing === 'undefined') {
											await createInvoiceSetting({
												// Create setting if none exists
												variables: {
													invoiceSetting: {
														type: invoiceSettingsType,
														automaticInvoiceGroupPrefixes: automaticInvoiceGroupPrefixes,
														isAmountBelowAmount: newIsAmountBelowAmount,
														minHourlyRate: minCaseHourlyRate,
													},
												},
											});
										} else {
											// Update existing setting if it exists
											await updateInvoiceSetting({
												variables: {
													id: existing.id,
													invoiceSetting: {
														type: existing.type,
														automaticInvoiceGroupPrefixes: automaticInvoiceGroupPrefixes,
														isAmountBelowAmount: newIsAmountBelowAmount,
														minHourlyRate: minCaseHourlyRate,
													},
												},
											});
										}
										await refetchInvoiceSettings();
										setShowUpdateIsAmountBelowAmount(false);
									}}
								/>
							</form>
						}
					/>
				</BoxContainer>
			{adminSetMinCaseHourlyRate && (
				<BoxContainer>
					<Box title="invoices.caseMinHourlyRate" full icon={faEdit} onClick={() => setShowMinCaseHourlyRate(true)}>
						<p className="font-bold">{minCaseHourlyRateData} DKK</p>
					</Box>
					<Modal
						size={ModalSize.SMALL}
						title="invoices.setCaseMinHourlyRateAmount"
						close={() => setShowMinCaseHourlyRate(false)}
						visible={showMinCaseHourlyRate}
						body={
							<form>
								<Input
									type="number"
									title="invoices.amount"
									name="minCaseHourlyRateData"
									defaultValue={minCaseHourlyRateData}
									onChange={e => setMinCaseHourlyRate(e.currentTarget.valueAsNumber)}
								/>

								<Button
									success
									submit
									text="common.save"
									loading={updateLoading || createLoading}
									className="mt-4"
									disabled={isNaN(minCaseHourlyRate)}
									onClick={async () => {
										if (isNaN(minCaseHourlyRate)) return;

										const existing = invoiceSettingsData?.setting.find(s => s.type === invoiceSettingsType);
										if (typeof existing === 'undefined') {
											await createInvoiceSetting({
												// Create setting if none exists
												variables: {
													invoiceSetting: {
														type: invoiceSettingsType,
														automaticInvoiceGroupPrefixes: automaticInvoiceGroupPrefixes,
														isAmountBelowAmount: newIsAmountBelowAmount,
														minHourlyRate: minCaseHourlyRate,
													},
												},
											});
										} else {
											// Update existing setting if it exists
											await updateInvoiceSetting({
												variables: {
													id: existing.id,
													invoiceSetting: {
														type: existing.type,
														automaticInvoiceGroupPrefixes: automaticInvoiceGroupPrefixes,
														isAmountBelowAmount: newIsAmountBelowAmount,
														minHourlyRate: minCaseHourlyRate,
													},
												},
											});
										}
										await refetchInvoiceSettings();
										setShowMinCaseHourlyRate(false);
									}}
								/>
							</form>
						}
					/>
				</BoxContainer>
			)}
			<BoxContainer>
				<Box title="invoices.reasonCodes" full icon={faPlus} onClick={() => setShowCreateReasonModal(true)}>
					<Table
						data={invoiceReasonCodesData?.invoiceReasonCodes ?? []}
						keySelector={rc => rc.id}
						loading={typeof invoiceReasonCodesData !== 'undefined' && invoiceReasonCodesLoading}
						noDataFoundText="invoices.noReasonCodes"
						columns={[
							{
								label: 'common.name',
								selectFn: cr => cr.name,
							},
							{
								label: '',
								align: 'RIGHT',
								selectFn: cr => (
									<div className="flex items-center space-x-6 px-4">
										<TextButton icon={faEdit} className="ml-auto mr-0" onClick={() => setReasonCodeToEdit(cr)} />
										<TextButton icon={faTrashAlt} className="text-red ml-auto mr-0" onClick={() => setReasonCodeToDelete(cr)} />
									</div>
								),
							},
						]}
					/>
				</Box>
			</BoxContainer>

			{deleteInvoiceAdminFlag && <DeleteBrokenInvoice />}

			{showCreateReasonModal && <CreateInvoiceReasonCodeModal close={() => setShowCreateReasonModal(false)} visible={showCreateReasonModal} />}
			{typeof reasonCodeToEdit !== 'undefined' && (
				<UpdateInvoiceReasonCodeModal close={() => setReasonCodeToEdit(undefined)} visible={typeof reasonCodeToEdit !== 'undefined'} reasonCode={reasonCodeToEdit} />
			)}
			{typeof reasonCodeToDelete !== 'undefined' && (
				<DeleteInvoiceReasonCodeModal close={() => setReasonCodeToDelete(undefined)} visible={typeof reasonCodeToDelete !== 'undefined'} reasonCode={reasonCodeToDelete} />
			)}

			<BoxContainer>
				<Box title="invoices.automaticInvoicingGroups" full icon={faPlus} onClick={() => setShowAddAutomaticInvoiceGroupModal(true)}>
					<Table
						data={automaticInvoiceGroupPrefixes.map(p => ({
							name: p,
						}))}
						keySelector={p => p.name}
						loading={loadingInvoiceSettingsData}
						noDataFoundText="invoices.noAutomaticInvoicingGroups"
						columns={[
							{
								label: 'common.name',
								selectFn: p => <p>{p.name}</p>,
							},
							{
								label: '',
								align: 'RIGHT',
								selectFn: p => (
									<div className="flex items-center space-x-6 px-4">
										<TextButton icon={faTrashAlt} className="text-red ml-auto mr-0" onClick={() => setAutomaticInvoiceGroupToRemove(p.name)} />
									</div>
								),
							},
						]}
					/>
				</Box>
			</BoxContainer>

			<BoxContainer>
				<Box title="invoices.checkNotPostedInBc" full>
					<CheckNotPostedInBc />
				</Box>
			</BoxContainer>
			<AddAutomaticInvoiceGroupPrefixModal
				visible={showAddAutomaticInvoiceGroupModal}
				close={() => setShowAddAutomaticInvoiceGroupModal(false)}
				blockedValues={automaticInvoiceGroupPrefixes}
				addValue={async value => {
					const existing = invoiceSettingsData?.setting.find(s => s.type === invoiceSettingsType);
					if (typeof existing === 'undefined') {
						await createInvoiceSetting({
							// Create setting if none exists
							variables: {
								invoiceSetting: {
									type: invoiceSettingsType,
									automaticInvoiceGroupPrefixes: [value],
									isAmountBelowAmount: isAmountBelowAmount,
									minHourlyRate: minCaseHourlyRate,
								},
							},
						});
					} else {
						// Update existing setting if it exists
						await updateInvoiceSetting({
							variables: {
								id: existing.id,
								invoiceSetting: {
									type: existing.type,
									automaticInvoiceGroupPrefixes: [...automaticInvoiceGroupPrefixes, value],
									isAmountBelowAmount: isAmountBelowAmount,
									minHourlyRate: minCaseHourlyRate,
								},
							},
						});
					}
					await refetchInvoiceSettings();
					setShowAddAutomaticInvoiceGroupModal(false);
				}}
			/>

			<RemoveAutomaticInvoiceGroupPrefixModal
				visible={typeof automaticInvoiceGroupToRemove !== 'undefined'}
				close={() => setAutomaticInvoiceGroupToRemove(undefined)}
				value={automaticInvoiceGroupToRemove ?? 'error'}
				remove={async value => {
					const existing = invoiceSettingsData?.setting.find(s => s.type === invoiceSettingsType);
					if (typeof existing === 'undefined') {
						return;
					}

					await updateInvoiceSetting({
						variables: {
							id: existing.id,
							invoiceSetting: {
								type: existing.type,
								automaticInvoiceGroupPrefixes: automaticInvoiceGroupPrefixes.filter(aigp => aigp !== value),
								isAmountBelowAmount: isAmountBelowAmount,
								minHourlyRate: minCaseHourlyRate,
							},
						},
					});
					await refetchInvoiceSettings();
					setAutomaticInvoiceGroupToRemove(undefined);
				}}
			/>
		</div>
	);
};

export default Invoices;
