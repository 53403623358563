import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faQuestionCircle as faQuistionCircleFull } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import Loading from '@ssg/common/Components/Loading';

interface Props {
	title?: string;
	edit?: boolean;
	form?: boolean;
	half?: boolean;
	full?: boolean;
	className?: string;
	onClick?(): void;
	showHelpText?: boolean;
	loading?: boolean;
	helpButton?: boolean;

	icon?: IconDefinition;
}
const Box: React.FC<Props> = ({ title, edit = false, form = false, half = false, full = false, children, className, onClick, showHelpText, loading, helpButton = false, icon }): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<div
			className={classNames('mb-8 w-full px-4 lg:px-4', className, {
				'lg:w-1/3': !form && !half,
				'lg:w-full': form || full,
				'lg:w-1/2 xl:w-1/2': half,
			})}
		>
			{loading && <Loading />}
			<div className="w-full bg-white p-4">
				<div className="flex flex-row items-center">
					{typeof title !== 'undefined' && <div className="text-blue text-lg font-semibold">{t(title)}</div>}
					{helpButton && (
						<div onClick={onClick} className="text-blue flex h-8 w-8 cursor-pointer items-center justify-center">
							{showHelpText ? <FontAwesomeIcon icon={faQuistionCircleFull} size="lg" /> : <FontAwesomeIcon icon={faQuestionCircle} size="lg" />}
						</div>
					)}
					{icon && (
						<div className="flex-grow-default flex items-center justify-end">
							<button className="text-blue focus-within:ring-offset-blue-light w-8 cursor-pointer focus:ring-0" onClick={onClick}>
								{<FontAwesomeIcon icon={icon} className="mx-auto" />}
							</button>
						</div>
					)}
				</div>
				{children}
			</div>
		</div>
	);
};

export default Box;
