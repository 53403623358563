import React from 'react';
import Button from '@ssg/common/Components/Button';
import Table from '@ssg/common/Components/Table';
import APVModal from './APVModal';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import Loading from '@ssg/common/Components/Loading';
import { GetAPVs, GetAPVs_apvs } from '../../../GraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GET_APVS = loader('src/GraphQL/APV/GetAPVs.gql');

const APV: React.FC = () => {
	const [createAPV, setCreateAPV] = React.useState(false);
	const [updateAPV, setUpdateAPV] = React.useState(false);
	const [deleteAPV, setDeleteAPV] = React.useState(false);
	const [apvData, setAPVData] = React.useState<GetAPVs_apvs>();

	const { data, loading } = useQuery<GetAPVs>(GET_APVS);

	return (
		<div className="text-blue">
			<header>
				<div className="flex justify-end">
					<Button primary text="apv.create" icon={faPlus} onClick={() => setCreateAPV(true)} />
				</div>
			</header>

			{loading ? (
				<div className="relative h-40">
					<Loading />
				</div>
			) : (
				<Table
					data={data?.apvs ?? []}
					columns={[
						{
							label: 'common.url',
							selectFn: a => <p className="py-1">{a.url}</p>,
						},
						{
							label: 'apv.damageCategory',
							selectFn: a => <p className="py-1">{a.damageCategory.name}</p>,
						},
						{
							label: 'apv.damageCause',
							selectFn: a => <p className="py-1">{a.damageCause.name}</p>,
						},
						{
							label: 'common.edit',
							classNameTh: 'text-right',
							selectFn: a => (
								<div className="flex content-start justify-end text-right">
									<FontAwesomeIcon
										icon={faEdit}
										size="lg"
										onClick={() => {
											setAPVData(a);
											setUpdateAPV(true);
										}}
										className="cursor-pointer"
									/>
								</div>
							),
						},
						{
							label: 'common.delete',
							classNameTh: 'text-right',
							selectFn: a => (
								<div className="text-red flex content-start justify-end text-right">
									<FontAwesomeIcon
										icon={faTrashAlt}
										size="lg"
										onClick={() => {
											setAPVData(a);
											setDeleteAPV(true);
										}}
										className="cursor-pointer"
									/>
								</div>
							),
						},
					]}
					keySelector={a => a.id}
					noDataFoundText="apv.noAPVsFound"
				/>
			)}

			{createAPV && <APVModal open={createAPV} close={() => setCreateAPV(false)} />}
			{updateAPV && <APVModal edit data={apvData} open={updateAPV} close={() => setUpdateAPV(false)} />}
			{deleteAPV && <APVModal erase data={apvData} open={deleteAPV} close={() => setDeleteAPV(false)} />}
		</div>
	);
};

export default APV;
