import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		min: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const VisitationCategorySchema = yup.object().shape({
	damageCategory: yup.string().required(),
	damageCause: yup.string().required(),
	question: yup.string().required(),
});
