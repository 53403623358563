import Header from '@ssg/common/Components/Header';
import { useTranslation } from 'react-i18next';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import {
	CreateGroupPermission,
	CreateGroupPermissionVariables,
	CreateGroupPermission_createGroupPermission,
	GetGroupsPermissions,
	GetGroupsPermissions_groupsPermissions,
	UpdateGroupPermission,
	UpdateGroupPermissionVariables,
	UpdateGroupPermission_updateGroupPermission,
	Permissions as EnumPermissions,
} from '../../GraphQL/index';
import React, { useEffect, useMemo, useState } from 'react';
import Checkbox from '@ssg/common/Components/Checkbox';
import { getGroupPermissionsList, Group, Permission } from './GroupPermissionsHelper';
import FormHelpText from '@ssg/common/Components/FormHelpText';
import Box from '../../Components/Layout/Box';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Button from '@ssg/common/Components/Button';
import UserPermissionsEvaluatorModal from './UserPermissionsEvaluatorModal';
import ADGroupDebitorModal from './ADGroupDebitorModal';
import ADGroupLocationDepartmentModal from './ADGroupLocationDepartmentModal';
import ADGroupDamageCategoryCauseModal from './ADGroupDamageCategoryCauseModal';
import { useForm } from 'react-hook-form';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Table from '@ssg/common/Components/Table';
import ADGroupRequisitionDepartmentModal from './ADGroupRequisitionDepartmentModal';

const GET_GROUPS_PERMISSIONS = loader('../../GraphQL/GroupPermission/GetGroupsPermissions.gql');
const UPDATE_GROUP_PERMISSION = loader('../../GraphQL/GroupPermission/UpdateGroupPermission.gql');
const CREATE_GROUP_PERMISSION = loader('../../GraphQL/GroupPermission/CreateGroupPermission.gql');

const GroupPermission: React.FC = () => {
	const { t } = useTranslation();

	const [searchUser, setSearchUser] = useState(false);
	const [editDebitorGroup, setEditDebitorGroup] = useState(false);
	const [editLocationDepartmentGroup, setEditLocationDepartmentGroup] = useState(false);
	const [editCategoryCauseGroup, setEditCategoryCauseGroup] = useState(false);
	const [editRequisitionDepartmentGroup, setEditRequisitionDepartmentGroup] = useState(false);
	const [showHelpText, setShowHelpText] = useState(false);

	const { loading, data } = useQuery<GetGroupsPermissions>(GET_GROUPS_PERMISSIONS, {
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-only',
	});

	const [updateGroupPermission, { loading: updateGroupPermissionSubmitting }] = useMutation<UpdateGroupPermission, UpdateGroupPermissionVariables>(UPDATE_GROUP_PERMISSION);

	const [createGroupPermission, { loading: createGroupPermissionSubmitting }] = useMutation<CreateGroupPermission, CreateGroupPermissionVariables>(CREATE_GROUP_PERMISSION);

	const [groupPermissions, setGroupPermissions] = useState<{
		groupId: string;
		groupPermissions: Group[];
	}>();

	const [dropDownItems, setDropDownItems] = useState<SelectOption[]>();

	const [selectedGroupPermission, setSelectedGroupPermission] = useState<GetGroupsPermissions_groupsPermissions>();

	const [allGroupPermissions, setAllGroupPermissions] = useState<GetGroupsPermissions_groupsPermissions[]>();

	const { control } = useForm({});

	const [searchPermissionGroupText, setSearchPermissionGroupText] = useState<string>('');

	useEffect(() => {
		if (data?.groupsPermissions) {
			setDropDownItems(
				data.groupsPermissions
					.filter(ct => ct.name.toLowerCase().includes(searchPermissionGroupText ? searchPermissionGroupText.toLowerCase() : ''))
					.map((u): SelectOption => ({ value: u.id, label: u.name })) ?? [],
			);

			if (!data.groupsPermissions.find(gp => gp.name === searchPermissionGroupText)) {
				setSelectedGroupPermission(undefined);
			}
		}
	}, [data, searchPermissionGroupText]);

	useEffect(() => {
		if (data?.groupsPermissions) {
			const ddi = data.groupsPermissions.map(item => ({
				label: item.name,
				value: item.id,
			}));
			setDropDownItems(ddi);

			setAllGroupPermissions(data.groupsPermissions);
		}
	}, [data]);

	useEffect(() => {
		if (typeof selectedGroupPermission !== 'undefined') {
			setGroupPermissions({
				groupId: selectedGroupPermission.id,
				groupPermissions: getGroupPermissionsList(selectedGroupPermission.groupPermissions),
			});
		}
	}, [selectedGroupPermission]);

	function handleChange(value: string): void {
		if (data) {
			const groupPermissions = data?.groupsPermissions.find(g => g.id === value);

			if (groupPermissions) {
				setSelectedGroupPermission(groupPermissions);
			}
		}
	}

	const handleSave = async () => {
		const selectedPermissions: EnumPermissions[] = [];

		if (groupPermissions) {
			groupPermissions.groupPermissions.forEach(gr => {
				gr.items.forEach(item => {
					if (item.selected) {
						selectedPermissions.push(EnumPermissions[item.name as string as keyof typeof EnumPermissions]);
					}
				});
			});
		}

		if (selectedGroupPermission) {
			if (selectedGroupPermission.isNew) {
				const newGroupPermission = await create({
					groupPermission: {
						adGroupPermissionId: selectedGroupPermission.groupPermissionId,
						groupPermissionId: selectedGroupPermission.id,
						groupPermissions: selectedPermissions,
						name: selectedGroupPermission.name,
					},
					groupPermissionsList: selectedPermissions,
				});
				if (newGroupPermission) {
					updateAllGroupPermissions(newGroupPermission);
				}
			} else {
				const updatedGroupPermission = await update({
					id: selectedGroupPermission.id,
					groupPermissionsList: selectedPermissions,
				});

				if (updatedGroupPermission) {
					updateAllGroupPermissions(updatedGroupPermission);
				}
			}
		}
	};

	const updateAllGroupPermissions = (groupPermission: UpdateGroupPermission_updateGroupPermission | CreateGroupPermission_createGroupPermission) => {
		if (allGroupPermissions) {
			const gp = [...allGroupPermissions];

			const groupIndex = gp.findIndex(gp => gp.name === groupPermission.name);

			gp[groupIndex] = groupPermission;

			setAllGroupPermissions(gp);

			setSelectedGroupPermission(groupPermission);
		}
	};

	const create = async ({ groupPermission, groupPermissionsList }: CreateGroupPermissionVariables): Promise<CreateGroupPermission_createGroupPermission | void> => {
		const newGroupPermission = await createGroupPermission({
			variables: {
				groupPermission,
				groupPermissionsList,
			},
			update: (cache, { data: cacheData }): void => {
				if (typeof cacheData === 'undefined' || cacheData === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetGroupsPermissions>({
					query: GET_GROUPS_PERMISSIONS,
				});

				if (cachedRequest === null || cachedRequest.groupsPermissions === null) {
					return;
				}

				cache.writeQuery<GetGroupsPermissions>({
					query: GET_GROUPS_PERMISSIONS,
					data: {
						groupsPermissions: [...cachedRequest.groupsPermissions, cacheData.createGroupPermission],
					},
				});
			},
		});

		return newGroupPermission.data?.createGroupPermission;
	};

	const update = async ({ id, groupPermissionsList }: UpdateGroupPermissionVariables): Promise<UpdateGroupPermission_updateGroupPermission | void> => {
		const newGroupPermission = await updateGroupPermission({
			variables: {
				id,
				groupPermissionsList,
			},
		});

		if (newGroupPermission.data?.updateGroupPermission) {
			return newGroupPermission.data?.updateGroupPermission;
		}
	};

	function handleCheckboxChange(item: Permission, groupName: string) {
		if (groupPermissions) {
			const gp = [...groupPermissions.groupPermissions];
			console.log(data);
			const groupIndex = gp.findIndex(gp => gp.name === groupName);
			const perms = [...gp[groupIndex].items];

			const permIndex = perms.findIndex(p => p === item);
			perms[permIndex].selected = !perms[permIndex].selected;

			gp[groupIndex].items = [...perms];

			setGroupPermissions({
				groupId: groupPermissions.groupId,
				groupPermissions: [...gp],
			});
		}
	}

	const selectedDebs = useMemo(() => {
		const debs = data?.groupsPermissions.find(gp => gp.id === selectedGroupPermission?.id);
		const selDebs = debs?.groupDebitors.map(
			(d): SelectOption => ({
				value: d.value ?? '',
				label: d.label ?? '',
			}),
		);
		const selDebGroups = debs?.groupDebitorGroups.map(
			(d): SelectOption => ({
				value: d ?? '',
				label: `(${t('groupPermissions.debitorGroup')}) - ${d}` ?? '',
			}),
		);

		return selDebs?.concat(selDebGroups ?? []);
	}, [data, t, selectedGroupPermission]);

	return (
		<div>
			<Header title="groupPermissions.groupPermissionTitle">
				{/* <div className='flex w-full lg:w-full'>
                    <div className='w-1/2 mr-2'>
                        {!loading && <DropDown title={t('groupPermissions.adGroups')} name='Group Permissions' data={dropDownItems} onChange={handleChange} />}
                    </div>
                    <div className='w-1/2 flex justify-end content-start mr-14'>
                        <div className='w-1/2'>
                            <Button primary text='groupPermissions.searchUserPermissions' onClick={() => setSearchUser(true)} className='mt-3' />
                        </div>

                        <div className='w-1/2'>
                            <Button primary text='groupPermissions.adGroupCategoryCause' onClick={() => setEditCategoryCauseGroup(true)} className='mt-3 mr-2' />
                            <Button primary text='groupPermissions.adGroupLocationDepartment' onClick={() => setEditLocationDepartmentGroup(true)} className='mt-3 mr-2' />
                            <Button primary text='groupPermissions.adGroupDebitor' onClick={() => setEditDebitorGroup(true)} className='mt-3 mr-2' />
                        </div>
                    </div>
                </div> */}
			</Header>
			<div className="px-5 pb-5 text-sm">
				<Box full title="groupPermissions.groups" onClick={() => setShowHelpText(!showHelpText)} showHelpText={showHelpText} helpButton loading={loading}>
					<div className="flex">
						<div className="w-1/5 justify-start">
							<SearchableSelect
								key="permissionGroupsLabel"
								control={control}
								name="selectedGroupPermission"
								title="groupPermissions.adGroups"
								allowEmpty
								options={dropDownItems ?? []}
								searchFn={searchText => setSearchPermissionGroupText(searchText)}
								onSelect={value => handleChange(value)}
								onBlur={() => undefined}
								minInputLength={-1}
								isLoading={loading}
							/>
						</div>
						<div className="mt-7 flex w-4/5 justify-end">
							<Button primary text="groupPermissions.searchUserPermissions" onClick={() => setSearchUser(true)} className="mr-2" />
							<Button disabled={!selectedGroupPermission} primary text="groupPermissions.adGroupCategoryCause" onClick={() => setEditCategoryCauseGroup(true)} className="mr-2" />
							<Button
								disabled={!selectedGroupPermission}
								primary
								text="groupPermissions.adGroupLocationDepartment"
								onClick={() => setEditLocationDepartmentGroup(true)}
								className="mr-2"
							/>
							<Button disabled={!selectedGroupPermission} primary text="groupPermissions.adGroupDebitor" onClick={() => setEditDebitorGroup(true)} className="mr-2" />
							<Button disabled={!selectedGroupPermission} primary text="groupPermissions.adGroupRequisitionerDepartment" onClick={() => setEditRequisitionDepartmentGroup(true)} />
						</div>
					</div>
					{showHelpText && <FormHelpText text="groupPermissions.helpText" />}
					<div className="my-3 flex flex-col flex-wrap lg:flex-row">
						{!loading && typeof groupPermissions !== 'undefined' && selectedGroupPermission !== undefined ? (
							groupPermissions.groupPermissions.map(gp => (
								<div key={gp.name} className="w-1/5 pr-5 pb-2">
									<div className="text-blue text-lg font-semibold">{gp.name}:</div>
									{gp.items.map(item => {
										return (
											<Checkbox
												key={`${groupPermissions.groupId}_${item.name}`}
												title={item.text}
												name={item.name}
												value={item.value}
												checked={item.selected}
												onChange={() => handleCheckboxChange(item, gp.name)}
											/>
										);
									})}
								</div>
							))
						) : (
							<h1 className="text-xl">{t('groupPermissions.selectFromDropdown')}</h1>
						)}
					</div>
					{!loading && (
						<Button className="mt-3" submit={false} success text="common.save" loading={updateGroupPermissionSubmitting || createGroupPermissionSubmitting} onClick={handleSave} />
					)}
				</Box>
			</div>
			<div className="px-5 pb-5 text-sm">
				<Box title="groupPermissions.adGroupDebitor" full>
					<Table
						data={selectedDebs ?? []}
						columns={[
							{
								label: 'common.name',
								selectFn: c => <p className="py-1 font-medium">{c.label}</p>,
								sortFn: (a, b) => (a.label ?? '').localeCompare(b.label ?? ''),
							},
							{
								label: 'common.id',
								selectFn: c => <p className="py-1 font-medium">{c.value}</p>,
								sortFn: (a, b) => (a.value ?? '').localeCompare(b.value ?? ''),
							},
						]}
						noDataFoundText="groupPermissions.noDebitor"
						keySelector={e => e.value}
					/>
				</Box>
			</div>
			{searchUser && <UserPermissionsEvaluatorModal closeFn={() => setSearchUser(false)} />}
			{editDebitorGroup && <ADGroupDebitorModal close={() => setEditDebitorGroup(false)} data={data?.groupsPermissions.find(gp => gp.id === selectedGroupPermission?.id)} />}
			{typeof selectedGroupPermission !== 'undefined' && editLocationDepartmentGroup && (
				<ADGroupLocationDepartmentModal
					id={selectedGroupPermission.id}
					groupLocations={data?.groupsPermissions.find(gp => gp.id === selectedGroupPermission?.id)?.groupLocations ?? []}
					groupDepartments={data?.groupsPermissions.find(gp => gp.id === selectedGroupPermission?.id)?.groupDepartments ?? []}
					close={() => setEditLocationDepartmentGroup(false)}
				/>
			)}
			{typeof selectedGroupPermission !== 'undefined' && editCategoryCauseGroup && (
				<ADGroupDamageCategoryCauseModal
					id={selectedGroupPermission.id}
					groupDamageCategories={data?.groupsPermissions.find(gp => gp.id === selectedGroupPermission?.id)?.groupDamageCategories ?? []}
					groupDamageCauses={data?.groupsPermissions.find(gp => gp.id === selectedGroupPermission?.id)?.groupDamageCauses ?? []}
					close={() => setEditCategoryCauseGroup(false)}
				/>
			)}
			{typeof selectedGroupPermission !== 'undefined' && editRequisitionDepartmentGroup && (
				<ADGroupRequisitionDepartmentModal
					id={selectedGroupPermission.id}
					groupDepartments={data?.groupsPermissions.find(gp => gp.id === selectedGroupPermission.id)?.groupRequisitionDepartments ?? []}
					close={() => setEditRequisitionDepartmentGroup(false)}
				/>
			)}
		</div>
	);
};

export default GroupPermission;
