import React from 'react';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import DepartmentsModal from './DepartmentsModal';
import DepartmentsFilters from './DepartmentsFilters';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GetAdminDepartments, GetAdminDepartments_departmentsAdmin } from '../../../GraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { tokenizeStringWithQuotesBySpaces } from '../../../helper';

const GET_DEPARTMENTS = loader('src/GraphQL/Department/GetAdminDepartments.gql');

interface DepartmentsFilter {
	name: string[];
	locationName: string[];
}

const Departments: React.FC = () => {
	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);
	const [activeDepartmentsFilters] = React.useState<DepartmentsFilter>({
		name: [],
		locationName: [],
	});

	const [createDepartments, setCreateDepartments] = React.useState(false);
	const [updateDepartments, setUpdateDepartments] = React.useState(false);
	const [deleteDepartments, setDeleteDepartments] = React.useState(false);
	const [DepartmentsData, setDepartmentsData] = React.useState<GetAdminDepartments_departmentsAdmin>();

	const { data: Departments, loading, refetch } = useQuery<GetAdminDepartments>(GET_DEPARTMENTS);

	const DepartmentsLocations = React.useMemo(() => Departments?.departmentsAdmin ?? [], [Departments?.departmentsAdmin]);
	const activeFilteredDepartments = React.useMemo(
		() =>
			DepartmentsLocations.filter(m => {
				return activeDepartmentsFilters.name.length > 0 ? activeDepartmentsFilters.name.flatMap(l => l).includes(m.name) : true;
			}),
		[DepartmentsLocations, activeDepartmentsFilters.name],
	);

	const fuse = React.useMemo(
		() =>
			new Fuse(activeFilteredDepartments, {
				shouldSort: true,
				threshold: 0.1,
				keys: ['name', 'locations.name'],
			}),
		[activeFilteredDepartments],
	);

	const filteredDepartments =
		searchTerm.length > 0
			? fuse
					.search({
						$and: tokenizeStringWithQuotesBySpaces(searchTerm).map((searchToken: string) => {
							const orFields: Fuse.Expression[] = [{ name: searchToken }, { 'locations.name': searchToken }];

							return {
								$or: orFields,
							};
						}),
					})
					.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
					.map(v => v.item)
			: activeFilteredDepartments;

	return (
		<div>
			<header className="flex">
				<div className="w-1/2">
					<DepartmentsFilters setFilterTerm={setSearchTerm} />
				</div>
				<div className="flex w-1/2 justify-end">
					<Button primary text="departmentsLocations.create" icon={faPlus} onClick={() => setCreateDepartments(true)} className="mb-2" />
				</div>
			</header>
			<div className="text-blue relative bg-white pb-1">
				{loading ? (
					<div className="h-40">
						<Loading />
					</div>
				) : (
					<Table
						data={filteredDepartments ?? []}
						columns={[
							{
								label: 'common.name',
								selectFn: c => <p className="py-1 font-medium">{c.name}</p>,
								sortFn: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
							},
							{
								label: 'departmentsLocations.departmentNumber',
								numeric: true,
								selectFn: c => <p className="font-medium">{c.departmentNumber}</p>,
								sortFn: (a, b) => a.departmentNumber - b.departmentNumber,
							},
							{
								label: 'departmentsLocations.parentLocation',
								selectFn: c => <p className="font-medium">{c.location.name}</p>,
								sortFn: (a, b) => (a.location.name ?? '').localeCompare(b.location.name ?? ''),
							},
							{
								label: 'departmentsLocations.operationsManagers',
								selectFn: c => <p className="font-medium">{c.operationManagers.map(o => o.name).join(', ')}</p>,
							},
							{
								label: 'common.edit',
								classNameTh: 'text-right',
								selectFn: c => (
									<div className="flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faEdit}
											size="lg"
											onClick={() => {
												setDepartmentsData(c);
												setUpdateDepartments(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
							{
								label: 'common.delete',
								classNameTh: 'text-right',
								selectFn: c => (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setDepartmentsData(c);
												setDeleteDepartments(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
						]}
						keySelector={e => e.id}
					/>
				)}
			</div>

			{createDepartments && (
				<DepartmentsModal
					open={createDepartments}
					close={() => setCreateDepartments(false)}
					submitCb={() => {
						refetch();
						setCreateDepartments(false);
					}}
				/>
			)}

			{updateDepartments && (
				<DepartmentsModal
					open={updateDepartments}
					close={() => setUpdateDepartments(false)}
					edit
					data={DepartmentsData}
					submitCb={() => {
						refetch();
						setUpdateDepartments(false);
					}}
				/>
			)}

			{deleteDepartments && (
				<DepartmentsModal
					erase
					open={deleteDepartments}
					close={() => setDeleteDepartments(false)}
					data={DepartmentsData}
					submitCb={() => {
						refetch();
						setDeleteDepartments(false);
					}}
				/>
			)}
		</div>
	);
};

export default Departments;
