import React from 'react';
import { faEdit, faPlus, faQuestionCircle, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GetRiskEvaluationQuestions, GetRiskEvaluationQuestions_riskEvaluationQuestions } from '../../../GraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import Button from '@ssg/common/Components/Button';
import RiskEvaluationModal from './RiskEvaluationModal';
import sanitizeHtml from 'sanitize-html';

const GET_QUESTIONS = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationQuestions.gql');

const RiskEvaluation: React.FC = () => {
	const { t } = useTranslation();

	const [createQuestion, setCreateQuestion] = React.useState(false);
	const [updateQuestion, setUpdateQuestion] = React.useState(false);
	const [deleteQuestion, setDeleteQuestion] = React.useState(false);

	const [questionData, setQuestionData] = React.useState<GetRiskEvaluationQuestions_riskEvaluationQuestions>();

	const { data, loading, refetch } = useQuery<GetRiskEvaluationQuestions>(GET_QUESTIONS);

	return (
		<div>
			<header className="flex">
				<div className="w-1/2"></div>
				<div className="flex w-1/2 justify-end">
					<Button primary text="riskEvaluation.create" icon={faPlus} onClick={() => setCreateQuestion(true)} className="mb-2" />
				</div>
			</header>

			<div className="text-blue relative bg-white pb-1">
				{loading ? (
					<div className="h-40">
						<Loading />
					</div>
				) : (
					<Table
						data={data?.riskEvaluationQuestions ?? []}
						columns={[
							{
								label: 'riskEvaluation.riskCategory',
								selectFn: q => <p>{q.category.categoryName}</p>,
								sortFn: (a, b) => (a.category.categoryName ?? '').localeCompare(b.category.categoryName ?? ''),
							},
							{
								label: 'riskEvaluation.riskQuestion',
								selectFn: q => <p className="py-1">{q.question}</p>,
								sortFn: (a, b) => (a.question ?? '').localeCompare(b.question ?? ''),
							},
							{
								label: 'riskEvaluation.helpText',
								selectFn: q => (
									<div
										dangerouslySetInnerHTML={{
											__html: sanitizeHtml(q.helpText, {
												allowedAttributes: {
													'*': ['style', 'href', 'rel', 'target'],
												},
											}),
										}}
										className="py-1"
									></div>
								),
								noTruncate: true,
							},
							{
								label: 'riskEvaluation.default',
								selectFn: q => <p>{q.defaultChecked ? t('common.yes') : t('common.no')}</p>,
							},
							{
								label: 'common.edit',
								classNameTh: 'text-right',
								selectFn: q => (
									<div className="flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faEdit}
											size="lg"
											onClick={() => {
												setQuestionData(q);
												setUpdateQuestion(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
							{
								label: 'common.delete',
								classNameTh: 'text-right',
								selectFn: q => (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setQuestionData(q);
												setDeleteQuestion(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
						]}
						keySelector={q => q.id}
					/>
				)}
			</div>
			{createQuestion && <RiskEvaluationModal open={createQuestion} close={() => setCreateQuestion(false)} />}
			{updateQuestion && <RiskEvaluationModal edit open={updateQuestion} close={() => setUpdateQuestion(false)} data={questionData} />}
			{deleteQuestion && <RiskEvaluationModal erase open={deleteQuestion} close={() => setDeleteQuestion(false)} data={questionData} />}
		</div>
	);
};

export default RiskEvaluation;
