import React from 'react';
import { DragLayerMonitorProps } from '@minoru/react-dnd-treeview';

import { DrivingSlipQuestionnaireNodeData } from './DrivingSlipQuestionnaireNodeData';

import styles from './DrivingSlipQuestionnaireNodeDragPreview.module.css';

interface Props {
	monitorProps: DragLayerMonitorProps<DrivingSlipQuestionnaireNodeData>;
}

export const DrivingSlipQuestionnaireNodeDragPreview: React.FC<Props> = ({ monitorProps: { item } }) => (
	<div className={styles.root}>
		<div className={styles.label}>{item.text}</div>
	</div>
);
