import React from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { DeleteInvoiceReasonCode, DeleteInvoiceReasonCodeVariables, GetInvoiceReasonCodes, GetInvoiceReasonCodes_invoiceReasonCodes } from '../../GraphQL';
import Button from '@ssg/common/Components/Button';
import Modal from '@ssg/common/Components/Modal';

const DELETE_INVOICE_REASON_CODE = loader('src/GraphQL/Invoices/DeleteInvoiceReasonCode.gql');
const GET_INVOICE_REASON_CODES = loader('src/GraphQL/Invoices/GetInvoiceReasonCodes.gql');

interface Props {
	close(): void;
	visible: boolean;
	reasonCode: GetInvoiceReasonCodes_invoiceReasonCodes;
}

const DeleteInvoiceReasonCodeModal: React.FC<Props> = ({ close, reasonCode, visible }): React.ReactElement => {
	const { t } = useTranslation();

	const [deleteInvoiceReasonCode, { loading }] = useMutation<DeleteInvoiceReasonCode, DeleteInvoiceReasonCodeVariables>(DELETE_INVOICE_REASON_CODE);

	const onDelete = async () => {
		try {
			await deleteInvoiceReasonCode({
				variables: {
					id: reasonCode.id,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}
					const cachedRequest = cache.readQuery<GetInvoiceReasonCodes>({
						query: GET_INVOICE_REASON_CODES,
					});

					if (cachedRequest === null || cachedRequest.invoiceReasonCodes === null) {
						return;
					}

					cache.writeQuery<GetInvoiceReasonCodes>({
						query: GET_INVOICE_REASON_CODES,
						data: {
							invoiceReasonCodes: cachedRequest.invoiceReasonCodes.filter(rc => rc.id !== cacheData.deleteInvoiceReasonCode),
						},
					});
				},
			});
			close();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal
			title="invoices.deleteReasonCode"
			visible={visible}
			close={close}
			body={
				<div>
					<p className="text-blue">
						{t('common.confirmDeletionOf')} <strong>{reasonCode.name}</strong>?
					</p>
					<div className="mt-4 flex space-x-2">
						<Button danger text="common.delete" loading={loading} onClick={() => onDelete()} />
						<Button secondary text="common.cancel" onClick={() => close()} />
					</div>
				</div>
			}
		/>
	);
};

export default DeleteInvoiceReasonCodeModal;
