import React from 'react';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { GetInvoiceReasonCodes_invoiceReasonCodes, InvoiceReasonCodeInputType, UpdateInvoiceReasonCode, UpdateInvoiceReasonCodeVariables } from '../../GraphQL';
import { InvoiceReasonCodeSchema } from '../../Schemas/InvoiceReasonCodeSchema';
import Modal from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';

const UPDATE_INVOICE_REASON_CODE = loader('src/GraphQL/Invoices/UpdateInvoiceReasonCode.gql');

interface Props {
	close(): void;
	visible: boolean;
	reasonCode: GetInvoiceReasonCodes_invoiceReasonCodes;
}

const UpdateInvoiceReasonCodeModal: React.FC<Props> = ({ close, visible, reasonCode }): React.ReactElement => {
	const [updateInvoiceReasonCode, { loading }] = useMutation<UpdateInvoiceReasonCode, UpdateInvoiceReasonCodeVariables>(UPDATE_INVOICE_REASON_CODE);

	const { handleSubmit, register, errors } = useForm<InvoiceReasonCodeInputType>({
		resolver: yupResolver(InvoiceReasonCodeSchema),
	});

	const onSubmit = async (data: InvoiceReasonCodeInputType) => {
		try {
			await updateInvoiceReasonCode({
				variables: {
					id: reasonCode.id,
					name: data.name,
				},
			});
			close();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal
			title="invoices.updateReasonCode"
			visible={visible}
			close={close}
			body={
				<form onSubmit={handleSubmit(onSubmit)}>
					<Input title="common.name" required name="name" innerRef={register} errorMessage={errors.name?.message} defaultValue={reasonCode.name} />

					<Button success submit text="invoices.updateReasonCode" loading={loading} className="mt-4" />
				</form>
			}
		/>
	);
};

export default UpdateInvoiceReasonCodeModal;
