import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
	BaseMovablesLocationFragment,
	GetAdminLocations,
	GetAdminLocationsVariables,
	GetMovablesLocations,
	GetMovablesLocationsVariables,
	GetMovablesLocations_movablesLocations,
} from '../../../GraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faEdit, faPlus, faSquare, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { tokenizeStringWithQuotesBySpaces } from '../../../helper';
import { useTranslation } from 'react-i18next';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import MovablesModal from './MovablesLocationModal';
import MovablesFilters from './MovablesFilters';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import useArrayToggler from '@ssg/common/Hooks/useArrayToggler';
import MovablesPrintLabel from './MovablesPrintLabel';

const GET_MOVABLES = loader('src/GraphQL/MovablesLocations/GetMovablesLocations.gql');
const GET_LOCATIONS = loader('src/GraphQL/Locations/GetAdminLocations.gql');
const PRINT_LIMIT = 300;

const Movables: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();

	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);
	const [location, setLocation] = React.useState<string | undefined>(undefined);

	const [createMovables, setCreateMovables] = React.useState(false);
	const [updateMovables, setUpdateMovables] = React.useState(false);
	const [deleteMovables, setDeleteMovables] = React.useState(false);
	const [MovablesData, setMovablesData] = React.useState<GetMovablesLocations_movablesLocations>();
	const [bulkPrint, setBulkPrint] = React.useState(false);
	const [selectedRows, toggleSelectedRows, toggleAllRows] = useArrayToggler<string>([]);

	const [selectedRowsBatches, setSelectedRowsBatches] = React.useState<string[][]>([]);

	React.useEffect(() => {
		if (selectedRows.length > PRINT_LIMIT) {
			const slicedArray: string[][] = [];
			for (let i = 0; i < selectedRows.length; i += PRINT_LIMIT) {
				const chunk = selectedRows.slice(i, i + PRINT_LIMIT);
				slicedArray.push(chunk);
			}
			setSelectedRowsBatches(slicedArray);
		} else {
			setSelectedRowsBatches([selectedRows]);
		}
	}, [selectedRows]);

	React.useEffect(() => console.log(selectedRowsBatches));

	const { data: locations } = useQuery<GetAdminLocations, GetAdminLocationsVariables>(GET_LOCATIONS, {
		variables: {
			movableLocationsOnly: true,
		},
		onCompleted(data) {
			setLocation(data.locationsAdmin.slice().sort((a, b) => a.name.localeCompare(b.name))[0].id);
		},
	});
	const {
		data: movables,
		loading,
		refetch,
	} = useQuery<GetMovablesLocations, GetMovablesLocationsVariables>(GET_MOVABLES, {
		variables: {
			location: location,
		},
		skip: typeof location === 'undefined',
	});

	const movablesMap = new Map<string, BaseMovablesLocationFragment>();

	movables?.movablesLocations.forEach(m => {
		locations?.locationsAdmin.forEach(l => {
			if (m.location.id === l.id) {
				movablesMap.set(m.id, {
					id: m.id,
					name: m.name,
					volume: m.volume,
					accVolume: m.accVolume,
					location: { id: l.id, name: l.name, address: l.address },
				});
			}
		});
	});

	const movablesArray = Array.from(movablesMap.values());

	const movablesLocations = React.useMemo(() => movablesArray ?? [], [movablesArray]);

	const fuse = React.useMemo(
		() =>
			new Fuse(movablesLocations, {
				shouldSort: true,
				threshold: 0.1,
				keys: ['name'],
			}),
		[movablesLocations],
	);

	const filteredMovables =
		searchTerm.length > 0
			? fuse
					.search({
						$and: tokenizeStringWithQuotesBySpaces(searchTerm).map((searchToken: string) => {
							const orFields: Fuse.Expression[] = [{ name: searchToken }];

							return {
								$or: orFields,
							};
						}),
					})
					.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
					.map(v => v.item)
			: movablesLocations;

	const isAllSelected = React.useMemo((): boolean => {
		const filteredMovablesLength = filteredMovables.length;
		const filteredMovablesIds = filteredMovables.map(m => m.id);
		const filteredMovablesInSelectedRowsLength = selectedRows.filter(r => filteredMovablesIds.includes(r)).length;

		return filteredMovablesLength === filteredMovablesInSelectedRowsLength;
	}, [filteredMovables, selectedRows]);

	return (
		<div>
			<header className="flex">
				<div className="w-1/2">
					<MovablesFilters setSearchTerm={setSearchTerm} setLocation={setLocation} setBulkPrint={setBulkPrint} bulkPrint={bulkPrint} locations={locations?.locationsAdmin ?? []} />
				</div>
				<div className="flex w-1/2 justify-end">
					<Button primary text="movablesLocations.create" icon={faPlus} onClick={() => setCreateMovables(true)} className="mb-2" />
				</div>
			</header>
			<div className="text-blue relative bg-white pb-1">
				<div className="mt-2 flex flex-row space-x-2">
					{bulkPrint &&
						selectedRowsBatches.map((batch, i) => (
							<MovablesPrintLabel
								key={'batch' + i}
								movables={movablesLocations.filter(m => batch.includes(m.id))}
								buttonText={
									selectedRowsBatches.length > 1
										? `${t('movablesLocations.printSelected')} (${i * PRINT_LIMIT + 1} - ${
												batch.length % PRINT_LIMIT !== 0 ? batch.length + PRINT_LIMIT * i : PRINT_LIMIT + i * PRINT_LIMIT
										  })`
										: `${t('movablesLocations.printSelected')} (${batch.length})`
								}
								disabled={selectedRows.length === 0}
							/>
						))}
				</div>
				{loading ? (
					<div className="h-40">
						<Loading />
					</div>
				) : (
					<Table
						data={filteredMovables ?? []}
						onRowClick={bulkPrint ? m => toggleSelectedRows(m.id) : undefined}
						keySelector={m => m.id}
						columns={[
							!bulkPrint
								? {
										label: 'movablesLocations.print',
										selectFn: m => <MovablesPrintLabel movables={[m]} />,
								  }
								: {
										label: '',
										selectFn: m => (
											<div className="">{selectedRows.includes(m.id) ? <FontAwesomeIcon icon={faCheckSquare} size="lg" /> : <FontAwesomeIcon icon={faSquare} size="lg" />}</div>
										),

										icon: isAllSelected ? faCheckSquare : faSquare,

										actionFn: () => toggleAllRows([...filteredMovables.map(m => m.id)]),
								  },
							{
								label: 'common.name',
								selectFn: m => <p className="font-medium">{m.name}</p>,
								sortFn: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
							},
							{
								label: 'movablesLocations.volume',
								numeric: true,
								selectFn: m => <p className="font-medium">{m.volume} m&sup3;</p>,
								sortFn: (a, b) => a.volume - b.volume,
							},
							{
								label: 'movablesLocations.parentLocation',
								selectFn: m => (
									<p>
										{m.location.name}
										<br />
										{m.location.address.postalCode} {m.location.address.city}
									</p>
								),
							},
							{
								label: 'common.edit',
								classNameTh: 'text-right',
								selectFn: m => (
									<div className="flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faEdit}
											size="lg"
											onClick={() => {
												setMovablesData(m);
												setUpdateMovables(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
							{
								label: 'common.delete',
								classNameTh: 'text-right',
								selectFn: m => (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setMovablesData(m);
												setDeleteMovables(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
						]}
					/>
				)}
			</div>

			{createMovables && (
				<MovablesModal
					open={createMovables}
					close={() => setCreateMovables(false)}
					submitCb={() => {
						refetch();
						setCreateMovables(false);
					}}
					locationsData={locations?.locationsAdmin ?? []}
				/>
			)}

			{updateMovables && (
				<MovablesModal
					open={updateMovables}
					close={() => setUpdateMovables(false)}
					edit
					data={MovablesData}
					submitCb={() => {
						refetch();
						setUpdateMovables(false);
					}}
					locationsData={locations?.locationsAdmin ?? []}
				/>
			)}

			{deleteMovables && (
				<MovablesModal
					erase
					open={deleteMovables}
					close={() => setDeleteMovables(false)}
					data={MovablesData}
					submitCb={() => {
						refetch();
						setDeleteMovables(false);
					}}
					locationsData={locations?.locationsAdmin ?? []}
				/>
			)}
		</div>
	);
};

export default Movables;
