import React from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '@ssg/common/Components/Tabs';
import Header from '@ssg/common/Components/Header';
import GDPRTab from './GDPRTab';

const tabs: string[] = ['gdpr.cases'];

const GDPR: React.FC = () => {
	const { t } = useTranslation();

	const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);

	return (
		<div>
			<Header>
				<h1 className="text-blue mb-2 text-2xl font-bold">{t('gdpr.overviewTitle')}</h1>
			</Header>

			<div className="text-blue relative mx-14 bg-white px-4 pb-2 pt-1">
				<Tabs titles={tabs} active={activeTab} onClick={setActiveTab} full>
					{activeTab === 'gdpr.cases' && <GDPRTab />}
				</Tabs>
			</div>
		</div>
	);
};
export default GDPR;
