import React from 'react';
import Header from '@ssg/common/Components/Header';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import CollectionItemsOverview from './CollectionItems/CollectionItemsOverview';
import ScreeningPositionTextsOverview from './ScreeningPositionTexts/ScreeningPositionTextsOverview';

const tabs: string[] = ['general.collectionItemsOverviewTitle', 'screening.screeningPositionTexts'];

const GeneralTabs: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);

	return (
		<div>
			<Header title="general.overviewTitle">
				<div className="flex">
					{tabs.map((title, i) => {
						return (
							<div className="mr-3 mt-1" key={i}>
								<button
									type="button"
									onClick={() => setActiveTab(title)}
									className={classNames('text-blue focus:outline-none', {
										'border-blue border-b-2': title === activeTab,
									})}
								>
									{t(title)}
								</button>
							</div>
						);
					})}
				</div>
			</Header>

			<div className="mx-14 bg-white p-4 pb-1">
				{activeTab === 'general.collectionItemsOverviewTitle' && <CollectionItemsOverview />}
				{activeTab === 'screening.screeningPositionTexts' && <ScreeningPositionTextsOverview />}
			</div>
		</div>
	);
};

export default GeneralTabs;
