import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	CreateRiskEvaluationQuestion,
	CreateRiskEvaluationQuestionVariables,
	DeleteRiskEvaluationQuestion,
	DeleteRiskEvaluationQuestionVariables,
	GetRiskEvaluationCategories,
	GetRiskEvaluationQuestions,
	GetRiskEvaluationQuestions_riskEvaluationQuestions,
	UpdateRiskEvaluationQuestion,
	UpdateRiskEvaluationQuestionVariables,
} from '../../../GraphQL';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import TextEditor from '@ssg/common/Components/TextEditor';
import sanitizeHtml from 'sanitize-html';
import Checkbox from '@ssg/common/Components/Checkbox';

const GET_RISK_EVALUATION_CATEGORIES = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationCategories.gql');
const GET_RISK_EVALUATION_QUESTIONS = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationQuestions.gql');
const CREATE_RISK_EVALUATION_QUESTION = loader('src/GraphQL/RiskEvaluation/CreateRiskEvaluationQuestion.gql');
const UPDATE_RISK_EVALUATION_QUESTION = loader('src/GraphQL/RiskEvaluation/UpdateRiskEvaluationQuestion.gql');
const DELETE_RISK_EVALUATION_QUESTION = loader('src/GraphQL/RiskEvaluation/DeleteRiskEvaluationQuestion.gql');

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	erase?: boolean;
	data?: GetRiskEvaluationQuestions_riskEvaluationQuestions;
}

const RiskEvaluationModal: React.FC<Props> = ({ open, close, edit = false, erase = false, data }): React.ReactElement => {
	const { t } = useTranslation();

	const [createRiskEvaluationQuestion, { loading: createLoading }] = useMutation<CreateRiskEvaluationQuestion, CreateRiskEvaluationQuestionVariables>(CREATE_RISK_EVALUATION_QUESTION);
	const [updateRiskEvaluationQuestion, { loading: updateLoading }] = useMutation<UpdateRiskEvaluationQuestion, UpdateRiskEvaluationQuestionVariables>(UPDATE_RISK_EVALUATION_QUESTION);
	const [deleteRiskEvaluationQuestion, { loading: deleteLoading }] = useMutation<DeleteRiskEvaluationQuestion, DeleteRiskEvaluationQuestionVariables>(DELETE_RISK_EVALUATION_QUESTION);

	const { data: cat } = useQuery<GetRiskEvaluationCategories>(GET_RISK_EVALUATION_CATEGORIES);

	const categories = React.useMemo(() => {
		return cat?.riskEvaluationCategories.map((c): SelectOption => ({ value: c.id, label: c.categoryName }));
	}, [cat]);

	const { handleSubmit, register, setValue } = useForm({
		reValidateMode: 'onChange',
	});

	const onSubmit = async (question: GetRiskEvaluationQuestions_riskEvaluationQuestions) => {
		console.log(question);

		if (data) {
			try {
				await updateRiskEvaluationQuestion({
					variables: {
						id: data.id,
						category: question.category.id,
						question: question.question,
						helpText: question.helpText,
						defaultChecked: question.defaultChecked,
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}

						const cachedRequest = cache.readQuery<GetRiskEvaluationQuestions>({
							query: GET_RISK_EVALUATION_QUESTIONS,
						});

						if (cachedRequest === null || cachedRequest.riskEvaluationQuestions === null) {
							return;
						}

						const newQuestion = [...cachedRequest.riskEvaluationQuestions];
						const index = newQuestion.findIndex(q => q.id === data?.id ?? '');
						newQuestion[index] = cacheData.updateRiskEvaluationQuestion;

						cache.writeQuery<GetRiskEvaluationQuestions>({
							query: GET_RISK_EVALUATION_QUESTIONS,
							data: {
								riskEvaluationQuestions: newQuestion,
							},
						});
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await createRiskEvaluationQuestion({
					variables: {
						riskEvaluationQuestion: {
							category: question.category.id,
							question: question.question,
							helpText: question.helpText,
							defaultChecked: question.defaultChecked,
						},
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}

						const cachedRequest = cache.readQuery<GetRiskEvaluationQuestions>({
							query: GET_RISK_EVALUATION_QUESTIONS,
						});

						if (cachedRequest === null || cachedRequest.riskEvaluationQuestions === null) {
							return;
						}

						cache.writeQuery<GetRiskEvaluationQuestions>({
							query: GET_RISK_EVALUATION_QUESTIONS,
							data: {
								riskEvaluationQuestions: [...cachedRequest.riskEvaluationQuestions, cacheData.createRiskEvaluationQuestion],
							},
						});
					},
				});
			} catch (e) {
				console.log(e);
			}
		}
		close();
	};

	const handleChange = (content: string) => {
		setValue('helpText', content, { shouldValidate: true });
	};

	return (
		<Modal
			title={(erase && 'riskEvaluation.delete') || (data && 'riskEvaluation.update') || 'riskEvaluation.create'}
			visible={open}
			close={close}
			size={ModalSize.LARGE}
			body={
				erase && typeof data !== 'undefined' ? (
					<div>
						<FormFieldHeader title="riskEvaluation.wantToDelete" />
						<div>
							<span className="text-blue font-semibold">{t('riskEvaluation.riskCategory')}:</span> {data.category.categoryName}
						</div>
						<div>
							<span className="text-blue font-semibold">{t('riskEvaluation.riskQuestion')}:</span> {data.question}
						</div>
						<div>
							<span className="text-blue font-semibold">{t('riskEvaluation.helpText')}:</span>
							<div
								dangerouslySetInnerHTML={{
									__html: sanitizeHtml(data.helpText, {
										allowedAttributes: {
											'*': ['style', 'href', 'rel', 'target'],
										},
									}),
								}}
							></div>
						</div>

						<Button
							danger
							text="riskEvaluation.delete"
							loading={deleteLoading}
							onClick={async () => {
								await deleteRiskEvaluationQuestion({
									variables: {
										id: data.id,
									},
									update: (cache, { data: cacheData }): void => {
										if (typeof cacheData === 'undefined' || cacheData === null) {
											return;
										}

										const cachedRequest = cache.readQuery<GetRiskEvaluationQuestions>({
											query: GET_RISK_EVALUATION_QUESTIONS,
										});

										if (cachedRequest === null || cachedRequest.riskEvaluationQuestions === null) {
											return;
										}

										cache.writeQuery<GetRiskEvaluationQuestions>({
											query: GET_RISK_EVALUATION_QUESTIONS,
											data: {
												riskEvaluationQuestions: cachedRequest.riskEvaluationQuestions.filter(c => c.id !== data.id),
											},
										});
									},
								});
								close();
							}}
							className="mt-4"
						/>
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<Dropdown name="category.id" title="riskEvaluation.riskCategory" required data={categories} defaultValue={data?.category.id} innerRef={register} />

						<Input name="question" title="riskEvaluation.riskQuestion" required defaultValue={data?.question} innerRef={register} />

						<Checkbox name="defaultChecked" title="riskEvaluation.default" defaultChecked={data?.defaultChecked} innerRef={register} className="mt-3 w-full lg:w-full" />

						<FormFieldHeader title="riskEvaluation.helpText" required />
						<TextEditor editorId="helpTextEditor" handleChange={handleChange} value={data?.helpText ?? ''} />
						<div>
							<Input name="helpText" defaultValue={data?.helpText ?? ''} innerRef={register} className="invisible mb-4" />
						</div>

						<Button success submit text={data ? 'riskEvaluation.update' : 'riskEvaluation.create'} loading={createLoading || updateLoading} className="mt-4" />
					</form>
				)
			}
		/>
	);
};

export default RiskEvaluationModal;
