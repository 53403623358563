import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import type { XYCoord, Identifier } from 'dnd-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faTimes, faTrash } from '@fortawesome/pro-regular-svg-icons';

import Input from '@ssg/common/Components/Input';
import { ItemError } from './ItemError';
interface Props {
	id: string | number;
	text: string;
	index: number;
	disableDragAndDrop?: boolean;
	onMove: (dragIndex: number, hoverIndex: number) => void;
	onChange: (id: string | number, newValue: string) => ItemError | undefined;
	onDelete: (id: string | number) => void;
}

interface DragItem {
	index: number;
	id: string;
	type: string;
}

const ItemTypes = {
	ITEM: 'item',
};

export const Item: React.FC<Props> = ({ id, text: initialText, index, disableDragAndDrop, onMove, onChange, onDelete }) => {
	const { t } = useTranslation();
	const [text, setText] = useState(initialText);
	const [errorMessage, setErrorMessage] = useState<string>();
	const [isEditMode, setIsEditMode] = useState<boolean>(false);

	const ref = useRef<HTMLDivElement>(null);

	const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
		accept: ItemTypes.ITEM,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}

			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			onMove(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag] = useDrag({
		item: {
			type: ItemTypes.ITEM,
			id,
			index,
		},
		collect: (monitor: DragSourceMonitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
		setText(e.target.value);
		setErrorMessage(undefined);
	};

	const handleSubmitChangeText = () => {
		const itemError = onChange(id, text);
		if (itemError) {
			// There was a validation error saving the text in parent component
			setErrorMessage(itemError.errorMessage);
		} else {
			setIsEditMode(false);
			setErrorMessage(undefined);
		}
	};

	const handleCancelChangeText = () => {
		setText(initialText);
		setIsEditMode(false);
		setErrorMessage(undefined);
	};

	const handleShowEditMode = () => {
		setIsEditMode(true);
	};

	const handleDelete = () => onDelete(id);

	const opacity = isDragging ? 0 : 1;

	const style = {
		border: disableDragAndDrop ? '1px solid gray' : '1px dashed gray',
		padding: '0.5rem 1rem',
		marginBottom: '.5rem',
		backgroundColor: 'white',
		cursor: disableDragAndDrop ? 'auto' : 'move',
	};

	if (!disableDragAndDrop) {
		drag(drop(ref)); // This enables the drag and drop functionality
	}

	return (
		<div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId} className="flex items-center">
			{isEditMode ? (
				<div className="flex w-full items-center">
					<Input name={id as string} defaultValue={text} onChange={handleChangeText} className="flex flex-1" />
					<div className="flex pl-1 pr-1">
						<FontAwesomeIcon icon={faCheck} className="cursor-pointer" onClick={handleSubmitChangeText} title={t('common.save')} />
					</div>
					<div className="flex pl-1 pr-1">
						<FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={handleCancelChangeText} title={t('common.cancel')} />
					</div>
					{errorMessage && (
						<div className="flex w-full pl-1 pr-1">
							<p className="text-red">{errorMessage}</p>
						</div>
					)}
				</div>
			) : (
				<div className="flex-1">
					<span>{text}</span>
				</div>
			)}

			{!isEditMode && (
				<>
					<div className="ml-5 pl-1 pr-1">
						<FontAwesomeIcon icon={faEdit} className="cursor-pointer" onClick={handleShowEditMode} title={t('common.edit')} />
					</div>
					<div className="pl-1 pr-1">
						<FontAwesomeIcon icon={faTrash} className="cursor-pointer" onClick={handleDelete} title={t('common.delete')} />
					</div>
				</>
			)}
		</div>
	);
};
