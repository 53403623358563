import * as yup from 'yup';

yup.setLocale({
	string: {
		required: 'error.required',
	},
});

export const ScreeningPositionTextSchema = yup.object().shape({
	templateCode: yup.string().required(),

	position: yup.string().required(),

	text: yup.string().required(),
});
