import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateInvoiceReasonCode, CreateInvoiceReasonCodeVariables, GetInvoiceReasonCodes, InvoiceReasonCodeInputType } from '../../GraphQL';
import { InvoiceReasonCodeSchema } from '../../Schemas/InvoiceReasonCodeSchema';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import Button from '@ssg/common/Components/Button';
import Modal from '@ssg/common/Components/Modal';
import Input from '@ssg/common/Components/Input';

const CREATE_INVOICE_REASON_CODE = loader('src/GraphQL/Invoices/CreateInvoiceReasonCode.gql');
const GET_INVOICE_REASON_CODES = loader('src/GraphQL/Invoices/GetInvoiceReasonCodes.gql');

interface Props {
	close(): void;
	visible: boolean;
}
const CreateInvoiceReasonCodeModal: React.FC<Props> = ({ close, visible }): React.ReactElement => {
	const [createInvoiceReasonCode, { loading }] = useMutation<CreateInvoiceReasonCode, CreateInvoiceReasonCodeVariables>(CREATE_INVOICE_REASON_CODE);

	const { handleSubmit, register, errors } = useForm<InvoiceReasonCodeInputType>({
		resolver: yupResolver(InvoiceReasonCodeSchema),
	});

	const onSubmit = async (data: InvoiceReasonCodeInputType) => {
		try {
			await createInvoiceReasonCode({
				variables: {
					invoiceReasonCode: {
						name: data.name,
					},
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}
					const cachedRequest = cache.readQuery<GetInvoiceReasonCodes>({
						query: GET_INVOICE_REASON_CODES,
					});

					if (cachedRequest === null || cachedRequest.invoiceReasonCodes === null) {
						return;
					}

					cache.writeQuery<GetInvoiceReasonCodes>({
						query: GET_INVOICE_REASON_CODES,
						data: {
							invoiceReasonCodes: [...cachedRequest.invoiceReasonCodes, cacheData.createInvoiceReasonCode],
						},
					});
				},
			});
			close();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal
			title="invoices.createReasonCode"
			visible={visible}
			close={close}
			body={
				<form onSubmit={handleSubmit(onSubmit)}>
					<Input title="common.name" required name="name" innerRef={register} errorMessage={errors.name?.message} />

					<Button success submit text="invoices.createReasonCode" loading={loading} className="mt-4" />
				</form>
			}
		/>
	);
};

export default CreateInvoiceReasonCodeModal;
