import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import Input from '@ssg/common/Components/Input';
import ButtonGroup from '@ssg/common/Components/ButtonGroup';

interface Props {
	setSearchTerm: (v: string) => void;
	
	setBulkPrint(bool: boolean): void;
	bulkPrint: boolean;
	
}

const MaterialsFilters: React.FC<Props> = ({ setSearchTerm, setBulkPrint, bulkPrint }) => {
	const { t } = useTranslation();



	return (
		<div className="text-blue flex space-x-4">
			<div>
				<label className="text-blue mb-1 block text-xs font-medium">{t('common.search')}</label>
				<div className="relative flex">
					<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
					<Input name="overview-search" placeholder={t('vehicles.searchPlaceholder')} className="pr-8 text-sm" style={{ minWidth: '220px' }} onChange={(e): void => setSearchTerm(e.target.value)} />
				</div>
			</div>
			
			<div className="order-4 mt-5 ml-3 lg:order-4">
				<ButtonGroup
					items={[
						{
							title: t('movablesLocations.viewNormal'),
							isActive: !bulkPrint,
							onClick: (): void => setBulkPrint(false),
						},
						{
							title: t('movablesLocations.viewPrint'),
							isActive: bulkPrint,
							onClick: (): void => setBulkPrint(true),
						},
					]}
				/>
			</div>
		</div>
	);
};

export default MaterialsFilters;
