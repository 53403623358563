import React from 'react';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCheck, faLockAlt, faWarehouse, faBell, faPlug, faHouseDamage, faTerminal, faBuilding, faQuestion, faFileInvoice, faLock, faUser, faCar } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import SideMenu from '@ssg/common/Components/SideMenu';
import MenuLink from '@ssg/common/Components/MenuLink';
import RestrictedArea from '@ssg/common/Components/RestrictedArea';
import { Permissions } from '../../GraphQL';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

const Main: React.FC = ({ children }): React.ReactElement => {
	const { t } = useTranslation();

	const [menuCollapse, setMenuCollapse] = React.useState(false);

	const GDPRMenuItemFlag = useFlag('GDPRMenuItem');
	const vehiclesFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_VEHICLES);
	const EditUserInADFlag = useFlag(FeatureFlagEnums.EXTERNAL_USERS_VIEW);

	const mainPaddingAnimation = useSpring({
		marginLeft: menuCollapse ? '4.5rem' : '16rem',
	});
	return (
		<div className="flex min-h-screen flex-row bg-gray-100">
			<SideMenu menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}>
				<MenuLink to="/grouppermissions" text="groupPermissions.groupPermissionTitle" collapsed={menuCollapse} className="whitespace-nowrap">
					<FontAwesomeIcon icon={faShieldCheck} size="lg" />
				</MenuLink>

				{/* <MenuLink to="/gdpr" text="GDPR" collapsed={menuCollapse} className="whitespace-nowrap">
                    <FontAwesomeIcon icon={faLockAlt} size="lg" />
                </MenuLink> */}

				<MenuLink to="/locations" text="locations.overviewTitle" collapsed={menuCollapse} className="whitespace-nowrap">
					<FontAwesomeIcon icon={faWarehouse} size="lg" />
				</MenuLink>

				{vehiclesFlag &&
					<MenuLink to="/vehicles" text="vehicles.overviewTitle" collapsed={menuCollapse} className="whitespace-nowrap">
						<FontAwesomeIcon icon={faCar} size="lg" />
					</MenuLink>
				}

				<MenuLink to="/damagecategories" text="damageCategory.overviewTitle" collapsed={menuCollapse} className="whitespace-nowrap">
					<FontAwesomeIcon icon={faHouseDamage} size="lg" />
				</MenuLink>

				<MenuLink to="/catalogs" text="catalog.title" collapsed={menuCollapse} className="whitespace-nowrap">
					<FontAwesomeIcon icon={faBuilding} size="lg" />
				</MenuLink>
				<MenuLink to="/invoices" text="invoices.title" collapsed={menuCollapse} className="whitespace-nowrap">
					<FontAwesomeIcon icon={faFileInvoice} size="lg" />
				</MenuLink>

				{EditUserInADFlag &&
					<MenuLink to="/users" text="users.overviewTitle" collapsed={menuCollapse} className="whitespace-nowrap">
						<FontAwesomeIcon icon={faUser} size="lg" />
					</MenuLink>
				}

				<MenuLink to="/integrations" text="integrations.overviewTitle" collapsed={menuCollapse} className="whitespace-nowrap">
					<FontAwesomeIcon icon={faPlug} size="lg" />
				</MenuLink>

				<MenuLink to="/notifications" text="notifications.overviewTitle" collapsed={menuCollapse} className="whitespace-nowrap">
					<FontAwesomeIcon icon={faBell} size="lg" />
				</MenuLink>

				<MenuLink to="/general" text="general.overviewTitle" collapsed={menuCollapse} className="whitespace-nowrap">
					<FontAwesomeIcon icon={faBell} size="lg" />
				</MenuLink>

				<MenuLink to="/questionnaire" text="questionnaire.overviewTitle" collapsed={menuCollapse} className="whitespace-nowrap">
					<FontAwesomeIcon icon={faQuestion} size="lg" />
				</MenuLink>
				{GDPRMenuItemFlag && (
					<RestrictedArea permissions={[Permissions.ADMINISTRATION_GDPR]}>
						<MenuLink to="/gdpr" text="gdpr.overviewTitle" collapsed={menuCollapse}>
							<FontAwesomeIcon icon={faLock} size="lg" />
						</MenuLink>
					</RestrictedArea>
				)}
				<MenuLink to="/log" text="Log" collapsed={menuCollapse} className="whitespace-nowrap">
					<FontAwesomeIcon icon={faTerminal} size="lg" />
				</MenuLink>
			</SideMenu>
			<animated.main style={mainPaddingAnimation} className={classNames('relative w-full')}>
				{children}
			</animated.main>
		</div>
	);
};

export default Main;
