import React from 'react';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import {
	CreateMovablesLocations,
	CreateMovablesLocationsVariables,
	DeleteMovablesLocation,
	DeleteMovablesLocationVariables,
	GetAdminLocations_locationsAdmin,
	GetMovablesLocations,
	GetMovablesLocationsVariables,
	GetMovablesLocations_movablesLocations,
	MovablesLocationInputType,
	UpdateMovablesLocation,
	UpdateMovablesLocationVariables,
} from '../../../GraphQL';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { MovablesLocationSchema } from '../../../Schemas/MovablesLocationSchema';
import { IMovableLocation } from '../../../Schemas/IMovableLocation';
import { useTranslation } from 'react-i18next';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import Checkbox from '@ssg/common/Components/Checkbox';
import { SelectOption } from '@ssg/common/Helpers/Helpers';

const GET_MOVABLES_LOCATIONS = loader('src/GraphQL/MovablesLocations/GetMovablesLocations.gql');
const CREATE_LOCATIONS = loader('src/GraphQL/MovablesLocations/CreateMovablesLocations.gql');
const UPDATE_LOCATION = loader('src/GraphQL/MovablesLocations/UpdateMovablesLocation.gql');
const DELETE_LOCATION = loader('src/GraphQL/MovablesLocations/DeleteMovablesLocation.gql');

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	data?: GetMovablesLocations_movablesLocations;
	erase?: boolean;
	submitCb?: () => unknown;
	locationsData: GetAdminLocations_locationsAdmin[];
}

const MovablesLocationModal: React.FC<Props> = ({ open, close, edit = false, data, erase, submitCb, locationsData }): React.ReactElement => {
	const { t } = useTranslation();

	const [isMultiple, setIsMultiple] = React.useState(false);
	const [multipleRange, setMultipleRange] = React.useState<{
		from: number;
		to: number;
	}>();
	React.useEffect(() => setMultipleRange(undefined), [isMultiple]);

	const [createMovablesLocations, { loading: createLoading }] = useMutation<CreateMovablesLocations, CreateMovablesLocationsVariables>(CREATE_LOCATIONS);
	const [updateMovablesLocation, { loading: updateLoading }] = useMutation<UpdateMovablesLocation, UpdateMovablesLocationVariables>(UPDATE_LOCATION);
	const [deleteMovablesLocation, { loading: deleteLoading }] = useMutation<DeleteMovablesLocation, DeleteMovablesLocationVariables>(DELETE_LOCATION);

	const locations = React.useMemo(() => {
		const onlyMovables: GetAdminLocations_locationsAdmin[] = [];
		locationsData.forEach(l => {
			if (l.movables) {
				onlyMovables.push(l);
			}
		});
		return onlyMovables.map((l): SelectOption => ({ value: l?.id ?? '', label: l?.name ?? '' }));
	}, [locationsData]);

	// if (resources && resources[0].label !== '' && resources[0].value !== ''){
	//     resources?.unshift({value: '', label: ''});
	// }

	const { handleSubmit, register, errors } = useForm<IMovableLocation>({
		resolver: yupResolver(MovablesLocationSchema),
		reValidateMode: 'onChange',
	});

	const onSubmit = async (location: MovablesLocationInputType) => {
		// TODO: Refactor to accept number
		const volumeAsNumber = parseFloat(location.volume.toString());

		if (edit) {
			try {
				await updateMovablesLocation({
					variables: {
						id: data?.id ?? '',
						name: location.name,
						volume: volumeAsNumber,
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				const movablesLocation = {
					name: location.name,
					volume: volumeAsNumber,
					locationId: location.locationId,
				};

				if (isMultiple && typeof multipleRange !== 'undefined') {
					if (multipleRange.from > multipleRange.to) {
						return window.alert(t('error.invalidFromToRange'));
					}

					await createMovablesLocations({
						variables: {
							movablesLocations: Array.from(
								{
									length: multipleRange.to - multipleRange.from + 1,
								},
								(_, i) => i + multipleRange.from,
							).map(number => ({
								...movablesLocation,
								name: `${movablesLocation.name} ${number}`,
							})),
						},
					});
				} else {
					await createMovablesLocations({
						variables: { movablesLocations: [movablesLocation] },
					});
				}
			} catch (e) {
				console.log(e);
			}
		}

		submitCb?.();
	};

	return (
		<Modal
			title={(erase && 'movablesLocations.delete') || (data && 'movablesLocations.update') || 'movablesLocations.create'}
			size={ModalSize.SMALL}
			visible={open}
			close={close}
			body={
				erase && typeof data !== 'undefined' ? (
					<div>
						<FormFieldHeader title="movablesLocations.wantToDelete" />
						<div className="flex flex-col">
							<div>{data.name}</div>

							<Button
								danger
								text="movablesLocations.delete"
								loading={deleteLoading}
								onClick={async () => {
									await deleteMovablesLocation({
										variables: {
											id: data?.id,
										},
										update: (cache, { data: cacheData }): void => {
											if (typeof cacheData === 'undefined' || cacheData === null) {
												return;
											}

											const cachedRequest = cache.readQuery<GetMovablesLocations, GetMovablesLocationsVariables>({
												query: GET_MOVABLES_LOCATIONS,
												variables: {
													location: data.location.id,
												},
											});

											if (cachedRequest === null || cachedRequest.movablesLocations === null) {
												return;
											}

											cache.writeQuery<GetMovablesLocations, GetMovablesLocationsVariables>({
												query: GET_MOVABLES_LOCATIONS,
												variables: {
													location: data.location.id,
												},
												data: {
													movablesLocations: cachedRequest.movablesLocations.filter(m => m.id !== data.id),
												},
											});
										},
									});
									close();
								}}
								className="mt-4"
							/>
						</div>
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						{!edit && <Checkbox title="movablesLocations.createMultiple" name="multiple" checkedControlled={isMultiple} onChange={e => setIsMultiple(e.target.checked)} />}

						<Input title="common.name" name="name" innerRef={register} required defaultValue={data?.name} errorMessage={errors.name?.message} />

						{isMultiple && (
							<div className="mt-2 flex space-x-4">
								<Input
									title="common.from"
									name="multipleFrom"
									type="number"
									value={multipleRange?.from}
									onChange={e => {
										const number = e.target.valueAsNumber;
										if (!isNaN(number)) {
											setMultipleRange(current => ({
												from: number,
												to: current?.to ?? number,
											}));
										}
									}}
								/>

								<Input
									title="common.to"
									name="multipleTo"
									type="number"
									value={multipleRange?.to}
									onChange={e => {
										const number = e.target.valueAsNumber;
										if (!isNaN(number)) {
											setMultipleRange(current => ({
												from: current?.from ?? number,
												to: number,
											}));
										}
									}}
									errorMessage={typeof multipleRange !== 'undefined' && multipleRange.from > multipleRange.to ? 'error.invalidFromToRange' : undefined}
								/>
							</div>
						)}

						<Input
							title="movablesLocations.volume"
							name="volume"
							type="number"
							unit="m&sup3;"
							min={0}
							step={0.01}
							innerRef={register}
							required
							defaultValue={data?.volume}
							errorMessage={errors.volume?.message}
						/>

						<Dropdown
							innerRef={register}
							name="locationId"
							title="movablesLocations.parentLocation"
							required
							defaultValue={data?.location.id}
							data={locations}
							errorMessage={errors.location?.message}
							className={edit ? 'hidden' : ''}
						/>
						{edit && <p className="text-blue pl-1 font-semibold">{data?.location.name}</p>}

						<Button success submit text={data ? 'movablesLocations.update' : 'movablesLocations.create'} loading={createLoading || updateLoading} className="mt-4" />
					</form>
				)
			}
		/>
	);
};

export default MovablesLocationModal;
