import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import {
	APVInput,
	CreateAPV,
	CreateAPVVariables,
	DeleteAPV,
	DeleteAPVVariables,
	GetAPVs,
	GetAPVs_apvs,
	GetAdminDamageCategories,
	GetAdminDamageCauses,
	UpdateAPV,
	UpdateAPVVariables,
} from '../../../GraphQL';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { stringToByteArray, toBase64 } from '@ssg/common/Helpers/inputFileHelper';
import Input from '@ssg/common/Components/Input';
import Modal from '@ssg/common/Components/Modal';
import Dropdown from '@ssg/common/Components/Dropdown';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import classNames from 'classnames';

const CREATE_APV = loader('src/GraphQL/APV/CreateAPV.gql');
const UPDATE_APV = loader('src/GraphQL/APV/UpdateAPV.gql');
const DELETE_APV = loader('src/GraphQL/APV/DeleteAPV.gql');
const GET_DAMAGE_CATEGORIES = loader('src/GraphQL/DamageCategory/GetAdminDamageCategories.gql');
const GET_DAMAGE_CAUSES = loader('src/GraphQL/DamageCause/GetAdminDamageCauses.gql');
const GET_APVS = loader('src/GraphQL/APV/GetAPVs.gql');

interface Props {
	open: boolean;
	close: () => void;
	data?: GetAPVs_apvs;
	edit?: boolean;
	erase?: boolean;
}

const APVModal: React.FC<Props> = ({ open, close, data, edit, erase }) => {
	const { t } = useTranslation();

	const [file, setFile] = React.useState<File>();

	const [createAPV, { loading: loadingCreate }] = useMutation<CreateAPV, CreateAPVVariables>(CREATE_APV);
	const [updateAPV, { loading: loadingUpdate }] = useMutation<UpdateAPV, UpdateAPVVariables>(UPDATE_APV);
	const [deleteAPV, { loading: loadingDelete }] = useMutation<DeleteAPV, DeleteAPVVariables>(DELETE_APV);

	const { data: category, loading: loadingCategory } = useQuery<GetAdminDamageCategories>(GET_DAMAGE_CATEGORIES);
	const { data: cause, loading: loadingCause } = useQuery<GetAdminDamageCauses>(GET_DAMAGE_CAUSES);

	const { handleSubmit, register, control } = useForm();

	const onSubmit = async (apv: APVInput) => {
		console.log(apv);
		if (edit) {
			try {
				await updateAPV({
					variables: {
						id: data?.id ?? '',
						damageCategory: apv.damageCategory,
						damageCause: apv.damageCause,
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}
						const cachedRequest = cache.readQuery<GetAPVs>({
							query: GET_APVS,
						});

						if (cachedRequest === null || cachedRequest.apvs === null) {
							return;
						}

						const newAPVs = [...cachedRequest.apvs];
						const index = newAPVs.findIndex(apv => apv.id === data?.id ?? '');
						newAPVs[index] = cacheData.updateAPV;

						cache.writeQuery<GetAPVs>({
							query: GET_APVS,
							data: {
								apvs: newAPVs,
							},
						});
					},
				});
				close();
			} catch (e) {
				console.log(e);
			}
		} else if (file) {
			try {
				const base64 = await toBase64(file);
				const byteArray = stringToByteArray(base64);

				await createAPV({
					variables: {
						apv: {
							damageCategory: apv.damageCategory,
							damageCause: apv.damageCause,
						},
						file: {
							filename: file.name,
							fileData: byteArray,
						},
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}
						const cachedRequest = cache.readQuery<GetAPVs>({
							query: GET_APVS,
						});

						if (cachedRequest === null || cachedRequest.apvs === null) {
							return;
						}

						cache.writeQuery<GetAPVs>({
							query: GET_APVS,
							data: {
								apvs: [...cachedRequest.apvs, cacheData.createAPV],
							},
						});
					},
				});
			} catch (e) {
				console.log(e);
			}
			close();
		}
	};

	const damageCategories = React.useMemo(() => {
		return category?.damageCategoriesAdmin.map((c): SelectOption => ({ value: c.id ?? '', label: c.name ?? '' }));
	}, [category]);

	const damageCategory = useWatch({
		control: control,
		name: 'damageCategory',
		defaultValue: data?.damageCategory.id ?? (damageCategories && damageCategories[0].value),
	});

	const damageCauses = React.useMemo(() => {
		return cause?.damageCausesAdmin
			.filter(c => c.category.id === damageCategory)
			.map(
				(c): SelectOption => ({
					value: c.id ?? '',
					label: c.name ?? '',
				}),
			);
	}, [cause, damageCategory]);

	if (loadingCategory || loadingCause)
		return (
			<div className="relative h-40">
				<Loading />
			</div>
		);

	return (
		<Modal
			title={(erase && 'apv.delete') || (data && 'apv.update') || 'apv.create'}
			visible={open}
			close={close}
			body={
				erase && typeof data !== 'undefined' ? (
					<div>
						<FormFieldHeader title="apv.wantToDelete" />
						<div>
							<p>{data.damageCategory.name}</p>
							<p>{data.damageCause.name}</p>
							<p>{data.url}</p>

							<Button
								danger
								text="apv.delete"
								loading={loadingDelete}
								onClick={async () => {
									await deleteAPV({
										variables: {
											id: data.id,
										},
										update: (cache, { data: cacheData }): void => {
											if (typeof cacheData === 'undefined' || cacheData === null) {
												return;
											}
											const cachedRequest = cache.readQuery<GetAPVs>({
												query: GET_APVS,
											});

											if (cachedRequest === null || cachedRequest.apvs === null) {
												return;
											}

											cache.writeQuery<GetAPVs>({
												query: GET_APVS,
												data: {
													apvs: cachedRequest.apvs.filter(c => c.id !== data.id),
												},
											});
										},
									});
									close();
								}}
								className="mt-4"
							/>
						</div>
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<Input name="url" innerRef={register} defaultValue={data?.url} className="hidden" />

						<Dropdown title="apv.damageCategory" name="damageCategory" required innerRef={register} data={damageCategories} defaultValue={data?.damageCategory.id} />

						<Dropdown title="apv.damageCause" name="damageCause" required innerRef={register} data={damageCauses} defaultValue={data?.damageCause.id} />
						<div
							className={classNames({
								hidden: data,
								flex: !data,
							})}
						>
							<label className="rounded-default bg-blue mt-3 flex cursor-pointer flex-col items-center px-3 py-2 text-white focus:outline-none">
								<span>
									<FontAwesomeIcon icon={faFileUpload} className="mr-2" />
									{t('common.chooseFile')}
								</span>
								<input type="file" autoComplete="nope" className="hidden" onChange={e => e.target.files && setFile(e.target.files[0])} />
							</label>
						</div>

						<Button success submit text={data ? 'apv.update' : 'apv.create'} loading={loadingCreate || loadingUpdate} className="mt-4" />
					</form>
				)
			}
		/>
	);
};

export default APVModal;
