import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useMicrosoftAuth } from '@ssg/common/Components/MicrosoftAuth';
import { Permissions } from './GraphQL';
import Home from './Pages/Home';
import Main from './Components/Layout/Main';
import GroupPermission from './Pages/GroupPermission/GroupPermission';
import Login from './Pages/Login';
import Integrations from './Pages/Integrations/Integrations';
import LocationsTabs from './Pages/LocationsTabs/LocationsTabs';
import FieldOverview from './Pages/InputFields/FieldOverview';
import UserContext from './UserContext';
import Log from './Pages/LogData/Log';
import GDPR from './Pages/GDPR/GDPR';
import DamageTabs from './Pages/DamageTabs/DamageTabs';
import NotificationTabs from './Pages/NotificationTabs/NotificationTabs';
import GeneralTabs from './Pages/GeneralTabs/GeneralTabs';
import CatalogTabs from './Pages/Catalogs/CatalogTabs';
import DrivingSlipQuestionnaireOverview from './Pages/DrivingSlipQuestionnaire/DrivingSlipQuestionnaireOverview';
import Invoices from './Pages/Invoices/Invoices';
import Loading from '@ssg/common/Components/Loading';
import RestrictedRoute from '@ssg/common/Components/RestrictedRoute';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import UsersOverview from 'Pages/Users/UsersOverview';
import VehicleTabs from 'Pages/VehicleTabs/VehicleTabs';

const App: React.FC = (): React.ReactElement => {
	const userContext = React.useContext(UserContext);
	const { isAuthenticated } = useMicrosoftAuth();
	const GDPRMenuItemFlag = useFlag('GDPRMenuItem');
	const EditUserInADFlag = useFlag('EditUserInAD');
	const ExternalUsersViewFlag = useFlag(FeatureFlagEnums.EXTERNAL_USERS_VIEW);

	if (!isAuthenticated) {
		return <Login />;
	}

	if (userContext.loading) {
		return <Loading />;
	}

	if (!userContext.user) {
		return <p>No user</p>;
	}
	const userPermissions = userContext.user?.permissions ?? [];

	if (!userPermissions.includes(Permissions.ADMINISTRATION_SITE_VIEW)) {
		return <div className="font-mono">Access denied</div>;
	}

	return (
		<Router>
			<Main>
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/grouppermissions" exact component={GroupPermission} />
					<Route path="/fieldoverview" exact component={FieldOverview} />
					<Route path="/locations" exact component={LocationsTabs} />
					<Route path="/vehicles" exact component={VehicleTabs} />
					<Route path="/damagecategories" exact component={DamageTabs} />
					<Route path="/catalogs" exact component={CatalogTabs} />
					<Route path="/invoices" exact component={Invoices} />
					{ExternalUsersViewFlag && <Route path="/users" exact component={UsersOverview} />}
					<Route path="/integrations" exact component={Integrations} />
					<Route path="/notifications" exact component={NotificationTabs} />
					<Route path="/questionnaire" exact component={DrivingSlipQuestionnaireOverview} />
					<Route path="/general" exact component={GeneralTabs} />
					{GDPRMenuItemFlag && <RestrictedRoute path="/gdpr" exact component={GDPR} userPermissions={userPermissions} requiredPermissions={[Permissions.ADMINISTRATION_GDPR]} />}
					<Route path="/log" exact component={Log} />
				</Switch>
			</Main>
		</Router>
	);
};

export default App;
