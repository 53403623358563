import React, { useContext } from 'react';
import MenuLink from './MenuLink';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faSignOut } from '@fortawesome/pro-regular-svg-icons';
import { useMicrosoftAuth } from './MicrosoftAuth';
import SmallLogo from './Logo/SmallLogo';
import classNames from 'classnames';
import LanguageSelect from './LanguageSelect';
import EnvironmentVariableContext from '../EnvironmentVariableContext';

interface Props {
	menuCollapse: boolean;
	setMenuCollapse(a: boolean): void;
}

const SideMenu: React.FC<Props> = ({ children, menuCollapse, setMenuCollapse }): React.ReactElement => {
	const { logout, account } = useMicrosoftAuth();

	const sideMenuAnimation = useSpring({
		width: menuCollapse ? '4.5rem' : '15rem',
	});

	const envVar = useContext(EnvironmentVariableContext);

	const environmentTag = envVar.environmentTag ?? '';

	return (
		<animated.div style={sideMenuAnimation} className="bg-blue fixed z-10 flex h-screen min-h-full flex-col py-4 text-white">
			<div className="-ml-1 flex h-16 w-full flex-row overflow-x-hidden px-4">
				<Link to="/" className="flex flex-row items-center">
					<div className="h-12 w-12">
						<SmallLogo className="h-12 w-12 object-contain" />
					</div>
					<div className="ml-5 text-4xl font-semibold italic">5C</div>
				</Link>
			</div>
			<div className="-my-2 h-8 w-full overflow-x-hidden pl-20">
				<p
					className={classNames('rounded-default w-18 border-2 border-white pt-px text-center font-bold', {
						'bg-orange': environmentTag === 'TEST' || environmentTag === 'DEV',
						'text-blue bg-white': environmentTag === 'PROD',
					})}
				>
					{environmentTag}
				</p>
			</div>
			<div className="bg-blue ml-auto -mt-22 mb-12 -mr-10 flex h-10 w-10 cursor-pointer items-center justify-center" onClick={() => setMenuCollapse(!menuCollapse)}>
				{menuCollapse ? <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" /> : <FontAwesomeIcon icon={faAngleDoubleLeft} size="lg" />}
			</div>
			<div className="no-scrollbar flex h-full flex-col overflow-x-hidden">
				<div className="flex-grow-default mt-10 px-4">{children}</div>
				<div>
					<div className="px-4">
						<div className="mb-8 h-px w-full bg-white" />
					</div>
					<div className="-mb-8 px-4">
						{/* <RestrictedArea
							permissions={[]}  // Permissions.MY_PAGE_VIEW
						> */}
						<MenuLink to="/" text={account?.name || ''} collapsed={menuCollapse}>
							<div className="border-1 flex h-10 w-10 items-center justify-center rounded-full border-white bg-gray-900 text-lg">{account?.name?.substr(0, 1).toUpperCase()}</div>
						</MenuLink>
						{/* </RestrictedArea> */}

						<div className="mb-10">
							<LanguageSelect />
						</div>

						<MenuLink to="" text="common.logout" collapsed={menuCollapse} className="whitespace-nowrap" onClick={() => logout()}>
							<FontAwesomeIcon icon={faSignOut} size="lg" />
						</MenuLink>
					</div>
				</div>
			</div>
		</animated.div>
	);
};

export default SideMenu;
