import { useMutation, useQuery } from '@apollo/client';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import { loader } from 'graphql.macro';
import React from 'react';
import { GetAdminDamageCauses, GetGroupsPermissions, UpdateGroupPermission, UpdateGroupPermissionVariables } from '../../GraphQL';

interface Props extends Pick<GetGroupsPermissions['groupsPermissions'][number], 'id' | 'groupDamageCategories' | 'groupDamageCauses'> {
	close: () => unknown;
}

const GET_CAUSES = loader('../../GraphQL/DamageCause/GetAdminDamageCauses.gql');
const UPDATE_GROUP_PERMISSION = loader('../../GraphQL/GroupPermission/UpdateGroupPermission.gql');
const GET_GROUPS_PERMISSIONS = loader('../../GraphQL/GroupPermission/GetGroupsPermissions.gql');

type DamageCause = GetAdminDamageCauses['damageCausesAdmin'][number];
type CategoryCauseMap = Array<
	Pick<DamageCause['category'], 'id' | 'name'> & {
		selected: boolean;
		causes: Array<
			Pick<DamageCause, 'id' | 'name'> & {
				selected: boolean;
			}
		>;
	}
>;

const ADGroupDamageCategoryCauseModal: React.FC<Props> = ({ id, groupDamageCategories, groupDamageCauses, close }) => {
	const [selectedCategories, setSelectedCategories] = React.useState(groupDamageCategories);
	const [selectedCauses, setSelectedCauses] = React.useState(groupDamageCauses);

	const { data } = useQuery<GetAdminDamageCauses>(GET_CAUSES, {
		fetchPolicy: 'network-only',
		variables: { useLimitations: false },
	});
	const [updateGroupPermission] = useMutation<UpdateGroupPermission, UpdateGroupPermissionVariables>(UPDATE_GROUP_PERMISSION);

	const categoryCauseMap = React.useMemo(
		() =>
			(data?.damageCausesAdmin ?? []).reduce<CategoryCauseMap>((map, cause) => {
				let category = map.find(l => l.id === cause.category.id);
				if (typeof category === 'undefined') {
					const mapLength = map.push({
						id: cause.category.id,
						selected: selectedCategories.includes(cause.category.id),
						name: cause.category.name,
						causes: [],
					});
					category = map[mapLength - 1];
				}

				category.causes.push({
					id: cause.id,
					selected: selectedCauses.includes(cause.id),
					name: cause.name,
				});

				map.sort((a, b) => a.name.localeCompare(b.name));
				return map;
			}, []),
		[data?.damageCausesAdmin, selectedCategories, selectedCauses],
	);

	return (
		<Modal
			visible
			close={close}
			title="groupPermissions.adGroupCategoryCause"
			size={ModalSize.LARGE}
			body={
				<div className="-my-4 flex flex-row flex-wrap">
					{categoryCauseMap.map(category => (
						<div key={category.id} className="my-4 w-1/2">
							<Checkbox
								name={category.id}
								title={category.name}
								checkedControlled={selectedCategories.includes(category.id)}
								onChange={e => {
									const categoryCauses = category.causes.map(c => c.id);
									if (e.target.checked && !selectedCategories.includes(category.id)) {
										setSelectedCategories([...selectedCategories, category.id]);
										setSelectedCauses([...selectedCauses, ...categoryCauses]);
									} else if (!e.target.checked && selectedCategories.includes(category.id)) {
										setSelectedCategories(selectedCategories.filter(sc => sc !== category.id));
										setSelectedCauses(selectedCauses.filter(sc => !categoryCauses.includes(sc)));
									}
								}}
								className="text-2xl"
							/>

							{category.causes.map(cause => (
								<div key={cause.id}>
									<Checkbox
										name={cause.id}
										title={cause.name}
										checkedControlled={selectedCauses.includes(cause.id)}
										onChange={e => {
											if (e.target.checked && !selectedCauses.includes(cause.id)) {
												setSelectedCauses([...selectedCauses, cause.id]);
											} else if (!e.target.checked && selectedCauses.includes(cause.id)) {
												setSelectedCauses(selectedCauses.filter(sc => sc !== cause.id));
											}
										}}
										disabled={!category.selected}
									/>
								</div>
							))}
						</div>
					))}
				</div>
			}
			footer={
				<>
					<Button
						primary
						text="common.save"
						onClick={async () => {
							await updateGroupPermission({
								variables: {
									id,
									groupDamageCategories: selectedCategories,
									groupDamageCauses: selectedCauses,
								},
								update: (cache, { data: cacheData }): void => {
									if (typeof cacheData === 'undefined' || cacheData === null) {
										return;
									}

									const cachedRequest = cache.readQuery<GetGroupsPermissions>({
										query: GET_GROUPS_PERMISSIONS,
									});

									if (cachedRequest === null || cachedRequest.groupsPermissions === null) {
										return;
									}

									cache.writeQuery<GetGroupsPermissions>({
										query: GET_GROUPS_PERMISSIONS,
										data: {
											groupsPermissions: cachedRequest.groupsPermissions.map(grp => (grp.id === cacheData.updateGroupPermission.id ? cacheData.updateGroupPermission : grp)),
										},
									});
								},
							});
							close();
						}}
					/>
					<Button
						primary
						text="common.allowAll"
						onClick={async () => {
							await updateGroupPermission({
								variables: {
									id,
									groupDamageCategories: [],
									groupDamageCauses: [],
								},
								update: (cache, { data: cacheData }): void => {
									if (typeof cacheData === 'undefined' || cacheData === null) {
										return;
									}

									const cachedRequest = cache.readQuery<GetGroupsPermissions>({
										query: GET_GROUPS_PERMISSIONS,
									});

									if (cachedRequest === null || cachedRequest.groupsPermissions === null) {
										return;
									}

									cache.writeQuery<GetGroupsPermissions>({
										query: GET_GROUPS_PERMISSIONS,
										data: {
											groupsPermissions: cachedRequest.groupsPermissions.map(grp => (grp.id === cacheData.updateGroupPermission.id ? cacheData.updateGroupPermission : grp)),
										},
									});
								},
							});
							close();
						}}
					/>
				</>
			}
		/>
	);
};
export default ADGroupDamageCategoryCauseModal;
