import Button from '@ssg/common/Components/Button';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import { useAddRemoveUsersToAllCatalogCustomersMutation } from '@ssg/common/GraphQL/indexV2';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	add: boolean
	visible: boolean;
	close(): void;
	refetch: () => Promise<unknown>
}


function isEmail(input: string): boolean {
	// Regular expression for validating an email address
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	// Test the input string against the regex pattern
	return emailRegex.test(input);
}

const AllCatalogCustomersModal: React.FC<Props> = ({ visible, close, add, refetch }): React.ReactElement => {
	const { t } = useTranslation();
	const [users, setUsers] = React.useState<string[]>([]);

	const [addRemoveUsersToAllCatalogCustomers, { loading }] = useAddRemoveUsersToAllCatalogCustomersMutation();

	const submit = async (shouldAdd: boolean) => {
		const replaceAtSigneAddition = shouldAdd ? [] : users.map(u => u.replace(/@/g, '_'));
		try {
			const res = await addRemoveUsersToAllCatalogCustomers({
				variables: {
					adUsers: [...users, ...replaceAtSigneAddition],
					add: shouldAdd,
				},
			});

			if (res.data?.addRemoveUsersToAllCatalogCustomers === 'ok') {
				await refetch();
			}

			close();

		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal
			title={add ? 'catalog.customer.addToAll' : 'catalog.customer.removeFromAll'}
			size={ModalSize.MEDIUM}
			visible={visible}
			close={close}
			body={
				<div>
					<Input
						name=''
						title='catalog.customer.users'
						placeholder="catalog.customer.usersPlaceholder"
						onChange={e => {
							const text = e.currentTarget.value;
							const textArr = text.toLowerCase().trim().split(',').map(t => t.trim());

							setUsers(textArr.filter(t => isEmail(t)));
						}}
					/>

					<FormFieldHeader title="catalog.customer.usersSelected" />
					<p>{users.map(u => <span key={u}>{u}, </span>)}</p>
					<p>{users.length === 0 && t('common.none')}</p>
				</div>
			}
			footer={
				<div className="flex flex-row space-x-4">
					{add
						? <Button success loading={loading} text="catalog.customer.addToAll" onClick={() => submit(true)} disabled={users.length === 0} />
						: <Button danger loading={loading} text="catalog.customer.removeFromAll" onClick={() => submit(false)} disabled={users.length === 0} />
					}
					<Button secondary text="common.cancel" onClick={close} />
				</div>
			}

		/>
	);
};

export default AllCatalogCustomersModal;