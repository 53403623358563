import React from 'react';
import {
	CreateQuestion,
	CreateQuestionVariables,
	DeleteQuestion,
	DeleteQuestionVariables,
	GetAdminDamageCategories_damageCategoriesAdmin,
	GetAdminDamageCauses_damageCausesAdmin,
	GetQuestions,
	GetQuestions_questions,
	UpdateQuestion,
	UpdateQuestionVariables,
	VisitationQuestionInput,
} from '../../../GraphQL';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { yupResolver } from '@hookform/resolvers/yup';
import { VisitationCategorySchema } from '../../../Schemas/VisitationCategorySchema';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';

const CREATE_QUESTION = loader('src/GraphQL/VisitationQuestions/CreateQuestion.gql');
const UPDATE_QUESTION = loader('src/GraphQL/VisitationQuestions/UpdateQuestion.gql');
const DELETE_QUESTION = loader('src/GraphQL/VisitationQuestions/DeleteQuestion.gql');
const GET_QUESTIONS = loader('src/GraphQL/VisitationQuestions/GetQuestions.gql');

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	erase?: boolean;
	data?: GetQuestions_questions;
	submitCb?: () => unknown;
	categoriesList: GetAdminDamageCategories_damageCategoriesAdmin[];
	causesList: GetAdminDamageCauses_damageCausesAdmin[];
}

const VisitationModal: React.FC<Props> = ({ open, close, edit = false, erase = false, data, submitCb, categoriesList, causesList }) => {
	const [createQuestion, { loading: createLoading }] = useMutation<CreateQuestion, CreateQuestionVariables>(CREATE_QUESTION);
	const [updateQuestion, { loading: updateLoading }] = useMutation<UpdateQuestion, UpdateQuestionVariables>(UPDATE_QUESTION);
	const [deleteQuestion, { loading: deleteLoading }] = useMutation<DeleteQuestion, DeleteQuestionVariables>(DELETE_QUESTION);

	const [category, setCategory] = React.useState<string>(data ? data.damageCategory.id : '');

	const damageCategories = React.useMemo(
		() =>
			categoriesList.map(
				(d): SelectOption => ({
					value: d.id ?? '',
					label: d.name ?? '',
				}),
			),
		[categoriesList],
	);

	const damageCauses = React.useMemo(() => {
		if (category === '') {
			return [];
		}
		return causesList
			.filter(dam => dam.category.id === category)
			.map(
				(d): SelectOption => ({
					value: d.id ?? '',
					label: d.name ?? '',
				}),
			);
	}, [causesList, category]);

	// if (damageCauses && damageCauses[0].label !== '' && damageCauses[0].value !== '') {
	// 	damageCauses?.unshift({ value: '', label: '' });
	// }

	const { handleSubmit, register, errors } = useForm<VisitationQuestionInput>({
		resolver: yupResolver(VisitationCategorySchema),
	});

	const onSubmit = async (question: VisitationQuestionInput) => {
		console.log(question);
		if (edit) {
			try {
				await updateQuestion({
					variables: {
						id: data?.id ?? '',
						question: question.question,
						damageCategory: question.damageCategory,
						damageCause: question.damageCause,
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}
						const cachedRequest = cache.readQuery<GetQuestions>({
							query: GET_QUESTIONS,
						});

						if (cachedRequest === null || cachedRequest.questions === null) {
							return;
						}

						cache.writeQuery<GetQuestions>({
							query: GET_QUESTIONS,
							data: {
								questions: cachedRequest.questions.map(c => (c.id === cacheData.updateQuestion?.id ? cacheData.updateQuestion : c)),
							},
						});
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await createQuestion({
					variables: {
						question: {
							question: question.question,
							damageCategory: question.damageCategory,
							damageCause: question.damageCause,
						},
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}
						const cachedRequest = cache.readQuery<GetQuestions>({
							query: GET_QUESTIONS,
						});

						if (cachedRequest === null || cachedRequest.questions === null) {
							return;
						}

						cache.writeQuery<GetQuestions>({
							query: GET_QUESTIONS,
							data: {
								questions: [...cachedRequest.questions, cacheData.createQuestion],
							},
						});
					},
				});
			} catch (e) {
				console.log(e);
			}
		}

		submitCb?.();
	};

	return (
		<>
			<Modal
				title={(erase && 'visitationQuestion.delete') || (data && 'visitationQuestion.update') || 'visitationQuestion.create'}
				size={ModalSize.SMALL}
				visible={open}
				close={close}
				body={
					erase && typeof data !== 'undefined' ? (
						<div>
							<FormFieldHeader title="visitationQuestion.wantToDelete" />
							<div>
								<div>{data.question}</div>
								<div>{data.damageCategory.name}</div>
								<div>{data.damageCause.name}</div>

								<Button
									danger
									text="visitationQuestion.delete"
									loading={deleteLoading}
									onClick={async () => {
										await deleteQuestion({
											variables: {
												id: data.id,
											},
											update: (cache, { data: cacheData }): void => {
												if (typeof cacheData === 'undefined' || cacheData === null) {
													return;
												}
												const cachedRequest = cache.readQuery<GetQuestions>({
													query: GET_QUESTIONS,
												});

												if (cachedRequest === null || cachedRequest.questions === null) {
													return;
												}

												cache.writeQuery<GetQuestions>({
													query: GET_QUESTIONS,
													data: {
														questions: cachedRequest.questions.filter(c => c.id !== data.id),
													},
												});
											},
										});
										close();
									}}
									className="mt-4"
								/>
							</div>
						</div>
					) : (
						<form onSubmit={handleSubmit(onSubmit)}>
							<Dropdown
								innerRef={register}
								name="damageCategory"
								title="visitationQuestion.damageCategory"
								required
								defaultValue={data?.damageCategory.id}
								onChange={e => setCategory(e.target.value)}
								data={[{ label: '', value: '' }, ...damageCategories]}
							/>

							<Dropdown
								innerRef={register}
								name="damageCause"
								title="visitationQuestion.damageCause"
								required
								defaultValue={data?.damageCause.id}
								data={damageCauses}
								disabled={category === ''}
							/>

							<Input title="visitationQuestion.question" required innerRef={register} name="question" defaultValue={data?.question} errorMessage={errors.question?.message ?? ''} />

							<Button success submit text={data ? 'visitationQuestion.update' : 'visitationQuestion.create'} loading={createLoading || updateLoading} className="mt-4" />
						</form>
					)
				}
			/>
		</>
	);
};

export default VisitationModal;
