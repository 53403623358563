import React from 'react';
import { faPrint } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import Button from '@ssg/common/Components/Button';
import classNames from 'classnames';
import QRCode from 'react-qr-code';
import { VehicleFragment } from '@ssg/common/GraphQL/indexV2';

interface Props {
	movables: VehicleFragment[];
	buttonText?: string;
	disabled?: boolean;
}

const VehiclesPrintLabel: React.FC<Props> = ({ movables, buttonText, disabled }) => {
	const { t } = useTranslation();

	const componentRef = React.useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onAfterPrint() {
			setPrintDone(true);
		},
	});

	const [printDone, setPrintDone] = React.useState(false);

	const [print, setPrint] = React.useState(false);

	React.useLayoutEffect(() => {
		if (print && typeof handlePrint !== 'undefined') {
			handlePrint();
		}
		setPrint(false);
	}, [handlePrint, print]);

	return (
		<div>
			{print && (
				<div className="hidden">
					<div ref={componentRef} className="flex flex-row flex-wrap justify-between py-1">
						{movables.map((m, i) => (
							<div key={m.vehicleNumber} className="w-1/2">
								<div
									className={classNames('my-3 flex flex-row justify-between p-4', {
										'mb-px': i % 4 && movables.length - 1 !== i,
									})}
								>
									<div className="mx-4 flex h-56 w-full flex-row justify-between">
										<QRCode value={`p:${m.vehicleNumber}`} size={200} className="my-1 ml-4 self-center" />

										<div
											className="w-28 overflow-hidden"
											style={{
												writingMode: 'vertical-rl',
											}}
										>
											<h3
												className="line-clamp-3 h-56 break-words text-left text-4xl font-semibold leading-none"
												style={{
													writingMode: 'vertical-rl',
													textOrientation: 'mixed',
												}}
											>
												{m.vehicleNumber}
											</h3>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			)}

			<Button
				icon={faPrint}
				onClick={() => setPrint(true)}
				primary
				text={typeof buttonText !== 'undefined' ? buttonText + (printDone ? ' ✓' : '') : undefined}
				disabled={disabled}
				className="cursor-pointer"
			/>
		</div>
	);
};
export default VehiclesPrintLabel;
