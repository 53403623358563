import React from 'react';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GetRiskEvaluationCategories, GetRiskEvaluationCategories_riskEvaluationCategories } from '../../../GraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import RiskEvaluationCategoryModal from './RiskEvaluationCategoryModal';

const GET_CATEGORIES = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationCategories.gql');

const RiskEvaluationCategory: React.FC = () => {
	const { t } = useTranslation();

	const [createCategory, setCreateCategory] = React.useState(false);
	const [updateCategory, setUpdateCategory] = React.useState(false);
	const [deleteCategory, setDeleteCategory] = React.useState(false);

	const [categoryData, setCategoryData] = React.useState<GetRiskEvaluationCategories_riskEvaluationCategories>();

	const { data, loading, refetch } = useQuery<GetRiskEvaluationCategories>(GET_CATEGORIES);

	return (
		<div>
			<header className="flex">
				<div className="w-1/2"></div>
				<div className="flex w-1/2 justify-end">
					<Button primary text="riskEvaluation.createCategory" icon={faPlus} onClick={() => setCreateCategory(true)} className="mb-2" />
				</div>
			</header>

			<div className="text-blue relative bg-white pb-1">
				{loading ? (
					<div className="h-40">
						<Loading />
					</div>
				) : (
					<Table
						data={data?.riskEvaluationCategories ?? []}
						columns={[
							{
								label: 'riskEvaluation.riskCategory',
								selectFn: q => <p>{q.categoryName}</p>,
								sortFn: (a, b) => (a.categoryName ?? '').localeCompare(b.categoryName ?? ''),
							},
							{
								label: 'common.edit',
								classNameTh: 'text-right',
								selectFn: q => (
									<div className="flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faEdit}
											size="lg"
											onClick={() => {
												setCategoryData(q);
												setUpdateCategory(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
							{
								label: 'common.delete',
								classNameTh: 'text-right',
								selectFn: q => (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setCategoryData(q);
												setDeleteCategory(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
						]}
						keySelector={q => q.id}
					/>
				)}
			</div>
			{createCategory && <RiskEvaluationCategoryModal open={createCategory} close={() => setCreateCategory(false)} />}
			{updateCategory && <RiskEvaluationCategoryModal edit open={updateCategory} close={() => setUpdateCategory(false)} data={categoryData} />}
			{deleteCategory && <RiskEvaluationCategoryModal erase open={deleteCategory} close={() => setDeleteCategory(false)} data={categoryData} />}
		</div>
	);
};

export default RiskEvaluationCategory;
