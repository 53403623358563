import React from 'react';
import Tabs from '@ssg/common/Components/Tabs';
import MachineTab from './MachineTab';
//import TemplatesTab from './TemplatesTab/TemplatesTab';

//const tabs: string[] = ['notifications.templates', 'notifications.machines', 'notifications.cases', 'notifications.drivingSlips'];
// const tabs: string[] = ['notifications.machines', 'notifications.cases', 'notifications.drivingSlips'];
const tabs: string[] = ['notifications.machines'];

const NotificationSettings: React.FC = (): React.ReactElement => {
	const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);

	return (
		<div className="-mt-4 bg-white pb-1">
			<Tabs full titles={tabs} active={activeTab} onClick={setActiveTab}>
				{/* {activeTab === 'notifications.templates' && <TemplatesTab />} */}
				{activeTab === 'notifications.machines' && <MachineTab />}
			</Tabs>
		</div>
	);
};

export default NotificationSettings;
