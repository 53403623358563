import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '@ssg/common/Components/Header';
import classNames from 'classnames';
import DamageCategory from './DamageCategory/DamageCategory';
import BusinessArea from './BusinessArea/BusinessArea';
import DamageCause from './DamageCause/DamageCause';
import Visitation from './Visitation/Visitation';
import APV from './APV/APV';
import RiskEvaluation from './RiskEvaluation/RiskEvaluation';
import RiskEvaluationCategory from './RiskEvaluation/RiskEvaluationCategory';
import DrivingSlipCategoryMappings from './DrivingSlipCategoryMapping/DrivingSlipCategoryMappings';

const tabs: string[] = [
	'damageCategory.overviewTitle',
	'businessArea.overviewTitle',
	'damageCause.overviewTitle',
	'visitationQuestion.overviewTitle',
	'apv.overviewTitle',
	'riskEvaluation.overviewTitle',
	'riskEvaluation.overviewTitleCategory',
	'dcm.drivingSlipCategories',
];

const DamageTabs: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);

	return (
		<div>
			<Header title="damageCategory.overviewTitle">
				<div className="flex">
					{tabs.map((title, i) => {
						return (
							<div className="mr-3 mt-1" key={i}>
								<button
									type="button"
									onClick={() => setActiveTab(title)}
									className={classNames('text-blue focus:outline-none', {
										'border-blue border-b-2': title === activeTab,
									})}
								>
									{t(title)}
								</button>
							</div>
						);
					})}
				</div>
			</Header>

			<div className="mx-14 bg-white p-4 pb-1">
				{activeTab === 'damageCategory.overviewTitle' && <DamageCategory />}
				{activeTab === 'businessArea.overviewTitle' && <BusinessArea />}
				{activeTab === 'damageCause.overviewTitle' && <DamageCause />}
				{activeTab === 'visitationQuestion.overviewTitle' && <Visitation />}
				{activeTab === 'apv.overviewTitle' && <APV />}
				{activeTab === 'riskEvaluation.overviewTitle' && <RiskEvaluation />}
				{activeTab === 'riskEvaluation.overviewTitleCategory' && <RiskEvaluationCategory />}
				{activeTab === 'dcm.drivingSlipCategories' && <DrivingSlipCategoryMappings />}
			</div>
		</div>
	);
};

export default DamageTabs;
