import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Item } from './Item';
import { ItemModel } from './ItemModel';
import { NewItemSchema } from '../../Schemas/NewItemSchema';
import { NewItemInputType } from './NewItemInputType';
import { ItemError } from './ItemError';
import update from 'immutability-helper';
import Input from '@ssg/common/Components/Input';
import Modal from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';

interface Props {
	visible: boolean;
	title: string;
	items: ItemModel[];
	newItemInputPlaceholder: string;
	newItemAllowDuplicates?: boolean;
	disableDragAndDrop?: boolean;
	onClose: () => void;
	onSubmit: (updatedItems: ItemModel[]) => void;
}

function getMaxNumber(arr: number[]): number {
	return [...arr].sort((a: number, b: number) => b - a)[0];
}

const ItemManagerModal: React.FC<Props> = ({ title, visible, items: initialItems, newItemInputPlaceholder, newItemAllowDuplicates, disableDragAndDrop, onClose, onSubmit }) => {
	const { t } = useTranslation();
	const [items, setItems] = React.useState<ItemModel[]>(initialItems);

	const getLastId = React.useCallback(() => (items.length > 0 ? getMaxNumber(items.map(i => i.id)) : 0), [items]);

	const { handleSubmit, register, errors, setValue, getValues, setError } = useForm<NewItemInputType>({
		resolver: yupResolver(NewItemSchema),
	});

	const handleMove = React.useCallback((dragIndex: number, hoverIndex: number) => {
		if (disableDragAndDrop) return;

		setItems((previous: ItemModel[]) =>
			update(previous, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, previous[dragIndex] as ItemModel],
				],
			}),
		);
	}, []);

	const handleOnChangeText = (id: string | number, newValue: string): ItemError | undefined => {
		const trimmed = newValue?.trim();
		if (trimmed === '') {
			return {
				errorMessage: t('error.required'),
			};
		}

		if (!newItemAllowDuplicates && items.find(i => i.id !== id && i.text === trimmed) !== undefined) {
			return {
				errorMessage: t('error.duplicatesNotAllowed'),
			};
		}

		setItems(current => [
			...current.map(item => {
				if (item.id !== id) {
					return { ...item };
				}
				return { ...item, text: trimmed };
			}),
		]);
	};

	const handleOnDelete = (id: string | number) => {
		setItems(current => [...current.filter(item => item.id !== id)]);
	};

	const renderItem = React.useCallback(
		(item: { id: number; text: string }, index: number) => (
			<Item key={item.id} index={index} id={item.id} text={item.text} disableDragAndDrop={disableDragAndDrop} onMove={handleMove} onChange={handleOnChangeText} onDelete={handleOnDelete} />
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleMove, disableDragAndDrop],
	);

	const onSubmitNewItem = async (formData: NewItemInputType) => {
		const newItemText = formData.text?.trim();
		if (!newItemAllowDuplicates && items.find(i => i.text === newItemText) !== undefined) {
			setError('text', { message: t('error.duplicatesNotAllowed') });
			return;
		}

		const newId = getLastId() + 1;
		setItems(current => [...current.concat([{ id: newId, text: newItemText }])]);
		resetNewItemText();
	};

	const onSubmitItems = () => {
		const newItemText = getValues().text?.trim();
		if (newItemText && newItemText !== '') {
			if (!window.confirm(t('common.unsavedChangesDoYouWantToProceed'))) {
				return;
			}
		}

		onSubmit(items);
	};

	const resetNewItemText = () =>
		setValue('text', undefined, {
			shouldDirty: false,
			shouldValidate: false,
		});

	return (
		<Modal
			visible={visible}
			close={onClose}
			title={title}
			body={
				<>
					<div>{items.map((item, i) => renderItem(item, i))}</div>
					<form onSubmit={handleSubmit(onSubmitNewItem)}>
						<div className="flex items-center">
							<div className="flex flex-1">
								<Input name="text" innerRef={register} placeholder={newItemInputPlaceholder} errorMessage={errors.text?.message} required />
							</div>
							<div className="flex pl-1 pr-1">
								<button type="submit" className="styleless-input">
									<FontAwesomeIcon icon={faCheck} className="cursor-pointer" title={t('common.save')} />
								</button>
							</div>
							<div className="flex pl-1 pr-1">
								<FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={resetNewItemText} title={t('common.cancel')} />
							</div>
						</div>
					</form>
					<div className="mt-2 flex gap-2">
						<Button danger onClick={() => onClose()}>
							{t('common.cancel')}
						</Button>
						<Button success onClick={onSubmitItems}>
							{t('common.save')}
						</Button>
					</div>
				</>
			}
		/>
	);
};

export default ItemManagerModal;
