import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { FlagProvider } from '@unleash/proxy-client-react';
import { MicrosoftAuthProvider } from '@ssg/common/Components/MicrosoftAuth';
import ApolloAuthenticationWrapper from '@ssg/common/Components/ApolloAuthenticationWrapper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import App from './App';
import { UserContextProvider } from './UserContext';
import EnvironmentVariableContext, { EnvironmentVariableContextProvider } from '@ssg/common/EnvironmentVariableContext';
import useDependencyConfiguration from '@ssg/common/Hooks/useDependencyConfiguration';

import './index.css';
import './i18n';

const AppWrapper: React.FC = (): React.ReactElement => {
	const envVar = useContext(EnvironmentVariableContext);

	const [configured, { unleashConfig, telemetryPlugin, appInsights }] = useDependencyConfiguration({});

	if (!(configured && unleashConfig && telemetryPlugin && appInsights)) {
		return <></>;
	}

	return (
		<FlagProvider config={unleashConfig}>
			<MicrosoftAuthProvider
				msalConfig={{
					auth: {
						clientId: envVar.msClientId,
						authority: envVar.msAuthority,
						redirectUri: window.location.origin,
					},
				}}
				msalScopesApp={[envVar.msScopeApp]}
				msalScopesBC={[envVar.msScopeBc]}
				autoLogin={true}
			>
				<ApolloAuthenticationWrapper
					graphUrl={envVar.baseGraphUrl}
					onError={errorMessage => {
						appInsights.trackException({
							exception: new Error(errorMessage),
						});
					}}
				>
					<UserContextProvider>
						<DndProvider backend={HTML5Backend}>
							<App />
						</DndProvider>
					</UserContextProvider>
				</ApolloAuthenticationWrapper>
			</MicrosoftAuthProvider>
		</FlagProvider>
	);
};

ReactDOM.render(
	<React.StrictMode>
		<EnvironmentVariableContextProvider name="admin">
			<AppWrapper />
		</EnvironmentVariableContextProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
