import { useQuery } from '@apollo/client';
import Button from '@ssg/common/Components/Button';
import { loader } from 'graphql.macro';
import React, { CSSProperties, useState } from 'react';
import { LazyLog } from 'react-lazylog';
import Box from '../../Components/Layout/Box';
import Header from '@ssg/common/Components/Header';
import { GetLogDataPeriod, GetLogDataPeriodVariables } from '../../GraphQL';
import dateToDateOnlyString, { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { useTranslation } from 'react-i18next';
import LogExport from './LogExport';

const GET_LOG_DATA_PERIOD = loader('../../GraphQL/LogData/GetLogDataPeriod.gql');

const Log: React.FC = () => {
	const { t } = useTranslation();

	const getDate = (now: boolean) => {
		const d = new Date();
		if (!now) {
			d.setDate(d.getDate() - 7);
		}
		return d;
	};

	// Dates
	const [fromDate, setFromDate] = useState<Date>(() => {
		return getDate(false);
	});
	const [toDate, setToDate] = useState<Date>(() => {
		return getDate(true);
	});

	const { loading, data, refetch } = useQuery<GetLogDataPeriod, GetLogDataPeriodVariables>(GET_LOG_DATA_PERIOD, {
		variables: {
			dateFrom: `${getDate(false).toLocaleDateString('en-US', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
			})} 00:00:00`,
			dateTo: `${getDate(true).toLocaleDateString('en-US', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
			})} 23:59:59`,
		},
	});

	const refetchLogData = () => {
		refetch({
			dateTo: `${toDate.toLocaleDateString('en-US', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
			})} 23:59:59`,
			dateFrom: `${fromDate.toLocaleDateString('en-US', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
			})} 00:00:00`,
		});
	};

	const styles: CSSProperties = {
		backgroundColor: '#264D64',
	};

	return (
		<div>
			<Header
				title={'log.headerTitle'}
				actions={
					<>
						<LogExport data={data?.logDataPeriod ?? ''} />
					</>
				}
			></Header>
			<div className="px-5 pb-5">
				<Box full loading={loading} title={'log.boxTitle'}>
					<div>
						<div className="my-3 flex justify-end">
							<label className="text-blue mb-1 mr-3 text-xs font-medium">
								{t('log.datePickerText')}
								<div className="flex items-center">
									<input
										type="date"
										value={formatDateForInput(fromDate, false)}
										max={formatDateForInput(toDate, false)}
										onChange={e => {
											const date = e.target.valueAsDate;
											if (date === null) {
												return;
											}

											date.setHours(0, 0, 0, 0);
											setFromDate(date);
										}}
										className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
										style={{
											height: '36px',
											maxWidth: '140px',
											minWidth: '100px',
										}}
									/>
									<span className="px-1">&mdash;</span>
									<input
										type="date"
										max={dateToDateOnlyString(getDate(true))}
										value={formatDateForInput(toDate, false)}
										onChange={e => {
											const date = e.target.valueAsDate;
											if (date === null) {
												return;
											}

											date.setHours(23, 59, 59, 999);
											setToDate(date);

											if (date < fromDate) {
												const from = new Date(date);
												from.setHours(23, 59, 59, 999);
												setFromDate(from);
											}
										}}
										className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
										style={{
											height: '36px',
											maxWidth: '140px',
											minWidth: '100px',
										}}
									/>
									<Button className="ml-3" text={'common.update'} onClick={refetchLogData} primary />
								</div>
							</label>
						</div>
						<div style={{ height: 800 }}>
							<LazyLog
								text={loading || data === undefined ? t('log.loadingLogs') : data?.logDataPeriod}
								lineClassName="lineStyles"
								highlightLineClassName="highlightStyles"
								containerStyle={styles}
								style={styles}
								enableSearch
								caseInsensitive
							/>
						</div>
					</div>
				</Box>
			</div>
		</div>
	);
};

export default Log;
