import React, { useContext } from 'react';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import {
	CreateLocation,
	CreateLocationVariables,
	DeleteLocation,
	DeleteLocationVariables,
	GetAdminLocations,
	GetAdminLocations_locationsAdmin,
	SearchUserEmails,
	SearchUserEmailsVariables,
	UpdateLocation,
	UpdateLocationVariables,
} from '../../../GraphQL';
import { useMutation, useQuery } from '@apollo/client';
import { countries } from '@ssg/common/Helpers/countries';
import { postalCodeToCity, resolvePostalCodes } from '@ssg/common/Helpers/postalCodes';
import { yupResolver } from '@hookform/resolvers/yup';
import { LocationSchema } from '../../../Schemas/LocationSchema';
import { ILocation } from '../../../Schemas/ILocation';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import Input from '@ssg/common/Components/Input';
import Textarea from '@ssg/common/Components/Textarea';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import Dropdown from '@ssg/common/Components/Dropdown';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

const GET_LOCATIONS = loader('src/GraphQL/Locations/GetAdminLocations.gql');
const CREATE_LOCATION = loader('src/GraphQL/Locations/CreateLocation.gql');
const UPDATE_LOCATION = loader('src/GraphQL/Locations/UpdateLocation.gql');
const DELETE_LOCATION = loader('src/GraphQL/Locations/DeleteLocation.gql');
const SEARCH_USER_EMAILS = loader('src/GraphQL/Users/SearchUserEmails.gql');

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	data?: GetAdminLocations_locationsAdmin;
	erase?: boolean;
	submitCb?: () => unknown;
}

const LocationModal: React.FC<Props> = ({ open, close, edit = false, data, erase, submitCb }): React.ReactElement => {
	const envVar = useContext(EnvironmentVariableContext);

	const [country, setCountry] = React.useState(data?.address.country ?? envVar.defaultCountry ?? '');
	const [attachedPostalCodes, setAttachedPostalCodes] = React.useState(data?.attachedPostalCodesInputString ?? '');
	const [withHTML, setWithHTML] = React.useState('');

	//Error states in attached postal codes input
	const [formatError, setFormatError] = React.useState(false);
	const [hyphenError, setHyphenError] = React.useState(false);
	const [intervalError, setIntervalError] = React.useState(false);

	const [createLocation, { loading: createLoading }] = useMutation<CreateLocation, CreateLocationVariables>(CREATE_LOCATION);
	const [updateLocation, { loading: updateLoading }] = useMutation<UpdateLocation, UpdateLocationVariables>(UPDATE_LOCATION);
	const [deleteLocation, { loading: deleteLoading }] = useMutation<DeleteLocation, DeleteLocationVariables>(DELETE_LOCATION);

	const [userSearchText, setUserSearchText] = useDebouncedState('', 100);
	const { data: searchedUsers, loading: isSearchingForUsers } = useQuery<SearchUserEmails, SearchUserEmailsVariables>(SEARCH_USER_EMAILS, {
		variables: { searchText: userSearchText },
		skip: userSearchText.length < 2,
	});

	const { handleSubmit, register, control, errors, setValue, trigger } = useForm<ILocation>({
		resolver: yupResolver(LocationSchema),
		reValidateMode: 'onChange',
		defaultValues: {
			email: data?.email,
		},
	});

	register('attachmentOk');

	React.useEffect(() => {
		setValue('attachmentOk', !formatError && !hyphenError && !intervalError);
	}, [setValue, formatError, hyphenError, intervalError]);

	const onSubmit = async (location: ILocation) => {
		console.log(location);
		if (edit) {
			try {
				await updateLocation({
					variables: {
						id: data?.id ?? '',
						name: location.name,
						movables: location.movables,
						address: location.address,
						email: location.email,
						attachedPostalCodesInputString: location.attachedPostalCodesInputString,
						attachedPostalCodes:
							location.attachedPostalCodesInputString !== null && typeof location.attachedPostalCodesInputString !== 'undefined'
								? await resolvePostalCodes(country, location.attachedPostalCodesInputString.split(','))
								: null,
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await createLocation({
					variables: {
						location: {
							name: location.name,
							movables: location.movables,
							address: location.address,
							email: location.email,
							attachedPostalCodesInputString: location.attachedPostalCodesInputString,
							attachedPostalCodes:
								location.attachedPostalCodesInputString !== null && typeof location.attachedPostalCodesInputString !== 'undefined'
									? await resolvePostalCodes(country, location.attachedPostalCodesInputString.split(','))
									: null,
						},
					},
				});
			} catch (e) {
				console.log(e);
			}
		}

		submitCb?.();
	};

	const singlePostalCodeValidator = React.useCallback(
		(postalCode: string): string => {
			if (country === 'SE') {
				if (/^[0-9]{3} [0-9]{2}$/.test(postalCode)) {
					return `<span>${postalCode}</span>`;
				}
			} else if (country === 'DK' || country === 'NO') {
				if (/^[0-9]{4}$/.test(postalCode)) {
					return `<span>${postalCode}</span>`;
				}
			}
			postalCode.length > 0 && setFormatError(true);
			return `<span class="text-red">${postalCode}</span>`;
		},
		[country],
	);

	React.useEffect(() => {
		setHyphenError(false);
		setIntervalError(false);
		setFormatError(false);
		const textArray = attachedPostalCodes.split(',');
		const mappedArray = textArray.slice().map((t, i) => {
			if (t.includes('-')) {
				const splitT = t.split('-');
				if (splitT.length > 2) {
					setHyphenError(true);
					return `<span class="text-red">${t}</span>`;
				}
				if (parseInt(splitT[0].replace(' ', '')) >= parseInt(splitT[1].replace(' ', ''))) {
					setIntervalError(true);
					return `<span class="text-red">${singlePostalCodeValidator(splitT[0])}-${singlePostalCodeValidator(splitT[1])}</span>`;
				}

				return `<span>${singlePostalCodeValidator(splitT[0])}-${singlePostalCodeValidator(splitT[1])}</span>`;
			} else {
				return singlePostalCodeValidator(t);
			}
		});

		const string = mappedArray.toString();
		setWithHTML(string);
	}, [attachedPostalCodes, singlePostalCodeValidator]);

	return (
		<Modal
			title={(erase && 'locations.delete') || (data && 'locations.update') || 'locations.create'}
			size={ModalSize.SMALL}
			visible={open}
			close={close}
			body={
				erase && typeof data !== 'undefined' ? (
					<div>
						<FormFieldHeader title="locations.wantToDelete" />
						<div className="flex flex-col">
							<div>{data?.name}</div>

							<div className="mt-2">
								{data?.address.road} {data.address.houseNumber} {data.address.floor ?? ''}
							</div>

							<div>
								{data?.address.postalCode} {data?.address.city}
							</div>

							<div>{data?.address.country}</div>

							<div>
								<Checkbox disabled name="movables" title="locations.movablesLocation" defaultChecked={data?.movables} className="mt-4" />
							</div>

							<Button
								danger
								submit
								text="locations.delete"
								loading={deleteLoading}
								onClick={async () => {
									await deleteLocation({
										variables: {
											id: data?.id ?? '',
										},
										update: (cache, { data: cacheData }): void => {
											if (typeof cacheData === 'undefined' || cacheData === null) {
												return;
											}
											const cachedRequest = cache.readQuery<GetAdminLocations>({
												query: GET_LOCATIONS,
											});

											if (cachedRequest === null || cachedRequest.locationsAdmin === null) {
												return;
											}

											cache.writeQuery<GetAdminLocations>({
												query: GET_LOCATIONS,
												data: {
													locationsAdmin: cachedRequest.locationsAdmin.filter(c => c.id !== data.id),
												},
											});
										},
									});
									close();
								}}
								className="mt-4"
							/>
						</div>
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<Input title="common.name" name="name" required innerRef={register} defaultValue={data?.name} errorMessage={errors.name?.message} />

						<div className="flex w-full">
							<div className="w-3/4">
								<Input innerRef={register} name="address.road" title="common.road" required defaultValue={data?.address.road} errorMessage={errors.address?.road?.message} />
							</div>
							<div className="ml-4 w-1/4">
								<Input
									innerRef={register}
									name="address.houseNumber"
									title="common.houseNumber"
									required
									defaultValue={data?.address.houseNumber}
									errorMessage={errors.address?.houseNumber?.message}
								/>
							</div>
							<div className="ml-4 w-1/4">
								<Input innerRef={register} name="address.floor" title="common.floor" defaultValue={data?.address.floor ?? ''} errorMessage={errors.address?.floor?.message} />
							</div>
						</div>

						<Input
							innerRef={register}
							name="address.addressLineAlt"
							title="common.addressLine2"
							defaultValue={data?.address.addressLineAlt ?? undefined}
							errorMessage={errors.address?.addressLineAlt?.message}
						/>

						<div className="flex w-full flex-row lg:w-full">
							<div className="mr-4 w-1/3">
								<Input
									innerRef={register}
									name="address.postalCode"
									title="common.zip"
									required
									onBlur={async e => {
										const pc = e.currentTarget.value;
										const city = await postalCodeToCity(country, pc);
										if (typeof city !== undefined) {
											setValue('address.city', city);
										}
									}}
									defaultValue={data?.address.postalCode}
									errorMessage={errors.address?.postalCode?.message}
								/>
							</div>
							<div className="w-2/3">
								<Input innerRef={register} name="address.city" title="common.city" required defaultValue={data?.address.city} errorMessage={errors.address?.city?.message} />
							</div>
						</div>

						<Dropdown
							innerRef={register}
							name="address.country"
							title="common.country"
							required
							defaultValue={country}
							onChange={e => setCountry(e.target.value)}
							data={countries}
							errorMessage={errors.address?.country?.message}
						/>

						<Checkbox innerRef={register} name="movables" title="locations.movablesLocation" defaultChecked={data?.movables} className="mt-4" />

						<SearchableSelect
							control={control}
							name="email"
							required
							title="common.email"
							initialSelection={typeof data !== 'undefined' ? { label: data.email, value: data.email } : undefined}
							options={(searchedUsers?.searchUsers ?? []).map(u => ({ value: u.email, label: u.email }))}
							searchFn={setUserSearchText}
							onSelect={value => setValue('email', value)}
							onBlur={clearFn => undefined}
							minInputLength={2}
							isLoading={isSearchingForUsers}
							errorMessage={errors.email?.message}
						/>

						<FormFieldHeader htmlFor="attachedPostalCodes" title="locations.attachedPostalCodes" />
						<p className="text-sm">Postnumre skal adskilles med komma. Postnummerintervaller skrives med bindestreg.</p>
						<div className="relative h-40 w-full">
							{/* <div
                                className="w-full h-full p-1 text-sm border-1 rounded-default border-transparent break-words"
                                style={{ lineHeight: '24px' }}
                                dangerouslySetInnerHTML={{ __html: withHTML }}
                            /> */}
							<div className="absolute top-0 left-0 -mt-2 w-full">
								<Textarea
									name="attachedPostalCodesInputString"
									title=""
									rows={6}
									className="h-40 w-full caret-black lg:w-full"
									onChange={e => {
										const input = e.target.value;

										if (input.length === 0 || /^[0-9,\- ]+$/.test(input)) {
											setAttachedPostalCodes(e.target.value);
										}
									}}
									onBlur={() => trigger('attachmentOk')}
									placeholder={country === 'SE' ? 'locations.placeholderSE' : 'locations.placeholderDKNO'}
									value={attachedPostalCodes}
									innerRef={register}
									errorMessage={errors.attachmentOk?.message && ' '}
								/>
							</div>
						</div>
						<div className="h-16">
							{formatError && <FormErrorText text="locations.formatError" />}
							{hyphenError && <FormErrorText text="locations.hyphenError" />}
							{intervalError && <FormErrorText text="locations.intervalError" />}
						</div>

						<Button success submit text={data ? 'locations.update' : 'locations.create'} loading={createLoading || updateLoading} className="mt-4" onClick={() => console.log('click')} />
					</form>
				)
			}
		/>
	);
};

export default LocationModal;
