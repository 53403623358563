import React, { useMemo } from 'react';
import { faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { GetAdminDamageCauses, GetIn4moTypes, In4moDamageType, In4moSettingsInput, In4moTaskType } from '../../../GraphQL';
import { H3 } from '@ssg/common/Components/Text';
import Table from '@ssg/common/Components/Table';
import TextButton from '@ssg/common/Components/TextButton';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@ssg/common/Helpers/Helpers';

const GET_DAMAGE_CAUSES = loader('src/GraphQL/DamageCause/GetAdminDamageCauses.gql');
const GET_IN4MO_TYPES = loader('../../../GraphQL/AdminSettings/GetIn4moTypes.gql');

interface Props {
	settings: In4moSettingsInput;
	setSettings: React.Dispatch<React.SetStateAction<In4moSettingsInput>>;
}

export function toTitleCase(str: string): string {
	const replaced = str.replaceAll('_', ' ');
	return replaced.replace(/\w\S*/g, function (txt: string) {
		return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
	});
}

const In4moDamageMappings: React.FC<Props> = ({ settings, setSettings }) => {
	const { t } = useTranslation();
	const { control: damageControl } = useForm();

	const { data: causes, loading: causesLoading } = useQuery<GetAdminDamageCauses>(GET_DAMAGE_CAUSES);
	const { data: in4moTypes, loading: in4moTypesLoading } = useQuery<GetIn4moTypes>(GET_IN4MO_TYPES);

	const in4moDamageTypeOptions = useMemo(() => {
		if (in4moTypes?.in4moDamageTypes) {
			return in4moTypes.in4moDamageTypes.map(type => ({
				label: toTitleCase(type),
				value: type,
			}));
		}

		return [] as SelectOption[];
	}, [in4moTypes]);

	const in4moTaskTypeOptions = useMemo(() => {
		if (in4moTypes?.in4moTaskTypes) {
			return in4moTypes.in4moTaskTypes.map(type => ({
				label: toTitleCase(type),
				value: type,
			}));
		}

		return [] as SelectOption[];
	}, [in4moTypes]);

	const damageCauseOptions = useMemo(() => {
		if (causes?.damageCausesAdmin) {
			return causes.damageCausesAdmin.map(cause => ({
				label: `${cause.name} (${cause.category.name})`,
				value: cause.id,
			}));
		}

		return [] as SelectOption[];
	}, [causes]);

	return (
		<div>
			<H3 className="mb-2">{t('integrations.in4moDamageMappings')}</H3>

			<Table
				disableOverflowAuto
				data={settings.damageMappings || []}
				columns={[
					{
						label: 'integrations.damageType',
						selectFn: (m, i) => {
							const initialSelected = in4moDamageTypeOptions.find(x => x.value === m.damageType);
							return (
								<SearchableSelect
									name="damageMappings-damageType"
									control={damageControl}
									options={in4moDamageTypeOptions}
									searchFn={searchText => console.log(searchText)}
									onSelect={value => {
										const damageType = value as In4moDamageType;
										if (!damageType) return;

										return setSettings(current => ({
											...current,
											damageMappings: [
												...current.damageMappings.slice(0, i),
												{
													...current.damageMappings[i],
													damageType: damageType,
												},
												...current.damageMappings.slice(i + 1),
											],
										}));
									}}
									onBlur={() => undefined}
									minInputLength={-1}
									isLoading={in4moTypesLoading}
									initialSelection={initialSelected}
								/>
							);
						},

						noTruncate: true,
					},
					{
						label: 'integrations.taskType',
						selectFn: (m, i) => {
							const initialSelected = in4moTaskTypeOptions.find(x => x.value === m.taskType);
							return (
								<SearchableSelect
									name="damageMappings-taskType"
									control={damageControl}
									options={in4moTaskTypeOptions}
									searchFn={searchText => console.log(searchText)}
									onSelect={value => {
										const taskType = value as In4moTaskType;
										if (!taskType) return;

										return setSettings(current => ({
											...current,
											damageMappings: [
												...current.damageMappings.slice(0, i),
												{
													...current.damageMappings[i],
													taskType: taskType,
												},
												...current.damageMappings.slice(i + 1),
											],
										}));
									}}
									onBlur={() => undefined}
									minInputLength={-1}
									isLoading={in4moTypesLoading}
									initialSelection={initialSelected}
								/>
							);
						},

						noTruncate: true,
					},
					{
						label: 'integrations.cause',
						selectFn: (m, i) => {
							const initialSelected = damageCauseOptions.find(x => x.value === m.damageCause);
							return (
								<SearchableSelect
									name="damageMappings-damageCause"
									control={damageControl}
									options={damageCauseOptions}
									searchFn={searchText => console.log(searchText)}
									onSelect={value => {
										if (!value) return;

										return setSettings(current => ({
											...current,
											damageMappings: [
												...current.damageMappings.slice(0, i),
												{
													...current.damageMappings[i],
													damageCause: value,
												},
												...current.damageMappings.slice(i + 1),
											],
										}));
									}}
									onBlur={() => undefined}
									minInputLength={-1}
									isLoading={causesLoading}
									initialSelection={initialSelected}
								/>
							);
						},

						noTruncate: true,
					},
					{
						label: 'common.delete',
						classNameTh: 'text-right',
						selectFn: (m, i) => (
							<div className="text-red flex content-start justify-end text-right">
								<FontAwesomeIcon
									className="cursor-pointer"
									icon={faTrashAlt}
									size="lg"
									onClick={() => {
										const confirm = window.confirm(t('common.wantToDelete'));
										if (confirm) {
											setSettings(current => ({
												...current,
												damageMappings: [...current.damageMappings.slice(0, i), ...current.damageMappings.slice(i + 1)],
											}));
										}
									}}
								/>
							</div>
						),
					},
				]}
				keySelector={(m, i) => String(i)}
			/>

			<TextButton
				icon={faPlus}
				text="common.add"
				className="mt-1"
				onClick={() =>
					setSettings(current => ({
						...current,
						damageMappings: [
							...current.damageMappings,
							{
								damageType: In4moDamageType.OTHER,
								taskType: In4moTaskType.FIRST_AID,
								damageCause: '',
							},
						],
					}))
				}
			/>
		</div>
	);
};

export default In4moDamageMappings;
