import * as yup from 'yup';

yup.setLocale({
	string: {
		required: 'error.required',
	},
});
export const InvoiceReasonCodeSchema = yup.object().shape({
	name: yup.string().required(),
});
