import React from 'react';
import { useTranslation } from 'react-i18next';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GetAdminCases, GetAdminCasesVariables, CaseStatus, CasesGDPRCompliant, CasesGDPRCompliantVariables, GetAdminCases_cases } from '../../GraphQL';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Input from '@ssg/common/Components/Input';
import Datepicker from '@ssg/common/Components/Datepicker';
import Button from '@ssg/common/Components/Button';
import FormHelpText from '@ssg/common/Components/FormHelpText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

const GET_CASES = loader('src/GraphQL/Cases/GetAdminCases.gql');

const CASES_GDPR_COMPLIANT = loader('../../GraphQL/GDPR/CasesGRPDCompliant.gql');

interface ReturnData {
	multiple: boolean;
	erpNos: string[];
}

const GDPRTab: React.FC = () => {
	const { t } = useTranslation();

	const [casesToRandomize, setCasesToRandomize] = React.useState<GetAdminCases_cases[] | undefined>(undefined);
	const [caseErpNo, setCaseErpNo] = React.useState<string>('');
	const [returnData, setReturnData] = React.useState<ReturnData | undefined>(undefined);
	const [casesDateRange, setCasesDateRange] = React.useState<{
		fromDate: Date | null;
		toDate: Date | null;
	}>({
		fromDate: null,
		toDate: null,
	});

	const [makeCasesGDRPCompliant, { loading: makeCasesGDRPCompliantLoading }] = useMutation<CasesGDPRCompliant, CasesGDPRCompliantVariables>(CASES_GDPR_COMPLIANT);

	const [fetchCases, { loading: casesLoading }] = useLazyQuery<GetAdminCases, GetAdminCasesVariables>(GET_CASES, {
		fetchPolicy: 'cache-and-network',
		onCompleted(data) {
			setCasesToRandomize(data.cases);
		},
	});

	const submitMakeCasesGDRPCompliant = async (caseIds: string[]) => {
		if (caseIds.length === 0) {
			return;
		}
		try {
			const res = await makeCasesGDRPCompliant({
				variables: { caseIds: caseIds },
			});
			const casesRandomized = res.data?.casesGDPRCompliant;
			if (casesRandomized) {
				setReturnData({
					erpNos: casesRandomized.map(c => c.erpNo),
					multiple: caseIds.length < 1,
				});
			}

			setCaseErpNo('');
			setCasesToRandomize(undefined);
			setCasesDateRange({
				fromDate: null,
				toDate: null,
			});
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div className="text-blue px-2">
			<div className="flex w-full">
				<div className="mr-2 w-1/2">
					<div className="font-semibold">{t('gdpr.randomizeSingleCase')}</div>
					<Input title="gdpr.erpReferenceNo" name={'erpReferenceNo'} value={caseErpNo} onChange={e => setCaseErpNo(e.target.value)} />
					<Button
						success
						disabled={caseErpNo === ''}
						text="gdpr.randomizeData"
						loading={casesLoading}
						className="mt-2"
						onClick={() => {
							fetchCases({
								variables: {
									erpReferenceNo: caseErpNo,
								},
							});
						}}
					/>
					{typeof returnData !== 'undefined' && !returnData.multiple && (
						<div className="bg-green border-green-dark border-1 mb-2 mt-2 w-48 cursor-pointer rounded-md bg-opacity-25 p-2" onClick={() => setReturnData(undefined)}>
							<h2>{t('gdpr.caseRandomized')}</h2>
							{returnData.erpNos.map(erpNo => (
								<p className="text-green-dark font-semibold">
									<FontAwesomeIcon icon={faCheckCircle} className="mr-3" />
									{erpNo}
								</p>
							))}
						</div>
					)}
				</div>

				<div className="mr-2 w-1/2">
					<div className="font-semibold">{t('gdpr.randomizeCases')}</div>
					<Datepicker
						title="gdpr.fromDate"
						name={'from'}
						onChange={e => {
							const fromDate = e.target.valueAsDate;
							if (fromDate === null) {
								return;
							}
							fromDate.setHours(0, 0, 0, 0);

							const toDate = new Date(fromDate);
							toDate.setHours(23, 59, 59, 999);
							setCasesDateRange({ ...casesDateRange, fromDate });
						}}
					/>
					<Datepicker
						title="gdpr.toDate"
						name={'to'}
						value={formatDateForInput(casesDateRange.toDate)}
						onChange={e => {
							const toDate = e.target.valueAsDate;
							if (toDate === null) {
								return;
							}
							toDate.setHours(23, 59, 59, 999);

							setCasesDateRange({ ...casesDateRange, toDate });
						}}
					/>
					<Button
						success
						disabled={casesDateRange.fromDate === null || casesDateRange.toDate === null}
						text="gdpr.randomizeData"
						loading={casesLoading}
						className="mt-2"
						onClick={() => {
							fetchCases({
								variables: {
									minDate: formatDateForInput(casesDateRange?.fromDate),
									maxDate: formatDateForInput(casesDateRange.toDate),
									closedCases: true,
								},
							});
						}}
					/>
					{typeof returnData !== 'undefined' && returnData.multiple && (
						<div className="bg-green border-green-dark border-1 mb-2 w-48 cursor-pointer rounded-md bg-opacity-25 p-2" onClick={() => setReturnData(undefined)}>
							<h2>{t('gdpr.casesRandomized')}</h2>
							{returnData.erpNos.map(erpNo => (
								<p className="text-green-dark font-semibold">
									<FontAwesomeIcon icon={faCheckCircle} className="mr-3" />
									{erpNo}
								</p>
							))}
						</div>
					)}
				</div>
			</div>

			<div>
				<p className="font-medium">{t('gdpr.randomizeWillChangeTheFollowing')}:</p>
				<ul className="list-disc pl-5">
					<li>{t('gdpr.policyholderContactDetails')}</li>
					<li>{t('gdpr.debitorContactDetails')}</li>
					<li>{t('gdpr.damageContactDetails')}</li>
					<li>{t('gdpr.referrerContactDetails')}</li>
					<li>{t('gdpr.requisitionerContactDetails')}</li>
					<li>{t('gdpr.adviserContactDetails')}</li>
					<li>{t('gdpr.images')}</li>
				</ul>
			</div>

			<Modal
				title="gdpr.randomizeData"
				size={ModalSize.SMALL}
				visible={typeof casesToRandomize !== 'undefined'}
				close={() => setCasesToRandomize(undefined)}
				body={
					<>
						{typeof casesToRandomize !== 'undefined' && (
							<div>
								<p className="mb-2">{t('gdpr.confirmCases')}:</p>

								{casesToRandomize.length && (
									<p>
										Antal <span className="font-medium">{casesToRandomize.length}</span>
									</p>
								)}
								<hr />

								<div className="h-56 overflow-hidden overflow-y-auto">
									<ul>
										{casesToRandomize.map(c => (
											<li key={c.id}>
												<p>{c.erpNo}</p>
												{c.status !== CaseStatus.CLOSED && <FormHelpText text="gdpr.notClosedWarning" />}
											</li>
										))}
									</ul>
								</div>
								<div className="bg-red border-red border-1 mb-2 cursor-pointer rounded-md bg-opacity-25 p-2">
									<p className="text-red font-semibold">
										<FontAwesomeIcon icon={faExclamationCircle} className="mr-3" />
										{t('gdpr.warning')}
									</p>
								</div>
								<div className="flex justify-between">
									<Button
										danger
										disabled={casesToRandomize.length === 0}
										loading={makeCasesGDRPCompliantLoading}
										text="gdpr.randomizeData"
										className="mt-3"
										onClick={() => submitMakeCasesGDRPCompliant(casesToRandomize.map(c => c.id))}
									/>
									<Button secondary text="common.cancel" className="mt-3" onClick={() => setCasesToRandomize(undefined)} />
								</div>
							</div>
						)}
					</>
				}
			/>
		</div>
	);
};

export default GDPRTab;
