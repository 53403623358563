import React, { CSSProperties } from 'react';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { GetDebitorsAndGroups, GetGroupsPermissions, GetGroupsPermissions_groupsPermissions, UpdateGroupPermission, UpdateGroupPermissionVariables } from '../../GraphQL';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Select, { ControlProps, ValueType } from 'react-select';
import Modal from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

interface Props {
	close: () => void;
	data?: GetGroupsPermissions_groupsPermissions;
}

const GET_DEBITORS_AND_GROUPS = loader('../../GraphQL/Debitors/GetDebitorsAndGroups.gql');
const UPDATE_GROUP_PERMISSION = loader('../../GraphQL/GroupPermission/UpdateGroupPermission.gql');
const GET_GROUPS_PERMISSIONS = loader('../../GraphQL/GroupPermission/GetGroupsPermissions.gql');

const ADGroupDebitorModal: React.FC<Props> = ({ close, data }) => {
	const { t } = useTranslation();

	const [searchText, setSearchText] = React.useState('');
	const [selectedDebitors, setSelectedDebitors] = React.useState<SelectOption[]>(
		data?.groupDebitors
			.map(
				(d): SelectOption => ({
					value: d.value ?? '',
					label: d.label ?? '',
				}),
			)
			.concat(
				data?.groupDebitorGroups.map(
					(d): SelectOption => ({
						value: d ?? '',
						label: `(${t('groupPermissions.debitorGroup')}) - ${d}` ?? '',
					}),
				),
			) ?? [],
	);

	const [updateGroupPermission, { loading: updateLoading }] = useMutation<UpdateGroupPermission, UpdateGroupPermissionVariables>(UPDATE_GROUP_PERMISSION);

	const { data: debs } = useQuery<GetDebitorsAndGroups>(GET_DEBITORS_AND_GROUPS, {
		variables: {
			searchText: searchText,
			noLimitations: true,
		},
		skip: searchText.length === 0,
	});

	const debitors = React.useMemo(() => {
		const debList = debs?.debitorsAndGroups.debitors
			? debs.debitorsAndGroups.debitors.map(
					(d): SelectOption => ({
						value: d.debitorId ?? '',
						label: d.company ?? '',
					}),
			  )
			: [];
		const debGroupList = debs?.debitorsAndGroups.debitorGroups
			? debs.debitorsAndGroups.debitorGroups.map(
					(d): SelectOption => ({
						value: d ?? '',
						label: `(${t('groupPermissions.debitorGroup')}) - ${d}` ?? '',
					}),
			  )
			: [];

		return debList.concat(debGroupList);
	}, [debs, t]);

	const selectedDebs = React.useMemo(() => {
		const selDebs = data?.groupDebitors.map(
			(d): SelectOption => ({
				value: d.value ?? '',
				label: d.label ?? '',
			}),
		);
		const selDebGroups = data?.groupDebitorGroups.map(
			(d): SelectOption => ({
				value: d ?? '',
				label: `(${t('groupPermissions.debitorGroup')}) - ${d}` ?? '',
			}),
		);

		return selDebs?.concat(selDebGroups ?? []);
	}, [data, t]);

	const { handleSubmit, register, setValue, control } = useForm();

	const onSubmit = async (debitor: GetGroupsPermissions_groupsPermissions) => {
		try {
			if (typeof data !== 'undefined') {
				await updateGroupPermission({
					variables: {
						id: data.id,
						groupPermissionsList: data.groupPermissions,
						groupDebitors: debitor.groupDebitors.filter(grp => !grp.label.startsWith('(')) ?? [],
						groupDebitorGroups: debitor.groupDebitors.filter(grp => grp.label.startsWith('(')).map(gd => gd.value) ?? [],
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}

						const cachedRequest = cache.readQuery<GetGroupsPermissions>({
							query: GET_GROUPS_PERMISSIONS,
						});

						if (cachedRequest === null || cachedRequest.groupsPermissions === null) {
							return;
						}

						cache.writeQuery<GetGroupsPermissions>({
							query: GET_GROUPS_PERMISSIONS,
							data: {
								groupsPermissions: cachedRequest.groupsPermissions.map(grp => (grp.id === cacheData.updateGroupPermission.id ? cacheData.updateGroupPermission : grp)),
							},
						});
					},
				});
			}
		} catch (e) {
			console.log(e);
		}

		close();
	};

	const debitorHandler = (value: ValueType<SelectOption, true>): void => {
		const values: SelectOption[] = Array.isArray(value) ? value.map(v => ({ value: v.value, label: v.label })) : [];
		setValue('groupDebitors', values, { shouldValidate: true });
		setSelectedDebitors(values);
	};

	const customStyles = {
		indicatorSeparator: () => ({
			display: 'none',
		}),
		dropdownIndicator: () => ({
			display: 'none',
		}),
		control: (provided: CSSProperties) => ({
			...provided,
			border: 'none',
			fontSize: '1rem',
			outline: '0px',
		}),
		valueContainer: (provided: CSSProperties, state: ControlProps<{ label: string; value: string }, true>) => ({
			...provided,
			padding: '4px',
			outline: state.isFocused ? '0px' : '0px',
		}),
	};

	return (
		<Modal
			title="groupPermissions.adGroupDebitor"
			visible
			close={close}
			body={
				<div className="text-blue">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="min-h-64 flex flex-col">
							<div>
								<Controller
									name="groupDebitors"
									control={control}
									render={() => (
										<Select
											isMulti
											options={debitors?.filter(d => d.label.toLowerCase().includes(searchText.toLowerCase())) ?? []}
											closeMenuOnSelect={false}
											noOptionsMessage={inputValue =>
												inputValue.inputValue === '' ? t('groupPermissions.noDebitor') : `${t('groupPermissions.noDebitorWithName')} "${inputValue.inputValue}"`
											}
											placeholder={<div>{t('groupPermissions.searchDebitors')}</div>}
											styles={customStyles}
											inputRef={register}
											onChange={val => debitorHandler(val)}
											onInputChange={val => setSearchText(val)}
											defaultValue={selectedDebs}
											className="border-1 rounded-default border-gray-600"
										/>
									)}
								/>
								<p className="mt-1 text-sm font-medium">
									<FontAwesomeIcon icon={faInfoCircle} className="mr-px" /> {t('groupPermissions.debitorGroupInfo')}
								</p>
							</div>
							<div className="flex-grow-default mt-2">
								<FormFieldHeader title="groupPermissions.selectedDebitors" />
								<ul className="list-disc pl-6">
									{selectedDebitors &&
										selectedDebitors.map((d, i) => {
											return <li key={i}>{d.label.startsWith('(') ? d.label : `${d.label} (${d.value})`}</li>;
										})}
								</ul>
							</div>

							<div>
								<Button success submit text="common.save" loading={updateLoading} className="mt-2" />
							</div>
						</div>
					</form>
				</div>
			}
		/>
	);
};

export default ADGroupDebitorModal;
