import React from 'react';
import { useQuery } from '@apollo/client';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { GetAdminDamageCategories, GetAdminDamageCauses, GetQuestions, GetQuestions_questions } from '../../../GraphQL';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { tokenizeStringWithQuotesBySpaces } from '../../../helper';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import VisitationModal from './VisitationModal';
import VisitationFilters from './VisitationFilters';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';

const GET_QUESTIONS = loader('src/GraphQL/VisitationQuestions/GetQuestions.gql');
const GET_DAMAGE_CAUSES = loader('src/GraphQL/DamageCause/GetAdminDamageCauses.gql');
const GET_DAMAGE_CATEGIES = loader('src/GraphQL/DamageCategory/GetAdminDamageCategories.gql');

export interface VisitationFilter {
	category: string | undefined;
	cause: string | undefined;
}

const Visitation: React.FC = () => {
	const [createQuestion, setCreateQuestion] = React.useState(false);
	const [updateQuestion, setUpdateQuestion] = React.useState(false);
	const [deleteQuestion, setDeleteQuestion] = React.useState(false);

	const [filter, setFilter] = React.useState<VisitationFilter>({
		category: '',
		cause: '',
	});

	const [filterTerm, setFilterTerm] = useDebouncedState<string>('', 100);

	const [questionData, setQuestionData] = React.useState<GetQuestions_questions>();

	const { data, loading } = useQuery<GetQuestions>(GET_QUESTIONS, {
		fetchPolicy: 'cache-and-network',
	});

	const { data: categoriesList } = useQuery<GetAdminDamageCategories>(GET_DAMAGE_CATEGIES, {
		fetchPolicy: 'cache-and-network',
	});

	const { data: causesList } = useQuery<GetAdminDamageCauses>(GET_DAMAGE_CAUSES, {
		fetchPolicy: 'cache-and-network',
	});

	const catDropDown = React.useMemo(() => categoriesList?.damageCategoriesAdmin.map((cat): SelectOption => ({ value: cat.id, label: cat.name })) ?? [], [categoriesList]);
	const cuaseDropDown = React.useMemo(
		() => causesList?.damageCausesAdmin.filter(f => filter.category && f.category.id === filter.category).map((cat): SelectOption => ({ value: cat.id, label: cat.name })) ?? [],
		[causesList, filter],
	);

	const questions = React.useMemo(
		() => data?.questions.filter(f => (filter.category === '' ? true : filter.cause !== '' ? f.damageCause.id === filter.cause : f.damageCategory.id === filter.category)) ?? [],
		[data, filter],
	);

	const fuse = React.useMemo(
		() =>
			new Fuse(questions, {
				shouldSort: true,
				threshold: 0.1,
				ignoreLocation: true,
				findAllMatches: true,
				keys: ['damageCategory.name', 'damageCause.name', 'question'],
			}),
		[questions],
	);

	const filteredQuestions =
		filterTerm.length > 0
			? fuse
					.search({
						$and: tokenizeStringWithQuotesBySpaces(filterTerm).map((searchToken: string) => {
							const orFields: Fuse.Expression[] = [{ 'damageCategory.name': searchToken }, { 'damageCause.name': searchToken }, { question: searchToken }];

							return {
								$or: orFields,
							};
						}),
					})
					.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
					.map(v => v.item)
			: questions;

	return (
		<div>
			<header className="flex">
				<div className="w-3/4">
					<VisitationFilters setFilterTerm={setFilterTerm} setFilter={setFilter} catDropDown={catDropDown} causeDropDown={cuaseDropDown} />
				</div>
				<div className="flex w-1/4 justify-end">
					<Button primary text="visitationQuestion.create" icon={faPlus} onClick={() => setCreateQuestion(true)} className="mb-2" />
				</div>
			</header>

			<div className="text-blue relative bg-white pb-1">
				{loading ? (
					<div className="h-40">
						<Loading />
					</div>
				) : (
					<Table
						data={filteredQuestions}
						columns={[
							{
								label: 'visitationQuestion.damageCategory',
								selectFn: q => <p>{q.damageCategory.name}</p>,
								sortFn: (a, b) => (a.damageCategory.name ?? '').localeCompare(b.damageCategory.name ?? ''),
							},
							{
								label: 'visitationQuestion.damageCause',
								selectFn: q => <p>{q.damageCause.name}</p>,
								sortFn: (a, b) => (a.damageCause.name ?? '').localeCompare(b.damageCause.name ?? ''),
							},
							{
								label: 'visitationQuestion.question',
								noTruncate: true,
								selectFn: q => <p className="py-1">{q.question}</p>,
								sortFn: (a, b) => (a.question ?? '').localeCompare(b.question ?? ''),
							},
							{
								label: 'common.edit',
								classNameTh: 'text-right',
								selectFn: q => (
									<div className="flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faEdit}
											size="lg"
											onClick={() => {
												setQuestionData(q);
												setUpdateQuestion(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
							{
								label: 'common.delete',
								classNameTh: 'text-right',
								selectFn: q => (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setQuestionData(q);
												setDeleteQuestion(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
						]}
						keySelector={q => q.id}
					/>
				)}
			</div>

			{createQuestion && (
				<VisitationModal
					open={createQuestion}
					categoriesList={categoriesList?.damageCategoriesAdmin ?? []}
					causesList={causesList?.damageCausesAdmin ?? []}
					close={() => setCreateQuestion(false)}
					submitCb={() => {
						setCreateQuestion(false);
					}}
				/>
			)}

			{updateQuestion && (
				<VisitationModal
					edit
					open={updateQuestion}
					categoriesList={categoriesList?.damageCategoriesAdmin ?? []}
					causesList={causesList?.damageCausesAdmin ?? []}
					close={() => setUpdateQuestion(false)}
					data={questionData}
					submitCb={() => {
						setUpdateQuestion(false);
					}}
				/>
			)}

			{deleteQuestion && (
				<VisitationModal
					erase
					open={deleteQuestion}
					categoriesList={categoriesList?.damageCategoriesAdmin ?? []}
					causesList={causesList?.damageCausesAdmin ?? []}
					close={() => setDeleteQuestion(false)}
					data={questionData}
					submitCb={() => {
						setDeleteQuestion(false);
					}}
				/>
			)}
		</div>
	);
};

export default Visitation;
