import React from 'react';
import Modal from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import { useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
	CreateInputField,
	CreateInputFieldVariables,
	DeleteInputField,
	DeleteInputFieldVariables,
	GetInputFieldsAll_inputFieldsAll,
	Permissions,
	UpdateAdminInputField,
	UpdateAdminInputFieldVariables,
} from '../../GraphQL';
import { useTranslation } from 'react-i18next';
import Input from '@ssg/common/Components/Input';

interface FieldType {
	key: string;
	required: boolean;
	permissions: [Permissions | boolean];
}

interface Props {
	open: boolean;
	close: () => void;
	submitCb: () => void;
	fieldData?: GetInputFieldsAll_inputFieldsAll;
	edit?: boolean;
	erase?: boolean;
}

const CREATE_INPUT_FIELD = loader('../../GraphQL/InputFields/CreateInputField.gql');
const UPDATE_INPUT_FIELD = loader('../../GraphQL/InputFields/UpdateAdminInputField.gql');
const DELETE_INPUT_FIELD = loader('../../GraphQL/InputFields/DeleteInputField.gql');

const FieldModal: React.FC<Props> = ({ open, close, submitCb, fieldData, edit = false, erase = false }) => {
	const { t } = useTranslation();

	const [createInputField, { loading: createLoading }] = useMutation<CreateInputField, CreateInputFieldVariables>(CREATE_INPUT_FIELD);
	const [updateInputField, { loading: updateLoading }] = useMutation<UpdateAdminInputField, UpdateAdminInputFieldVariables>(UPDATE_INPUT_FIELD);
	const [deleteInputField, { loading: deleteLoading }] = useMutation<DeleteInputField, DeleteInputFieldVariables>(DELETE_INPUT_FIELD);

	const permissionMap = new Map();

	for (const p in Permissions) {
		permissionMap.set(p, {
			value: p,
			name: p.toString().toLowerCase().replaceAll('_', ' '),
		});
	}

	const permissions = Array.from(permissionMap.values());
	console.log(permissions);

	const { handleSubmit, register } = useForm();

	const onSubmit = async (field: FieldType) => {
		const permissionsList: Permissions[] = [];
		field.permissions.map(f => {
			if (typeof f !== 'boolean') {
				permissionsList.push(f);
			}
		});

		if (edit) {
			console.log('edit');
			console.log(field);

			try {
				await updateInputField({
					variables: {
						key: field.key,
						required: field.required,
						permissionsList: permissionsList,
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			console.log('create');
			console.log(field);

			try {
				await createInputField({
					variables: {
						inputField: {
							key: field.key,
							required: field.required,
							permissionsList: permissionsList,
						},
					},
				});
			} catch (e) {
				console.log(e);
			}
		}

		submitCb?.();
	};

	return (
		<Modal
			title={(erase && 'fieldOverview.delete') || (edit && 'fieldOverview.update') || 'fieldOverview.create'}
			// size={ModalSize.SMALL}
			visible={open}
			close={close}
			body={
				erase ? (
					<div className="text-blue-default flex flex-col">
						<div className="capitalize">{fieldData?.key.toLowerCase().replaceAll('_', ' ')}</div>
						<div className="mt-2">
							<div className="font-semibold">{t('fieldOverview.permissions')}:</div>
							{fieldData?.permissions.map((p, i) => {
								return (
									<div className="capitalize" key={i}>
										{fieldData.permissions.length > 1 && p === Permissions.NONE ? '' : p.toLowerCase().replaceAll('_', ' ')}
									</div>
								);
							})}
						</div>
						<div>
							<Checkbox disabled name={'required'} title="fieldOverview.required" defaultChecked={fieldData?.required} className="mt-4" />
						</div>

						<Button
							danger
							submit
							text="fieldOverview.delete"
							loading={deleteLoading}
							onClick={() => {
								deleteInputField({
									variables: {
										key: fieldData?.key ?? '',
									},
								});
								submitCb?.();
							}}
							className="mt-4"
						/>
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="flex w-full lg:w-full">
							<div className="mr-6 w-full lg:w-full">
								<Input name="key" title="fieldOverview.translationKey" innerRef={register} defaultValue={fieldData?.key} className="mr-2 w-full lg:w-full" />
							</div>

							<div className="mr-6 w-full lg:w-full">
								<Checkbox name="required" title="fieldOverview.required" innerRef={register} defaultChecked={fieldData?.required} className="mt-12" />
							</div>
						</div>

						<FormFieldHeader title="fieldOverview.permissions" />
						<div className="flex flex-row flex-wrap">
							{permissions.map((p, i) => {
								return (
									<div key={i} className="w-1/2">
										<Checkbox
											name={`permissions[${i}]`}
											title={p.name}
											value={p.value}
											innerRef={register}
											defaultChecked={(fieldData?.permissions.length ?? 0) > 1 && p.value !== Permissions.NONE ? fieldData?.permissions.includes(p.value) : false}
											className="mt-2 mr-3 capitalize"
										/>
									</div>
								);
							})}
						</div>

						<Button success submit text="common.save" className="mt-3" loading={edit ? updateLoading : createLoading} />
					</form>
				)
			}
		/>
	);
};

export default FieldModal;
