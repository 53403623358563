import React from 'react';
import { useGetUsersQuery } from '@ssg/common/GraphQL/indexV2';
import Header from '@ssg/common/Components/Header';
import Box from 'Components/Layout/Box';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import Table from '@ssg/common/Components/Table';
import dateToDateOnlyString, { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Datepicker from '@ssg/common/Components/Datepicker';
import { useTranslation } from 'react-i18next';
import Button from '@ssg/common/Components/Button';
import { CSVLink } from 'react-csv';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/pro-regular-svg-icons';

const UsersOverview: React.FC = (): React.ReactElement => {

	const { t } = useTranslation();

	const [selectedDate, setSelectedDate] = React.useState<Date>();

	const { data, loading } = useGetUsersQuery({
		variables: {
			external: true,
			lastUpdatedBefore: selectedDate ? formatDateForInput(selectedDate) : selectedDate,
		},
	});

	return (
		<>
			<Header title="users.externals" />
			<BoxContainer>
				<Box full>
					<div className="flex flex-row justify-between">
						<div>
							<Datepicker
								name=''
								title='users.lastUpdatedBefore'
								onBlur={e => e.currentTarget.valueAsDate && setSelectedDate(e.currentTarget.valueAsDate)}
							/>
						</div>
						<div className="pr-3 pt-3">
							<CSVLink
								data={data?.users ?? []}
								separator={';'}
								filename={`${t('users.externals')}-${formatDate(new Date())}.csv`}
								className="text-hover-blue flex items-center justify-center text-sm font-medium focus:outline-none text-blue"
							>
								<FontAwesomeIcon icon={faFileCsv} className="mr-2" />
								{t('log.exportForExcel')}
							</CSVLink>
						</div>
					</div>
					<Table
						data={data?.users ?? []}
						loading={loading}
						columns={[
							{
								label: 'common.name',
								selectFn: c => <p className="py-2">{c.name} </p>,
								sortFn: (a, b) => a.name.localeCompare(b.name),
							},
							{
								label: 'common.email',
								selectFn: c => <p className="py-2">{c.email} </p>,
								sortFn: (a, b) => a.email.localeCompare(b.email),
							},
							{
								label: 'common.lastLogin',
								selectFn: c => <p className="py-2">{dateToDateOnlyString(new Date(c.updatedAt))} </p>,
								sortFn: (a, b) => (a.updatedAt ?? '').localeCompare(b.updatedAt ?? ''),
							},
							{
								label: 'common.phone',
								selectFn: c => <p className="py-2">{c.phone} </p>,
								sortFn: (a, b) => (a.phone ?? '').localeCompare(b.phone ?? ''),
							},
							{
								label: 'users.UserPrincipalName',
								selectFn: c => <p className="py-2">{c.id} </p>,
								sortFn: (a, b) => (a.id ?? '').localeCompare(b.id ?? ''),
							},

						]}
						keySelector={(c, i) => c.id + i}
					/>
				</Box>
			</BoxContainer>
		</>
	);
};

export default UsersOverview;