import { useQuery } from '@apollo/client';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import { loader } from 'graphql.macro';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SearchUserPermissions } from '../../GraphQL';

interface Props {
	closeFn: () => unknown;
}

const SEARCH_USERS = loader('../../GraphQL/GroupPermission/SearchUserPermissions.gql');

const UserPermissionsEvaluatorModal: React.FC<Props> = ({ closeFn }) => {
	const { t } = useTranslation();

	const [searchText, setSearchText] = useDebouncedState('', 100);
	const [selectedUser, setSelectedUser] = React.useState<SearchUserPermissions['searchUsers'][number]>();

	const { control } = useForm();

	const { loading, data } = useQuery<SearchUserPermissions>(SEARCH_USERS, {
		variables: {
			searchText: searchText,
		},
		skip: searchText.length === 0,
	});
	const options = React.useMemo<SelectOption[]>(
		() =>
			(data?.searchUsers ?? []).map(u => ({
				label: `${u.name} (${u.email})`,
				value: u.id,
			})),
		[data?.searchUsers],
	);

	return (
		<Modal
			visible
			size={ModalSize.MEDIUM}
			close={closeFn}
			title="common.userPermissions"
			body={
				<div className="">
					<SearchableSelect
						control={control}
						name="user"
						title="common.user"
						options={options}
						searchFn={searchText => setSearchText(searchText)}
						onSelect={value => {
							const user = data?.searchUsers.find(u => u.id === value);
							if (typeof user !== 'undefined') {
								setSelectedUser(user);
							}
						}}
						onBlur={clearFn => undefined}
						minInputLength={2}
						isLoading={loading}
						className="w-full"
					/>

					<hr className="mt-2 mb-1" />

					{typeof selectedUser === 'undefined' ? (
						<div className="h-40">{t('groupPermissions.noUserSelected')}</div>
					) : (
						<>
							<span>
								{selectedUser.name}&nbsp;({selectedUser.email})
							</span>
							<div className="font-mono">
								{selectedUser.permissions.length > 0 ? (
									selectedUser.permissions.map((p, i) => {
										return (
											<span>
												{i ? ', ' : ''}
												{t(`enumPermissions.${p}`)}
											</span>
										);
									})
								) : (
									<div>{t('groupPermissions.noPermissions')}</div>
								)}
							</div>
						</>
					)}
				</div>
			}
		/>
	);
};

export default UserPermissionsEvaluatorModal;
