import { useMutation, useQuery } from '@apollo/client';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import { loader } from 'graphql.macro';
import React from 'react';
import { GetAdminDepartments, GetGroupsPermissions, UpdateGroupPermission, UpdateGroupPermissionVariables } from '../../GraphQL';

interface Props extends Pick<GetGroupsPermissions['groupsPermissions'][number], 'id' | 'groupLocations' | 'groupDepartments'> {
	close: () => unknown;
}

const GET_DEPARTMENTS = loader('../../GraphQL/Department/GetAdminDepartments.gql');
const UPDATE_GROUP_PERMISSION = loader('../../GraphQL/GroupPermission/UpdateGroupPermission.gql');
const GET_GROUPS_PERMISSIONS = loader('../../GraphQL/GroupPermission/GetGroupsPermissions.gql');

type Department = GetAdminDepartments['departmentsAdmin'][number];
type LocationDepartmentMap = Array<
	Pick<Department['location'], 'id' | 'name'> & {
		selected: boolean;
		departments: Array<
			Pick<Department, 'id' | 'departmentNumber' | 'name'> & {
				selected: boolean;
			}
		>;
	}
>;

const ADGroupLocationDepartmentModal: React.FC<Props> = ({ id, groupLocations, groupDepartments, close }) => {
	const [selectedLocations, setSelectedLocations] = React.useState(groupLocations);
	const [selectedDepartments, setSelectedDepartments] = React.useState(groupDepartments);

	const { data } = useQuery<GetAdminDepartments>(GET_DEPARTMENTS, {
		fetchPolicy: 'network-only',
		variables: { useLimitations: false },
	});
	// const { data: locationsData } = useQuery<GetLocations>(GET_LOCATIONS, { fetchPolicy: 'network-only', variables: { useLimitations: false } });
	const [updateGroupPermission] = useMutation<UpdateGroupPermission, UpdateGroupPermissionVariables>(UPDATE_GROUP_PERMISSION);

	const locationDepartmentMap = React.useMemo(
		() =>
			(data?.departmentsAdmin ?? []).reduce<LocationDepartmentMap>((map, department) => {
				let location = map.find(l => l.id === department.location.id);
				if (typeof location === 'undefined') {
					const mapLength = map.push({
						id: department.location.id,
						selected: selectedLocations.includes(department.location.id),
						name: department.location.name,
						departments: [],
					});
					location = map[mapLength - 1];
				}

				location.departments.push({
					id: department.id,
					selected: selectedDepartments.includes(department.id),
					departmentNumber: department.departmentNumber,
					name: department.name,
				});

				map.sort((a, b) => a.name.localeCompare(b.name));
				return map;
			}, []),
		[data?.departmentsAdmin, selectedLocations, selectedDepartments],
	);

	// TODO: useEffect that deselects all departments in location?

	return (
		<Modal
			visible
			close={close}
			title="groupPermissions.adGroupLocationDepartment"
			size={ModalSize.LARGE}
			body={
				<div className="-my-4 flex flex-row flex-wrap">
					{locationDepartmentMap.map(location => (
						<div key={location.id} className="my-4 w-1/2">
							<Checkbox
								name={location.id}
								title={location.name}
								checkedControlled={selectedLocations.includes(location.id)}
								onChange={e => {
									const locationDepartments = location.departments.map(d => d.id);
									if (e.target.checked && !selectedLocations.includes(location.id)) {
										setSelectedLocations([...selectedLocations, location.id]);
										setSelectedDepartments([...selectedDepartments, ...locationDepartments]);
									} else if (!e.target.checked && selectedLocations.includes(location.id)) {
										setSelectedLocations(selectedLocations.filter(sl => sl !== location.id));
										setSelectedDepartments(selectedDepartments.filter(sd => !locationDepartments.includes(sd)));
									}
								}}
								className="text-2xl"
							/>

							{location.departments.map(department => (
								<div key={department.id}>
									<Checkbox
										name={department.id}
										title={department.name}
										checkedControlled={selectedDepartments.includes(department.id)}
										onChange={e => {
											if (e.target.checked && !selectedDepartments.includes(department.id)) {
												setSelectedDepartments([...selectedDepartments, department.id]);
											} else if (!e.target.checked && selectedDepartments.includes(department.id)) {
												setSelectedDepartments(selectedDepartments.filter(dl => dl !== department.id));
											}
										}}
										disabled={!location.selected}
									/>
								</div>
							))}
						</div>
					))}
				</div>
			}
			footer={
				<>
					<Button
						primary
						text="common.save"
						onClick={async () => {
							await updateGroupPermission({
								variables: {
									id,
									groupLocations: selectedLocations,
									groupDepartments: selectedDepartments,
								},
								update: (cache, { data: cacheData }): void => {
									if (typeof cacheData === 'undefined' || cacheData === null) {
										return;
									}

									const cachedRequest = cache.readQuery<GetGroupsPermissions>({
										query: GET_GROUPS_PERMISSIONS,
									});

									if (cachedRequest === null || cachedRequest.groupsPermissions === null) {
										return;
									}

									cache.writeQuery<GetGroupsPermissions>({
										query: GET_GROUPS_PERMISSIONS,
										data: {
											groupsPermissions: cachedRequest.groupsPermissions.map(grp => (grp.id === cacheData.updateGroupPermission.id ? cacheData.updateGroupPermission : grp)),
										},
									});
								},
							});
							close();
						}}
					/>
					<Button
						primary
						text="common.allowAll"
						onClick={async () => {
							await updateGroupPermission({
								variables: {
									id,
									groupLocations: [],
									groupDepartments: [],
								},
								update: (cache, { data: cacheData }): void => {
									if (typeof cacheData === 'undefined' || cacheData === null) {
										return;
									}

									const cachedRequest = cache.readQuery<GetGroupsPermissions>({
										query: GET_GROUPS_PERMISSIONS,
									});

									if (cachedRequest === null || cachedRequest.groupsPermissions === null) {
										return;
									}

									cache.writeQuery<GetGroupsPermissions>({
										query: GET_GROUPS_PERMISSIONS,
										data: {
											groupsPermissions: cachedRequest.groupsPermissions.map(grp => (grp.id === cacheData.updateGroupPermission.id ? cacheData.updateGroupPermission : grp)),
										},
									});
								},
							});
							close();
						}}
					/>
				</>
			}
		/>
	);
};
export default ADGroupLocationDepartmentModal;
