import Button from '@ssg/common/Components/Button';
import Modal from '@ssg/common/Components/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	visible: boolean;
	close: () => unknown;
	value: string;
	remove: (value: string) => Promise<unknown>;
}

const RemoveAutomaticInvoiceGroupPrefixModal: React.FC<Props> = ({ visible, close, value, remove }) => {
	const { t } = useTranslation();

	const [loading, setLoading] = React.useState(false);

	return (
		<Modal
			title="invoices.removeAutomaticInvoiceGroupPrefix"
			visible={visible}
			close={close}
			body={
				<div>
					<p className="text-blue">
						{t('common.confirmRemovalOf')} <strong>{value}</strong>?
					</p>
					<div className="mt-4 flex space-x-2">
						<Button
							danger
							text="common.remove"
							loading={loading}
							onClick={async () => {
								setLoading(true);
								await remove(value);
								setLoading(false);
							}}
							disabled={loading}
						/>
						<Button secondary text="common.cancel" onClick={() => close()} disabled={loading} />
					</div>
				</div>
			}
		/>
	);
};
export default RemoveAutomaticInvoiceGroupPrefixModal;
