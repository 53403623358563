import React from 'react';

import UnitOfMeasurement from './UnitOfMeasurement';

interface Props {
	onClick: (value: string) => void;
}

const unitOfMeasurements = ['m2', 'm3', 'liter', 'kg', 'cm'];

const UnitOfMeasurementSuggestions: React.FC<Props> = ({ onClick }): React.ReactElement => {
	return (
		<div className="mt-1 w-full gap-1">
			{unitOfMeasurements.map(uom => (
				<button
					className="bg-blue-xlight hover:bg-blue-lightcalm mr-2 rounded-lg px-2.5 py-0.5 py-1 px-3 text-xs font-semibold text-blue-800"
					onClick={e => {
						e.preventDefault();
						onClick(uom);
					}}
				>
					<UnitOfMeasurement value={uom} />
				</button>
			))}
		</div>
	);
};

export default UnitOfMeasurementSuggestions;
