import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		min: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const DamageCauseSchema = yup.object().shape({
	name: yup.string().required(),
	departments: yup.array().of(yup.string().required()).min(1).required(),
	category: yup.string().required(),
	business: yup.string().required(),
	track: yup.number().required(),
	urgent: yup.boolean().required(),
});
