import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		min: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const AddressSchema = yup.object().shape({
	road: yup.string().required(),

	houseNumber: yup.string().required(),

	floor: yup.string(),

	addressLineAlt: yup.string(),

	postalCode: yup.string().required(),

	city: yup.string().required(),

	country: yup.string().required(),
});
