import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { UpdateScreeningPositionText, UpdateScreeningPositionTextVariables, GetAdminScreeningPositionTexts, GetScreeningTemplates } from '../../../GraphQL';
import { ScreeningPositionTextSchema } from '../../../Schemas/ScreeningPositionTextSchema';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Textarea from '@ssg/common/Components/Textarea';
import Loading from '@ssg/common/Components/Loading';

const UPDATE_SCREENING_POSITION_TEXT = loader('src/GraphQL/Screening/UpdateScreeningPositionText.gql');
const GET_SCREENING_TEMPLATES = loader('src/GraphQL/Screening/GetScreeningTemplates.gql');

const NO_SCREENING_TEMPLATE = '__NO_SELECTION__';

interface Props {
	close: () => unknown;
	current: GetAdminScreeningPositionTexts['screeningPositionTexts'][number];
}

const ScreeningPositionTextEditModal: React.FC<Props> = ({ close, current }) => {
	const [selectedTemplateCode, setSelectedTemplateCode] = React.useState(current.templateCode);

	const { data: templateData, loading } = useQuery<GetScreeningTemplates>(GET_SCREENING_TEMPLATES);

	const [editScreeningPositionText] = useMutation<UpdateScreeningPositionText, UpdateScreeningPositionTextVariables>(UPDATE_SCREENING_POSITION_TEXT);

	const { handleSubmit, register, errors, formState } = useForm<{
		templateCode: string;
		position: string;
		text: string;
	}>({
		resolver: yupResolver(ScreeningPositionTextSchema),
		mode: 'all',
		defaultValues: {
			templateCode: current.templateCode,
			position: current.position,
			text: current.text,
		},
	});

	const submit = handleSubmit(async ({ templateCode, position, text }) => {
		await editScreeningPositionText({
			variables: {
				id: current.id,
				templateCode,
				position,
				text,
			},
		});
		close();
	});

	return (
		<Modal
			size={ModalSize.SMALL}
			title="common.edit"
			visible
			close={close}
			body={
				<>
					{loading ? (
						<div className="relative h-40">
							<Loading />
						</div>
					) : (
						<>
							<Dropdown
								required
								title="screening.screeningTemplate"
								name="templateCode"
								value={selectedTemplateCode}
								data={[
									{ label: '', value: NO_SCREENING_TEMPLATE },
									...(templateData?.screeningTemplates ?? []).map(st => ({
										label: st.templateName,
										value: st.templateCode,
									})),
								]}
								onChange={e => setSelectedTemplateCode(e.target.value)}
								innerRef={register}
							/>

							<Dropdown
								required
								title="screening.position"
								name="position"
								data={[
									...(templateData?.screeningTemplates.find(t => t.templateCode === selectedTemplateCode)?.lines ?? [])
										.map(l =>
											l.position
												? {
														label: `${l.title} (${l.position})`,
														value: l.position,
												  }
												: { label: '', value: '' },
										)
										.filter(so => so.value !== '' && so.label !== ''),
								]}
								innerRef={register}
								errorMessage={errors.position?.message}
							/>

							<Textarea required title="screening.helpText" name="text" rows={6} innerRef={register} errorMessage={errors.text?.message} />
						</>
					)}
				</>
			}
			footer={<Button success text="common.edit" onClick={submit} disabled={!formState.isValid} />}
		/>
	);
};
export default ScreeningPositionTextEditModal;
