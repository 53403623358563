import React from 'react';
import { loader } from 'graphql.macro';
import { Controller, useForm } from 'react-hook-form';
import {
	CreateDepartment,
	CreateDepartmentVariables,
	DeleteDepartment,
	DeleteDepartmentVariables,
	GetAdminDepartments_departmentsAdmin,
	UpdateDepartment,
	UpdateDepartmentVariables,
	GetAdminLocations,
	GetAdminLocations_locationsAdmin,
	GetAdminDepartments,
	SearchUserNames,
	SearchUserNamesVariables,
} from '../../../GraphQL';
import { yupResolver } from '@hookform/resolvers/yup';
import { IDepartment, IOperationManager } from '../../../Schemas/IDepartment';
import { DepartmentSchema } from '../../../Schemas/DepartmentSchema';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import TextButton from '@ssg/common/Components/TextButton';

const GET_DEPARTMENTS = loader('src/GraphQL/Department/GetAdminDepartments.gql');
const GET_LOCATIONS = loader('src/GraphQL/Locations/GetAdminLocations.gql');
const CREATE_LOCATION = loader('src/GraphQL/Department/CreateDepartment.gql');
const UPDATE_LOCATION = loader('src/GraphQL/Department/UpdateDepartment.gql');
const DELETE_LOCATION = loader('src/GraphQL/Department/DeleteDepartment.gql');
const SEARCH_USER_NAMES = loader('src/GraphQL/Users/SearchUserNames.gql');

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	data?: GetAdminDepartments_departmentsAdmin;
	erase?: boolean;
	submitCb?: () => unknown;
}

const setLocationsDropdownItems = (locations: GetAdminLocations_locationsAdmin[] | undefined): SelectOption[] => {
	if (locations) {
		const ddLocations = locations.map(
			(l): SelectOption => ({
				value: l?.id ?? '',
				label: l?.name ?? '',
			}),
		);

		return ddLocations;
	}

	return [];
};

function useSearchableUsers() {
	const [userSearchText, setUserSearchText] = useDebouncedState('', 100);
	const [searchForUsers, { data: searchedUsers, loading: isSearchingForUsers }] = useLazyQuery<SearchUserNames, SearchUserNamesVariables>(SEARCH_USER_NAMES);

	React.useEffect(() => {
		if (isSearchingForUsers) return;
		if (userSearchText.length < 2) return;

		searchForUsers({ variables: { searchText: userSearchText } });
	}, [userSearchText, isSearchingForUsers, searchForUsers]);

	return {
		setUserSearchText,
		searchedUsers: searchedUsers?.searchUsers ?? [],
		isSearchingForUsers,
	};
}

const DepartmentsModal: React.FC<Props> = ({ open, close, edit = false, data, erase, submitCb }): React.ReactElement => {
	const { data: locationData } = useQuery<GetAdminLocations>(GET_LOCATIONS);
	const [createDepartments, { loading: createLoading }] = useMutation<CreateDepartment, CreateDepartmentVariables>(CREATE_LOCATION);
	const [updateDepartments, { loading: updateLoading }] = useMutation<UpdateDepartment, UpdateDepartmentVariables>(UPDATE_LOCATION);
	const [deleteDepartments, { loading: deleteLoading }] = useMutation<DeleteDepartment, DeleteDepartmentVariables>(DELETE_LOCATION);

	const locations = React.useMemo(() => {
		return setLocationsDropdownItems(locationData?.locationsAdmin);
	}, [locationData?.locationsAdmin]);

	const { handleSubmit, register, errors, control } = useForm<IDepartment>({
		resolver: yupResolver(DepartmentSchema),
		defaultValues: {
			operationManagers:
				data?.operationManagers.map(x => ({
					id: x.id,
					name: x.name,
				})) ?? [],
		},
	});

	const { setUserSearchText, searchedUsers, isSearchingForUsers } = useSearchableUsers();

	const onSubmit = async (department: IDepartment) => {
		console.log(department);

		if (edit) {
			try {
				await updateDepartments({
					variables: {
						departmentNumber: Number(department.departmentNumber),
						id: data?.id ?? '',
						name: department.name,
						operationManagers: department.operationManagers.map(d => d.id),
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await createDepartments({
					variables: {
						department: {
							name: department.name,
							departmentNumber: Number(department.departmentNumber),
							locationId: department.location,
							operationManagers: department.operationManagers.map(d => d.id),
						},
					},
				});
			} catch (e) {
				console.log(e);
			}
		}

		submitCb?.();
	};

	return (
		<Modal
			title={(erase && 'departmentsLocations.delete') || (data && 'departmentsLocations.update') || 'departmentsLocations.create'}
			size={ModalSize.SMALL}
			visible={open}
			close={close}
			body={
				erase && typeof data !== 'undefined' ? (
					<div>
						<FormFieldHeader title="departmentsLocations.wantToDelete" />
						<div className="flex flex-col">
							<div>{data.name}</div>

							<Button
								danger
								submit
								text="departmentsLocations.delete"
								loading={deleteLoading}
								onClick={async () => {
									await deleteDepartments({
										variables: {
											id: data.id,
										},
										update: (cache, { data: cacheData }): void => {
											if (typeof cacheData === 'undefined' || cacheData === null) {
												return;
											}
											const cachedRequest = cache.readQuery<GetAdminDepartments>({ query: GET_DEPARTMENTS });

											if (cachedRequest === null || cachedRequest.departmentsAdmin === null) {
												return;
											}

											cache.writeQuery<GetAdminDepartments>({
												query: GET_DEPARTMENTS,
												data: {
													departmentsAdmin: cachedRequest.departmentsAdmin.filter(d => d.id !== data.id),
												},
											});
										},
									});
									close();
								}}
								className="mt-4"
							/>
						</div>
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<Input title="common.name" name="name" innerRef={register} required defaultValue={data?.name} errorMessage={errors.name?.message ?? ''} />

						<Input
							title="departmentsLocations.departmentNumber"
							name="departmentNumber"
							innerRef={register}
							required
							defaultValue={data?.departmentNumber}
							errorMessage={errors.departmentNumber?.message ?? ''}
						/>

						<Controller
							control={control}
							name="operationManagers"
							render={({ value: selected, onChange }: { value: IOperationManager[]; onChange(value: IOperationManager[]): void }) => (
								<>
									<SearchableSelect
										control={control}
										name="operationsManagers"
										title="departmentsLocations.operationsManagers"
										options={searchedUsers
											.filter(u => !selected.some(s => s.id === u.id))
											.map(u => ({
												value: u.id,
												label: u.name,
											}))}
										searchFn={setUserSearchText}
										onSelect={value => {
											const user = searchedUsers.find(u => u.id === value);

											if (user == null) {
												console.error('Could not find user in state after selecting it');
												return;
											}

											onChange([
												...selected,
												{
													id: user.id,
													name: user.name,
												},
											]);
										}}
										onBlur={clearFn => clearFn()}
										minInputLength={2}
										isLoading={isSearchingForUsers}
										helpText="case.helpText.drivenBy"
									/>

									<ul className="w-full space-y-1 py-1">
										{selected.map(user => (
											<li key={user.id} className="flex items-center">
												<div className="inline-block">
													<TextButton icon={faTimes} onClick={() => onChange(selected.filter(u => u.id !== user.id))} />
												</div>
												<span className="ml-1">{user.name}</span>
											</li>
										))}
									</ul>
								</>
							)}
						/>

						{!edit && <Dropdown name="location" innerRef={register} title="departmentsLocations.parentLocation" required defaultValue={data?.location?.id} data={locations} />}

						<Button success submit text={data ? 'departmentsLocations.update' : 'departmentsLocations.create'} loading={createLoading || updateLoading} className="mt-4" />
					</form>
				)
			}
		/>
	);
};

export default DepartmentsModal;
