import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { faEdit, faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';

import Loading from '@ssg/common/Components/Loading';
import { removeTypenameGeneric } from '@ssg/common/Helpers/typenameHelper';
import Button from '@ssg/common/Components/Button';
import Tabs from '@ssg/common/Components/Tabs';
import Box from '../../Components/Layout/Box';
import Header from '@ssg/common/Components/Header';
import DrivingSlipQuestionnaireTab from './DrivingSlipQuestionnaireTab';
import {
	BaseQuestionnaireTemplateFragment as Questionnaire,
	BaseQuestionnaireTemplateFragment_sections as Section,
	BaseQuestionnaireTemplateFragment_sections_choices as Choice,
	CreateQuestionnaireTemplate,
	CreateQuestionnaireTemplateVariables,
	GetAllQuestionnaireTemplates,
	UpdateQuestionnaireTemplate,
	UpdateQuestionnaireTemplateVariables,
	DeleteQuestionnaireTemplates,
	DeleteQuestionnaireTemplatesVariables,
	GetDrivingSlipCategories,
	QuestionnaireTemplateInput,
} from '../../GraphQL';
import ItemManagerModal from '../../Components/ItemManager/ItemManager';
import { ItemModel } from '../../Components/ItemManager/ItemModel';
import Dropdown from '@ssg/common/Components/Dropdown';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CREATE_TEMPLATE = loader('../../GraphQL/QuestionnaireTemplate/CreateQuestionnaireTemplate.gql');
const UPDATE_TEMPLATE = loader('../../GraphQL/QuestionnaireTemplate/UpdateQuestionnaireTemplate.gql');
const DELETE_TEMPLATES = loader('../../GraphQL/QuestionnaireTemplate/DeleteQuestionnaireTemplates.gql');
const GET_ALL_TEMPLATES = loader('../../GraphQL/QuestionnaireTemplate/GetAllQuestionnaireTemplates.gql');
const GET_DRIVING_SLIPS_CATEGORIES = loader('../../GraphQL/DrivingSlipCategory/GetDrivingSlipCategory.gql');

const emptyQuestionnaireId = '';

const DrivingSlipQuestionnaireOverview: React.FC = () => {
	const { t } = useTranslation();

	const [questionnaires, setQuestionnaires] = React.useState<Questionnaire[]>([]);

	const [activeQuestionnaire, setActiveQuestionnaire] = React.useState<string>();
	const activeQuestionnaireObject = useMemo(() => questionnaires.find(q => q.label === activeQuestionnaire), [activeQuestionnaire, questionnaires]);

	const [activeSection, setActiveSection] = React.useState<string>();

	const [visibleQuestionnairesEditor, setVisibleQuestionnairesEditor] = React.useState<boolean>();

	const [createQuestionnaireTemplate, { loading: loadingCreateQuestionnaireTemplate }] = useMutation<CreateQuestionnaireTemplate, CreateQuestionnaireTemplateVariables>(CREATE_TEMPLATE);

	const [updateQuestionnaireTemplate, { loading: loadingUpdateQuestionnaireTemplate }] = useMutation<UpdateQuestionnaireTemplate, UpdateQuestionnaireTemplateVariables>(UPDATE_TEMPLATE);

	const [deleteQuestionnaireTemplates, { loading: loadingDeleteQuestionnaireTemplates }] = useMutation<DeleteQuestionnaireTemplates, DeleteQuestionnaireTemplatesVariables>(DELETE_TEMPLATES);

	const allQuestionnaireTemplates = useRef<Questionnaire[]>([]);

	const { loading: loadingLatestQuestionnaireTemplate } = useQuery<GetAllQuestionnaireTemplates>(GET_ALL_TEMPLATES, {
		// Never cache the latest questionnaire template, always fetch from network
		fetchPolicy: 'no-cache',
		nextFetchPolicy: 'no-cache',
		onCompleted: data => {
			setQuestionnaires(data.allQuestionnaireTemplates);
			allQuestionnaireTemplates.current = data.allQuestionnaireTemplates;

			if (!activeQuestionnaire) {
				setActiveQuestionnaire(data.allQuestionnaireTemplates[0]?.label);
			}
		},
	});
	const { loading: loadingDrivingSlipCategories, data: drivingSlipCategoryData } = useQuery<GetDrivingSlipCategories>(GET_DRIVING_SLIPS_CATEGORIES);

	const questionnaireAsItems = React.useMemo(() => questionnaires.map((q, index) => ({ id: index, text: q.label })), [questionnaires]);

	const handleOnSaveTemplate = async () => {
		const questionnairesWithMissingDrivingSlipCategory = questionnaires.filter(q => !q.drivingSlipCategories || q.drivingSlipCategories.length === 0).map(q => q.label);

		if (questionnairesWithMissingDrivingSlipCategory.length > 0) {
			alert(t('questionnaire.drivingSlipCategoryRequiredFor') + ` ${questionnairesWithMissingDrivingSlipCategory.join(', ')}`);
			return;
		}

		let hasPerformedCRUDOperation = false;

		// Delete questionnaires flow. Do this first to avoid deleting newly created questionnaires.
		const questionnairesToDelete = allQuestionnaireTemplates.current.filter(x => !questionnaires.some(y => y.id === x.id));
		if (questionnairesToDelete.length > 0) {
			await deleteQuestionnaireTemplates({
				variables: {
					questionnaireTemplateIds: questionnairesToDelete.map(q => q.id),
				},
			});

			hasPerformedCRUDOperation = true;
		}

		// Create/Update questionnaire flow
		for (const questionnaireToUpdateOrCreate of questionnaires) {
			// Avoids https://eslint.org/docs/latest/rules/no-loop-func
			const createCallback = (questionnaireToReturn: Questionnaire) => (questionnaire: Questionnaire) => {
				if (questionnaire.label === questionnaireToUpdateOrCreate.label) {
					return questionnaireToReturn;
				}

				return questionnaire;
			};

			const data = removeTypenameGeneric<QuestionnaireTemplateInput>({
				label: questionnaireToUpdateOrCreate.label,
				sections: questionnaireToUpdateOrCreate.sections,
				damageCauses: questionnaireToUpdateOrCreate.damageCauses,
				drivingSlipCategories: questionnaireToUpdateOrCreate.drivingSlipCategories,
			});

			// Create new questionnaire
			if (questionnaireToUpdateOrCreate.id === emptyQuestionnaireId) {
				const createResult = await createQuestionnaireTemplate({
					variables: {
						questionnaireTemplate: data,
					},
				});

				hasPerformedCRUDOperation = true;

				if (createResult.data) {
					const callback = createCallback(createResult.data.createQuestionnaireTemplate);
					setQuestionnaires(current => current.map(callback));
					allQuestionnaireTemplates.current = allQuestionnaireTemplates.current.concat([createResult.data.createQuestionnaireTemplate]);
				}
			} else {
				// Otherwise update existing questionnaire
				const originalTemplate = allQuestionnaireTemplates.current.find(q => q.id === questionnaireToUpdateOrCreate.id);

				if (!originalTemplate) {
					console.error('Unable to find original template');
					continue;
				}

				const originalData = removeTypenameGeneric<QuestionnaireTemplateInput>({
					label: originalTemplate.label,
					sections: originalTemplate.sections,
					damageCauses: originalTemplate.damageCauses,
					drivingSlipCategories: originalTemplate.drivingSlipCategories,
				});

				// Cheap way of comparing questionnaire data to identity whether update is required
				if (JSON.stringify(data) === JSON.stringify(originalData)) {
					console.log(`No changes found for questionnaire tab: ${data.label}.`);
					continue;
				}

				console.log('Updating questionnaire', {
					questionnaireToUpdateOrCreate,
					data,
				});

				const updateResult = await updateQuestionnaireTemplate({
					variables: {
						id: questionnaireToUpdateOrCreate.id,
						...data,
					},
				});

				hasPerformedCRUDOperation = true;

				if (updateResult.data) {
					const callback = createCallback(updateResult.data.updateQuestionnaireTemplate);
					setQuestionnaires(current => current.map(callback));
					allQuestionnaireTemplates.current = allQuestionnaireTemplates.current.map(callback);
				}
			}
		}

		if (!hasPerformedCRUDOperation) {
			alert(t('questionnaire.noChangesHaveBeenMade'));
		}
	};

	const handleOnChangeChoices = (questionnaireName: string, sectionName: string, newChoices: Choice[]): void => {
		setQuestionnaires([
			...questionnaires.map(q =>
				modifySectionsBasedOnQuestionnaire(questionnaireName, q, () =>
					q.sections.map(section => ({
						...section,
						choices: section.label === sectionName ? newChoices : section.choices,
					})),
				),
			),
		]);

		setActiveQuestionnaire(questionnaireName);
		setActiveSection(sectionName);
	};

	const handleOnChangeSections = (questionnaireName: string, updatedSections: Section[], sectionName?: string): void => {
		setQuestionnaires([...questionnaires.map(q => modifySectionsBasedOnQuestionnaire(questionnaireName, q, () => updatedSections))]);
		setActiveQuestionnaire(questionnaireName);
		setActiveSection(sectionName);
	};

	const openQuestionnairesEditor = () => {
		setVisibleQuestionnairesEditor(true);
	};

	const handleOnCloseQuestionnairesEditor = () => {
		setVisibleQuestionnairesEditor(false);
	};

	const handleOnSubmitQuestionnairesEditor = (updatedItems: ItemModel[]) => {
		const getNewActiveQuestionnaireAndSection = (newQuestionnaires: Questionnaire[]): { questionnaire?: string; section?: string } => {
			const activeQuestionnaireItem = questionnaireAsItems.find(i => i.text === activeQuestionnaire);
			const updatedQuestionnaireItem = updatedItems.find(i => i.id === activeQuestionnaireItem?.id);

			// If the questionnaire(s) has been been deleted then set active to undefined
			if (newQuestionnaires.length === 0) {
				return { questionnaire: undefined, section: undefined };
			}

			if (!activeQuestionnaireItem || !updatedQuestionnaireItem) {
				return {
					questionnaire: newQuestionnaires[0].label,
					section: undefined,
				};
			}

			const questionnaireLabel = updatedQuestionnaireItem.text === activeQuestionnaireItem.text ? activeQuestionnaire : updatedQuestionnaireItem.text;
			return {
				questionnaire: questionnaireLabel,
				section: activeSection,
			};
		};

		const newQuestionnaires = updatedItems.reduce((newQuestionnaires: Questionnaire[], updatedItem: ItemModel) => {
			const questionnaireItem = questionnaireAsItems.find(i => i.id === updatedItem.id);
			if (!questionnaireItem) {
				const newQuestionnaireProps = {
					id: emptyQuestionnaireId,
					version: 1,
					createdAt: '',
					updatedAt: '',
				};
				return newQuestionnaires.concat([
					{
						label: updatedItem.text,
						sections: [],
						damageCauses: [],
						drivingSlipCategories: [],
						...newQuestionnaireProps,
					},
				]);
			}

			const questionnaire = questionnaires.find(t => t.label === questionnaireItem.text);

			if (questionnaire) {
				newQuestionnaires.push({
					...questionnaire,
					label: updatedItem.text,
				});
			}

			return newQuestionnaires;
		}, []);

		const newActiveQuestionnaireAndSection = getNewActiveQuestionnaireAndSection(newQuestionnaires);
		setQuestionnaires(newQuestionnaires);
		setActiveQuestionnaire(newActiveQuestionnaireAndSection.questionnaire);
		setActiveSection(newActiveQuestionnaireAndSection.section);
		setVisibleQuestionnairesEditor(false);
	};

	// Modify the sections of the questionnaire - if the questionnaire name matches - by using the selector func
	const modifySectionsBasedOnQuestionnaire = (questionnaireName: string, questionnaire: Questionnaire, sectionsToSetFunc: () => Section[]): Questionnaire => {
		if (questionnaire.label !== questionnaireName) {
			return {
				...questionnaire,
			};
		}

		return {
			...questionnaire,
			sections: sectionsToSetFunc(),
		};
	};

	const filteredDrivingSlipCategories: SelectOption[] = React.useMemo(() => {
		return (
			drivingSlipCategoryData?.drivingSlipCategories.map(c => ({
				value: c.code,
				label: c.name,
			})) ?? []
		);
	}, [drivingSlipCategoryData]);

	const [activeDrivingSlipCategory, setActiveDrivingSlipCategory] = useState<string>('');

	const handleAddDrivingSlipCategory = () => {
		if (!activeDrivingSlipCategory) {
			alert(t('questionnaire.drivingSlipCategoryRequired'));
			return;
		}

		const drivingSlipCategoryUsed = questionnaires.some(q => q.drivingSlipCategories.some(id => id === activeDrivingSlipCategory));
		if (drivingSlipCategoryUsed) {
			alert(t('questionnaire.drivingSlipCategoryAlreadyUsed'));
			return;
		}

		setQuestionnaires([
			...questionnaires.map(q => {
				if (q.label !== activeQuestionnaire) {
					return q;
				}

				return {
					...q,
					drivingSlipCategories: [...q.drivingSlipCategories, activeDrivingSlipCategory],
				};
			}),
		]);
	};

	const handleRemoveDrivingSlipCategory = (drivingSlipCategoryCode: string | undefined) => {
		if (!drivingSlipCategoryCode) {
			return;
		}

		setQuestionnaires([
			...questionnaires.map(q => {
				if (q.label !== activeQuestionnaire) {
					return q;
				}

				const drivingSlipCategories = q.drivingSlipCategories.filter(id => id !== drivingSlipCategoryCode);
				return { ...q, drivingSlipCategories: drivingSlipCategories };
			}),
		]);
	};

	return (
		<>
			{(loadingLatestQuestionnaireTemplate ||
				loadingCreateQuestionnaireTemplate ||
				loadingUpdateQuestionnaireTemplate ||
				loadingDeleteQuestionnaireTemplates ||
				loadingDrivingSlipCategories) && <Loading />}
			<div>
				<Header title="questionnaire.overviewTitle" />

				<div className="px-10 pb-10">
					<Box full>
						<div className="flex">
							<div className="text-blue text-lg font-semibold">{t('questionnaire.overviewTitle')}</div>

							{questionnaires.length > 0 && (
								<div className="ml-auto flex space-x-1 text-sm">
									<Button primary icon={faEdit} onClick={() => openQuestionnairesEditor()}>
										{t('questionnaire.tabsEditor')}
									</Button>
									<Button success icon={faSave} onClick={() => handleOnSaveTemplate()}>
										{t('common.save')}
									</Button>
								</div>
							)}
						</div>
						{questionnaires.length > 0 && (
							<Tabs
								full
								titles={questionnaires.map(t => t.label)}
								active={activeQuestionnaire ?? ''}
								onClick={title => {
									setActiveSection(undefined);
									setActiveDrivingSlipCategory('');
									setActiveQuestionnaire(title);
								}}
							>
								<div className="border-b-1 flex w-full border-gray-500 p-2">
									<div className="border-1 m-2 w-1/2 border-gray-500 p-2">
										<Dropdown
											title="questionnaire.drivingSlipCategory"
											required
											name="drivingSlipCategory"
											data={[{ value: '', label: '' }].concat(filteredDrivingSlipCategories)}
											value={activeDrivingSlipCategory}
											onChange={e => setActiveDrivingSlipCategory(e.target.value)}
										/>

										<Button primary className="mt-2" onClick={handleAddDrivingSlipCategory}>
											{t('common.add')}
										</Button>
									</div>
									<div className="border-1 m-2 w-1/2 border-gray-500 p-2">
										<p className="text-blue font-semibold">{t('questionnaire.drivingSlipCategory')}:</p>
										{activeQuestionnaireObject && activeQuestionnaireObject.drivingSlipCategories && activeQuestionnaireObject.drivingSlipCategories.length > 0 && (
											<ul>
												{activeQuestionnaireObject.drivingSlipCategories.map(code => {
													const drivingslipCategory = drivingSlipCategoryData?.drivingSlipCategories.find(dsc => dsc.code === code);

													return (
														<li key={code}>
															<FontAwesomeIcon
																icon={faTimes}
																className="text-red mr-2 cursor-pointer"
																onClick={() => handleRemoveDrivingSlipCategory(drivingslipCategory?.code)}
																title={t('common.delete')}
															/>
															{drivingslipCategory?.name}
														</li>
													);
												})}
											</ul>
										)}

										{(!activeQuestionnaireObject || !activeQuestionnaireObject.drivingSlipCategories || activeQuestionnaireObject.drivingSlipCategories.length === 0) && (
											<p>{t('common.none')}</p>
										)}
									</div>
								</div>

								{questionnaires.map(
									(q, qIndex) =>
										q.label === activeQuestionnaire && (
											<DrivingSlipQuestionnaireTab
												key={qIndex}
												sections={q.sections}
												activeSection={activeSection}
												onChangeChoices={(sectionName: string, updatedChoices: Choice[]) => handleOnChangeChoices(q.label, sectionName, updatedChoices)}
												onChangeSections={(updatedSections: Section[], sectionName: string | undefined) => handleOnChangeSections(q.label, updatedSections, sectionName)}
											/>
										),
								)}
							</Tabs>
						)}
						{!loadingLatestQuestionnaireTemplate && questionnaires.length === 0 && (
							<>
								<p>{t('questionnaire.noTabsCreated')}</p>
								<Button primary onClick={() => openQuestionnairesEditor()} className="mt-2">
									{t('questionnaire.tabsEditor')}
								</Button>
							</>
						)}
					</Box>
				</div>
			</div>
			{visibleQuestionnairesEditor && (
				<ItemManagerModal
					visible={visibleQuestionnairesEditor}
					title={t('questionnaire.tabsEditor')}
					items={questionnaireAsItems}
					newItemInputPlaceholder={t('questionnaire.tabName')}
					disableDragAndDrop={true} // Tabs shouldn't be re-ordered
					onClose={handleOnCloseQuestionnairesEditor}
					onSubmit={handleOnSubmitQuestionnairesEditor}
				/>
			)}
		</>
	);
};

export default DrivingSlipQuestionnaireOverview;
