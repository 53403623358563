import React from 'react';
import { useTranslation } from 'react-i18next';
import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { tokenizeStringWithQuotesBySpaces } from '../../../helper';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import { useGetVehiclesQuery } from '@ssg/common/GraphQL/indexV2';
import useArrayToggler from '@ssg/common/Hooks/useArrayToggler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialsPrintLabel from './MaterialsPrintLabel';
import MaterialsFilters from './MaterialsFilter';

interface MaterialFilter {
	vehicleNumber: string[];
	registrationNumber: string[];
}

const Materials: React.FC = () => {
	const { t } = useTranslation();
	const [bulkPrint, setBulkPrint] = React.useState(false);
	const [selectedRowsBatches, setSelectedRowsBatches] = React.useState<string[][]>([]);

	const [selectedRows, toggleSelectedRows, toggleAllRows] = useArrayToggler<string>([]);
	const PRINT_LIMIT = 300;

	const { data, loading } = useGetVehiclesQuery({
		context: { debatch: true },
		variables: {
			isCar: false,

		},

	});

	React.useEffect(() => {
		if (selectedRows.length > PRINT_LIMIT) {
			const slicedArray: string[][] = [];
			for (let i = 0; i < selectedRows.length; i += PRINT_LIMIT) {
				const chunk = selectedRows.slice(i, i + PRINT_LIMIT);
				slicedArray.push(chunk);
			}
			setSelectedRowsBatches(slicedArray);
		} else {
			setSelectedRowsBatches([selectedRows]);
		}
	}, [selectedRows]);

	React.useEffect(() => console.log(selectedRowsBatches));

	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);
	const [activeLocationFilters, setActiveLocationFilters] = React.useState<MaterialFilter>({
		vehicleNumber: [],
		registrationNumber: [],
	});

	const vehicles = React.useMemo(() => data?.vehicles ?? [], [data?.vehicles]);
	const activeFilteredVehicles = React.useMemo(
		() =>
			vehicles
				.filter(l => {
					return activeLocationFilters.vehicleNumber.length > 0 ? activeLocationFilters.vehicleNumber.flatMap(l => l).includes(l.vehicleNumber) : true;
				})
				.filter(l => {
					return activeLocationFilters.registrationNumber.length > 0 ? activeLocationFilters.registrationNumber.flatMap(l => l).includes(l.registrationNumber) : true;
				}),
		[
			vehicles,
			activeLocationFilters.vehicleNumber,
			activeLocationFilters.registrationNumber,
		],
	);

	const fuse = React.useMemo(
		() =>
			new Fuse(activeFilteredVehicles, {
				shouldSort: true,
				threshold: 0.1,
				keys: ['vehicleNumber', 'registrationNumber', 'locationCode', 'departmentCode'],
			}),
		[activeFilteredVehicles],
	);

	const filteredLocations = React.useMemo(() => {

		return searchTerm.length > 0
			? fuse
				.search({
					$and: tokenizeStringWithQuotesBySpaces(searchTerm).map((searchToken: string) => {
						const orFields: Fuse.Expression[] = [
							{ vehicleNumber: searchToken },
							{ 'registrationNumber': searchToken },
							{ 'locationCode': searchToken },
							{ 'departmentCode': searchToken },
						];

						return {
							$or: orFields,
						};
					}),
				})
				.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
				.map(v => v.item)
			: activeFilteredVehicles;
	}, [activeFilteredVehicles, fuse, searchTerm]);

	const isAllSelected = React.useMemo((): boolean => {
		const filteredMovablesLength = filteredLocations.length;
		const filteredMovablesIds = filteredLocations.map(m => m.vehicleNumber);
		const filteredMovablesInSelectedRowsLength = selectedRows.filter(r => filteredMovablesIds.includes(r)).length;

		return filteredMovablesLength === filteredMovablesInSelectedRowsLength;
	}, [filteredLocations, selectedRows]);

	return (
		<div>
			<header className="flex">
				<div className="w-1/2">
					<MaterialsFilters setSearchTerm={setSearchTerm} setBulkPrint={setBulkPrint} bulkPrint={bulkPrint} />
				</div>

			</header>

			<div className="text-blue relative bg-white pb-1">
				<div className="mt-2 flex flex-row space-x-2">
					{bulkPrint &&
						selectedRowsBatches.map((batch, i) => (
							<MaterialsPrintLabel
								key={'batch' + i}
								movables={filteredLocations.filter(m => batch.includes(m.vehicleNumber))}
								buttonText={
									selectedRowsBatches.length > 1
										? `${t('movablesLocations.printSelected')} (${i * PRINT_LIMIT + 1} - ${batch.length % PRINT_LIMIT !== 0 ? batch.length + PRINT_LIMIT * i : PRINT_LIMIT + i * PRINT_LIMIT
										})`
										: `${t('movablesLocations.printSelected')} (${batch.length})`
								}
								disabled={selectedRows.length === 0}
							/>
						))}
				</div>
				{loading ? (
					<div className="h-40">
						<Loading />
					</div>
				) : (
					<Table
						data={filteredLocations ?? []}
						onRowClick={bulkPrint ? m => toggleSelectedRows(m.vehicleNumber) : undefined}
						keySelector={m => m.vehicleNumber}
						columns={[
							!bulkPrint
								? {
									label: 'movablesLocations.print',
									selectFn: m => <MaterialsPrintLabel movables={[m]} />,
								}
								: {
									label: '',
									selectFn: m => (
										<div className="">{selectedRows.includes(m.vehicleNumber) ? <FontAwesomeIcon icon={faCheckSquare} size="lg" /> : <FontAwesomeIcon icon={faSquare} size="lg" />}</div>
									),

									icon: isAllSelected ? faCheckSquare : faSquare,

									actionFn: () => toggleAllRows([...filteredLocations.map(m => m.vehicleNumber)]),
								},
							{
								label: 'vehicles.vehicleNumber',
								selectFn: l => <p className="font-semibold">{l.vehicleNumber}</p>,
								sortFn: (a, b) => (a.vehicleNumber ?? '').localeCompare(b.vehicleNumber ?? ''),
							},
							{
								label: 'common.description',
								selectFn: l => (
									<p>
										{l.brand}
									</p>
								),
								sortFn: (a, b) => (a.brand ?? '').localeCompare(b.brand ?? ''),
							},
							{
								label: 'vehicles.registrationNumber',
								numeric: true,
								selectFn: l => (
									<p>
										{l.registrationNumber}
									</p>
								),
								sortFn: (a, b) => (a.registrationNumber ?? '').localeCompare(b.registrationNumber ?? ''),
							},
							{
								label: 'common.location',
								selectFn: l => <p className="font-semibold">{l.locationCode}</p>,
								sortFn: (a, b) => (a.locationCode ?? '').localeCompare(b.locationCode ?? ''),
							},
							{
								label: 'vehicles.departmentCode',
								selectFn: l => <p className="font-semibold">{l.departmentCode}</p>,
								sortFn: (a, b) => (a.departmentCode ?? '').localeCompare(b.departmentCode ?? ''),
							},


						]}
					/>
				)}
			</div>


		</div>
	);
};

export default Materials;
